import { useEffect, useState } from "react";
import { getFbClientId } from "../libraries/ercLibrary";

// DETECT FACEBOOK CLIENT ID(_fbp cookie)
export default function useFbc() {
  // DETECT AN _fbc IF ANY
  const [fbc, setFbc] = useState("");
  useEffect(() => {
    const clientID = getFbClientId();
    if (clientID) {
      setFbc(clientID);
    }
  }, []);
  return fbc;
}
