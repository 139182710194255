import { CART_ADD_SKU_ITEM, CART_EMPTY_SKU } from "../constants/cartConstants";

export function undoDiscountsInCart(dispatch) {
  const cartSkuItems = JSON.parse(localStorage.getItem("cartSkuItems"));

  const revisedItems = cartSkuItems.map((item) => {
    const numQty = Number(item.qty);

    const originalItemsTotalPrice = item.priceForOne * numQty;

    return {
      ...item,
      price: item.price === 0 ? 0 : item.priceForOne,
      itemsTotalPrice: item.price === 0 ? 0 : originalItemsTotalPrice,
    };
  });

  dispatch({ type: CART_EMPTY_SKU });

  localStorage.setItem("cartSkuItems", JSON.stringify(revisedItems));

  revisedItems.forEach((item) => {
    dispatch({ type: CART_ADD_SKU_ITEM, payload: item });
  });
}
