import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToInitiateIyzicoThreeDsPayment = createAsyncThunk(
  "initiateIyzicoThreeDsPayment",
  async (bodyData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/iyzico/initiateIyzicoThreeDsPayment",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  threeDSHtmlContent: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const initiateIyzicoThreeDsPaymentSlice = createSlice({
  name: "initiateIyzicoThreeDsPayment",
  initialState,
  reducers: {
    resetInitiateIyzicoThreeDsPayment: (state) => {
      state.threeDSHtmlContent = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToInitiateIyzicoThreeDsPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToInitiateIyzicoThreeDsPayment.fulfilled,
        (state, action) => {
          state.loading = false;
          state.threeDSHtmlContent = action.payload;
        }
      )
      .addCase(
        actionToInitiateIyzicoThreeDsPayment.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetInitiateIyzicoThreeDsPayment } =
  initiateIyzicoThreeDsPaymentSlice.actions;

// Export the reducer
const reducerToInitiateIyzicoThreeDsPayment =
  initiateIyzicoThreeDsPaymentSlice.reducer;

// Export the async action creator for use in components
export {
  reducerToInitiateIyzicoThreeDsPayment,
  resetInitiateIyzicoThreeDsPayment,
};
