import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetDetailsWholeSaleAccount = createAsyncThunk(
  "wholeSale/jobInActionToGetDetailsWholeSaleAccount",
  async (id, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token;

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/wholesale/getDetailsWholeSaleAccount",
        {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  detailsWholeSaleAccount: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getDetailsWholeSaleAccountSlice = createSlice({
  name: "getDetailsWholeSaleAccount",
  initialState,
  reducers: {
    resetGetDetailsWholeSaleAccount: (state) => {
      state.detailsWholeSaleAccount = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetDetailsWholeSaleAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToGetDetailsWholeSaleAccount.fulfilled,
        (state, action) => {
          state.loading = false;
          state.detailsWholeSaleAccount = action.payload;
        }
      )
      .addCase(actionToGetDetailsWholeSaleAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetGetDetailsWholeSaleAccount } =
  getDetailsWholeSaleAccountSlice.actions;

// Export the reducer
const reducerToGetDetailsWholeSaleAccount =
  getDetailsWholeSaleAccountSlice.reducer;

// Export the async action creator for use in components
export { reducerToGetDetailsWholeSaleAccount, resetGetDetailsWholeSaleAccount };
