import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useWidth from "../../../../hooks/useWidth";
import { stylerDecimalPart } from "../../../../utils/stylerDecimalPart";

export default function FreeGoodsDisplay(props) {
  const history = useHistory();
  const locationPathname = useLocation().pathname;
  const width = useWidth();
  const freeGoodsAllocationInAction = JSON.parse(
    localStorage.getItem("orderHasFreeGoodsAllocation")
  )?.freeGoodsAllocation;

  // PROPS COMING FROM NavigationTop
  const {
    ordersInfo,
    freeGoodsAllocationActive,
    setFreeGoodsAllocationActive,
  } = props;

  // HANDLE TOGGLE freeGoodsAllocationActive/passive
  function handleTogglefreeGoodsAllocation() {
    localStorage.removeItem("orderHasFreeGoodsAllocation");
  }

  // STYLES
  const passiveStyle = {
    position: "absolute",
    top: width <= 600 ? "5px" : "6px",
    left: width <= 600 ? "6px" : "6px",
    fontSize: width <= 600 ? "16px" : "16px",
    fontWeight: "bold",
    color: "white",
  };
  const activeStyle = {
    position: "absolute",
    top: "6px",
    left: "8px",
    fontSize: "16px",
    color: "white",
  };

  return (
    <>
      <div
        className={`row-center-nowrap  ${
          freeGoodsAllocationActive ? "greenbcklight" : "padq lighterredbck"
        } shadow-light blink-effect-3 pointer`}
      >
        {ordersInfo?.freeGoodsAllocation > 0 && (
          <div
            className={`${
              freeGoodsAllocationActive ? "lighterredbck" : "greenbcklight-1"
            } col marq h2r shadow-light`}
            onClick={handleTogglefreeGoodsAllocation}
          >
            {!freeGoodsAllocationActive && (
              <div
                title="Mal Fazlası Kullanmaya Başlayın"
                className="relative"
                onClick={() =>
                  ordersInfo?.freeGoodsAllocation > 0 &&
                  history.push("/toptan-siparislerim")
                }
              >
                <i className="fs15 fas fa-circle green padhq"></i>
                <i className="far fa-plug" style={activeStyle}></i>
              </div>
            )}
            {freeGoodsAllocationActive && (
              <div
                title="Mal Fazlası Kullanımını Durdur"
                className="relative"
                onClick={() => setFreeGoodsAllocationActive(null)}
              >
                <i className="fs15 fas fa-circle red-col padhq"></i>
                <i className="far fa-power-off" style={passiveStyle}></i>
              </div>
            )}
          </div>
        )}
        <div
          onClick={() =>
            ordersInfo?.freeGoodsAllocation > 0 &&
            history.push("/toptan-siparislerim")
          }
        >
          <div className={`row-center marb-q beddo-border-bottom`}>
            {ordersInfo?.freeGoodsAllocation > 0 && (
              <div className="col money-font deep-green fs1 ">
                <p className="padq">
                  {parseInt(
                    freeGoodsAllocationInAction
                      ? freeGoodsAllocationInAction
                      : ordersInfo?.freeGoodsAllocation
                  )}
                  .
                  {stylerDecimalPart(
                    freeGoodsAllocationInAction
                      ? freeGoodsAllocationInAction
                      : ordersInfo?.freeGoodsAllocation
                  )}{" "}
                  TL
                </p>
              </div>
            )}
            <div className="col z-1">
              <i className="fas fa-money-bill-alt fs125 dolar-green marb-q">
                <i className="fas fa-coins gold-color"></i>
              </i>
            </div>
          </div>
          {ordersInfo?.freeGoodsAllocation > 0 && (
            <p className="purple fs-85 txtcenter">
              {`${
                freeGoodsAllocationActive
                  ? "Mal Fazlası Modu Aktif"
                  : `${
                      locationPathname.includes("/toptan-siparislerim")
                        ? "Aşağıdan Mal Fazlası Seç"
                        : "Mal Fazlası Modu Pasif"
                    }`
              }`}
            </p>
          )}
        </div>
      </div>
    </>
  );
}
