import { useEffect, useState } from "react";

export function useOrderLimitThresholds(limits, cartTotalVatExcluded) {
  const [isMinOrderLimitReached, setIsMinOrderLimitReached] = useState(false);
  const [levelPercentages, setLevelPercentages] = useState({
    levelOne: "0",
    levelTwo: "0",
    levelThree: "0",
  });

  // DETECT IF MINIMUM ORDER LIMIT REACHED
  useEffect(() => {
    if (cartTotalVatExcluded > 0 && limits) {
      const minOrderLimit =
        limits.purchaseLevelOne.amount - cartTotalVatExcluded;

      if (minOrderLimit < 0) {
        setIsMinOrderLimitReached(true);
      } else {
        setIsMinOrderLimitReached(false);
      }
    }
  }, [limits, cartTotalVatExcluded]);

  // CALCULATE LEVEL PERCENTAGES
  useEffect(() => {
    if (cartTotalVatExcluded > 0 && limits) {
      const purchaseLevelOne = limits.purchaseLevelOne.amount;
      const purchaseLevelTwo = limits.purchaseLevelTwo.amount;
      // const purchaseLevelThree = limits.purchaseLevelThree.amount;

      // LEVEL ONE
      if (cartTotalVatExcluded <= purchaseLevelOne) {
        const levelOne = (
          (cartTotalVatExcluded / limits.purchaseLevelOne.amount) *
          100
        ).toFixed(0);

        setLevelPercentages({
          levelOne: levelOne > 100 ? "100" : levelOne < 0 ? 0 : levelOne,
          levelTwo: 0,
          levelThree: 0,
        });

        // LEVEL TWO
      } else if (
        cartTotalVatExcluded > purchaseLevelOne &&
        cartTotalVatExcluded < purchaseLevelTwo
      ) {
        const levelTwo = (
          ((cartTotalVatExcluded - limits.purchaseLevelOne.amount) /
            (limits.purchaseLevelTwo.amount - limits.purchaseLevelOne.amount)) *
          100
        ).toFixed(0);

        setLevelPercentages({
          levelOne: "100",
          levelTwo: levelTwo > 100 ? "100" : levelTwo < 0 ? 0 : levelTwo,
          levelThree: "0",
        });

        // LEVEL THREE
      } else if (cartTotalVatExcluded > purchaseLevelTwo) {
        const levelThree = (
          ((cartTotalVatExcluded - limits.purchaseLevelTwo.amount) /
            (limits.purchaseLevelThree.amount -
              limits.purchaseLevelTwo.amount)) *
          100
        ).toFixed(0);

        setLevelPercentages({
          levelOne: "100",
          levelTwo: "100",
          levelThree:
            levelThree > 100 ? "100" : levelThree < 0 ? 0 : levelThree,
        });
      }
    }
  }, [limits, cartTotalVatExcluded]);

  return { isMinOrderLimitReached, levelPercentages };
}
