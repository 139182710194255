import {
  ADMIN_VARIANT_CREATE_FAIL,
  ADMIN_VARIANT_CREATE_REQUEST,
  ADMIN_VARIANT_CREATE_RESET,
  ADMIN_VARIANT_CREATE_SUCCESS,
  ADMIN_VARIANT_DETAILS_FAIL,
  ADMIN_VARIANT_DETAILS_REQUEST,
  ADMIN_VARIANT_DETAILS_SUCCESS,
  ADMIN_VARIANT_EDIT_FAIL,
  ADMIN_VARIANT_EDIT_REQUEST,
  ADMIN_VARIANT_EDIT_RESET,
  ADMIN_VARIANT_EDIT_SUCCESS,
  ADMIN_VARIANT_LIST_FAIL,
  ADMIN_VARIANT_LIST_REQUEST,
  ADMIN_VARIANT_LIST_SUCCESS,
} from "../constants/adminVariantConstants";

//SAVE THE CREATED VARIANT IN REDUX STATE
export const reducerAdminToCreateVariant = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_VARIANT_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_VARIANT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        variant: action.payload,
      };
    case ADMIN_VARIANT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_VARIANT_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF VARIANTS IN REDUX STATE
export function reducerAdminToListVariants(
  state = { loading: true, variantsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_VARIANT_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_VARIANT_LIST_SUCCESS:
      return {
        loading: false,
        variantsList: action.payload,
      };
    case ADMIN_VARIANT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF VARIANT IN REDUX STATE
export function reducerAdminToDetailsVariant(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_VARIANT_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_VARIANT_DETAILS_SUCCESS:
      return {
        loading: false,
        variantDetails: action.payload,
      };
    case ADMIN_VARIANT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF VARIANT IN REDUX STATE
export function reducerAdminToEditVariant(state = { success: false }, action) {
  switch (action.type) {
    case ADMIN_VARIANT_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_VARIANT_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_VARIANT_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_VARIANT_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}
