import { useEffect, useState } from "react";

export function useFbPixelFire(cartSkuItems, cartTotal) {
  // FACEBOOK PIXEL FIRED
  const [isItFired, setIsItFired] = useState(true);
  useEffect(() => {
    if (cartSkuItems.length > 0 && isItFired) {
      // FACEBOOK PIXEL BASED EVENT TRIGGER
      window.fbq("track", "AddToCart", {
        value: cartTotal?.totalAmount,
        currency: "TRY",
      });
    }
    return () => {
      setIsItFired(false);
    };
  }, [isItFired, cartSkuItems, cartTotal?.totalAmount]);
}
