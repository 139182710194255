import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToUpdateWholeSaleOrderStatus = createAsyncThunk(
  "wholeSale/orderStatus",
  async ({ id, statusData, ip, price, paymentId }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState()?.sendSecurityCodeToSignIn?.authenticatedUser?.token;

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/updateWholeSaleOrderStatus",
        { id, statusData, ip, price, paymentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  updatedWholeSaleOrder: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleOrderStatusSlice = createSlice({
  name: "updateWholeSaleAccount",
  initialState,
  reducers: {
    resetUpdateWholeSaleOrderStatus: (state) => {
      state.updatedWholeSaleOrder = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateWholeSaleOrderStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToUpdateWholeSaleOrderStatus.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updatedWholeSaleOrder = action.payload;
        }
      )
      .addCase(actionToUpdateWholeSaleOrderStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetUpdateWholeSaleOrderStatus } =
  updateWholeSaleOrderStatusSlice.actions;

// Export the reducer
const reducerToUpdateWholeSaleOrderStatus =
  updateWholeSaleOrderStatusSlice.reducer;

// Export the async action creator for use in components
export { reducerToUpdateWholeSaleOrderStatus, resetUpdateWholeSaleOrderStatus };
