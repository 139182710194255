import { useEffect, useState } from "react";

export function useCalculateDiscountEndTime(remainingTime) {
  const [displayDate, setDisplayDate] = useState();

  useEffect(() => {
    if (remainingTime) {
      const currentMiliseconds = Date.now();
      const discountEndsMilisecons = currentMiliseconds + remainingTime;
      const date = new Date(discountEndsMilisecons);
      const days = [
        "Pazar",
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
      ];
      const formattedDate = `${days[date.getDay()]} saat ${date.getHours()}:${
        date.getMinutes() < 10 ? "0" : ""
      }${date.getMinutes()}`;

      setDisplayDate(formattedDate);
    }
  }, [remainingTime, setDisplayDate]);

  return displayDate;
}
