import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteItem } from "../actions/actionToAddOrRemoveWholeSaleItemInCart";

export function useRemoveItemsMissingFreeProduct() {
  const dispatch = useDispatch();

  const addOrRemoveWholeSaleItemInCart = useSelector(
    (state) => state.addOrRemoveWholeSaleItemInCart
  );
  const { wholeSaleCartItems } = addOrRemoveWholeSaleItemInCart;

  useEffect(() => {
    if (wholeSaleCartItems.length > 0) {
      const freeProviders = wholeSaleCartItems.filter(
        (item) => item.buyOneGetOneFree
      );

      if (freeProviders.length > 0) {
        const freeItemMissing = freeProviders.filter(
          (item) => item.freeProducts.length === 0
        );

        if (freeItemMissing.length > 0) {
          freeItemMissing.forEach((item) => {
            dispatch(deleteItem(item.skuId));
          });
        }
      }
    }
  }, [dispatch, wholeSaleCartItems]);
}
