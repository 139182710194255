import {
  ADMIN_SMSCAMPAIGN_CREATE_FAIL,
  ADMIN_SMSCAMPAIGN_CREATE_REQUEST,
  ADMIN_SMSCAMPAIGN_CREATE_RESET,
  ADMIN_SMSCAMPAIGN_CREATE_SUCCESS,
  ADMIN_SMSCAMPAIGN_DETAILS_FAIL,
  ADMIN_SMSCAMPAIGN_DETAILS_REQUEST,
  ADMIN_SMSCAMPAIGN_DETAILS_RESET,
  ADMIN_SMSCAMPAIGN_DETAILS_SUCCESS,
  ADMIN_SMSCAMPAIGN_FILTER_FAIL,
  ADMIN_SMSCAMPAIGN_FILTER_REQUEST,
  ADMIN_SMSCAMPAIGN_FILTER_RESET,
  ADMIN_SMSCAMPAIGN_FILTER_SUCCESS,
  ADMIN_SMSCAMPAIGN_LIST_FAIL,
  ADMIN_SMSCAMPAIGN_LIST_REQUEST,
  ADMIN_SMSCAMPAIGN_LIST_RESET,
  ADMIN_SMSCAMPAIGN_LIST_SUCCESS,
  ADMIN_SMSCAMPAIGN_REPORT_FAIL,
  ADMIN_SMSCAMPAIGN_REPORT_REQUEST,
  ADMIN_SMSCAMPAIGN_REPORT_RESET,
  ADMIN_SMSCAMPAIGN_REPORT_SUCCESS,
  ADMIN_SMSCAMPAIGN_START_FAIL,
  ADMIN_SMSCAMPAIGN_START_REQUEST,
  ADMIN_SMSCAMPAIGN_START_RESET,
  ADMIN_SMSCAMPAIGN_START_SUCCESS,
  USER_SMSCAMPAIGN_CANCEL_FAIL,
  USER_SMSCAMPAIGN_CANCEL_REQUEST,
  USER_SMSCAMPAIGN_CANCEL_RESET,
  USER_SMSCAMPAIGN_CANCEL_SUCCESS,
} from "../constants/adminSmsCampaignConstants";

//SAVE THE CREATED SUPPLIER IN REDUX STATE
export const reducerAdminSmsCampaignCreate = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdCampaign: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_CREATE_RESET:
      return { success: false };
    default:
      return state;
  }
};

//ADMIN FILTERS CUSTOMERS FOR SMS CAMPAIGN
export const reducerAdminFilterCustomersForSmsCampaign = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_FILTER_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_FILTER_SUCCESS:
      return {
        loading: false,
        contacts: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_FILTER_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_FILTER_RESET:
      return {};
    default:
      return state;
  }
};

//ADMIN LIST SMS CAMPAIGNS
export const reducerAdminListSmsCampaigns = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_LIST_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_LIST_SUCCESS:
      return {
        loading: false,
        campaigns: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_LIST_RESET:
      return {};
    default:
      return state;
  }
};

//GET
//ADMIN VIEW SMS CAMPAIGN DETAILS
export const reducerAdminViewSmsCampaignDetails = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_DETAILS_SUCCESS:
      return {
        loading: false,
        campaignDetails: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

//POST
//ADMIN START SMS CAMPAIGN
export const reducerAdminStartSmsCampaign = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_START_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_START_SUCCESS:
      return {
        loading: false,
        result: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_START_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_START_RESET:
      return {};
    default:
      return state;
  }
};

//GET
//ADMIN GET SMS CAMPAIGN DELIVERY REPORT
export const reducerAdminGetSmsCampaignReport = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SMSCAMPAIGN_REPORT_REQUEST:
      return { loading: true };
    case ADMIN_SMSCAMPAIGN_REPORT_SUCCESS:
      return {
        loading: false,
        report: action.payload,
      };
    case ADMIN_SMSCAMPAIGN_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SMSCAMPAIGN_REPORT_RESET:
      return {};
    default:
      return state;
  }
};

//POST
//USER DISALLOW SMS CAMPAIGN
export const reducerUserRemoveItselfFromSmsCampaignList = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case USER_SMSCAMPAIGN_CANCEL_REQUEST:
      return { loading: true };
    case USER_SMSCAMPAIGN_CANCEL_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_SMSCAMPAIGN_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case USER_SMSCAMPAIGN_CANCEL_RESET:
      return {};
    default:
      return state;
  }
};
