import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToUpdateSkuWithHepsiBuradaInfo = createAsyncThunk(
  "jobInActionToUpdateSkuWithHepsiBuradaInfo",
  async (skuData, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/hepsiBurada/updateSkuWithHepsiBuradaInfo",
        { skuData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const updateSkuWithHepsiBuradaInfo = createSlice({
  name: "sliceToUpdateSkuWithHepsiBuradaInfo",
  initialState: {
    isUpdated: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetUpdateSkuWithHepsiBuradaInfoIsUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateSkuWithHepsiBuradaInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actionToUpdateSkuWithHepsiBuradaInfo.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.isUpdated = true;
        }
      )
      .addCase(
        actionToUpdateSkuWithHepsiBuradaInfo.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetUpdateSkuWithHepsiBuradaInfoIsUpdated } =
  updateSkuWithHepsiBuradaInfo.actions;

const reducerToUpdateSkuWithHepsiBuradaInfo =
  updateSkuWithHepsiBuradaInfo.reducer;

export default reducerToUpdateSkuWithHepsiBuradaInfo;
