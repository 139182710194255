import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import useWidth from "../../../hooks/useWidth";
import "../../../index.css";
import LoadingBoxWithoutText from "./LoadingBoxWithoutText";
import MessageBox from "./MessageBox";

export default function ErcModal(props) {
  const width = useWidth();
  const contentRef = useRef();
  const printRef = useRef();

  // PROPS - "ErcModal"
  const {
    modalFrontWidth,
    modalFrontHeight,
    modalFrontBorder,
    modalFrontBackgroundColor,
    modalTitle,
    modalTitleDivWidth,
    modalTitleFontSize,
    modalTitleLineHeight,
    modalTitleColor,
    modalTitleUnderline,
    contentTitleFontSize,
    contentTitleMargin,
    contentTitle,
    content, // ARRAY
    positionFixedContent, // ARRAY
    modalContentMargin,
    modalContentHeight,
    modalParagBorderBottom,
    modalParagFontSize,
    images,
    disableModalTitle,
    success,
    popOnMount,
    showCloseIcon,
    closeOnClick, // Set a state in parent element and pass state to erc modal
    openOnClick,
    isUpsellCard,
    clickOutsideShouldWork,
    callThisFunctionInCloseModalFunction,
    callThisFunctionOnPopUp,
    disableToggle,
    loading,
    error,
    successMessage,
    isPrintable, // If true, a print icon appears next to close icon
    printableContent,
  } = props;

  // STATE TO MANAGE MODAL
  const [ercModalOpen, setErcModalOpen] = useState(false); // This state renders modal and remove modal
  const [isClickedToClose, setIsClickedToClose] = useState(false); // This state detects if user clicked to close the modal and run the css transition

  // IF success PROP IS TRUE CLOSE MODAL
  useEffect(() => {
    if (success) {
      // setErcModalOpen(false);
      setIsClickedToClose(true);
    }

    return () => setIsClickedToClose(false);
  }, [success]);

  // FUNCTION TO IMPLEMENT "ClickOutsideToClose" FUNCTIONALITY
  const refModal = useRef(null);
  function handleClickOutsideErcModal(event) {
    if (
      refModal.current &&
      !refModal.current.contains(event.target) &&
      (clickOutsideShouldWork || clickOutsideShouldWork === undefined) &&
      ercModalOpen
    ) {
      // setErcModalOpen(false);
      setIsClickedToClose(true);
      callThisFunctionInCloseModalFunction &&
        callThisFunctionInCloseModalFunction();
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideErcModal, false);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideErcModal,
        false
      );
      setIsClickedToClose(false);
    };
  });

  // FUNCTION TO IMPLEMENT TOGGLING "ErcModal"

  function handleToggleModal() {
    if (!isClickedToClose) {
      setErcModalOpen(true);
    }

    if (ercModalOpen) {
      // Wait for the transition to complete before setting ercModalOpen to false
      const modalFrontElement = document.getElementById("modalFront");
      modalFrontElement?.addEventListener(
        "transitionend",
        () => {
          callThisFunctionInCloseModalFunction &&
            callThisFunctionInCloseModalFunction();
        },
        { once: true }
      );
    }
  }

  // POP MODAL AUTO BASED ON popOnMount PARAMETER
  useEffect(() => {
    if (popOnMount === "OPEN") {
      setErcModalOpen(true);
    } else if (popOnMount === "CLOSE") {
      // setErcModalOpen(false);
      setIsClickedToClose(true);
    }

    return () => setIsClickedToClose(false);
  }, [popOnMount]);

  // CLOSE ON CLICK OF A BUTTON THAT IS IN CONTENT SECTION
  useEffect(() => {
    if (closeOnClick) {
      // setErcModalOpen(false);
      setIsClickedToClose(true);
    } else if (openOnClick) {
      setErcModalOpen(true);
    }

    return () => setIsClickedToClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOnClick, openOnClick]);

  useEffect(() => {
    if (isClickedToClose) {
      setTransition({ ...stylingErcModal.modalFront, opacity: 0 }); // If user clicks to close modal, transition is set to opacity

      // Wait for the transition to complete before setting ercModalOpen to false
      const modalFrontElement = document.getElementById("modalFront");
      modalFrontElement?.addEventListener(
        "transitionend",
        () => {
          setErcModalOpen(false);
          setTransition(null); // Reset transition to initial state
        },
        { once: true }
      );
    }

    return () => setIsClickedToClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedToClose]);

  // CALL THIS FUNCTION ON MOUNT
  useEffect(() => {
    callThisFunctionOnPopUp && callThisFunctionOnPopUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // STYLE ErcModal
  let stylingErcModal = {
    modalTitle: {
      cursor: "pointer",
      fontSize: modalTitleFontSize ? modalTitleFontSize : "1.25rem",
      lineHeight: modalTitleLineHeight ? modalTitleLineHeight : "1.5rem",
      color: modalTitleColor ? modalTitleColor : "",
      borderBottom: modalTitleUnderline ? modalTitleUnderline : "",
      width: modalTitleDivWidth ? modalTitleDivWidth : "",
    },
    modal: {
      backgroundColor: "rgb(0, 0, 0, 0.75)",
      float: "right",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "100vh",
      position: "fixed",
      zIndex: "1002",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalFront: {
      position: "relative",
      margin: "0.5rem",
      width:
        width < 800
          ? "95%"
          : isUpsellCard && width >= 800
          ? "35%"
          : modalFrontWidth
          ? modalFrontWidth
          : "35%",
      maxHeight: modalFrontHeight
        ? modalFrontHeight
        : isUpsellCard && width < 600
        ? "70%"
        : "95%",
      borderRadius: "0.5rem",
      border: modalFrontBorder ? modalFrontBorder : "",
      backgroundColor: modalFrontBackgroundColor
        ? modalFrontBackgroundColor
        : "white",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      transition: "opacity 0.5s ease-in-out", // Add a transition property
      opacity: 0, // Initially set opacity to 0
    },
    modalHeader: {
      width: "95%",
      marginTop: "0.5rem",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid rgb(255, 153, 53, 0.25)",
    },
    contentTitle: {
      fontSize: contentTitleFontSize ? contentTitleFontSize : "1.5rem",
      textAlign: "center",
      color: "black",
      margin: `${
        contentTitleMargin ? contentTitleMargin : "2.5rem"
      } 1rem 1rem 1rem`,
    },
    modalContent: {
      width: "95%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      margin: modalContentMargin ? modalContentMargin : "1rem",
      height: modalContentHeight ? modalContentHeight : "auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
    modalParag: {
      width: "100%",
      color: "black",
      marginBottom: "0.5rem",
      fontSize: modalParagFontSize ? modalParagFontSize : "1.35rem",
      lineHeight: "1.75rem",
      borderBottom: modalParagBorderBottom ? modalParagBorderBottom : "",
      textAlign: "left",
    },
    image: {
      width: "100%",
      marginBottom: "1rem",
      borderBottom: "0.1rem solid #ff9935",
    },
  };

  // UPDATE STYLING - If user clicks to open or modal is set to open on mount, transition style is introduced
  const [transition, setTransition] = useState(null);
  useEffect(() => {
    if (ercModalOpen === true || popOnMount === "OPEN") {
      setTransition({ ...stylingErcModal.modalFront, opacity: 1 });
    }

    return () => {
      setTransition(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stylingErcModal.modalFront.opacity, ercModalOpen, popOnMount]);

  // Bring in print status from redux store
  const handlePrint = useReactToPrint({
    content: () => (printableContent ? printRef.current : contentRef.current),
    onBeforeGetContent: () => {
      if (printableContent) {
        // We do not want printable content to be in view, therefore we only make it visible just before printing
        // However this created a weird user experience that seeing a blink of the printable content and than the print preview pops
        // In order to avoid this we set zIndex -1 and position absoulute which creates a seemless shift to print preview
        printRef.current.style.display = "block";
        printRef.current.style.position = "absolute"; //
        printRef.current.style.zIndex = "-1000";
        printRef.current.style.width = "98%";
        return printRef.current;
      }
    },
    onAfterPrint: () => {
      if (printableContent) {
        printRef.current.style.display = "none";
      }
    },
  });

  return (
    <>
      <div
        style={disableModalTitle ? {} : stylingErcModal.modalTitle}
        onClick={
          disableToggle === undefined
            ? handleToggleModal
            : disableToggle
            ? null
            : handleToggleModal
        }
        ref={refModal}
      >
        {modalTitle()}
      </div>

      {ercModalOpen && (
        <div style={stylingErcModal.modal}>
          <div
            ref={refModal}
            style={transition ? transition : stylingErcModal.modalFront}
            id="modalFront" // id is assigned to listen transitionend event
          >
            <ul style={stylingErcModal.modalHeader}>
              <li>
                <img
                  className="w3r"
                  src="/images/beddo-mern-logo.png"
                  alt="beddo logosu - beddo senin huzur senin"
                />
              </li>
              <li>
                {isPrintable && (
                  <i
                    className="fas fa-print purple pointer marginright1r fs1"
                    title="Yazdır"
                    onClick={() => {
                      handlePrint();
                    }}
                  ></i>
                )}
                {(showCloseIcon || showCloseIcon === undefined) && (
                  <i
                    className="fas fa-times beddo-color pointer fs1125"
                    title="Kapat"
                    onClick={() => {
                      return [setIsClickedToClose(true), handleToggleModal()];
                    }}
                  ></i>
                )}
              </li>
            </ul>

            {contentTitle && (
              <h2 style={stylingErcModal.contentTitle}>{contentTitle}</h2>
            )}

            <div style={stylingErcModal.modalContent}>
              {images &&
                images.map((image, index) => (
                  <img
                    key={index}
                    style={stylingErcModal.image}
                    src={image}
                    alt={"beddo"}
                  />
                ))}
              {content?.length > 0 &&
                content.map((paragraph, index) => {
                  return (
                    paragraph && (
                      <div
                        key={index}
                        style={stylingErcModal.modalParag}
                        ref={contentRef}
                      >
                        {paragraph}
                      </div>
                    )
                  );
                })}
              {printableContent?.length > 0 &&
                printableContent.map((paragraph, index) => {
                  return (
                    paragraph && (
                      <div
                        key={index}
                        style={{
                          display: "none",
                        }}
                        ref={printRef}
                      >
                        {paragraph}
                      </div>
                    )
                  );
                })}
            </div>

            {positionFixedContent?.length > 0 &&
              positionFixedContent.map((fixedContent, index) => {
                return (
                  fixedContent && (
                    <div key={index} ref={contentRef}>
                      {fixedContent}
                    </div>
                  )
                );
              })}

            {loading ? (
              <LoadingBoxWithoutText />
            ) : error ? (
              <div className="marb-5 fs1">
                <MessageBox variant="danger">{error}</MessageBox>
              </div>
            ) : (
              successMessage && (
                <div className="marb-5 fs1">
                  <MessageBox variant="success">{successMessage}</MessageBox>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
}
