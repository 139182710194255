import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetIyzicoInstallmentPlan = createAsyncThunk(
  "getIyzicoInstallmentPlan",
  async (bodyData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/iyzico/getInstallmentPlan",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  installmentPlan: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getIyzicoInstallmentPlanSlice = createSlice({
  name: "iyzicoInstallmentPlan",
  initialState,
  reducers: {
    resetIyzicoInstallmentPlan: (state) => {
      state.installmentPlan = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetIyzicoInstallmentPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToGetIyzicoInstallmentPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.installmentPlan = action.payload;
      })
      .addCase(actionToGetIyzicoInstallmentPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetIyzicoInstallmentPlan } = getIyzicoInstallmentPlanSlice.actions;

// Export the reducer
const reducerToGetIyzicoInstallmentPlan = getIyzicoInstallmentPlanSlice.reducer;

// Export the async action creator for use in components
export { reducerToGetIyzicoInstallmentPlan, resetIyzicoInstallmentPlan };
