import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetHepsiBuradaEnumValues = createAsyncThunk(
  "jobInActionToGetHepsiBuradaEnumValues",
  async ({ categoryId, attributeId }, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/hepsiBurada/getHepsiBuradaEnumValues",
        {
          params: {
            categoryId,
            attributeId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getHepsiBuradaEnumValues = createSlice({
  name: "sliceToGetHepsiBuradaEnumValues",
  initialState: {
    enumValues: {}, // Object to hold values, loading, and error states for each attribute
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetHepsiBuradaEnumValues.pending, (state, action) => {
        const { attributeId } = action.meta.arg;
        state.enumValues[attributeId] = {
          loading: true,
          error: null,
          values: [],
        };
      })
      .addCase(actionToGetHepsiBuradaEnumValues.fulfilled, (state, action) => {
        const { attributeId } = action.meta.arg;
        state.enumValues[attributeId] = {
          loading: false,
          error: null,
          values: action.payload,
        };
      })
      .addCase(actionToGetHepsiBuradaEnumValues.rejected, (state, action) => {
        const { attributeId } = action.meta.arg;
        state.enumValues[attributeId] = {
          loading: false,
          error: action.error.message,
          values: [],
        };
      });
  },
});

const reducerToGetHepsiBuradaEnumValues = getHepsiBuradaEnumValues.reducer;

export default reducerToGetHepsiBuradaEnumValues;
