import Axios from "axios";
import {
  ADMIN_CREATE_TOPBAR_FAIL,
  ADMIN_CREATE_TOPBAR_REQUEST,
  ADMIN_CREATE_TOPBAR_SUCCESS,
  ADMIN_DETAILS_TOPBAR_FAIL,
  ADMIN_DETAILS_TOPBAR_REQUEST,
  ADMIN_DETAILS_TOPBAR_SUCCESS,
  ADMIN_EDIT_TOPBAR_FAIL,
  ADMIN_EDIT_TOPBAR_REQUEST,
  ADMIN_EDIT_TOPBAR_SUCCESS,
  LIST_TOPBARS_FAIL,
  LIST_TOPBARS_REQUEST,
  LIST_TOPBARS_SUCCESS,
} from "../constants/adminTopBarConstants";

// POST
// CREATE A TOP BAR
export const actionAdminToCreateTopBar =
  (topBarPreferences) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_CREATE_TOPBAR_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        "/api/admintopbar/create",
        topBarPreferences,
        {
          headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
        }
      );
      dispatch({ type: ADMIN_CREATE_TOPBAR_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_CREATE_TOPBAR_FAIL, payload: message });
    }
  };

// GET
// ADMIN LIST TOP BARS
export const actionToListTopBars = (requester) => async (dispatch) => {
  dispatch({ type: LIST_TOPBARS_REQUEST });

  try {
    const { data } = await Axios.get(
      `/api/admintopbar/listtopbars?requestedby=${requester}`
    );
    dispatch({ type: LIST_TOPBARS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: LIST_TOPBARS_FAIL, payload: message });
  }
};

//PUT
// UPDATE/EDIT TOP BAR
export const actionAdminToEditTopBar =
  (updateInfo) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_EDIT_TOPBAR_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.put(`/api/admintopbar/edit`, updateInfo, {
        headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
      });
      dispatch({ type: ADMIN_EDIT_TOPBAR_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_EDIT_TOPBAR_FAIL, payload: message });
    }
  };

// GET
// GET DETAILS OF TOP BAR
export const actionAdminGetDetailsOfTopBar =
  (topBarId) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_DETAILS_TOPBAR_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.get(
        `/api/admintopbar/detailsoftopbar/${topBarId}`,
        {
          headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
        }
      );
      dispatch({ type: ADMIN_DETAILS_TOPBAR_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_DETAILS_TOPBAR_FAIL, payload: message });
    }
  };
