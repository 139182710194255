import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToUploadAProductInTrendyol = createAsyncThunk(
  "jobInActionToUploadAProductInTrendyol",
  async (productId, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/trendyol/uploadAProductInTrendyol",
        { productId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      const { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const uploadAProductInTrendyol = createSlice({
  name: "sliceToUploadAProductInTrendyol",
  initialState: {
    isUploaded: false,
    batchRequestId: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetUploadAProductInTrendyolIsUploaded: (state) => {
      state.isUploaded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUploadAProductInTrendyol.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToUploadAProductInTrendyol.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.isUploaded = true;
        state.batchRequestId = action.payload;
      })
      .addCase(actionToUploadAProductInTrendyol.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetUploadAProductInTrendyolIsUploaded } =
  uploadAProductInTrendyol.actions;

const reducerToUploadAProductInTrendyol = uploadAProductInTrendyol.reducer;

export default reducerToUploadAProductInTrendyol;
