import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function SearchBar(props) {
  const history = useHistory();
  const [keyword, setKeyword] = useState("");

  //PROPS COMING FROM "ModalNavMenu"
  const { handleModalMenu } = props;

  //SUBMIT SEARCH KEYWORD
  function submitHandler(e) {
    e.preventDefault();
    if (handleModalMenu) {
      if (keyword.trim()) {
        handleModalMenu();
        history.push(`/ara/${keyword}`);
      } else {
        history.push(`/`);
      }
    } else {
      if (keyword.trim()) {
        //handleModalMenu();
        history.push(`/ara/${keyword}`);
      } else {
        history.push(`/`);
      }
    }
  }

  return (
    <>
      <div className="w90p mar1 beddo-border rad2-5r">
        <form className="row-nowrap w100p" onSubmit={submitHandler}>
          <input
            className="w70p lightbck h1-5r iphone-fs"
            type="text"
            name="search"
            placeholder="Ürün ara..."
            required
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button type="submit" className="search-bar-button">
            Ara
          </button>
        </form>
      </div>
    </>
  );
}
