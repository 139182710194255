import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import LoadingBox from "../../modules/shared/components/LoadingBox";
import AdminRoute from "../AdminRoute";
const AdminLeftNav = React.lazy(() =>
  import("../../components/AdminNavigation/AdminLeftNav")
);
const AdminRegisterScreen = React.lazy(() =>
  import("../../screens/AdminRegisterScreen")
);
const AdminLoginScreen = React.lazy(() =>
  import("../../screens/AdminLoginScreen")
);
const AdminPanelScreen = React.lazy(() =>
  import("../../screens/AdminPanelScreen")
);
const AdminPanelUsersScreen = React.lazy(() =>
  import("../../screens/AdminPanelUsersScreen")
);
const AdminPanelOrdersScreen = React.lazy(() =>
  import("../../modules/adminPanel/retail/AdminPanelOrdersScreen")
);
const AdminPanelUserDetailsScreen = React.lazy(() =>
  import("../../screens/AdminPanelUserDetailsScreen")
);
const AdminPanelOrderDetailsScreen = React.lazy(() =>
  import("../../screens/AdminPanelOrderDetailsScreen")
);
const AdminPanelDashboardScreen = React.lazy(() =>
  import("../../screens/AdminPanelDashboardScreen")
);
const AdminPanelProductsScreen = React.lazy(() =>
  import("../../screens/AdminProductsScreen/AdminPanelProductsScreen")
);
const AdminPanelDepartmentsScreen = React.lazy(() =>
  import("../../screens/AdminPanelDepartmentsScreen")
);
const AdminPanelProductCreateScreen = React.lazy(() =>
  import("../../screens/AdminProductsScreen/AdminPanelProductCreateScreen")
);
const AdminPanelCategoriesScreen = React.lazy(() =>
  import("../../screens/AdminPanelCategoriesScreen")
);
const AdminPanelBrandsScreen = React.lazy(() =>
  import("../../screens/AdminPanelBrandsScreen")
);
const AdminVariantsScreen = React.lazy(() =>
  import("../../screens/AdminVariants/AdminVariantsScreen")
);
const AdminPanelSuppliersScreen = React.lazy(() =>
  import("../../screens/AdminPanelSuppliersScreen")
);
const AdminPanelModelsScreen = React.lazy(() =>
  import("../../screens/AdminPanelModelsScreen")
);
const AdminPanelCategorySubsScreen = React.lazy(() =>
  import("../../screens/AdminPanelCategorySubsScreen")
);
const AdminPanelCategoryLowsScreen = React.lazy(() =>
  import("../../screens/AdminPanelCategoryLowsScreen")
);
const AdminPanelProductEditScreen = React.lazy(() =>
  import("../../screens/AdminProductsScreen/AdminPanelProductEditScreen")
);
const AdminPanelSkuEditScreen = React.lazy(() =>
  import("../../screens/AdminProductsScreen/AdminPanelSkuEditScreen")
);
const AdminPanelCourierScreen = React.lazy(() =>
  import("../../screens/AdminPanelCourierScreen")
);
const AdminPanelUpsCityListScreen = React.lazy(() =>
  import("../../screens/AdminPanelUpsCityListScreen")
);
const AdminPanelUpsCityDetailsScreen = React.lazy(() =>
  import("../../screens/AdminPanelUpsCityDetailsScreen")
);
const AdminPanelCustomerServiceScreen = React.lazy(() =>
  import("../AdminCustomerService/AdminPanelCustomerServiceScreen")
);
const AdminPanelCustomerServiceDetailsScreen = React.lazy(() =>
  import("../AdminCustomerService/AdminPanelCustomerServiceDetailsScreen")
);
const AdminPanelCustomerSupportStatusScreen = React.lazy(() =>
  import("../AdminCustomerService/AdminPanelCustomerSupportStatusScreen")
);
const AdminPanelSecuredOrderStatusScreen = React.lazy(() =>
  import("../../screens/AdminPanelSecuredOrderStatusScreen")
);
const AdminPrintOrderScreen = React.lazy(() =>
  import("../../screens/AdminPrintOrder/AdminPrintOrderScreen")
);
const CampaignScreen = React.lazy(() =>
  import("../../screens/AdminCampaign/CampaignScreen")
);
const CampaignCreateScreen = React.lazy(() =>
  import("../../screens/AdminCampaign/CampaignCreateScreen")
);
const AdminInvoiceOperationsScreen = React.lazy(() =>
  import("../../screens/AdminInvoice/AdminInvoiceOperationsScreen")
);
const DiscountOperationsScreen = React.lazy(() =>
  import("../../screens/AdminDiscount/DiscountOperationsScreen")
);

const DiscountDetailsScreen = React.lazy(() =>
  import("../../screens/AdminDiscount/DiscountDetailsScreen")
);
const AdminPopsScreen = React.lazy(() =>
  import("../../components/PopAnnouncesAndOffers/AdminPopsScreen")
);
const AdminPopDetailsScreen = React.lazy(() =>
  import("../../components/PopAnnouncesAndOffers/AdminPopDetailsScreen")
);
/*const ProductRecipeScreen = React.lazy(() =>
  import("../../screens/AdminRecipe/ProductRecipeScreen")
);
const RecipeEditScreen = React.lazy(() =>
  import("../../screens/AdminRecipe/RecipeEditScreen")
);*/
const AdminAnalyticsScreen = React.lazy(() =>
  import("../../screens/AdminAnalytics/AdminAnalyticsScreen")
);
const AdminCreateMaterialScreen = React.lazy(() =>
  import("../../screens/AdminInventory/AdminCreateMaterialScreen")
);
const AdminCreateWarehouseScreen = React.lazy(() =>
  import("../../screens/AdminInventory/AdminCreateWarehouseScreen")
);
const OrderItemsScreen = React.lazy(() =>
  import("../../screens/AdminOrderItems/OrderItemsScreen")
);
const AdminShippingMessageScreen = React.lazy(() =>
  import("../../screens/AdminShippingMessage/AdminShippingMessageScreen")
);
const SmsCampaignScreen = React.lazy(() =>
  import("../../screens/AdminSmsCampaign/SmsCampaignScreen")
);
const SmsCampaignDetailsScreen = React.lazy(() =>
  import("../../screens/AdminSmsCampaign/SmsCampaignDetailsScreen")
);
const AdminRefundCodeScreen = React.lazy(() =>
  import("../../screens/AdminRefundCode/AdminRefundCodeScreen")
);
const PrintShippingDocument = React.lazy(() =>
  import("../../screens/AdminInventory/PrintShippingDocument")
);
const AdminInventoryScreen = React.lazy(() =>
  import("../../screens/AdminInventory/AdminInventoryScreen")
);
const CurrentAccountScreen = React.lazy(() =>
  import("../../screens/AdminCurrentAccount/CurrentAccountScreen")
);
const CreateCurrentAccountScreen = React.lazy(() =>
  import("../../screens/AdminCurrentAccount/CreateCurrentAccountScreen")
);
const SalesReportInventoryActivityScreen = React.lazy(() =>
  import(
    "../../screens/AdminInventory/SalesSummary/SalesReportInventoryActivityScreen"
  )
);
const NotificationsScreen = React.lazy(() =>
  import("../../screens/AdminNotification/NotificationsScreen")
);
const OrderManagementScreen = React.lazy(() =>
  import("../../screens/AdminInventory/OrderManagement/OrderManagementScreen")
);
const PrintManagedOrderScreen = React.lazy(() =>
  import("../../screens/AdminInventory/OrderManagement/PrintManagedOrderScreen")
);
const BulkProductUpdateScreen = React.lazy(() =>
  import(
    "../../screens/AdminProductsScreen/BulkProductUpdate/BulkProductUpdateScreen"
  )
);
const QnaAdminScreen = React.lazy(() =>
  import("../../screens/Qna/QnaAdminScreen")
);
const AdminTopBarScreen = React.lazy(() =>
  import("../../screens/AdminTopBar/AdminTopBarScreen")
);
const AdminTopBarDetailsScreen = React.lazy(() =>
  import("../../screens/AdminTopBar/TopBarDetailsScreen")
);
const VisitorAnalyticsScreen = React.lazy(() =>
  import("../../screens/AdminAnalyticsVisitors/VisitorAnalyticsScreen")
);
const ProductAnalyticsScreen = React.lazy(() =>
  import("../../screens/AdminProductAnalytics/ProductAnalyticsScreen")
);
const TrendyolScreen = React.lazy(() =>
  import("../../marketplaces/trendyol/TrendyolScreen")
);
const HepsiBuradaScreen = React.lazy(() =>
  import("../../marketplaces/hepsiBurada/HepsiBuradaScreen")
);
const WholeSaleSettingsScreen = React.lazy(() =>
  import("../../modules/adminPanel/wholeSale/accounts/WholeSaleSettingsScreen")
);
const ScreenOrdersList = React.lazy(() =>
  import(
    "../../modules/adminPanel/wholeSale/orders/ordersList/ScreenOrdersList"
  )
);
const ScreenOrderDetails = React.lazy(() =>
  import(
    "../../modules/adminPanel/wholeSale/orders/orderDetails/ScreenOrderDetails"
  )
);
const ScreenPrintOrder = React.lazy(() =>
  import("../../modules/adminPanel/wholeSale/print/printOrder/ScreenPrintOrder")
);

export default function AppAdminUser(props) {
  // PROPS COMING FROM App
  const { isAdminPanel } = props;

  return (
    <Suspense fallback={<LoadingBox></LoadingBox>}>
      <div className="admin">
        <Route exact path={"/admin-kayit"} component={AdminRegisterScreen} />
        <Route exact path={"/admin-giris"} component={AdminLoginScreen} />
        <AdminRoute path={"/admin-panel"} component={AdminPanelScreen} />
        <AdminRoute path={"/admin-panel"} component={AdminLeftNav} />
        <AdminRoute
          exact
          path={"/admin-panel/anasayfa"}
          component={AdminPanelDashboardScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kullanicilar/ara/:keyword"}
          component={AdminPanelUsersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kullanicilar/sayfa/:pageNumber"}
          component={AdminPanelUsersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kullanicilar/ara/:keyword/sayfa/:pageNumber"}
          component={AdminPanelUsersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kullanicilar"}
          component={AdminPanelUsersScreen}
        />
        <AdminRoute
          path={"/admin-panel/kullanici/:id"}
          component={AdminPanelUserDetailsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/siparisler/ara/:keyword"}
          component={AdminPanelOrdersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/siparisler/sayfa/:pageNumber"}
          component={AdminPanelOrdersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/siparisler/ara/:keyword/sayfa/:pageNumber"}
          component={AdminPanelOrdersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/siparisler"}
          component={AdminPanelOrdersScreen}
        />
        <AdminRoute
          path={"/admin-panel/siparis/:id"}
          component={AdminPanelOrderDetailsScreen}
        />
        <AdminRoute
          path={"/admin-panel/siparis-yazdir/:id/:shortId"}
          component={AdminPrintOrderScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/departmanlar"}
          component={AdminPanelDepartmentsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ana-kategoriler"}
          component={AdminPanelCategoriesScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/alt-kategoriler"}
          component={AdminPanelCategorySubsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/son-kategoriler"}
          component={AdminPanelCategoryLowsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/tedarikciler"}
          component={AdminPanelSuppliersScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/markalar"}
          component={AdminPanelBrandsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/varyantlar"}
          component={AdminVariantsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/modeller"}
          component={AdminPanelModelsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urun/analiz/:id/:name"}
          component={ProductAnalyticsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urunler/ara/:keyword"}
          component={AdminPanelProductsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urunler/sayfa/:pageNumber"}
          component={AdminPanelProductsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urunler/ara/:keyword/sayfa/:pageNumber"}
          component={AdminPanelProductsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urunler"}
          component={AdminPanelProductsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urunler/urun-ekle/:id?"}
          component={AdminPanelProductCreateScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/urun/duzenle/:id?"}
          component={AdminPanelProductEditScreen}
        />
        <AdminRoute
          path={
            "/admin-panel/sku/duzenle/:id/:productId/:skuIndex/:parasutProductId"
          }
          component={AdminPanelSkuEditScreen}
        />
        <AdminRoute
          path={"/admin-panel/kargo-islemleri"}
          component={AdminPanelCourierScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ups-il-ve-ilce-listesi/ara/:keyword"}
          component={AdminPanelUpsCityListScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ups-il-ve-ilce-listesi"}
          component={AdminPanelUpsCityListScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ups-il-detay/duzenle/:id"}
          component={AdminPanelUpsCityDetailsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/musteri-hizmetleri/ara/:keyword"}
          component={AdminPanelCustomerServiceScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/musteri-hizmetleri/sayfa/:pageNumber"}
          component={AdminPanelCustomerServiceScreen}
        />
        <AdminRoute
          exact
          path={
            "/admin-panel/musteri-hizmetleri/ara/:keyword/sayfa/:pageNumber"
          }
          component={AdminPanelCustomerServiceScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/musteri-hizmetleri"}
          component={AdminPanelCustomerServiceScreen}
        />
        <AdminRoute
          path={"/admin-panel/musteri-hizmeti/:id/:supportIndex"}
          component={AdminPanelCustomerServiceDetailsScreen}
        />
        <AdminRoute
          path={"/admin-panel/musteri-hizmetleri/durum-olusturma-ekrani"}
          component={AdminPanelCustomerSupportStatusScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/onayli-siparis-durumlari"}
          component={AdminPanelSecuredOrderStatusScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kampanya/:id"}
          component={CampaignScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/kampanya-olustur/:id"}
          component={CampaignCreateScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/fatura-islemleri/:id/:name/:orderId"}
          component={AdminInvoiceOperationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/indirim-kodlari/ara/:keyword"}
          component={DiscountOperationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/indirim-kodlari/sayfa/:pageNumber"}
          component={DiscountOperationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/indirim-kodlari/ara/:keyword/sayfa/:pageNumber"}
          component={DiscountOperationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/indirim-kodlari"}
          component={DiscountOperationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/indirim-kodu/:id"}
          component={DiscountDetailsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/pops"}
          component={AdminPopsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/pop-detay/:id"}
          component={AdminPopDetailsScreen}
        />
        {/*<AdminRoute
          exact
          path={"/admin-panel/recipe/:id"}
          component={ProductRecipeScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/recipe-edit/:productId/:recipeId"}
          component={RecipeEditScreen}
        />*/}
        <AdminRoute
          exact
          path={"/admin-panel/raporlar/sayfa/:pageNumber"}
          component={AdminAnalyticsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/raporlar"}
          component={AdminAnalyticsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/ara/:keyword"}
          component={AdminInventoryScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/sayfa/:pageNumber"}
          component={AdminInventoryScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/ara/:keyword/sayfa/:pageNumber"}
          component={AdminInventoryScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok"}
          component={AdminInventoryScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/malzemeler/ara/:keyword"}
          component={AdminCreateMaterialScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/malzemeler/sayfa/:pageNumber"}
          component={AdminCreateMaterialScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/malzemeler/ara/:keyword/sayfa/:pageNumber"}
          component={AdminCreateMaterialScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/malzemeler"}
          component={AdminCreateMaterialScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/depolar"}
          component={AdminCreateWarehouseScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/stok/irsaliye-yazdir/:id"}
          component={PrintShippingDocument}
        />
        <AdminRoute
          exact
          path={"/admin-panel/paketlenmesi-gereken-siparis-kalemleri"}
          component={OrderItemsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/mesaj-tahtasi"}
          component={AdminShippingMessageScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/sms-kampanyalari"}
          component={SmsCampaignScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/sms-kampanyasi-detayi/:id"}
          component={SmsCampaignDetailsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/iade-onay-kodu-olustur/ara/:keyword"}
          component={AdminRefundCodeScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/iade-onay-kodu-olustur/sayfa/:pageNumber"}
          component={AdminRefundCodeScreen}
        />
        <AdminRoute
          exact
          path={
            "/admin-panel/iade-onay-kodu-olustur/ara/:keyword/sayfa/:pageNumber"
          }
          component={AdminRefundCodeScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/iade-onay-kodu-olustur"}
          component={AdminRefundCodeScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ziyaretci-sorulari/ara/:keyword"}
          component={QnaAdminScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ziyaretci-sorulari/sayfa/:pageNumber"}
          component={QnaAdminScreen}
        />
        <AdminRoute
          exact
          path={
            "/admin-panel/ziyaretci-sorulari/ara/:keyword/sayfa/:pageNumber"
          }
          component={QnaAdminScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ziyaretci-sorulari"}
          component={QnaAdminScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ust-bar"}
          component={AdminTopBarScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ust-bar-detay/:id"}
          component={AdminTopBarDetailsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/ara/:keyword"}
          component={CurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/sayfa/:pageNumber"}
          component={CurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/ara/:keyword/sayfa/:pageNumber"}
          component={CurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari"}
          component={CurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/hesaplar/ara/:keyword"}
          component={CreateCurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/hesaplar/sayfa/:pageNumber"}
          component={CreateCurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/hesaplar/ara/:keyword/sayfa/:pageNumber"}
          component={CreateCurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/cari/hesaplar"}
          component={CreateCurrentAccountScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/satis-raporlari"}
          component={SalesReportInventoryActivityScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/bildirimler/ara/:keyword"}
          component={NotificationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/bildirimler/sayfa/:pageNumber"}
          component={NotificationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/bildirimler/ara/:keyword/sayfa/:pageNumber"}
          component={NotificationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/bildirimler"}
          component={NotificationsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/tedarikci-siparisleri"}
          component={OrderManagementScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/tedarikci-siparisi-yazdir/:id"}
          component={PrintManagedOrderScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toplu-guncelleme"}
          component={BulkProductUpdateScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/ziyaretci-analizleri"}
          component={VisitorAnalyticsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/trendyol"}
          component={TrendyolScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/hepsiburada"}
          component={HepsiBuradaScreen}
        />

        {/* ADMIN PANEL WHOLESALE SECTION */}
        <AdminRoute
          exact
          path={"/admin-panel/toptan-ayarlari"}
          component={WholeSaleSettingsScreen}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparisler/ara/:keyword"}
          render={(props) => <ScreenOrdersList {...props} />}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparisler/sayfa/:pageNumber"}
          render={(props) => <ScreenOrdersList {...props} />}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparisler/ara/:keyword/sayfa/:pageNumber"}
          render={(props) => <ScreenOrdersList {...props} />}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparisler"}
          render={(props) => <ScreenOrdersList {...props} />}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparis-detay"}
          render={(props) => (
            <ScreenOrderDetails isAdminPanel={isAdminPanel} {...props} />
          )}
        />
        <AdminRoute
          exact
          path={"/admin-panel/toptan-siparis-yazdir"}
          render={(props) => <ScreenPrintOrder {...props} />}
        />
      </div>
    </Suspense>
  );
}
