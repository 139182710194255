import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetWholeSaleOrdersListByAccount = createAsyncThunk(
  "wholeSale/ordersListByAccount",
  async (id, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/wholesale/getWholeSaleOrdersListByAccount",
        {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  orders: [],
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getWholeSaleOrdersListByAccountSlice = createSlice({
  name: "getWholeSaleOrdersListByAccount",
  initialState,
  reducers: {
    resetGetWholeSaleOrdersListByAccount: (state) => {
      state.orders = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetWholeSaleOrdersListByAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToGetWholeSaleOrdersListByAccount.fulfilled,
        (state, action) => {
          state.loading = false;
          state.orders = action.payload;
        }
      )
      .addCase(
        actionToGetWholeSaleOrdersListByAccount.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetGetWholeSaleOrdersListByAccount } =
  getWholeSaleOrdersListByAccountSlice.actions;

// Export the reducer
const reducerToGetWholeSaleOrdersListByAccount =
  getWholeSaleOrdersListByAccountSlice.reducer;

// Export the async action creator for use in components
export {
  reducerToGetWholeSaleOrdersListByAccount,
  resetGetWholeSaleOrdersListByAccount,
};
