import { useEffect, useRef } from "react";

//RETRIEVE PREVIOUS VALUE OF A SPESIFIC STATE
export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
