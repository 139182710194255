import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import usePrevious from "./usePrevious";

export default function useScrollDirection() {
  const history = useHistory();
  const [scrollDirection, setScrollDirection] = useState("");
  let prevDir = usePrevious(scrollDirection);
  let timer = "";

  useScrollPosition(({ prevPos, currPos }) => {
    if (timer !== "") {
      clearTimeout(timer);
    }

    if (history.action === "PUSH") {
      if (prevDir) {
        setTimeout(() => {
          prevDir = "";
        }, 1250);
      }
      timer = setTimeout(() => {
        if (currPos.y === 0) {
          prevDir ? setScrollDirection(prevDir) : setScrollDirection("up");
        } else if (currPos.y < prevPos.y) {
          prevDir ? setScrollDirection(prevDir) : setScrollDirection("down");
        } else if (currPos.y > prevPos.y) {
          prevDir ? setScrollDirection(prevDir) : setScrollDirection("up");
        }
      }, 200);
    } else {
      timer = setTimeout(() => {
        if (currPos.y === 0) {
          setScrollDirection("up");
          sessionStorage.setItem("scrollDirection", "up");
        } else if (currPos.y < prevPos.y) {
          setScrollDirection("down");
          sessionStorage.setItem("scrollDirection", "down");
        } else if (currPos.y > prevPos.y) {
          setScrollDirection("up");
          sessionStorage.setItem("scrollDirection", "up");
        }
      }, 100);
    }
  });

  return scrollDirection;
}
