import Axios from "axios";
import {
  USER_AUTHENTICATE_FAIL,
  USER_AUTHENTICATE_LOGOUT,
  USER_AUTHENTICATE_REQUEST,
  USER_AUTHENTICATE_SUCCESS,
  USER_LOGOUT,
  USER_SMS_CODE_FAIL,
  USER_SMS_CODE_REQUEST,
  USER_SMS_CODE_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_SET_SCORE_REQUEST,
  USER_SET_SCORE_SUCCESS,
  USER_SET_SCORE_FAIL,
} from "../constants/userconstants";

//LOGS OUT USER YET KEEPS CART SKU ITEMS IN PLACE
export const actionToLogout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("authenticatedUser");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_AUTHENTICATE_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
};

//IF USER PHONE NUMBER IS MATCHED WITH ORDER PHONE NUMBER, SMS CODE IS SENT
export const actionToSendSmsCode = (phone) => async (dispatch) => {
  dispatch({ type: USER_SMS_CODE_REQUEST, payload: { phone } });
  try {
    const { data } = await Axios.post("/api/users/smscode", {
      phone,
    });
    dispatch({ type: USER_SMS_CODE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_SMS_CODE_FAIL, payload: message });
  }
};

//IF THE SMS CODE ENTERED CORRECTLY, USER OBJECT IS SENT TO BE CHECKED.
export const actionToAuthenticateUser = (user) => async (dispatch) => {
  dispatch({ type: USER_AUTHENTICATE_REQUEST, payload: user });
  try {
    const { data } = await Axios.post("/api/users/authenticate", user);
    dispatch({ type: USER_AUTHENTICATE_SUCCESS, payload: data });
    localStorage.setItem("authenticatedUser", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && (error.response.data.message || error.response.data)
        ? error.response.data.message || error.response.data
        : error.message;
    dispatch({ type: USER_AUTHENTICATE_FAIL, payload: message });
  }
};

//FETCH USER DETAILS FOR PROFILE PAGE
export const actionToGetUserDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST });
  const {
    authenticateUser: { authenticatedUser },
  } = getState();
  try {
    const { data } = await Axios.get(`/api/users/userdetails/${id}`, {
      headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

//UPDATE USER DETAILS FOR PROFILE PAGE
export const actionToUpdateUserProfile =
  (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(`/api/users/profile`, user, {
        headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
      });
      dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
      dispatch({ type: USER_AUTHENTICATE_SUCCESS, payload: data });
      localStorage.setItem("authenticatedUser", JSON.stringify(data));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
    }
  };

// SET USER SCORE
export const actionToSetUserScore = (details) => async (dispatch) => {
  dispatch({ type: USER_SET_SCORE_REQUEST });

  try {
    const { data } = await Axios.post(`/api/users/setuserscore`, details);
    dispatch({ type: USER_SET_SCORE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_SET_SCORE_FAIL, payload: message });
  }
};
