import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./MessageUnpaidOrders.css";

export default function MessageAnnounces() {
  //REDUX STATE THAT HOLDS AUTHENTICATED USER INFO
  const authenticateUser = useSelector((state) => state.authenticateUser);
  const { authenticatedUser } = authenticateUser;

  //CLOSE ANNOUNCE
  const [announceClosed, setAnnounceClosed] = useState(true);
  function handleAnnounce() {
    setAnnounceClosed(true);
  }

  return (
    <>
      {!announceClosed && (
        <div className="user-screen">
          <div className="unpaid-orders-message-area">
            <div className="unpaid-orders-message-flex">
              <div className="w15p">
                <i className="far fa-envelope beddo-color fs2 marh txtleft"></i>
              </div>
              <div className="w70p col">
                <div>
                  {authenticatedUser ? (
                    <>
                      {" "}
                      <h3 className="h3l">
                        Sayın {authenticatedUser.firstName}{" "}
                        {authenticatedUser.lastName.substring(0, 1)}...
                      </h3>{" "}
                      <h3 className="h3l">
                        Bu gece saat 00:00 'dan itibaren tüm ürünlerimizde
                        geçerli olmak üzere fiyatlar 10 TL artacaktır. Size
                        karşı duyduğumuz sorumluluğun bir gereği olarak
                        bilginize sunarız.{" "}
                      </h3>
                    </>
                  ) : (
                    <>
                      {" "}
                      <h3 className="h3l">Değerli Ziyaretçimiz</h3>
                      <h3 className="h3l">
                        Bu gece saat 00:00 'dan itibaren tüm ürünlerimizde
                        geçerli olmak üzere fiyatlar 10 TL artacaktır. beddo™
                        kullanıcılarına karşı duyduğumuz sorumluluğun bir gereği
                        olarak bilginize sunarız.{" "}
                      </h3>
                    </>
                  )}
                </div>
              </div>
              <div title="Kapat" className="close marh pointer w5p txtright">
                <i
                  className="fas fa-times beddo-color fs15"
                  onClick={handleAnnounce}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
