import {
  ADMIN_CATEGORY_CREATE_FAIL,
  ADMIN_CATEGORY_CREATE_REQUEST,
  ADMIN_CATEGORY_CREATE_RESET,
  ADMIN_CATEGORY_CREATE_SUCCESS,
  ADMIN_CATEGORY_DELETE_FAIL,
  ADMIN_CATEGORY_DELETE_REQUEST,
  ADMIN_CATEGORY_DELETE_RESET,
  ADMIN_CATEGORY_DELETE_SUCCESS,
  ADMIN_CATEGORY_DETAILS_FAIL,
  ADMIN_CATEGORY_DETAILS_REQUEST,
  ADMIN_CATEGORY_DETAILS_SUCCESS,
  ADMIN_CATEGORY_EDIT_FAIL,
  ADMIN_CATEGORY_EDIT_REQUEST,
  ADMIN_CATEGORY_EDIT_RESET,
  ADMIN_CATEGORY_EDIT_SUCCESS,
  ADMIN_CATEGORY_LIST_FAIL,
  ADMIN_CATEGORY_LIST_REQUEST,
  ADMIN_CATEGORY_LIST_SUCCESS,
  ADMIN_CATEGORY_RELATED_LIST_FAIL,
  ADMIN_CATEGORY_RELATED_LIST_REQUEST,
  ADMIN_CATEGORY_RELATED_LIST_SUCCESS,
} from "../constants/adminCategoryConstants";

//SAVE THE CREATED CATEGORY IN REDUX STATE
export const reducerAdminCategoryToCreateCategory = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_CATEGORY_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        category: action.payload,
      };
    case ADMIN_CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF CATEGORIES IN REDUX STATE
export function reducerAdminCategoryToListCategories(
  state = { loading: true, categoriesList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categoriesList: action.payload,
      };
    case ADMIN_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE RELATED LIST OF CATEGORIES IN REDUX STATE
export function reducerAdminCategoryToRelatedListCategories(
  state = { loading: true, categoriesRelatedList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_RELATED_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_RELATED_LIST_SUCCESS:
      return {
        loading: false,
        categoriesRelatedList: action.payload,
      };
    case ADMIN_CATEGORY_RELATED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY IN REDUX STATE
export function reducerAdminCategoryToDetailsCategory(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_DETAILS_SUCCESS:
      return {
        loading: false,
        categoryDetails: action.payload,
      };
    case ADMIN_CATEGORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY IN REDUX STATE
export function reducerAdminCategoryToEditCategory(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_CATEGORY_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF CATEGORY IN REDUX STATE
export function reducerAdminCategoryToDeleteCategory(state = {}, action) {
  switch (action.type) {
    case ADMIN_CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
