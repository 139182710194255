//CREATE REVIEW
export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

//GET REVIEW DETAILS
export const PRODUCT_DETAILS_REVIEW_REQUEST = "PRODUCT_DETAILS_REVIEW_REQUEST";
export const PRODUCT_DETAILS_REVIEW_SUCCESS = "PRODUCT_DETAILS_REVIEW_SUCCESS";
export const PRODUCT_DETAILS_REVIEW_FAIL = "PRODUCT_DETAILS_REVIEW_FAIL";
export const PRODUCT_DETAILS_REVIEW_RESET = "PRODUCT_DETAILS_REVIEW_RESET";

//EDIT REVIEW
export const PRODUCT_EDIT_REVIEW_REQUEST = "PRODUCT_EDIT_REVIEW_REQUEST";
export const PRODUCT_EDIT_REVIEW_SUCCESS = "PRODUCT_EDIT_REVIEW_SUCCESS";
export const PRODUCT_EDIT_REVIEW_FAIL = "PRODUCT_EDIT_REVIEW_FAIL";
export const PRODUCT_EDIT_REVIEW_RESET = "PRODUCT_EDIT_REVIEW_RESET";

//DELETE REVIEW
export const PRODUCT_DELETE_REVIEW_REQUEST = "PRODUCT_DELETE_REVIEW_REQUEST";
export const PRODUCT_DELETE_REVIEW_SUCCESS = "PRODUCT_DELETE_REVIEW_SUCCESS";
export const PRODUCT_DELETE_REVIEW_FAIL = "PRODUCT_DELETE_REVIEW_FAIL";
export const PRODUCT_DELETE_REVIEW_RESET = "PRODUCT_DELETE_REVIEW_RESET";

//LIST REVIEWS
export const PRODUCT_LIST_REVIEW_REQUEST = "PRODUCT_LIST_REVIEW_REQUEST";
export const PRODUCT_LIST_REVIEW_SUCCESS = "PRODUCT_LIST_REVIEW_SUCCESS";
export const PRODUCT_LIST_REVIEW_FAIL = "PRODUCT_LIST_REVIEW_FAIL";

//REFUND IMAGE REVIEW INCENTIVE
export const REFUND_IMAGE_REVIEW_REQUEST = "REFUND_IMAGE_REVIEW_REQUEST";
export const REFUND_IMAGE_REVIEW_SUCCESS = "REFUND_IMAGE_REVIEW_SUCCESS";
export const REFUND_IMAGE_REVIEW_FAIL = "REFUND_IMAGE_REVIEW_FAIL";
export const REFUND_IMAGE_REVIEW_RESET = "REFUND_IMAGE_REVIEW_RESET";
