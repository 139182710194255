//ADMIN CREATE CATEGORY
export const ADMIN_CATEGORY_CREATE_REQUEST = "ADMIN_CATEGORY_CREATE_REQUEST";
export const ADMIN_CATEGORY_CREATE_SUCCESS = "ADMIN_CATEGORY_CREATE_SUCCESS";
export const ADMIN_CATEGORY_CREATE_FAIL = "ADMIN_CATEGORY_CREATE_FAIL";
export const ADMIN_CATEGORY_CREATE_RESET = "ADMIN_CATEGORY_CREATE_RESET";

//ADMIN CATEGORY LIST
export const ADMIN_CATEGORY_LIST_REQUEST = "ADMIN_CATEGORY_LIST_REQUEST";
export const ADMIN_CATEGORY_LIST_SUCCESS = "ADMIN_CATEGORY_LIST_SUCCESS";
export const ADMIN_CATEGORY_LIST_FAIL = "ADMIN_CATEGORY_LIST_FAIL";

//ADMIN CATEGORY RELATED LIST
export const ADMIN_CATEGORY_RELATED_LIST_REQUEST =
  "ADMIN_CATEGORY_RELATED_LIST_REQUEST";
export const ADMIN_CATEGORY_RELATED_LIST_SUCCESS =
  "ADMIN_CATEGORY_RELATED_LIST_SUCCESS";
export const ADMIN_CATEGORY_RELATED_LIST_FAIL =
  "ADMIN_CATEGORY_RELATED_LIST_FAIL";

//ADMIN CATEGORY DETAILS
export const ADMIN_CATEGORY_DETAILS_REQUEST = "ADMIN_CATEGORY_DETAILS_REQUEST";
export const ADMIN_CATEGORY_DETAILS_SUCCESS = "ADMIN_CATEGORY_DETAILS_SUCCESS";
export const ADMIN_CATEGORY_DETAILS_FAIL = "ADMIN_CATEGORY_DETAILS_FAIL";

//ADMIN CATEGORY EDIT
export const ADMIN_CATEGORY_EDIT_REQUEST = "ADMIN_CATEGORY_EDIT_REQUEST";
export const ADMIN_CATEGORY_EDIT_SUCCESS = "ADMIN_CATEGORY_EDIT_SUCCESS";
export const ADMIN_CATEGORY_EDIT_FAIL = "ADMIN_CATEGORY_EDIT_FAIL";
export const ADMIN_CATEGORY_EDIT_RESET = "ADMIN_CATEGORY_EDIT_RESET";

//ADMIN CATEGORY DELETE
export const ADMIN_CATEGORY_DELETE_REQUEST = "ADMIN_CATEGORY_DELETE_REQUEST";
export const ADMIN_CATEGORY_DELETE_SUCCESS = "ADMIN_CATEGORY_DELETE_SUCCESS";
export const ADMIN_CATEGORY_DELETE_FAIL = "ADMIN_CATEGORY_DELETE_FAIL";
export const ADMIN_CATEGORY_DELETE_RESET = "ADMIN_CATEGORY_DELETE_RESET";
