import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToSendSecurityCodeToSignIn = createAsyncThunk(
  "signIn/jobInActionToSendSecurityCodeToSignIn ",
  async (
    {
      phone,
      securityCode,
      userType,
      collectionName,
      property,
      captchaValue,
      tokenExpiry,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axios.get("/api/signIn/sendSecurityCodeToSignIn", {
        params: {
          phone,
          securityCode,
          userType,
          collectionName,
          property,
          captchaValue,
          tokenExpiry,
        },
      });

      // SET LOCAL STORAGE TO PERSIST STATE - Comparing data properties to detect which type of user is signed in
      if (data.currentAccountPosition) {
        localStorage.setItem(
          "authenticatedWholeSaleUser",
          JSON.stringify(data)
        );
      } else if (data.userPosition && data.isAdmin !== undefined) {
        localStorage.setItem("adminUserInfo", JSON.stringify(data));
      } else if (data.userPosition) {
        localStorage.setItem("authenticatedUser", JSON.stringify(data));
      }

      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  authenticatedUser: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const sendSecurityCodeToSignInSlice = createSlice({
  name: "sendSecurityCodeToSignIn",
  initialState,
  reducers: {
    signOut: (state) => {
      state.authenticatedUser = null;
      state.loading = false;
      state.error = null;
      localStorage.removeItem("authenticatedWholeSaleUser");
      sessionStorage.removeItem("wholeSaleCartItems");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToSendSecurityCodeToSignIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToSendSecurityCodeToSignIn.fulfilled, (state, action) => {
        state.loading = false;
        state.authenticatedUser = action.payload;
      })
      .addCase(actionToSendSecurityCodeToSignIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export custom reducer
const { signOut } = sendSecurityCodeToSignInSlice.actions;

// Export the reducer
const reducerToSendSecurityCodeToSignIn = sendSecurityCodeToSignInSlice.reducer;

// Export the async action creator for use in components
export { reducerToSendSecurityCodeToSignIn, signOut };
