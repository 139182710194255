import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToUpdateSkuWithTrendyolInfo = createAsyncThunk(
  "jobInActionToUpdateSkuWithTrendyolInfo",
  async ({ skuInfo }, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/trendyol/updateSkuWithTrendyolInfo",
        skuInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      const { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

const updateSkuWithTrendyolInfo = createSlice({
  name: "sliceToUpdateSkuWithTrendyolInfo",
  initialState: {
    isUpdated: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetUpdateSkuWithTrendyolInfoIsUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateSkuWithTrendyolInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToUpdateSkuWithTrendyolInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.isUpdated = true;
      })
      .addCase(actionToUpdateSkuWithTrendyolInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetUpdateSkuWithTrendyolInfoIsUpdated } =
  updateSkuWithTrendyolInfo.actions;

const reducerToUpdateSkuWithTrendyolInfo = updateSkuWithTrendyolInfo.reducer;

export default reducerToUpdateSkuWithTrendyolInfo;
