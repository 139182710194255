import { useEffect, useState } from "react";
// import fetchData from "../utils/fetchData";

// GET LIMITED TIME DISCOUNT INFO (takes in a product or sku item object as an argument)
export default function useGetLimitedTimeDiscountInfo(
  product,
  createdFingerPrint,
  trigger
) {
  const [limitedTimeDiscountInfo, setLimitedTimeDiscountInfo] = useState({
    isDiscountValid: undefined,
    remainingTime: 0,
  });

  useEffect(() => {
    if (createdFingerPrint) {
      const calculateDiscountInfo = async () => {
        if (
          product &&
          (product.timeLimitedDiscount?.discountDuration > 0 ||
            product.indicatorProductLimitedDiscount?.discountDuration > 0) &&
          createdFingerPrint?.visitorId
        ) {
          let firstVisitTime = createdFingerPrint.firstVisitTime; // Number(localStorage.getItem("firstVisitTime"));

          /* const firstVisitTimeInDataBase = async () => {
            try {
              const { resultData } = await fetchData(
                "GET",
                "/api/userIdentification/getUserFirstVisitTime",
                { visitorId: createdFingerPrint.visitorId }
              );

              return resultData;
            } catch (error) {
              console.log(error);
            }
          };

          if (!firstVisitTime) {
            firstVisitTime = await firstVisitTimeInDataBase();
          }*/

          if (firstVisitTime) {
            // CALCULATE IF VALID
            const durationInHour =
              product?.timeLimitedDiscount?.discountDuration ||
              product.indicatorProductLimitedDiscount?.discountDuration;

            const durationInMilisecond =
              process.env.NODE_ENV === "development"
                ? durationInHour * 6000 * 15
                : durationInHour * 60 * 60 * 1000;
            const nowInMilisecond = Date.now();

            //CALCULATE REMAINING TIME
            const remainingTime =
              firstVisitTime + durationInMilisecond - nowInMilisecond;
            setLimitedTimeDiscountInfo({
              isDiscountValid:
                remainingTime <= 0 || !remainingTime ? false : true,
              remainingTime:
                remainingTime <= 0 || !remainingTime ? 0 : remainingTime,
            });
          } else {
            setLimitedTimeDiscountInfo({
              ...limitedTimeDiscountInfo,
              isDiscountValid: false,
            });
          }
        }
      };

      calculateDiscountInfo();

      return () =>
        setLimitedTimeDiscountInfo({
          ...limitedTimeDiscountInfo,
          isDiscountValid: undefined,
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, createdFingerPrint, trigger]);

  return limitedTimeDiscountInfo;
}
