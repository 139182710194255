//CREATE QNA
export const CREATE_QNA_REQUEST = "CREATE_QNA_REQUEST";
export const CREATE_QNA_SUCCESS = "CREATE_QNA_SUCCESS";
export const CREATE_QNA_FAIL = "CREATE_QNA_FAIL";
export const CREATE_QNA_RESET = "CREATE_QNA_RESET";

//ADMIN LIST QNA
export const ADMIN_LIST_QNA_REQUEST = "ADMIN_LIST_QNA_REQUEST";
export const ADMIN_LIST_QNA_SUCCESS = "ADMIN_LIST_QNA_SUCCESS";
export const ADMIN_LIST_QNA_FAIL = "ADMIN_LIST_QNA_FAIL";

//ADMIN ANSWER QNA
export const ADMIN_ANSWER_QNA_REQUEST = "ADMIN_ANSWER_QNA_REQUEST";
export const ADMIN_ANSWER_QNA_SUCCESS = "ADMIN_ANSWER_QNA_SUCCESS";
export const ADMIN_ANSWER_QNA_FAIL = "ADMIN_ANSWER_QNA_FAIL";

//VISITOR LIST QNA
export const LIST_QNA_REQUEST = "LIST_QNA_REQUEST";
export const LIST_QNA_SUCCESS = "LIST_QNA_SUCCESS";
export const LIST_QNA_FAIL = "LIST_QNA_FAIL";

//GET QNA DETAILS
export const DETAILS_QNA_REQUEST = "DETAILS_QNA_REQUEST";
export const DETAILS_QNA_SUCCESS = "DETAILS_QNA_SUCCESS";
export const DETAILS_QNA_FAIL = "DETAILS_QNA_FAIL";
export const DETAILS_QNA_RESET = "DETAILS_QNA_RESET";

//ADMIN EDIT QNA
export const ADMIN_EDIT_QNA_REQUEST = "ADMIN_EDIT_QNA_REQUEST";
export const ADMIN_EDIT_QNA_SUCCESS = "ADMIN_EDIT_QNA_SUCCESS";
export const ADMIN_EDIT_QNA_FAIL = "ADMIN_EDIT_QNA_FAIL";
export const ADMIN_EDIT_QNA_RESET = "ADMIN_EDIT_QNA_RESET";

//ADMIN DELETE QNA
export const ADMIN_DELETE_QNA_REQUEST = "ADMIN_DELETE_QNA_REQUEST";
export const ADMIN_DELETE_QNA_SUCCESS = "ADMIN_DELETE_QNA_SUCCESS";
export const ADMIN_DELETE_QNA_FAIL = "ADMIN_DELETE_QNA_FAIL";
export const ADMIN_DELETE_QNA_RESET = "ADMIN_DELETE_QNA_RESET";
