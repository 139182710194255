import { stylerDecimalPart } from "./stylerDecimalPart";

export function calculateDisplayedPrice(
  price,
  qty,
  priceGap,
  showDiscountAmount,
  onlyPriceGapTotal,
  timeLimitedDiscount,
  sum,
  grandUpsell,
  index,
  cartIsEmpty
) {
  let numPrice = price === 0 ? "0" : Number(price);
  let numQty = Number(qty);
  let numPriceGap = Number(priceGap);
  let numTimeLimitedDiscount = Number(timeLimitedDiscount);

  const calculateSum = (
    numPrice,
    numQty,
    numPriceGap,
    numTimeLimitedDiscount
  ) => {
    const totalWithoutDiscount = numPrice * qty;
    const totalDiscount =
      numQty > 1
        ? (numQty - 1) * (numPrice - numPriceGap) + numTimeLimitedDiscount
        : numTimeLimitedDiscount;

    return totalWithoutDiscount - totalDiscount;
  };

  if (numPrice && numQty && numPriceGap && sum) {
    // console.log("heelo1");
    return (
      <>
        {parseInt(
          calculateSum(numPrice, numQty, numPriceGap, numTimeLimitedDiscount)
        )}
        .
        {stylerDecimalPart(
          calculateSum(numPrice, numQty, numPriceGap, numTimeLimitedDiscount)
        )}
      </>
    );
  } else if (showDiscountAmount) {
    return grandUpsell ? (
      <>
        {parseInt(Number(qty) * (numPrice - numPriceGap))}.
        {stylerDecimalPart(Number(qty) * (numPrice - numPriceGap))}
      </>
    ) : numTimeLimitedDiscount && cartIsEmpty ? (
      <>
        {parseInt(
          numTimeLimitedDiscount + Number(qty - 1) * (numPrice - numPriceGap)
        )}
        .
        {stylerDecimalPart(
          numTimeLimitedDiscount + Number(qty - 1) * (numPrice - numPriceGap)
        )}
      </>
    ) : numTimeLimitedDiscount && numQty > 1 && index === 0 ? (
      <>
        {parseInt(
          Number(qty - 1) * (numPrice - numPriceGap) + numTimeLimitedDiscount
        )}
        .
        {stylerDecimalPart(
          Number(qty - 1) * (numPrice - numPriceGap) + numTimeLimitedDiscount
        )}
      </>
    ) : numTimeLimitedDiscount && numQty === 1 && index === 0 ? (
      <>
        {parseInt(numTimeLimitedDiscount)}.
        {stylerDecimalPart(numTimeLimitedDiscount)}
      </>
    ) : numTimeLimitedDiscount && numQty > 1 && index > 0 ? (
      <>
        {parseInt(Number(qty) * (numPrice - numPriceGap))}.
        {stylerDecimalPart(Number(qty) * (numPrice - numPriceGap))}
      </>
    ) : !qty ? (
      <>
        {parseInt(numPrice - numPriceGap)}.
        {stylerDecimalPart(numPrice - numPriceGap)}
      </>
    ) : (
      qty && (
        <>
          {parseInt(qty * (numPrice - numPriceGap))}.
          {stylerDecimalPart(qty * (numPrice - numPriceGap))}
        </>
      )
    );
  } else if ((numPrice, numQty, numPriceGap)) {
    // console.log("heelo3");
    return (
      <>
        {parseInt(numPrice + (numQty - 1) * numPriceGap)}.
        {stylerDecimalPart(numPrice + (numQty - 1) * numPriceGap)}
      </>
    );
  } else if (numPrice) {
    //console.log("heelo4");

    return numPrice === "0" ? (
      "0"
    ) : !numQty ? (
      <>
        {parseInt(numPrice)}.{stylerDecimalPart(numPrice)}
      </>
    ) : (
      <>
        {parseInt(numPrice * numQty)}.{stylerDecimalPart(numPrice * numQty)}
      </>
    );
  } else if (numPrice && numQty) {
    //  console.log("heelo5");
    return (
      <>
        {parseInt(numPrice * numQty)}.{stylerDecimalPart(numPrice * numQty)}
      </>
    );
  } else if (onlyPriceGapTotal) {
    // console.log("heelo6");
    return (
      <>
        {parseInt(numPriceGap * (qty - 1))}.
        {stylerDecimalPart(numPriceGap * (qty - 1))}
      </>
    );
  }
}
