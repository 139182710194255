import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS_RESET,
  ORDER_PHONE_CHECK_REQUEST,
  ORDER_PHONE_CHECK_SUCCESS,
  ORDER_PHONE_CHECK_FAIL,
  ORDER_PHONE_CHECK_RESET,
  //ORDER_IYZICO_FORM_REQUEST,
  //ORDER_IYZICO_FORM_SUCCESS,
  //ORDER_IYZICO_FORM_FAIL,
  //ORDER_IYZICO_FORM_RESULT_REQUEST,
  //ORDER_IYZICO_FORM_RESULT_SUCCESS,
  //ORDER_IYZICO_FORM_RESULT_FAIL,
  ORDER_UPDATE_PAYMENT_INFO_REQUEST,
  ORDER_UPDATE_PAYMENT_INFO_SUCCESS,
  ORDER_UPDATE_PAYMENT_INFO_FAIL,
  ORDER_UPDATE_PAYMENT_INFO_RESET,
  //ORDER_IYZICO_FORM_RESET,
  ORDER_LIST_USER_REQUEST,
  ORDER_LIST_USER_SUCCESS,
  ORDER_LIST_USER_FAIL,
  ORDER_CHECK_UNPAID_REQUEST,
  ORDER_CHECK_UNPAID_SUCCESS,
  ORDER_CHECK_UNPAID_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_RESET,
  ORDER_CANCEL_PARTIALLY_REQUEST,
  ORDER_CANCEL_PARTIALLY_SUCCESS,
  ORDER_CANCEL_PARTIALLY_FAIL,
  ORDER_CANCEL_PARTIALLY_RESET,
  ORDER_CHECK_UNPAID_RESET,
  ORDER_UPDATE_DELIVERY_CONTACT_REQUEST,
  ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS,
  ORDER_UPDATE_DELIVERY_CONTACT_FAIL,
  ORDER_UPDATE_DELIVERY_CONTACT_RESET,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET,
  ORDER_CANCEL_PAYMENT_SUCCESS,
  ORDER_CANCEL_PAYMENT_FAIL,
  ORDER_CANCEL_PAYMENT_REQUEST,
  ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS,
  ORDER_UPDATE_CANCELLED_PAYMENT_FAIL,
  ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_RESET,
  ORDER_REFUND_REQUEST,
  ORDER_REFUND_SUCCESS,
  ORDER_REFUND_FAIL,
  ORDER_REFUND_RESET,
  ORDER_REFUND_CANCEL_REQUEST,
  ORDER_REFUND_CANCEL_SUCCESS,
  ORDER_REFUND_CANCEL_FAIL,
  ORDER_REFUND_CANCEL_RESET,
  ORDER_REFUND_IBAN_SUCCESS,
  ORDER_REFUND_IBAN_FAIL,
  ORDER_REFUND_IBAN_RESET,
  ORDER_REFUND_IBAN_REQUEST,
  ORDER_APPLY_DISCOUNTCODE_REQUEST,
  ORDER_APPLY_DISCOUNTCODE_SUCCESS,
  ORDER_APPLY_DISCOUNTCODE_FAIL,
  ORDER_APPLY_DISCOUNTCODE_RESET,
  ORDER_VIEW_INVOICE_REQUEST,
  ORDER_VIEW_INVOICE_SUCCESS,
  ORDER_VIEW_INVOICE_FAIL,
  UPSELL_ORDER_DETAILS_REQUEST,
  UPSELL_ORDER_DETAILS_SUCCESS,
  UPSELL_ORDER_DETAILS_FAIL,
  ORDER_DETAILS_RESET,
  UPSELL_ORDER_DETAILS_RESET,
  ORDER_CANCEL_PAYMENT_RESET,
  ORDER_IS_PENDING_REQUEST,
  ORDER_IS_PENDING_SUCCESS,
  ORDER_IS_PENDING_FAIL,
  ORDER_IS_PENDING_RESET,
} from "../constants/orderConstants";

export const reducerToCreateOrder = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CREATE_SUCCESS_RESET:
      return { ...state, success: false };
    case ORDER_CREATE_RESET:
      return (state = {});
    default:
      return state;
  }
};

export function reducerToUpdatePaymentInfo(state = { loading: false }, action) {
  switch (action.type) {
    case ORDER_UPDATE_PAYMENT_INFO_REQUEST:
      return { loading: true };
    case ORDER_UPDATE_PAYMENT_INFO_SUCCESS:
      return { loading: false, paymentInfo: action.payload };
    case ORDER_UPDATE_PAYMENT_INFO_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_UPDATE_PAYMENT_INFO_RESET:
      return {};
    default:
      return state;
  }
}

export function reducerToMatchOrderPhone(state = { success: false }, action) {
  switch (action.type) {
    case ORDER_PHONE_CHECK_REQUEST:
      return { loading: true };
    case ORDER_PHONE_CHECK_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case ORDER_PHONE_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PHONE_CHECK_RESET:
      return (state = { success: false });
    default:
      return state;
  }
}

/*export function reducerToRequestIyzicoForm(state = { loading: true }, action) {
  switch (action.type) {
    case ORDER_IYZICO_FORM_REQUEST:
      return { loading: true };
    case ORDER_IYZICO_FORM_SUCCESS:
      return {
        loading: false,
        iyzicoForm: action.payload,
        //iyzicoToken: action.payload.token,
        //iyzicoConversationId: action.payload.conversationId,
      };
    case ORDER_IYZICO_FORM_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_IYZICO_FORM_RESET:
      return {};
    default:
      return state;
  }
}

export function reducerToGetIyzicoFormResult(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ORDER_IYZICO_FORM_RESULT_REQUEST:
      return { loading: true };
    case ORDER_IYZICO_FORM_RESULT_SUCCESS:
      return { loading: false, iyzicoPaymentResult: action.payload };
    case ORDER_IYZICO_FORM_RESULT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}*/

//LISTING USER ORDER HISTORY
export function reducerToListUserOrderHistory(
  state = { loading: true, orderHistory: [] },
  action
) {
  switch (action.type) {
    case ORDER_LIST_USER_REQUEST:
      return { loading: true };
    case ORDER_LIST_USER_SUCCESS:
      return {
        loading: false,
        orderHistory: action.payload,
      };
    case ORDER_LIST_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//THIS REDUCER IS TO CHECK IF THERE ARE ANY UNPAID ORDERS
//BELONG TO CUSTOMERS PHONE NUMBER
export function reducerOrderToCheckUnpaidOrders(
  state = { loading: true, unpaidOrders: [] },
  action
) {
  switch (action.type) {
    case ORDER_CHECK_UNPAID_REQUEST:
      return { loading: true };
    case ORDER_CHECK_UNPAID_SUCCESS:
      return { loading: false, unpaidOrders: action.payload };
    case ORDER_CHECK_UNPAID_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CHECK_UNPAID_RESET:
      return {};
    default:
      return state;
  }
}

//THIS REDUCER IS TO CANCEL AN ORDER (BEFORE PAYMENT)
export function reducerOrderToCancelAnOrder(state = {}, action) {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return { loading: true };
    case ORDER_CANCEL_SUCCESS:
      return { loading: false, cancelledOrder: action.payload };
    case ORDER_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CANCEL_RESET:
      return {};
    default:
      return state;
  }
}

//THIS REDUCER IS TO CANCEL AN ORDER (AFTER PAYMENT)
export function reducerOrderToCancelPaymentOrder(state = {}, action) {
  switch (action.type) {
    case ORDER_CANCEL_PAYMENT_REQUEST:
      return { loading: true };
    case ORDER_CANCEL_PAYMENT_SUCCESS:
      return { loading: false, cancelledPaymentOrder: action.payload };
    case ORDER_CANCEL_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CANCEL_PAYMENT_RESET:
      return {};
    default:
      return state;
  }
}

// UPDATE CANCELLED PAYMENT INFO IN ORDER
export function reducerOrderToUpdateCancelledPayment(state = {}, action) {
  switch (action.type) {
    case ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST:
      return { loading: true };
    case ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS:
      return { loading: false, updateCancelledPaymentOrder: action.payload };
    case ORDER_UPDATE_CANCELLED_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//THIS REDUCER IS TO CANCEL AN ORDER PARTIALLY (PRODUCT BY PRODUCT)
export function reducerOrderToCancelAnOrderPartially(state = {}, action) {
  switch (action.type) {
    case ORDER_CANCEL_PARTIALLY_REQUEST:
      return { loading: true };
    case ORDER_CANCEL_PARTIALLY_SUCCESS:
      return { loading: false, cancelledPartially: action.payload };
    case ORDER_CANCEL_PARTIALLY_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CANCEL_PARTIALLY_RESET:
      return {};
    default:
      return state;
  }
}

//THIS REDUCER UPDATES THE DELIVERY CONTACT INFORMATION
export function reducerOrderToUpdateDeliveryContact(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ORDER_UPDATE_DELIVERY_CONTACT_REQUEST:
      return { loading: true };
    case ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedDeliveryContact: action.payload,
      };
    case ORDER_UPDATE_DELIVERY_CONTACT_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_UPDATE_DELIVERY_CONTACT_RESET:
      return {};
    default:
      return state;
  }
}

//THIS REDUCER UPDATES THE INDIVIDUAL INVOICE INFORMATION
export function reducerOrderToUpdateIndividualInvoice(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST:
      return { loading: true };
    case ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedIndividualInvoice: action.payload,
      };
    case ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET:
      return {};
    default:
      return state;
  }
}

//SAVING ORDER DETAILS
export function reducerOrderToGetOrderDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        orderDetails: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}

//SAVING UPSELL ORDER DETAILS
export function reducerOrderToGetUpsellOrderDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case UPSELL_ORDER_DETAILS_REQUEST:
      return { loading: true };
    case UPSELL_ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        upsellOrderDetails: action.payload,
      };
    case UPSELL_ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case UPSELL_ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}

//DELETING AN ORDER
export function reducerOrderToDeleteOrder(
  state = { loading: true, success: false },
  action
) {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true };
    case ORDER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

//ORDER REFUND REDUCER
export function reducerOrderToRefundOrder(state = { success: false }, action) {
  switch (action.type) {
    case ORDER_REFUND_REQUEST:
      return { loading: true };
    case ORDER_REFUND_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload.order,
        refundShipmentNo: action.payload.refundShipmentNo,
      };
    case ORDER_REFUND_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_REFUND_RESET:
      return {};
    default:
      return state;
  }
}

//THIS REDUCER UPDATES THE DELIVERY CONTACT INFORMATION
export function reducerOrderToCancelRefund(state = { success: false }, action) {
  switch (action.type) {
    case ORDER_REFUND_CANCEL_REQUEST:
      return { loading: true };
    case ORDER_REFUND_CANCEL_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_REFUND_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_REFUND_CANCEL_RESET:
      return {};
    default:
      return state;
  }
}

// COLLECT REFUND IBAN - PUT
export function reducerOrderToCollectIban(state = { success: false }, action) {
  switch (action.type) {
    case ORDER_REFUND_IBAN_REQUEST:
      return { loading: true };
    case ORDER_REFUND_IBAN_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_REFUND_IBAN_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_REFUND_IBAN_RESET:
      return {};
    default:
      return state;
  }
}

// APPLY DISCOUNT
export function reducerOrderToApplyDiscountCode(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ORDER_APPLY_DISCOUNTCODE_REQUEST:
      return { loading: true };
    case ORDER_APPLY_DISCOUNTCODE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_APPLY_DISCOUNTCODE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_APPLY_DISCOUNTCODE_RESET:
      return {};
    default:
      return state;
  }
}

//SAVING ORDER DETAILS
export function reducerOrderToViewInvoice(state = { loading: true }, action) {
  switch (action.type) {
    case ORDER_VIEW_INVOICE_REQUEST:
      return { loading: true };
    case ORDER_VIEW_INVOICE_SUCCESS:
      return {
        loading: false,
        invoicePdfLink: action.payload,
      };
    case ORDER_VIEW_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// CHECK PENDING ORDER
export function reducerOrderToCheckIfThereIsApendingOrder(
  state = {
    loading: true,
    isPending: { isTherePending: "NOTYET", pendingOrders: [] },
  },
  action
) {
  switch (action.type) {
    case ORDER_IS_PENDING_REQUEST:
      return { loading: true };
    case ORDER_IS_PENDING_SUCCESS:
      return {
        loading: false,
        isPending: action.payload,
      };
    case ORDER_IS_PENDING_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_IS_PENDING_RESET:
      return {};
    default:
      return state;
  }
}
