import {
  ADMIN_CATEGORY_SUB_CREATE_FAIL,
  ADMIN_CATEGORY_SUB_CREATE_REQUEST,
  ADMIN_CATEGORY_SUB_CREATE_RESET,
  ADMIN_CATEGORY_SUB_CREATE_SUCCESS,
  ADMIN_CATEGORY_SUB_DELETE_FAIL,
  ADMIN_CATEGORY_SUB_DELETE_REQUEST,
  ADMIN_CATEGORY_SUB_DELETE_RESET,
  ADMIN_CATEGORY_SUB_DELETE_SUCCESS,
  ADMIN_CATEGORY_SUB_DETAILS_FAIL,
  ADMIN_CATEGORY_SUB_DETAILS_REQUEST,
  ADMIN_CATEGORY_SUB_DETAILS_SUCCESS,
  ADMIN_CATEGORY_SUB_EDIT_FAIL,
  ADMIN_CATEGORY_SUB_EDIT_REQUEST,
  ADMIN_CATEGORY_SUB_EDIT_RESET,
  ADMIN_CATEGORY_SUB_EDIT_SUCCESS,
  ADMIN_CATEGORY_SUB_LIST_FAIL,
  ADMIN_CATEGORY_SUB_LIST_REQUEST,
  ADMIN_CATEGORY_SUB_LIST_SUCCESS,
  ADMIN_CATEGORY_SUB_RELATED_LIST_FAIL,
  ADMIN_CATEGORY_SUB_RELATED_LIST_REQUEST,
  ADMIN_CATEGORY_SUB_RELATED_LIST_SUCCESS,
} from "../constants/adminCategorySubConstants";

//SAVE THE CREATED CATEGORY-SUB IN REDUX STATE
export const reducerAdminCategorySubToCreateCategorySub = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_SUB_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        categorySub: action.payload,
      };
    case ADMIN_CATEGORY_SUB_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_SUB_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF CATEGORY-SUB IN REDUX STATE
export function reducerAdminCategorySubToListCategorySubs(
  state = { loading: true, categorySubsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_SUB_LIST_SUCCESS:
      return {
        loading: false,
        categorySubsList: action.payload,
      };
    case ADMIN_CATEGORY_SUB_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE RELATED LIST OF CATEGORY-SUB IN REDUX STATE
export function reducerAdminCategorySubToRelatedListCategorySubs(
  state = { loading: true, categorySubsRelatedList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_RELATED_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_SUB_RELATED_LIST_SUCCESS:
      return {
        loading: false,
        categorySubsRelatedList: action.payload,
      };
    case ADMIN_CATEGORY_SUB_RELATED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY-SUB IN REDUX STATE
export function reducerAdminCategorySubToDetailsCategorySub(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_SUB_DETAILS_SUCCESS:
      return {
        loading: false,
        categorySubDetails: action.payload,
      };
    case ADMIN_CATEGORY_SUB_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY-SUB IN REDUX STATE
export function reducerAdminCategorySubToEditCategorySub(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_SUB_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_CATEGORY_SUB_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_SUB_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF CATEGORY-SUB IN REDUX STATE
export function reducerAdminCategorySubToDeleteCategorySub(state = {}, action) {
  switch (action.type) {
    case ADMIN_CATEGORY_SUB_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_SUB_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_CATEGORY_SUB_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_SUB_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
