import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

const initialState = {
  isConnected: false,
  loading: false,
  started: "",
  count: "",
  response: {
    data: [], // Initialized to an empty array
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  socketError: "",
};

// Async thunk to dispatch form data to the server via a socket connection
export const actionToBringInProductSalesData = createAsyncThunk(
  "productAnalytics/bringInProductSalesData",
  async ({ startDate, endDate, productId, limit, page }) => {
    try {
      // Get the socket connection
      const socket = io();

      // Emit the form data through the socket
      socket.emit("requestBringInProductSalesData", {
        startDate,
        endDate,
        productId,
        limit,
        page,
      });

      // Wrap the event listener inside a Promise
      const receivedData = await new Promise((resolve, reject) => {
        socket.on("responseBringInProductSalesData", (data) => {
          // Resolve the Promise with the received data
          resolve(data);
          socket.off("responseBringInProductSalesData");
        });

        // If there is no result, emit an error event to the client and reject the Promise
        socket.on("errorBringInProductSalesData", (error) => {
          // Reject the Promise with the error object
          reject(new Error(error));
        });
      });

      // Return the received data
      return receivedData;
    } catch (error) {
      // Handle any errors that occur during the socket connection or data transmission
      throw new Error(error.message);
    }
  }
);

const bringInProductSalesData = createSlice({
  name: "bringInProductSalesData",
  initialState,
  reducers: {
    resetState: () => initialState, // Reset the state to its initial value
  },
  extraReducers: (builder) => {
    // Handle the result of requestConversionDataBySocket async thunk
    builder.addCase(actionToBringInProductSalesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      actionToBringInProductSalesData.fulfilled,
      (state, action) => {
        state.loading = false;
        state.response = {
          data: [...state.response.data, ...action.payload.data],
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalCount: action.payload.totalCount,
        };
      }
    );
    builder.addCase(
      actionToBringInProductSalesData.rejected,
      (state, action) => {
        state.loading = false;
        state.socketError = action.error.message;
      }
    );
  },
});

// Export the socket reducer
export const reducerToBringInProductSalesData = bringInProductSalesData.reducer;

// Export the resetState action
export const { resetState } = bringInProductSalesData.actions;
