import { useEffect } from "react";

export function useScrollRestorationById(isMounted) {
  useEffect(() => {
    const itemIdToScrollTo = sessionStorage.getItem("scrollId");

    const scrollIfPossible = () => {
      const itemElement = document.getElementById(itemIdToScrollTo);
      if (itemElement && (isMounted === null || isMounted === true)) {
        itemElement.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Delay execution to ensure sessionStorage is ready
    const timeout = setTimeout(() => {
      scrollIfPossible();
    }, 400); // Adjust the delay time if needed

    return () => clearTimeout(timeout);
  }, [isMounted]); // Empty dependency array means this effect runs once when the component mounts
}
