// @desc Create an order for a material
export const ORDER_MANAGE_CREATE_REQUEST = "ORDER_MANAGE_CREATE_REQUEST";
export const ORDER_MANAGE_CREATE_SUCCESS = "ORDER_MANAGE_CREATE_SUCCESS";
export const ORDER_MANAGE_CREATE_FAIL = "ORDER_MANAGE_CREATE_FAIL";
export const ORDER_MANAGE_CREATE_RESET = "ORDER_MANAGE_CREATE_RESET";

// @desc List managed orders
export const ORDER_MANAGE_LIST_REQUEST = "ORDER_MANAGE_LIST_REQUEST";
export const ORDER_MANAGE_LIST_SUCCESS = "ORDER_MANAGE_LIST_SUCCESS";
export const ORDER_MANAGE_LIST_FAIL = "ORDER_MANAGE_LIST_FAIL";
export const ORDER_MANAGE_LIST_RESET = "ORDER_MANAGE_LIST_RESET";

// @desc Update managed order status
export const ORDER_MANAGE_STATUS_REQUEST = "ORDER_MANAGE_STATUS_REQUEST";
export const ORDER_MANAGE_STATUS_SUCCESS = "ORDER_MANAGE_STATUS_SUCCESS";
export const ORDER_MANAGE_STATUS_FAIL = "ORDER_MANAGE_STATUS_FAIL";
export const ORDER_MANAGE_STATUS_RESET = "ORDER_MANAGE_STATUS_RESET";

// @desc Managed order details
export const ORDER_MANAGE_DETAILS_REQUEST = "ORDER_MANAGE_DETAILS_REQUEST";
export const ORDER_MANAGE_DETAILS_SUCCESS = "ORDER_MANAGE_DETAILS_SUCCESS";
export const ORDER_MANAGE_DETAILS_FAIL = "ORDER_MANAGE_DETAILS_FAIL";
export const ORDER_MANAGE_DETAILS_RESET = "ORDER_MANAGE_DETAILS_RESET";
