import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToUpdateWholeSaleOrderWithFreeGoods = createAsyncThunk(
  "wholeSale/updateOrderWithFreeGoods ",
  async (updateData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/updateWholeSaleOrderWithFreeGoods",
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  updatedWholeSaleOrderWithFreeGoods: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleOrderWithFreeGoodsSlice = createSlice({
  name: "updateWholeSaleOrderWithFreeGoods",
  initialState,
  reducers: {
    resetUpdateWholeSaleOrderWithFreeGoods: (state) => {
      state.updatedWholeSaleOrderWithFreeGoods = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateWholeSaleOrderWithFreeGoods.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToUpdateWholeSaleOrderWithFreeGoods.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updatedWholeSaleOrderWithFreeGoods = action.payload;
        }
      )
      .addCase(
        actionToUpdateWholeSaleOrderWithFreeGoods.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetUpdateWholeSaleOrderWithFreeGoods } =
  updateWholeSaleOrderWithFreeGoodsSlice.actions;

// Export the reducer
const reducerToUpdateWholeSaleOrderWithFreeGoods =
  updateWholeSaleOrderWithFreeGoodsSlice.reducer;

// Export the async action creator for use in components
export {
  reducerToUpdateWholeSaleOrderWithFreeGoods,
  resetUpdateWholeSaleOrderWithFreeGoods,
};
