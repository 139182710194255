import {
  ADMIN_SUPPORT_STATUS_CREATE_FAIL,
  ADMIN_SUPPORT_STATUS_CREATE_REQUEST,
  ADMIN_SUPPORT_STATUS_CREATE_RESET,
  ADMIN_SUPPORT_STATUS_CREATE_SUCCESS,
  ADMIN_SUPPORT_STATUS_DELETE_FAIL,
  ADMIN_SUPPORT_STATUS_DELETE_REQUEST,
  ADMIN_SUPPORT_STATUS_DELETE_RESET,
  ADMIN_SUPPORT_STATUS_DELETE_SUCCESS,
  ADMIN_SUPPORT_STATUS_DETAILS_FAIL,
  ADMIN_SUPPORT_STATUS_DETAILS_REQUEST,
  ADMIN_SUPPORT_STATUS_DETAILS_SUCCESS,
  ADMIN_SUPPORT_STATUS_EDIT_FAIL,
  ADMIN_SUPPORT_STATUS_EDIT_REQUEST,
  ADMIN_SUPPORT_STATUS_EDIT_RESET,
  ADMIN_SUPPORT_STATUS_EDIT_SUCCESS,
  ADMIN_SUPPORT_STATUS_LIST_FAIL,
  ADMIN_SUPPORT_STATUS_LIST_REQUEST,
  ADMIN_SUPPORT_STATUS_LIST_SUCCESS,
  ADMIN_SUPPORT_UPDATE_FAIL,
  ADMIN_SUPPORT_UPDATE_REQUEST,
  ADMIN_SUPPORT_UPDATE_RESET,
  ADMIN_SUPPORT_UPDATE_SUCCESS,
  SUPPORT_ADMIN_DELETE_MESSAGE_FAIL,
  SUPPORT_ADMIN_DELETE_MESSAGE_REQUEST,
  SUPPORT_ADMIN_DELETE_MESSAGE_SUCCESS,
  SUPPORT_ADMIN_LIST_FAIL,
  SUPPORT_ADMIN_LIST_REQUEST,
  SUPPORT_ADMIN_LIST_SUCCESS,
  SUPPORT_ADMIN_MESSAGE_FAIL,
  SUPPORT_ADMIN_MESSAGE_REQUEST,
  SUPPORT_ADMIN_MESSAGE_SUCCESS,
  SUPPORT_ADMIN_SMS_FAIL,
  SUPPORT_ADMIN_SMS_REQUEST,
  SUPPORT_ADMIN_SMS_SUCCESS,
  SUPPORT_ADMIN_USER_LIST_FAIL,
  SUPPORT_ADMIN_USER_LIST_REQUEST,
  SUPPORT_ADMIN_USER_LIST_SUCCESS,
  SUPPORT_CREATE_FAIL,
  SUPPORT_CREATE_REQUEST,
  SUPPORT_CREATE_RESET,
  SUPPORT_CREATE_SUCCESS,
  SUPPORT_DETAILS_FAIL,
  SUPPORT_DETAILS_REQUEST,
  SUPPORT_DETAILS_SUCCESS,
  SUPPORT_NEW_MESSAGE_FAIL,
  SUPPORT_NEW_MESSAGE_REQUEST,
  SUPPORT_NEW_MESSAGE_SUCCESS,
  SUPPORT_USER_CLOSE_FAIL,
  SUPPORT_USER_CLOSE_REQUEST,
  SUPPORT_USER_CLOSE_RESET,
  SUPPORT_USER_CLOSE_SUCCESS,
  SUPPORT_USER_LIST_FAIL,
  SUPPORT_USER_LIST_REQUEST,
  SUPPORT_USER_LIST_SUCCESS,
  SUPPORT_WEB_FORM_FAIL,
  SUPPORT_WEB_FORM_REQUEST,
  SUPPORT_WEB_FORM_RESET,
  SUPPORT_WEB_FORM_SUCCESS,
} from "../constants/supportConstants";

//SAVE THE CREATED BRAND IN REDUX STATE
export const reducerSupportToCreateSupportMessage = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case SUPPORT_CREATE_REQUEST:
      return { loading: true };
    case SUPPORT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        createdSupportRequest: action.payload.createdSupportRequest,
      };
    case SUPPORT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SUPPORT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE SUPPORT REQUESTS LIST FOR USER IN REDUX STATE
export function reducerSupportToListUserRequests(
  state = { loading: true, supportRequestListForUser: [] },
  action
) {
  switch (action.type) {
    case SUPPORT_USER_LIST_REQUEST:
      return { loading: true, ...state };
    case SUPPORT_USER_LIST_SUCCESS:
      return {
        loading: false,
        supportRequestListForUser: action.payload,
      };
    case SUPPORT_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
//SAVE THE SUPPORT REQUESTS LIST FOR USER IN REDUX STATE
export function reducerSupportAdminToListUserRequests(
  state = { loading: true, supportRequestListForUser: [] },
  action
) {
  switch (action.type) {
    case SUPPORT_ADMIN_USER_LIST_REQUEST:
      return { loading: true, ...state };
    case SUPPORT_ADMIN_USER_LIST_SUCCESS:
      return {
        loading: false,
        supportRequestListForUser: action.payload,
      };
    case SUPPORT_ADMIN_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE CLOSED REQUEST DETAILS IN REDUX STATE
export function reducerSupportToUserCloseRequest(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_USER_CLOSE_REQUEST:
      return { loading: true };
    case SUPPORT_USER_CLOSE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case SUPPORT_USER_CLOSE_FAIL:
      return { loading: false, error: action.payload };
    case SUPPORT_USER_CLOSE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE CLOSED REQUEST DETAILS IN REDUX STATE
export function reducerSupportToGetSupportDetails(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_DETAILS_REQUEST:
      return { loading: true };
    case SUPPORT_DETAILS_SUCCESS:
      return {
        loading: false,
        supportDetails: action.payload,
      };
    case SUPPORT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE SEND NEW MESSAGE IN REDUX STATE
export function reducerSupportToSendNewMessage(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_NEW_MESSAGE_REQUEST:
      return { loading: true };
    case SUPPORT_NEW_MESSAGE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case SUPPORT_NEW_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
//ADMIN SEND NEW MESSAGE IN REDUX STATE
export function reducerSupportToAdminSendNewMessage(
  state = { loading: true, success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_ADMIN_MESSAGE_REQUEST:
      return { loading: true };
    case SUPPORT_ADMIN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case SUPPORT_ADMIN_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN REQUESTS SUPPORT REQUESTS LIST
export function reducerSupportAdminListUserRequests(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_ADMIN_LIST_REQUEST:
      return { loading: true };
    case SUPPORT_ADMIN_LIST_SUCCESS:
      return {
        loading: false,
        adminSupportRequestsList: action.payload.adminSupportRequestList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case SUPPORT_ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN SENDS MESSAGE SENT SMS
export function reducerSupportToAdminSendsMessageSms(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_ADMIN_SMS_REQUEST:
      return { loading: true };
    case SUPPORT_ADMIN_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case SUPPORT_ADMIN_SMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN DELETES SENT MESSAGE
export function reducerSupportToAdminDeleteSentMessage(
  state = { success: false },
  action
) {
  switch (action.type) {
    case SUPPORT_ADMIN_DELETE_MESSAGE_REQUEST:
      return { loading: true };
    case SUPPORT_ADMIN_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case SUPPORT_ADMIN_DELETE_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE CREATED BRAND IN REDUX STATE
export const reducerAdminToCreateSupportStatus = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_SUPPORT_STATUS_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SUPPORT_STATUS_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        supportStatus: action.payload,
      };
    case ADMIN_SUPPORT_STATUS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SUPPORT_STATUS_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF BRANDS IN REDUX STATE
export function reducerAdminToListSupportStatus(
  state = { loading: true, supportStatusList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_SUPPORT_STATUS_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_SUPPORT_STATUS_LIST_SUCCESS:
      return {
        loading: false,
        supportStatusList: action.payload,
      };
    case ADMIN_SUPPORT_STATUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF BRAND IN REDUX STATE
export function reducerAdminToDetailsSupportStatus(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_SUPPORT_STATUS_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_SUPPORT_STATUS_DETAILS_SUCCESS:
      return {
        loading: false,
        supportStatusDetails: action.payload,
      };
    case ADMIN_SUPPORT_STATUS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF BRAND IN REDUX STATE
export function reducerAdminToEditSupportStatus(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_SUPPORT_STATUS_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_SUPPORT_STATUS_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_SUPPORT_STATUS_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SUPPORT_STATUS_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF BRAND IN REDUX STATE
export function reducerAdminToDeleteSupportStatus(state = {}, action) {
  switch (action.type) {
    case ADMIN_SUPPORT_STATUS_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SUPPORT_STATUS_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_SUPPORT_STATUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SUPPORT_STATUS_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE THE UPDATE DETAILS IN REDUX STATE
export function reducerAdminToUpdateSupportRequest(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_SUPPORT_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_SUPPORT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_SUPPORT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SUPPORT_UPDATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

// A VISITOR OR USER FILL A FORM
export const reducerSupportToCreateWebFormMessage = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case SUPPORT_WEB_FORM_REQUEST:
      return { loading: true };
    case SUPPORT_WEB_FORM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case SUPPORT_WEB_FORM_FAIL:
      return { loading: false, error: action.payload };
    case SUPPORT_WEB_FORM_RESET:
      return {};
    default:
      return state;
  }
};
