import {
  NOTIFICATION_LIST_FAIL,
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_STATUS_UPDATE_FAIL,
  NOTIFICATION_STATUS_UPDATE_REQUEST,
  NOTIFICATION_STATUS_UPDATE_RESET,
  NOTIFICATION_STATUS_UPDATE_SUCCESS,
} from "../constants/notificationConstants";

// @desc List notifications
export function reducerListNotifications(
  state = { loading: true, notificationsList: [] },
  action
) {
  switch (action.type) {
    case NOTIFICATION_LIST_REQUEST:
      return { loading: true, ...state };
    case NOTIFICATION_LIST_SUCCESS:
      return {
        loading: false,
        notificationsList: action.payload.notificationsList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case NOTIFICATION_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
}

// @desc Update notifications
export function reducerUpdateNotification(
  state = { loading: false, success: false },
  action
) {
  switch (action.type) {
    case NOTIFICATION_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case NOTIFICATION_STATUS_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case NOTIFICATION_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload.result };
    case NOTIFICATION_STATUS_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
}
