import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import SuspenseFallback from "../SuspenseFallback";
import PrivateRouteWholeSaleUser from "./PrivateRouteWholeSaleUser";
import NavigationTop from "../../modules/customerInterface/components/navigation/NavigationTop";
import NavigationBottom from "../../modules/customerInterface/components/navigation/NavigationBottom";
import { useWholeSaleUserInfo } from "../../modules/customerInterface/wholeSale/hooks/useWholeSaleUserInfo";
import { useFreeGoodsAllocation } from "../../modules/customerInterface/wholeSale/hooks/useFreeGoodsAllocation";
import { useOrdersListByAccount } from "../../modules/customerInterface/wholeSale/hooks/useOrdersListByAccount";
import { useRedirectToSignInWhenTokenExpired } from "../SignInBySms/hooks/useRedirectToSignInWhenTokenExpired";
import { resetGetDetailsWholeSaleAccount } from "../../modules/customerInterface/wholeSale/actions/actionToGetDetailsWholeSaleAccount";
import { useSelector } from "react-redux";
import { useCartTotals } from "../../modules/customerInterface/wholeSale/cart/hooks/useCartTotals";
import { useSaveCartInDataBase } from "../../modules/customerInterface/wholeSale/hooks/useSaveCartInDataBase";

const SignInScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/authorization/signIn/SignInScreen"
  )
);
const SignUpScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/authorization/signUp/SignUpScreen"
  )
);
const AccountScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/account/accountHome/AccountScreen"
  )
);
const CompanyInfoScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/account/accountCompanyInfo/CompanyInfoScreen"
  )
);
const ProductsScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/products/productList/ProductsScreen"
  )
);
const DeliveryAddressesScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/account/accountDeliveryAddress/DeliveryAddressesScreen"
  )
);
const OrderScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/orders/orderCreate/OrderScreen"
  )
);
const ThankYouScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/orders/orderThankYou/ThankYouScreen"
  )
);
const OrdersListScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/orders/ordersList/OrdersListScreen"
  )
);
const OrderDetailsScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/wholeSale/orders/orderDetails/OrderDetailsScreen"
  )
);
const ThreeDsInitScreen = React.lazy(() =>
  import(
    "../../modules/customerInterface/components/paymentCreditCard/ThreeDsInitScreen"
  )
);

export default function AppWholeSaleUser() {
  const locationPath = useLocation().pathname;
  const history = useHistory();

  // SIGN IN AND SIGN UP SCREEN BACKGROUND STYLE
  const backGroundStyle =
    locationPath === "/toptan-giris" || locationPath === "/toptan-kayit"
      ? {
          backgroundColor: "#ff9935",
          height: "100vh",
        }
      : {
          backgroundColor: "white",
          height: "100vh",
        };

  // REDUX STORE CONTEXT "CREATE"
  const createWholeSaleOrder = useSelector(
    (state) => state.createWholeSaleOrder
  );
  const {
    loading: loadingOrderCreate,
    error: errorOrderCreate,
    createdOrder,
  } = createWholeSaleOrder;
  const createDeliveryAddress = useSelector(
    (state) => state.createDeliveryAddress
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    accountWithNewDeliveryAddress,
  } = createDeliveryAddress;

  // REDUX STORE CONTEXT "UPDATE"
  /* Update deliverey address */
  const updateDeliveryAddress = useSelector(
    (state) => state.updateDeliveryAddress
  );
  const {
    loading: loadingUpdateAddress,
    error: errorUpdateAddress,
    accountWithUpdatedDeliveryAddress,
  } = updateDeliveryAddress;
  /* Update account */
  const updateWholeSaleAccount = useSelector(
    (state) => state.updateWholeSaleAccount
  );
  const { loadingUpdate, errorUpdate, updatedWholeSaleAccount } =
    updateWholeSaleAccount;
  /* Update order with free goods */
  const updateWholeSaleOrderWithFreeGoods = useSelector(
    (state) => state.updateWholeSaleOrderWithFreeGoods
  );
  const {
    loading: loadingFreeGoodsOrder,
    error: errorFreeGoodsOrder,
    updatedWholeSaleOrderWithFreeGoods,
  } = updateWholeSaleOrderWithFreeGoods;
  /* Update order status*/
  const updateWholeSaleOrderStatus = useSelector(
    (state) => state.updateWholeSaleOrderStatus
  );
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    updatedWholeSaleOrder,
  } = updateWholeSaleOrderStatus;

  // STATE TO HOLD WHOLESALE USER STATUS
  const {
    authenticatedUser,
    loading,
    error,
    detailsWholeSaleAccount,
    isActive,
    name,
    companyName,
    companyAddress,
    deliveryAddressCount,
    companyProvince,
    companyDistrict,
    companyTaxAuthority,
    companyTaxNumber,
    deliveryAddresses,
    limits,
    wholeSaleType,
    // PAYMENT METHODS
    isCardAllowed,
    isBankTransferAllowed,
    isBankCheckAllowed,
    isBillAllowed,
    isDueAllowed,
    bankCheckTerm,
    billTerm,
    dueTerm,
    bankCheckDown,
    billDown,
    dueDown,
    // ACCOUNT LEVEL PRICONG
    privatePricing,
  } = useWholeSaleUserInfo(
    accountWithNewDeliveryAddress,
    updatedWholeSaleAccount
  );

  // REDIRECT USER TO SIGN IN IF JWT TOKEN IS EXPIRED
  useRedirectToSignInWhenTokenExpired(
    error,
    "/toptan-giris",
    resetGetDetailsWholeSaleAccount
  );

  // BRING IN initiateIyzicoThreeDsPayment FROM REDUX STORE
  const initiateIyzicoThreeDsPayment = useSelector(
    (state) => state.initiateIyzicoThreeDsPayment
  );
  const {
    loading: loadingInitiate,
    error: errorInitiate,
    threeDSHtmlContent,
  } = initiateIyzicoThreeDsPayment;
  useEffect(() => {
    if (threeDSHtmlContent) {
      history.push("/toptan-siparis-3ds-guvenli-odeme-sayfasi");
    }
  }, [threeDSHtmlContent, history]);

  // CHECK THE ORDERS TO GIVE INFO IN ORDERS WIDGET
  /* State to trigger order history per account */
  const [triggerOrdersList, setTriggerOrdersList] = useState(false);
  const { loadingOrdersList, errorOrdersList, ordersInfo, orders } =
    useOrdersListByAccount(
      detailsWholeSaleAccount?._id,
      createdOrder,
      updatedWholeSaleAccount,
      updatedWholeSaleOrderWithFreeGoods,
      updatedWholeSaleOrder,
      triggerOrdersList
    );

  // BRING IN CART ITEMS
  const addOrRemoveWholeSaleItemInCart = useSelector(
    (state) => state.addOrRemoveWholeSaleItemInCart
  );
  const { loadingCart, errorCart, itemAddedInCart, wholeSaleCartItems } =
    addOrRemoveWholeSaleItemInCart;

  // CART TOTALS
  const {
    cartTotalVatIncluded,
    cartTotalVatExcluded,
    cartQuantity,
    blinkAgain,
    setBlinkAgain,
  } = useCartTotals();

  // CREATE A DEFAULT CART ID
  const currentMiliseconds = Date.now();
  sessionStorage.setItem("cartId", currentMiliseconds);

  // SAVE CART IN DATABASE
  useSaveCartInDataBase(
    cartQuantity,
    detailsWholeSaleAccount,
    wholeSaleCartItems
  );

  // BRING IN ERC MODAL TOGGLE STATE FROM REDUX STORE
  const toggleErcModal = useSelector((state) => state.toggleErcModal);
  const { isModalOpen } = toggleErcModal;

  // CHECK IF THERE IS AN ORDER PENDING FOR FREE GOODS ALLOCATION COMPLETED
  const {
    setFreeGoodsAllocationActive,
    freeGoodsAllocationActive,
    orderNumber,
  } = useFreeGoodsAllocation(
    "",
    "",
    "",
    "",
    "",
    "",
    updatedWholeSaleOrderWithFreeGoods
  );

  return (
    <main style={backGroundStyle}>
      {locationPath !== "/toptan-giris" && locationPath !== "/toptan-kayit" && (
        <NavigationTop
          freeGoodsAllocationActive={freeGoodsAllocationActive}
          setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
          orderNumber={orderNumber}
          detailsWholeSaleAccount={detailsWholeSaleAccount}
          limits={limits}
          cartTotalVatExcluded={cartTotalVatExcluded}
          wholeSaleCartItems={wholeSaleCartItems}
          cartQuantity={cartQuantity}
          blinkAgain={blinkAgain}
          setBlinkAgain={setBlinkAgain}
          ordersInfo={ordersInfo}
          isModalOpen={isModalOpen}
          privatePricing={privatePricing}
        />
      )}

      <Suspense fallback={<SuspenseFallback></SuspenseFallback>}>
        <Switch>
          <Route
            exact
            path={"/toptan-giris"}
            render={(props) => (
              <SignInScreen authenticatedUser={authenticatedUser} {...props} />
            )}
          />
          <Route exact path={"/toptan-kayit"} component={SignUpScreen} />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-hesabi"}
            render={(props) => (
              <AccountScreen
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                isActive={isActive}
                name={name}
                companyName={companyName}
                companyAddress={companyAddress}
                deliveryAddressCount={deliveryAddressCount}
                loadingOrdersList={loadingOrdersList}
                errorOrdersList={errorOrdersList}
                ordersInfo={ordersInfo}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-firma-bilgileri"}
            render={(props) => (
              <CompanyInfoScreen
                loading={loading}
                error={error}
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                loadingUpdate={loadingUpdate}
                errorUpdate={errorUpdate}
                updatedWholeSaleAccount={updatedWholeSaleAccount}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-teslimat-adresleri"}
            render={(props) => (
              <DeliveryAddressesScreen
                loading={loading}
                error={error}
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                deliveryAddressCount={deliveryAddressCount}
                loadingUpdateAddress={loadingUpdateAddress}
                errorUpdateAddress={errorUpdateAddress}
                accountWithUpdatedDeliveryAddress={
                  accountWithUpdatedDeliveryAddress
                }
                loadingCreate={loadingCreate}
                errorCreate={errorCreate}
                accountWithNewDeliveryAddress={accountWithNewDeliveryAddress}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-siparis-detay"}
            render={(props) => (
              <OrderDetailsScreen
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                loadingUpdateStatus={loadingUpdateStatus}
                errorUpdateStatus={errorUpdateStatus}
                updatedWholeSaleOrder={updatedWholeSaleOrder}
                setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                // 3DS CARD PAYMENT INITIATE
                loadingInitiate={loadingInitiate}
                errorInitiate={errorInitiate}
                threeDSHtmlContent={threeDSHtmlContent}
                // PAYMENT METHODS
                isCardAllowed={isCardAllowed}
                isBankTransferAllowed={isBankTransferAllowed}
                isBankCheckAllowed={isBankCheckAllowed}
                isBillAllowed={isBillAllowed}
                isDueAllowed={isDueAllowed}
                bankCheckTerm={bankCheckTerm}
                billTerm={billTerm}
                dueTerm={dueTerm}
                bankCheckDown={bankCheckDown}
                billDown={billDown}
                dueDown={dueDown}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-siparis-onayi"}
            render={(props) => (
              <OrderScreen
                freeGoodsAllocationActive={freeGoodsAllocationActive}
                setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                isActive={isActive}
                name={name}
                companyName={companyName}
                companyAddress={companyAddress}
                companyProvince={companyProvince}
                companyDistrict={companyDistrict}
                companyTaxAuthority={companyTaxAuthority}
                companyTaxNumber={companyTaxNumber}
                deliveryAddresses={deliveryAddresses}
                limits={limits}
                wholeSaleCartItems={wholeSaleCartItems}
                cartTotalVatIncluded={cartTotalVatIncluded}
                cartTotalVatExcluded={cartTotalVatExcluded}
                loadingOrderCreate={loadingOrderCreate}
                errorOrderCreate={errorOrderCreate}
                createdOrder={createdOrder}
                loadingFreeGoodsOrder={loadingFreeGoodsOrder}
                errorFreeGoodsOrder={errorFreeGoodsOrder}
                updatedWholeSaleOrderWithFreeGoods={
                  updatedWholeSaleOrderWithFreeGoods
                }
                // 3DS CARD PAYMENT INITIATE
                loadingInitiate={loadingInitiate}
                errorInitiate={errorInitiate}
                threeDSHtmlContent={threeDSHtmlContent}
                // PAYMENT METHODS
                isCardAllowed={isCardAllowed}
                isBankTransferAllowed={isBankTransferAllowed}
                isBankCheckAllowed={isBankCheckAllowed}
                isBillAllowed={isBillAllowed}
                isDueAllowed={isDueAllowed}
                bankCheckTerm={bankCheckTerm}
                billTerm={billTerm}
                dueTerm={dueTerm}
                bankCheckDown={bankCheckDown}
                billDown={billDown}
                dueDown={dueDown}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-siparislerim"}
            render={(props) => (
              <OrdersListScreen
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                companyName={companyName}
                cartTotalVatExcluded={cartTotalVatExcluded}
                loadingOrdersList={loadingOrdersList}
                errorOrdersList={errorOrdersList}
                orders={orders}
                loadingOrderCreate={loadingOrderCreate}
                errorOrderCreate={errorOrderCreate}
                createdOrder={createdOrder}
                // 3DS CARD PAYMENT INITIATE
                loadingInitiate={loadingInitiate}
                errorInitiate={errorInitiate}
                threeDSHtmlContent={threeDSHtmlContent}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-urunler"}
            render={(props) => (
              <ProductsScreen
                freeGoodsAllocationActive={freeGoodsAllocationActive}
                detailsWholeSaleAccount={detailsWholeSaleAccount}
                isActive={isActive}
                wholeSaleType={wholeSaleType}
                cartTotalVatExcluded={cartTotalVatExcluded}
                isModalOpen={isModalOpen}
                loadingCart={loadingCart}
                errorCart={errorCart}
                itemAddedInCart={itemAddedInCart}
                wholeSaleCartItems={wholeSaleCartItems}
                privatePricing={privatePricing}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-siparis-tesekkurler"}
            render={(props) => (
              <ThankYouScreen
                authenticatedUser={authenticatedUser}
                companyName={companyName}
                setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                {...props}
              />
            )}
          />
          <PrivateRouteWholeSaleUser
            exact
            path={"/toptan-siparis-3ds-guvenli-odeme-sayfasi"}
            render={(props) => (
              <ThreeDsInitScreen
                loadingInitiate={loadingInitiate}
                errorInitiate={errorInitiate}
                threeDSHtmlContent={threeDSHtmlContent}
                setTriggerOrdersList={setTriggerOrdersList}
                {...props}
              />
            )}
          />
        </Switch>
      </Suspense>

      {locationPath !== "/toptan-giris" && locationPath !== "/toptan-kayit" && (
        <NavigationBottom
          freeGoodsAllocationActive={freeGoodsAllocationActive}
          orderNumber={orderNumber}
          wholeSaleCartItems={wholeSaleCartItems}
          cartQuantity={cartQuantity}
          blinkAgain={blinkAgain}
          setBlinkAgain={setBlinkAgain}
          limits={limits}
          cartTotalVatExcluded={cartTotalVatExcluded}
          isModalOpen={isModalOpen}
          privatePricing={privatePricing}
        />
      )}
    </main>
  );
}
