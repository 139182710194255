//ADMIN PRODUCT CREATE
export const ADMIN_PRODUCT_CREATE_REQUEST = "ADMIN_PRODUCT_CREATE_REQUEST";
export const ADMIN_PRODUCT_CREATE_SUCCESS = "ADMIN_PRODUCT_CREATE_SUCCESS";
export const ADMIN_PRODUCT_CREATE_FAIL = "ADMIN_PRODUCT_CREATE_FAIL";
export const ADMIN_PRODUCT_CREATE_RESET = "ADMIN_PRODUCT_CREATE_RESET";

//ADMIN PRODUCT SKU CREATE
export const ADMIN_PRODUCT_SKU_CREATE_REQUEST =
  "ADMIN_PRODUCT_SKU_CREATE_REQUEST";
export const ADMIN_PRODUCT_SKU_CREATE_SUCCESS =
  "ADMIN_PRODUCT_SKU_CREATE_SUCCESS";
export const ADMIN_PRODUCT_SKU_CREATE_FAIL = "ADMIN_PRODUCT_SKU_CREATE_FAIL";
export const ADMIN_PRODUCT_SKU_CREATE_RESET = "ADMIN_PRODUCT_SKU_CREATE_RESET";

//ADMIN PRODUCT SKU LIST
export const ADMIN_PRODUCT_SKU_LIST_REQUEST = "ADMIN_PRODUCT_SKU_LIST_REQUEST";
export const ADMIN_PRODUCT_SKU_LIST_SUCCESS = "ADMIN_PRODUCT_SKU_LIST_SUCCESS";
export const ADMIN_PRODUCT_SKU_LIST_FAIL = "ADMIN_PRODUCT_SKU_LIST_FAIL";
export const ADMIN_PRODUCT_SKU_LIST_RESET = "ADMIN_PRODUCT_SKU_LIST_RESET";

//ADMIN PRODUCT LIST
export const ADMIN_PRODUCT_LIST_REQUEST = "ADMIN_PRODUCT_LIST_REQUEST";
export const ADMIN_PRODUCT_LIST_SUCCESS = "ADMIN_PRODUCT_LIST_SUCCESS";
export const ADMIN_PRODUCT_LIST_FAIL = "ADMIN_PRODUCT_LIST_FAIL";

//ADMIN PRODUCT DETAILS
export const ADMIN_PRODUCT_DETAILS_REQUEST = "ADMIN_PRODUCT_DETAILS_REQUEST";
export const ADMIN_PRODUCT_DETAILS_SUCCESS = "ADMIN_PRODUCT_DETAILS_SUCCESS";
export const ADMIN_PRODUCT_DETAILS_FAIL = "ADMIN_PRODUCT_DETAILS_FAIL";
export const ADMIN_PRODUCT_DETAILS_RESET = "ADMIN_PRODUCT_DETAILS_RESET";

//ADMIN PRODUCT ACTIVE/PASSIVE
export const ADMIN_PRODUCT_ACTIVE_REQUEST = "ADMIN_PRODUCT_ACTIVE_REQUEST";
export const ADMIN_PRODUCT_ACTIVE_SUCCESS = "ADMIN_PRODUCT_ACTIVE_SUCCESS";
export const ADMIN_PRODUCT_ACTIVE_FAIL = "ADMIN_PRODUCT_ACTIVE_FAIL";
export const ADMIN_PRODUCT_ACTIVE_RESET = "ADMIN_PRODUCT_ACTIVE_RESET";

//ADMIN PRODUCT EDIT
export const ADMIN_PRODUCT_EDIT_REQUEST = "ADMIN_PRODUCT_EDIT_REQUEST";
export const ADMIN_PRODUCT_EDIT_SUCCESS = "ADMIN_PRODUCT_EDIT_SUCCESS";
export const ADMIN_PRODUCT_EDIT_FAIL = "ADMIN_PRODUCT_EDIT_FAIL";
export const ADMIN_PRODUCT_EDIT_RESET = "ADMIN_PRODUCT_EDIT_RESET";

//ADMIN PRODUCT SKU DETAILS
export const ADMIN_PRODUCT_SKU_DETAILS_REQUEST =
  "ADMIN_PRODUCT_SKU_DETAILS_REQUEST";
export const ADMIN_PRODUCT_SKU_DETAILS_SUCCESS =
  "ADMIN_PRODUCT_SKU_DETAILS_SUCCESS";
export const ADMIN_PRODUCT_SKU_DETAILS_FAIL = "ADMIN_PRODUCT_SKU_DETAILS_FAIL";
export const ADMIN_PRODUCT_SKU_DETAILS_RESET =
  "ADMIN_PRODUCT_SKU_DETAILS_RESET";

//ADMIN PRODUCT SKU EDIT
export const ADMIN_PRODUCT_SKU_EDIT_REQUEST = "ADMIN_PRODUCT_SKU_EDIT_REQUEST";
export const ADMIN_PRODUCT_SKU_EDIT_SUCCESS = "ADMIN_PRODUCT_SKU_EDIT_SUCCESS";
export const ADMIN_PRODUCT_SKU_EDIT_FAIL = "ADMIN_PRODUCT_SKU_EDIT_FAIL";
export const ADMIN_PRODUCT_SKU_EDIT_RESET = "ADMIN_PRODUCT_SKU_EDIT_RESET";

//ADMIN DEPARTMENT DELETE
export const ADMIN_PRODUCT_DELETE_REQUEST = "ADMIN_PRODUCT_DELETE_REQUEST";
export const ADMIN_PRODUCT_DELETE_SUCCESS = "ADMIN_PRODUCT_DELETE_SUCCESS";
export const ADMIN_PRODUCT_DELETE_FAIL = "ADMIN_PRODUCT_DELETE_FAIL";
export const ADMIN_PRODUCT_DELETE_RESET = "ADMIN_PRODUCT_DELETE_RESET";

//ADMIN GET SECTION DETAILS
export const ADMIN_SECTION_DETAILS_REQUEST = "ADMIN_SECTION_DETAILS_REQUEST";
export const ADMIN_SECTION_DETAILS_SUCCESS = "ADMIN_SECTION_DETAILS_SUCCESS";
export const ADMIN_SECTION_DETAILS_FAIL = "ADMIN_SECTION_DETAILS_FAIL";
export const ADMIN_SECTION_DETAILS_RESET = "ADMIN_SECTION_DETAILS_RESET";

//ADMIN DELETE SECTION DETAILS
export const ADMIN_SECTION_DELETE_REQUEST = "ADMIN_SECTION_DELETE_REQUEST";
export const ADMIN_SECTION_DELETE_SUCCESS = "ADMIN_SECTION_DELETE_SUCCESS";
export const ADMIN_SECTION_DELETE_FAIL = "ADMIN_SECTION_DELETE_FAIL";
export const ADMIN_SECTION_DELETE_RESET = "ADMIN_SECTION_DELETE_RESET";

//RECIPE CREATE
/*export const ADMIN_RECIPE_CREATE_REQUEST = "ADMIN_RECIPE_CREATE_REQUEST";
export const ADMIN_RECIPE_CREATE_SUCCESS = "ADMIN_RECIPE_CREATE_SUCCESS";
export const ADMIN_RECIPE_CREATE_FAIL = "ADMIN_RECIPE_CREATE_FAIL";
export const ADMIN_RECIPE_CREATE_RESET = "ADMIN_RECIPE_CREATE_RESET";

//RECIPE COST
export const ADMIN_RECIPE_COST_REQUEST = "ADMIN_RECIPE_COST_REQUEST";
export const ADMIN_RECIPE_COST_SUCCESS = "ADMIN_RECIPE_COST_SUCCESS";
export const ADMIN_RECIPE_COST_FAIL = "ADMIN_RECIPE_COST_FAIL";
export const ADMIN_RECIPE_COST_RESET = "ADMIN_RECIPE_COST_RESET";

//DELETE RECIPE ITEM
export const ADMIN_DELETE_RECIPE_ITEM_REQUEST =
  "ADMIN_DELETE_RECIPE_ITEM_REQUEST";
export const ADMIN_DELETE_RECIPE_ITEM_SUCCESS =
  "ADMIN_DELETE_RECIPE_ITEM_SUCCESS";
export const ADMIN_DELETE_RECIPE_ITEM_FAIL = "ADMIN_DELETE_RECIPE_ITEM_FAIL";
export const ADMIN_DELETE_RECIPE_ITEM_RESET = "ADMIN_DELETE_RECIPE_ITEM_RESET";

//DETAILS OF RECIPE ITEM
export const ADMIN_RECIPE_ITEM_DETAIL_REQUEST =
  "ADMIN_RECIPE_ITEM_DETAIL_REQUEST";
export const ADMIN_RECIPE_ITEM_DETAIL_SUCCESS =
  "ADMIN_RECIPE_ITEM_DETAIL_SUCCESS";
export const ADMIN_RECIPE_ITEM_DETAIL_FAIL = "ADMIN_RECIPE_ITEM_DETAIL_FAIL";
export const ADMIN_RECIPE_ITEM_DETAIL_RESET = "ADMIN_RECIPE_ITEM_DETAIL_RESET";

//UPDATE DETAILS OF RECIPE ITEM
export const ADMIN_UPDATE_RECIPE_ITEM_REQUEST =
  "ADMIN_UPDATE_RECIPE_ITEM_REQUEST";
export const ADMIN_UPDATE_RECIPE_ITEM_SUCCESS =
  "ADMIN_UPDATE_RECIPE_ITEM_SUCCESS";
export const ADMIN_UPDATE_RECIPE_ITEM_FAIL = "ADMIN_UPDATE_RECIPE_ITEM_FAIL";
export const ADMIN_UPDATE_RECIPE_ITEM_RESET = "ADMIN_UPDATE_RECIPE_ITEM_RESET";*/

//ADMIN PRODUCT LIST FOR SHIPPING MESSAGE
export const ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_REQUEST =
  "ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_REQUEST";
export const ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_SUCCESS =
  "ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_SUCCESS";
export const ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_FAIL =
  "ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_FAIL";
export const ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_RESET =
  "ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_RESET";

//ADMIN UPDATE SHIPPING MESSAGE
export const ADMIN_UPDATE_SHIPPING_MESSAGE_REQUEST =
  "ADMIN_UPDATE_SHIPPING_MESSAGE_REQUEST";
export const ADMIN_UPDATE_SHIPPING_MESSAGE_SUCCESS =
  "ADMIN_UPDATE_SHIPPING_MESSAGE_SUCCESS";
export const ADMIN_UPDATE_SHIPPING_MESSAGE_FAIL =
  "ADMIN_UPDATE_SHIPPING_MESSAGE_FAIL";
export const ADMIN_UPDATE_SHIPPING_MESSAGE_RESET =
  "ADMIN_UPDATE_SHIPPING_MESSAGE_RESET";

//ADMIN PRODUCT LIST FOR SMS CAMPAIGN
export const ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_REQUEST =
  "ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_REQUEST";
export const ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_SUCCESS =
  "ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_SUCCESS";
export const ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_FAIL =
  "ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_FAIL";
export const ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_RESET =
  "ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_RESET";

// @desc Update multiple products at same time
export const ADMIN_PRODUCT_BULK_UPDATE_REQUEST =
  "ADMIN_PRODUCT_BULK_UPDATE_REQUEST";
export const ADMIN_PRODUCT_BULK_UPDATE_SUCCESS =
  "ADMIN_PRODUCT_BULK_UPDATE_SUCCESS";
export const ADMIN_PRODUCT_BULK_UPDATE_FAIL = "ADMIN_PRODUCT_BULK_UPDATE_FAIL";
export const ADMIN_PRODUCT_BULK_UPDATE_RESET =
  "ADMIN_PRODUCT_BULK_UPDATE_RESET";
