import { useEffect, useState } from "react";

export function useGreetings() {
  // CREATE GREETINGS
  const [greeting, setGreeting] = useState("");
  useEffect(() => {
    // Get the current UTC time
    const utcTime = new Date();

    // Check if Daylight Saving Time is in effect (between March and October)
    const isDst = (date) => {
      const dstStart = new Date(
        date.getFullYear(),
        2,
        31 - ((date.getDay() + 6) % 7),
        3
      );
      const dstEnd = new Date(
        date.getFullYear(),
        9,
        31 - ((date.getDay() + 6) % 7),
        4
      );
      return date >= dstStart && date < dstEnd;
    };

    // Calculate the hour offset for Turkish time
    const hourOffset = isDst(utcTime) ? 4 : 3;

    // Get the current day in Turkish time (0: Sunday, 1: Monday, ..., 6: Saturday)
    const currentDayInTurkey = (utcTime.getUTCDay() + hourOffset / 24) % 7;

    // Check if it is Sunday in Turkish time (currentDayInTurkey = 0)
    const isSundayInTurkey = Math.floor(currentDayInTurkey) === 0;

    // Get the current hour in Turkish time
    const currentHourInTurkey = (utcTime.getUTCHours() + hourOffset) % 24;

    if (
      isSundayInTurkey &&
      currentHourInTurkey >= 6 &&
      currentHourInTurkey <= 20
    ) {
      setGreeting("İyi Pazar'lar dileriz");
    } else if (currentHourInTurkey >= 6 && currentHourInTurkey <= 12) {
      setGreeting("Günaydın");
    } else if (currentHourInTurkey > 12 && currentHourInTurkey <= 18) {
      setGreeting("İyi günler");
    } else if (currentHourInTurkey > 18 && currentHourInTurkey <= 22) {
      setGreeting("İyi akşamlar");
    } else if (
      (currentHourInTurkey > 22 && currentHourInTurkey <= 24) ||
      (currentHourInTurkey >= 1 && currentHourInTurkey <= 3) ||
      currentHourInTurkey === 0
    ) {
      setGreeting("İyi geceler");
    } else if (currentHourInTurkey > 3 && currentHourInTurkey < 6) {
      setGreeting("İyi sabahlar");
    }
  }, []);

  return greeting;
}
