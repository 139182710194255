import Axios from "axios";
import {
  ADMIN_CREATE_DISCOUNT_FAIL,
  ADMIN_CREATE_DISCOUNT_REQUEST,
  ADMIN_CREATE_DISCOUNT_SUCCESS,
  ADMIN_DETAILS_DISCOUNT_FAIL,
  ADMIN_DETAILS_DISCOUNT_REQUEST,
  ADMIN_DETAILS_DISCOUNT_SUCCESS,
  ADMIN_EDIT_DISCOUNT_FAIL,
  ADMIN_EDIT_DISCOUNT_REQUEST,
  ADMIN_EDIT_DISCOUNT_SUCCESS,
  ADMIN_LIST_DISCOUNTS_FAIL,
  ADMIN_LIST_DISCOUNTS_REQUEST,
  ADMIN_LIST_DISCOUNTS_SUCCESS,
} from "../constants/adminDiscountCodeConstants";

// POST
// CREATE A DISCOUNT CODE
export const actionAdminCreateDiscountCode =
  (codePreferences) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_CREATE_DISCOUNT_REQUEST });

    try {
      const { data } = await Axios.post(
        "/api/admindiscountcode/create",
        codePreferences
      );
      dispatch({ type: ADMIN_CREATE_DISCOUNT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_CREATE_DISCOUNT_FAIL, payload: message });
    }
  };

// GET
// LIST DISCOUNT CODES
export const actionAdminListDiscountCodes =
  (keyword = "", pageNumber = "", listAll = "") =>
  async (dispatch) => {
    dispatch({ type: ADMIN_LIST_DISCOUNTS_REQUEST });

    try {
      const { data } = await Axios.get(
        `/api/admindiscountcode/listdiscountcodes/?keyword=${keyword}&pageNumber=${pageNumber}&listAll=${listAll}`
      );
      dispatch({ type: ADMIN_LIST_DISCOUNTS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_LIST_DISCOUNTS_FAIL, payload: message });
    }
  };

// GET
// GET DETAILS OF DISCOUNT CODES
export const actionAdminGetDetailsOfDiscountCode =
  (discountCodeId) => async (dispatch) => {
    dispatch({ type: ADMIN_DETAILS_DISCOUNT_REQUEST });

    try {
      const { data } = await Axios.get(
        `/api/admindiscountcode/detailsofdiscountcode/${discountCodeId}`
      );
      dispatch({ type: ADMIN_DETAILS_DISCOUNT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_DETAILS_DISCOUNT_FAIL, payload: message });
    }
  };

//PUT
// UPDATE/EDIT DISCOUNT CODE
export const actionAdminEditDiscountCode =
  (discountCodeId) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_EDIT_DISCOUNT_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/admindiscountcode/edit`,
        discountCodeId,
        {
          headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
        }
      );
      dispatch({ type: ADMIN_EDIT_DISCOUNT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_EDIT_DISCOUNT_FAIL, payload: message });
    }
  };
