import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetModal } from "../../../actions/actionToToggleErcModal";

export default function ReturnShoppingOrCheckOut(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // PROPS COMING FROM CartFooter
  const {
    setCloseOnClick,
    isMinOrderLimitReached,
    freeGoodsAllocationActive,
    orderNumber,
  } = props;

  return (
    <div className="row-center-nowrap w100p">
      <button
        type="button"
        className={`primary-free h2r ${
          freeGoodsAllocationActive > 0 || isMinOrderLimitReached
            ? "w50p marginright0-25r"
            : "w100p"
        }`}
        onClick={() => {
          return [
            setCloseOnClick(true),
            dispatch(resetModal()),
            !location.pathname.includes("/toptan-urunler") &&
              history.push("/toptan-urunler"),
          ];
        }}
      >
        Başka Ürün Ekle
      </button>
      {(freeGoodsAllocationActive > 0 || isMinOrderLimitReached) && (
        <button
          type="button"
          className="primary-free h2r w50p marginleft0-25r"
          onClick={() => {
            return [
              history.push(
                freeGoodsAllocationActive > 0
                  ? `toptan-siparis-onayi?orderNumber=${orderNumber}&freeGoodsAllocation=${freeGoodsAllocationActive}`
                  : "toptan-siparis-onayi"
              ),
              setCloseOnClick(true),
              dispatch(resetModal()),
            ];
          }}
        >
          Sepeti Onayla
        </button>
      )}
    </div>
  );
}
