import Axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_SKU_PRICE_REQUEST,
  PRODUCT_SKU_PRICE_SUCCESS,
  PRODUCT_SKU_PRICE_FAIL,
  PRODUCT_SKU_CLASSIFY_REQUEST,
  PRODUCT_SKU_CLASSIFY_SUCCESS,
  PRODUCT_SKU_CLASSIFY_FAIL,
  PRODUCT_DETAILS_CAMPAIGNED_REQUEST,
  PRODUCT_DETAILS_CAMPAIGNED_SUCCESS,
  PRODUCT_DETAILS_CAMPAIGNED_RESET,
  PRODUCT_SKU_COMPAIGNED_REQUEST,
  PRODUCT_SKU_COMPAIGNED_SUCCESS,
  PRODUCT_SKU_COMPAIGNED_FAIL,
  PRODUCT_SIDE_LIST_REQUEST,
  PRODUCT_SIDE_LIST_SUCCESS,
  PRODUCT_SIDE_LIST_FAIL,
} from "../constants/productConstants.js";

export const actionToGetProductList =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const { data } = await Axios.get(
        `/api/products/?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_LIST_FAIL, payload: message });
    }
  };

export const actionToGetProductDetails = (productId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
    const { data } = await Axios.get(
      `/api/products/productdetails/${productId}`
    );
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_DETAILS_FAIL, payload: message });
  }
};
// CAMPAINGNED PRODUCT DETAILS
export const actionToGetCampaignedProductDetails =
  (productId) => async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_DETAILS_CAMPAIGNED_REQUEST,
        payload: productId,
      });
      const { data } = await Axios.get(
        `/api/products/campaignedproductdetails/${productId}`
      );
      dispatch({ type: PRODUCT_DETAILS_CAMPAIGNED_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_DETAILS_CAMPAIGNED_RESET, payload: message });
    }
  };

//GET
//BRING IN SKU PRICE
export const actionToBringInSkuPrice =
  (productId, skuCode) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_SKU_PRICE_REQUEST });
    try {
      const { data } = await Axios.get(
        `/api/products/pricesku/${productId}/${skuCode}`
      );
      dispatch({ type: PRODUCT_SKU_PRICE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_SKU_PRICE_FAIL, payload: message });
    }
  };

//CAMPAIGNED PRODUCT SKU
export const actionToBringInCampaignedSku =
  (productId, skuCode) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_SKU_COMPAIGNED_REQUEST });
    try {
      const { data } = await Axios.get(
        `/api/products/campaignsku/${productId}/${skuCode}`
      );
      dispatch({ type: PRODUCT_SKU_COMPAIGNED_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_SKU_COMPAIGNED_FAIL, payload: message });
    }
  };

//GET
//BRING IN SKU PRICE
export const actionToClassifySkus = (productId) => async (dispatch) => {
  dispatch({ type: PRODUCT_SKU_CLASSIFY_REQUEST });
  try {
    const { data } = await Axios.get(`/api/products/skusclassify/${productId}`);
    dispatch({ type: PRODUCT_SKU_CLASSIFY_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_SKU_CLASSIFY_FAIL, payload: message });
  }
};

//LIST SIDE PRODUCTS IF ANY
export const actionToGetSideProductsList = (idString) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_SIDE_LIST_REQUEST });
    const { data } = await Axios.get(
      `/api/products/sideproductslist/?sideProductIds=${idString}`
    );
    dispatch({ type: PRODUCT_SIDE_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_SIDE_LIST_FAIL, payload: message });
  }
};
