// GET LIMITED TIME DISCOUNT INFO (takes in a product or sku item object as an argument)
export default function calculateLimitedTimeDiscountValidity(
  item,
  firstVisitTime
) {
  let remainingTime;
  const sessionFirstVisitTime = Number(sessionStorage.getItem("visitTime"));

  if (
    item &&
    (item.timeLimitedDiscount?.discountDuration > 0 ||
      item.indicatorProductLimitedDiscount?.discountDuration > 0)
  ) {
    if (firstVisitTime || sessionFirstVisitTime) {
      // CALCULATE IF VALID
      const durationInHour =
        item.timeLimitedDiscount?.discountDuration ||
        item.indicatorProductLimitedDiscount.discountDuration;
      const durationInMilisecond =
        process.env.NODE_ENV === "development"
          ? durationInHour * 6000 * 15
          : durationInHour * 60 * 60 * 1000;
      const nowInMilisecond = Date.now();

      //CALCULATE REMAINING TIME
      remainingTime =
        (firstVisitTime ? firstVisitTime : sessionFirstVisitTime) +
        durationInMilisecond -
        nowInMilisecond;
    }

    return {
      isDiscountValid: remainingTime <= 0 || !remainingTime ? false : true,
      remainingTime: remainingTime <= 0 || !remainingTime ? 0 : remainingTime,
      itemSku: item?.sku,
    };
  } else {
    return {
      isDiscountValid: false,
      remainingTime: 0,
      itemSku: item?.sku,
    };
  }
}
