import Axios from "axios";
import {
  CART_ADD_SKU_ITEM,
  CART_REMOVE_SKU_ITEM,
  CART_EMPTY_SKU,
  CART_ADD_ITEM_REQUEST,
  // CART_CALCULATE_TAX,
  CALCULATE_ITEMS_PRICE,
  UPDATE_SHIPPING_PRICE,
  UPDATE_TOTAL_PRICE,
  CALCULATE_TAX_PRICE,
} from "../constants/cartConstants";
import { calculatePriceInCartAction } from "../utils/calculatePriceInCartAction";
import { calculateItemsTotalPriceInCartAction } from "../utils/calculateItemsTotalPriceAction";

// INDEX ID FOR CART ITEMS
let cartItemId = 0;

export const actionToAddSkuItemInCart =
  (
    isThereApendingOrder,
    productId,
    skuCode,
    qty,
    buttonLocation,
    discounted,
    free,
    timeLimitedDiscount // THIS KEEPS A NUMBER VALUE THAT REPRESENTS discountAmount
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: CART_ADD_ITEM_REQUEST,
    });

    const { data } = await Axios.get(
      `/api/products/skudetails/${productId}/${skuCode}`
    );

    const cartSkuItems = getState().addOrRemoveSkuItemInCart.cartSkuItems;
    const cartLength = cartSkuItems.length;
    const existItem = cartSkuItems.find(
      (item) => item.skuId === data.skus[0]._id
    );
    const indexOfExistItem = cartSkuItems.findIndex(
      (item) => item.sku === data.skus[0].sku
    );
    const isExistItemBuyOneGetOneFree = existItem?.buyOneGetOneFree;

    // Check if free product is same product or another product
    function isBuyOneGetOtherProductFree(data) {
      if (
        data.skus[0]?.buyOneGetOneFree &&
        data.skus[0]?.freeProductId !== data.skus[0]?.productId?.toString()
      ) {
        return true;
      } else {
        return false;
      }
    }

    dispatch({
      type: CART_ADD_SKU_ITEM,
      payload: {
        indexId: cartItemId++, // Increment each time an item is added in cart
        addToCartButtonLocation: buttonLocation,
        categoryPath: data.skus[0].categoryPath,
        isReviewed: data.skus[0].isReviewed,
        name: data.skus[0].name,
        target: data.skus[0].target,
        type: data.skus[0].type,
        size: data.skus[0].size,
        color: data.skus[0].color,
        sku: data.skus[0].sku,
        skuId: data.skus[0]._id,
        product: data._id,
        image: data.skus[0].skuImages[0],
        buyOneGetOneFree: data.skus[0].buyOneGetOneFree,
        buyOneGetOtherProductFree: isBuyOneGetOtherProductFree(data),
        buyOneGetAnotherFree: data.skus[0].buyOneGetAnotherFree,
        freeWithOtherProduct: data.skus[0].freeWithOtherProduct,
        timeLimitedDiscount: {
          discountDuration: data.skus[0].timeLimitedDiscount.discountDuration,
          discountAmount: data.skus[0].timeLimitedDiscount.discountAmount,
        },
        priceForOne: data.skus[0].price,
        price: calculatePriceInCartAction(
          timeLimitedDiscount,
          discounted,
          data,
          isThereApendingOrder,
          free
        ),
        priceGap: data.skus[0].priceGap,
        isThereApendingOrder: isThereApendingOrder,
        itemsTotalPrice: calculateItemsTotalPriceInCartAction(
          timeLimitedDiscount,
          data,
          isThereApendingOrder,
          free,
          qty,
          buttonLocation,
          cartLength,
          existItem,
          indexOfExistItem,
          isExistItemBuyOneGetOneFree
        ),
        purchasePrice: data.skus[0].purchasePrice,
        upToDateCostPerProduct: {
          trl:
            data.skus[0].upToDateCostPerProduct.usd *
            data.skus[0].upToDateCostPerProduct.usdTryRate,
          usd: data.skus[0].upToDateCostPerProduct.usd,
          euro: data.skus[0].upToDateCostPerProduct.euro,
        },
        vat: data.skus[0].vat,
        countInStock: data.skus[0].countInStock,
        maxPurchaseQty: data.skus[0].maxPurchaseQty,
        qty,
        productUnit: data.skus[0].productUnit,
        refundReasons: [
          "",
          "Vazgeçtim",
          "Beğenmedim",
          "Kalitesiz",
          "Ürün kusurlu gönderildi",
          "Yanlış ürün gönderildi",
          "Beden/Ebatı büyük",
          "Beden/Ebatı küçük",
          "Ürün ile görsel/içerik uyuşmuyor",
          "Yanlış sipariş verdim",
          "Diğer",
        ],
        parasutProductId:
          data.skus[0].parasutProductId && data.skus[0].parasutProductId,
        upsell: {
          upsellProduct: data.skus[0].upsell.upsellProduct,
          upsellSku: data.skus[0].upsell.upsellSku,
        },
        userManualLink: data.skus[0].userManualLink,
      },
    });

    localStorage.setItem(
      "cartSkuItems",
      JSON.stringify(getState().addOrRemoveSkuItemInCart.cartSkuItems)
    );
    localStorage.setItem("cartLoadedAt", new Date().getTime());
  };

export const calculateItemsPriceAction = (cartItems, firstVisitTime) => ({
  type: CALCULATE_ITEMS_PRICE,
  payload: { cartItems, firstVisitTime },
});

export const updateShippingPriceAction = (shippingPrice) => ({
  type: UPDATE_SHIPPING_PRICE,
  payload: shippingPrice,
});

export const updateTotalPriceAction = (totalPrice) => ({
  type: UPDATE_TOTAL_PRICE,
  payload: totalPrice,
});

export const calculateTaxPriceAction = (cartItems) => ({
  type: CALCULATE_TAX_PRICE,
  payload: cartItems,
});

export const actionToRemoveSkuItemInCart =
  (indexId) => async (dispatch, getState) => {
    cartItemId--; // Decrement eachtime an item is removed from cart
    dispatch({ type: CART_REMOVE_SKU_ITEM, payload: indexId });
    localStorage.setItem(
      "cartSkuItems",
      JSON.stringify(getState().addOrRemoveSkuItemInCart.cartSkuItems)
    );
  };

export const actionToEmptySkuCart = () => async (dispatch, getState) => {
  localStorage.removeItem("cartSkuItems");
  dispatch({ type: CART_EMPTY_SKU });
  localStorage.setItem(
    "cartSkuItems",
    JSON.stringify(getState().addOrRemoveSkuItemInCart.cartSkuItems)
  );

  cartItemId = 0; // Reset to zero if cart is cleared
};
