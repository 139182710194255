import React from "react";
import "./Footer.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ErcModal from "../modules/shared/components/ErcModal";
import PopRefundGuarantee from "./PopRefundGuarantee";

export default function Footer() {
  const location = useLocation();
  const history = useHistory();
  const converterAppeal = location.search.includes("?appeal");

  const fourReasons = [
    {
      icon: "fas fa-child",
      headline: "Mutlu Kullanıcılar",
      paragraph: "Her yaştan 1000'lerce mutlu kullanıcı",
    },
    {
      icon: "fas fa-user-shield",
      headline: "90 Gün Garanti",
      paragraph:
        "Riskiniz 0. Ya üründen memnun kalacaksınız ya da paranızı geri alıp memnun kalacaksınız.",
    },
    {
      icon: "fas fa-user-md",
      headline: "Doktorlara Danışıldı",
      paragraph:
        "Tasarımından, malzeme seçimine kadar her şey doktorlara danışılarak üretildi.",
    },
    {
      icon: "fas fa-tags",
      headline: "Kazançlı Alışveriş",
      title1: "Kullanışlılık :",
      // title2: "Teselli İndirimi Kodu :",
      title3: "Taksit :",
      title4: "Ücretsiz Kargo :",
      // title5: "Ek Sipariş İndirimi :",
      paragraph: `Kullanışlı ürünler geliştiriyoruz. Bu nedenle 90 gün iade garantisi sunabiliyoruz. Kaliteli ve kullanışlı bir ürünü, uygun fiyata almanın mutluluğunu yaşayın. Farklı mekanlar ya da sevdikleriniz için 1'er, 2'şer alın.`,
      // paragraph1: `Sınırlı süreli indirimi kaçırdınız mı? Üzülmeyin. Teselli indirimi kodu BEDDO20 ile indirimli alışveriş yapın.`,
      paragraph2: "Vade farksız 3 taksit imkanı.",
      paragraph3:
        "250 TL ve daha yüksek tutarlı alışverişlerde, fiyatlara kargo ücreti eklenmez.",
      // paragraph4: `Bir sipariş oluşturduktan sonra indirim hakkı kazanıyorsunuz. Hakkınız, "Ana Siparişiniz" paketlenene kadar devam ediyor.`,
    },
  ];

  // HANDLE HOMEPAGE LINK BASED ON LOCAL STORAGE(landing visitors should be directed to landing homepage during their visit)
  function handleHomepageLink() {
    let storage = JSON.parse(sessionStorage.getItem("landingVisitor"));
    if ((storage && location.pathname.includes("/land/")) || converterAppeal) {
      return null; // history.push(storage.url);
    } else {
      history.push("/");
    }
  }

  return (
    <>
      {location.pathname.includes("admin") ? (
        ""
      ) : (
        <footer className="user-screen">
          <div className="footer-shadow-platform">
            <div className="footer-news-div">
              <img
                className="footer-news-div-beddo-logo"
                src="/images/beddo-mern-logo.png"
                alt="beddo logosu - beddo senin huzur senin"
              />
              <h3 className="footer-news-div-h3">
                <ErcModal
                  modalTitle={() => <>beddo™ Ulusal Basında</>}
                  modalTitleFontSize={"1rem"}
                  modalTitleColor={"#0645ad"}
                  modalTitleUnderline={"0.1rem solid #0645ad"}
                  contentTitle={"beddo™ Kalitesi Avrupa Yolunda"}
                  contentTitleFontSize={"1.5rem"}
                  images={[
                    "https://res.cloudinary.com/beddo-com/image/upload/v1636395384/milliyet-haber_mtrbby.jpg",
                    "https://res.cloudinary.com/beddo-com/image/upload/v1636395373/hurriyet-haber_f2p8kr.jpg",
                    "https://res.cloudinary.com/beddo-com/image/upload/v1636395365/haberler-haber_cuujcf.jpg",
                  ]}
                />
              </h3>
              <img
                className="footer-news-div-media-logo"
                src="/images/milliyet.jpg"
                alt="beddo logosu - beddo senin huzur senin"
              />
              <img
                className="footer-news-div-media-logo"
                src="/images/sozcu.jpg"
                alt="beddo logosu - beddo senin huzur senin"
              />
              <img
                className="footer-news-div-media-logo"
                src="/images/hurriyet.png"
                alt="beddo logosu - beddo senin huzur senin"
              />
              <img
                className="footer-news-div-media-logo"
                src="/images/sabah.png"
                alt="beddo logosu - beddo senin huzur senin"
              />
              <img
                className="footer-news-div-media-logo"
                src="/images/haberler.png"
                alt="beddo logosu - beddo senin huzur senin"
              />
            </div>
            <div className="footer-four-reason-div">
              {fourReasons.map((reason, index) => {
                return (
                  <table key={index} className="footer-four-reason-table">
                    <tbody>
                      <tr className="footer-four-reason-first-tr">
                        <td className="footer-four-reason-first-td">
                          <i className={reason.icon}></i>
                        </td>
                        <td className="footer-four-reason-second-td">
                          {index === 1 ? (
                            <ErcModal
                              modalTitleDivWidth={"100%"}
                              modalTitle={() => (
                                <h2 className="linkblue inline">
                                  {reason.headline}
                                </h2>
                              )}
                              contentTitleFontSize={"1.35rem"}
                              contentTitle={
                                "Uzun Süreli İade Garantisi İle Riskiniz SIFIR"
                              }
                              content={[<PopRefundGuarantee />]}
                              modalParagFontSize={"1.25rem"}
                            />
                          ) : (
                            <h2>{reason.headline}</h2>
                          )}
                          {(location.pathname.includes("/land/") ||
                            converterAppeal) &&
                          reason.title1 ? (
                            ""
                          ) : (
                            <div
                              className="
                              beddo-border-top padupsideh-5"
                            >
                              <p className="bold-font">{reason.title1}</p>
                              <p>{reason.paragraph}</p>
                            </div>
                          )}

                          <div
                            className={
                              reason.paragraph2 &&
                              "beddo-border-top padupsideh-5"
                            }
                          >
                            <p className="bold-font">{reason.title3}</p>
                            <p>{reason.paragraph2}</p>
                          </div>
                          <div
                            className={
                              reason.paragraph3 &&
                              "beddo-border-top padupsideh-5"
                            }
                          >
                            <p className="bold-font">{reason.title4}</p>
                            <p>{reason.paragraph3}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
            {location.pathname.includes("/land/") || converterAppeal ? (
              ""
            ) : (
              <div className="footer-navigation-div">
                <ul>
                  <div
                    className={
                      location.pathname === "/"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                    onClick={handleHomepageLink}
                  >
                    <li
                      className={location.pathname === "/" ? "beddo-color" : ""}
                    >
                      Anasayfa
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </div>
                  <Link
                    to="/hakkimizda"
                    className={
                      location.pathname === "/hakkimizda"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/hakkimizda" ? "beddo-color" : ""
                      }
                    >
                      Hakkımızda
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/iletisim"
                    className={
                      location.pathname === "/iletisim"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/iletisim" ? "beddo-color" : ""
                      }
                    >
                      İletişim
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/sponsorluklar"
                    className={
                      location.pathname === "/sponsorluklar"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/sponsorluklar"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Sponsorluklar
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/insan-kaynaklari"
                    className={
                      location.pathname === "/insan-kaynaklari"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/insan-kaynaklari"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      İnsan Kaynakları
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/musteri-hizmetleri"
                    className={
                      location.pathname === "/musteri-hizmetleri" ||
                      location.pathname === "/musteri-hizmetleri-giris"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/musteri-hizmetleri" ||
                        location.pathname === "/musteri-hizmetleri-giris"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Müşteri Hizmetleri
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/profilim"
                    className={
                      location.pathname === "/profilim"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/profilim" ? "beddo-color" : ""
                      }
                    >
                      Profilim
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/siparislerim"
                    className={
                      location.pathname === "/siparislerim"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/siparislerim"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Siparişlerim
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/destek"
                    className={
                      location.pathname === "/destek"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/destek" ? "beddo-color" : ""
                      }
                    >
                      Destek
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                    className={
                      location.pathname ===
                      "/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname ===
                        "/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Nasıl İndirimli Alışveriş Yapılır?
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  {/*<Link
                    to="/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                    className={
                      location.pathname ===
                      "/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname ===
                        "/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      İndirimli Alışveriş Nasıl Yapılıyor?
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>*/}
                </ul>
                <ul>
                  <Link
                    to="/mesafeli-satis-sozlesmesi"
                    className={
                      location.pathname === "/mesafeli-satis-sozlesmesi"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/mesafeli-satis-sozlesmesi"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Mesafeli Satış Sözleşmesi
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/gizlilik-sozlesmesi"
                    className={
                      location.pathname === "/gizlilik-sozlesmesi"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/gizlilik-sozlesmesi"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Gizlilik Politikası
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/cayma-hakki"
                    className={
                      location.pathname === "/cayma-hakki"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/cayma-hakki"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Teslimat Ve İade Koşulları
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <a
                    href="https://www.instagram.com/beddohome"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>
                      <i className="fab fa-instagram">
                        <span> Instagram / beddo™</span>
                      </i>
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </a>
                  <a
                    href="https://www.facebook.com/beddohome"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>
                      <i className="fab fa-facebook-square">
                        <span> Facebook / beddo™</span>
                      </i>
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCUX2Gzf4GxKY7fwT9rukrmg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>
                      <i className="fab fa-youtube-square">
                        <span> Youtube / beddo™</span>
                      </i>
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </a>
                </ul>
                <ul>
                  {/*<Link
                    to="/bayi-basvuru-formu"
                    className={
                      location.pathname === "/bayi-basvuru-formu"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/bayi-basvuru-formu"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Bayi Başvuru Formu
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>*/}
                  <Link
                    to="/toptan-giris"
                    className={
                      location.pathname === "/toptan-giris"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/toptan-giris"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Toptan Satış Girişi
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  <Link
                    to="/tedarikci-basvuru-formu"
                    className={
                      location.pathname === "/tedarikci-basvuru-formu"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/tedarikci-basvuru-formu"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Tedarikçi Başvuru Formu
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                  {/* <Link
                    to="/kurumsal-ve-toptan-siparis-talep-formu"
                    className={
                      location.pathname ===
                      "/kurumsal-ve-toptan-siparis-talep-formu"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname ===
                        "/kurumsal-ve-toptan-siparis-talep-formu"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Kurumsal/Toptan Sipariş Talep Formu
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>*/}
                  <Link
                    to="/export-inquiries-form"
                    className={
                      location.pathname === "/export-inquiries-form"
                        ? "footer-navigation-div-active pointer"
                        : "footer-navigation-div-div pointer"
                    }
                  >
                    <li
                      className={
                        location.pathname === "/export-inquiries-form"
                          ? "beddo-color"
                          : ""
                      }
                    >
                      Export Inquiries Form
                      <i className="fas fa-chevron-right footer-chevron"></i>
                    </li>
                  </Link>
                </ul>
              </div>
            )}

            <div className="footer-company-div">
              <div className="footer-logo" onClick={handleHomepageLink}>
                <img
                  className={
                    location.pathname.includes("/land/") || converterAppeal
                      ? ""
                      : "pointer"
                  }
                  src="/images/beddo-mern-logo.png"
                  alt="beddo logosu - beddo senin huzur senin"
                />
              </div>
              <div className="footer-address">
                <h3>Beddo Elektronik Ticaret Hizmetleri Ltd Şti</h3>
                <h3>Veliefendi Mah Prof Dr Turan Güneş Cd</h3>
                <h3 className="marb-q">
                  4.Çeşme sk No:6 Zeytinburnu / İstanbul
                </h3>
                <p className="fs-65 italic-font">
                  Adresimizde fiziksel satış yapmıyoruz. Yalnızca online
                  siparişlerinizi kabul ediyoruz.
                </p>
              </div>
              <div className="footer-copyright">
                <img
                  className="footer-iyzico-image"
                  src="/images/logo_band_colored@1X.png"
                  alt="beddo™, iyzico ile ödeme alır"
                />
                <h3 className="txtcenter">
                  beddo™ © Copyright 2016-2024 Tüm Hakkı Saklıdır
                </h3>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
