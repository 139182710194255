import {
  ADMIN_ADD_FIELD_FAIL,
  ADMIN_ADD_FIELD_REQUEST,
  ADMIN_ADD_FIELD_RESET,
  ADMIN_ADD_FIELD_SUCCESS,
  ADMIN_CREATE_UPS_CITY_FAIL,
  ADMIN_CREATE_UPS_CITY_REQUEST,
  ADMIN_CREATE_UPS_CITY_RESET,
  ADMIN_CREATE_UPS_CITY_SUCCESS,
  ADMIN_UPS_CITY_DETAILS_FAIL,
  ADMIN_UPS_CITY_DETAILS_REQUEST,
  ADMIN_UPS_CITY_DETAILS_SUCCESS,
  ADMIN_UPS_CITY_EDIT_FAIL,
  ADMIN_UPS_CITY_EDIT_REQUEST,
  ADMIN_UPS_CITY_EDIT_RESET,
  ADMIN_UPS_CITY_EDIT_SUCCESS,
  ADMIN_UPS_CITY_LIST_FAIL,
  ADMIN_UPS_CITY_LIST_REQUEST,
  ADMIN_UPS_CITY_LIST_SUCCESS,
  ADMIN_UPS_CREATE_COD_SHIPMENT_FAIL,
  ADMIN_UPS_CREATE_COD_SHIPMENT_REQUEST,
  ADMIN_UPS_CREATE_COD_SHIPMENT_RESET,
  ADMIN_UPS_CREATE_COD_SHIPMENT_SUCCESS,
  ADMIN_UPS_CREATE_SHIPMENT_FAIL,
  ADMIN_UPS_CREATE_SHIPMENT_REQUEST,
  ADMIN_UPS_CREATE_SHIPMENT_RESET,
  ADMIN_UPS_CREATE_SHIPMENT_SUCCESS,
  UPS_CANCEL_SHIPMENT_FAIL,
  UPS_CANCEL_SHIPMENT_REQUEST,
  UPS_CANCEL_SHIPMENT_RESET,
  UPS_CANCEL_SHIPMENT_SUCCESS,
} from "../constants/adminCourierConstants";

//SAVE THE DETAILS OF BRAND IN REDUX STATE
export function reducerAdminToAddNewField(state = { success: false }, action) {
  switch (action.type) {
    case ADMIN_ADD_FIELD_REQUEST:
      return { loading: true };
    case ADMIN_ADD_FIELD_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_ADD_FIELD_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ADD_FIELD_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE CREATE UPS CITY
export function reducerAdminToCreateUpsCity(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_CREATE_UPS_CITY_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_UPS_CITY_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_CREATE_UPS_CITY_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_UPS_CITY_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE UPS CITY LIST
export function reducerAdminToListUpsCities(state = { loading: true }, action) {
  switch (action.type) {
    case ADMIN_UPS_CITY_LIST_REQUEST:
      return { loading: true };
    case ADMIN_UPS_CITY_LIST_SUCCESS:
      return {
        loading: false,
        citiesList: action.payload.cities,
      };
    case ADMIN_UPS_CITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE UPS CITY DETAILS
export function reducerAdminToGetUpsCityDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_UPS_CITY_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_UPS_CITY_DETAILS_SUCCESS:
      return {
        loading: false,
        cityDetails: action.payload,
      };
    case ADMIN_UPS_CITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//EDIT CITY DETAILS
export function reducerAdminToEditUpsCity(state = { success: false }, action) {
  switch (action.type) {
    case ADMIN_UPS_CITY_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_UPS_CITY_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_UPS_CITY_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPS_CITY_EDIT_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE CREATE UPS SHIPMENT
export function reducerAdminToCreateUpsShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_UPS_CREATE_SHIPMENT_REQUEST:
      return { loading: true };
    case ADMIN_UPS_CREATE_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        shipmentDetails: action.payload,
      };
    case ADMIN_UPS_CREATE_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPS_CREATE_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}
//SAVE CREATE UPS COD SHIPMENT
export function reducerAdminToCreateUpsCodShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_UPS_CREATE_COD_SHIPMENT_REQUEST:
      return { loading: true };
    case ADMIN_UPS_CREATE_COD_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        codShipmentDetails: action.payload,
      };
    case ADMIN_UPS_CREATE_COD_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPS_CREATE_COD_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE CANCEL UPS SHIPMENT
export function reducerAdminToCancelUpsShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case UPS_CANCEL_SHIPMENT_REQUEST:
      return { loading: true };
    case UPS_CANCEL_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case UPS_CANCEL_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case UPS_CANCEL_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}
