//USER CREATE SUPPORT MESSAGE
export const SUPPORT_CREATE_REQUEST = "SUPPORT_CREATE_REQUEST";
export const SUPPORT_CREATE_SUCCESS = "SUPPORT_CREATE_SUCCESS";
export const SUPPORT_CREATE_FAIL = "SUPPORT_CREATE_FAIL";
export const SUPPORT_CREATE_RESET = "SUPPORT_CREATE_RESET";

//SUPPORT REQUEST LIST FOR USER
export const SUPPORT_USER_LIST_REQUEST = "SUPPORT_USER_LIST_REQUEST";
export const SUPPORT_USER_LIST_SUCCESS = "SUPPORT_USER_LIST_SUCCESS";
export const SUPPORT_USER_LIST_FAIL = "SUPPORT_USER_LIST_FAIL";
//SUPPORT REQUEST LIST FOR ADMIN
export const SUPPORT_ADMIN_USER_LIST_REQUEST =
  "SUPPORT_ADMIN_USER_LIST_REQUEST";
export const SUPPORT_ADMIN_USER_LIST_SUCCESS =
  "SUPPORT_ADMIN_USER_LIST_SUCCESS";
export const SUPPORT_ADMIN_USER_LIST_FAIL = "SUPPORT_ADMIN_USER_LIST_FAIL";

//USER UPDATE SUPPORT REQUEST
export const SUPPORT_USER_CLOSE_REQUEST = "SUPPORT_USER_CLOSE_REQUEST";
export const SUPPORT_USER_CLOSE_SUCCESS = "SUPPORT_USER_CLOSE_SUCCESS";
export const SUPPORT_USER_CLOSE_FAIL = "SUPPORT_USER_CLOSE_FAIL";
export const SUPPORT_USER_CLOSE_RESET = "SUPPORT_USER_CLOSE_RESET";

//GET USER SUPPORT REQUEST DETAILS
export const SUPPORT_DETAILS_REQUEST = "SUPPORT_DETAILS_REQUEST";
export const SUPPORT_DETAILS_SUCCESS = "SUPPORT_DETAILS_SUCCESS";
export const SUPPORT_DETAILS_FAIL = "SUPPORT_DETAILS_FAIL";
export const SUPPORT_DETAILS_RESET = "SUPPORT_DETAILS_RESET";

//USER SEND SUPPORT MESSAGE
export const SUPPORT_NEW_MESSAGE_REQUEST = "SUPPORT_NEW_MESSAGE_REQUEST";
export const SUPPORT_NEW_MESSAGE_SUCCESS = "SUPPORT_NEW_MESSAGE_SUCCESS";
export const SUPPORT_NEW_MESSAGE_FAIL = "SUPPORT_NEW_MESSAGE_FAIL";
//ADMIN SEND SUPPORT MESSAGE
export const SUPPORT_ADMIN_MESSAGE_REQUEST = "SUPPORT_ADMIN_MESSAGE_REQUEST";
export const SUPPORT_ADMIN_MESSAGE_SUCCESS = "SUPPORT_ADMIN_MESSAGE_SUCCESS";
export const SUPPORT_ADMIN_MESSAGE_FAIL = "SUPPORT_ADMIN_MESSAGE_FAIL";

//ADMIN REQUESTS SUPPORT REQUESTS LIST
export const SUPPORT_ADMIN_LIST_REQUEST = "SUPPORT_ADMIN_LIST_REQUEST";
export const SUPPORT_ADMIN_LIST_SUCCESS = "SUPPORT_ADMIN_LIST_SUCCESS";
export const SUPPORT_ADMIN_LIST_FAIL = "SUPPORT_ADMIN_LIST_FAIL";

//ADMIN SEND MESSAGE SENT SMS
export const SUPPORT_ADMIN_SMS_REQUEST = "SUPPORT_ADMIN_SMS_REQUEST";
export const SUPPORT_ADMIN_SMS_SUCCESS = "SUPPORT_ADMIN_SMS_SUCCESS";
export const SUPPORT_ADMIN_SMS_FAIL = "SUPPORT_ADMIN_SMS_FAIL";

//ADMIN DELETES A SENT MESSAGE
export const SUPPORT_ADMIN_DELETE_MESSAGE_REQUEST =
  "SUPPORT_ADMIN_DELETE_MESSAGE_REQUEST";
export const SUPPORT_ADMIN_DELETE_MESSAGE_SUCCESS =
  "SUPPORT_ADMIN_DELETE_MESSAGE_SUCCESS";
export const SUPPORT_ADMIN_DELETE_MESSAGE_FAIL =
  "SUPPORT_ADMIN_DELETE_MESSAGE_FAIL";

//ADMIN CREATE SUPPORT STATUS
export const ADMIN_SUPPORT_STATUS_CREATE_REQUEST =
  "ADMIN_SUPPORT_STATUS_CREATE_REQUEST";
export const ADMIN_SUPPORT_STATUS_CREATE_SUCCESS =
  "ADMIN_SUPPORT_STATUS_CREATE_SUCCESS";
export const ADMIN_SUPPORT_STATUS_CREATE_FAIL =
  "ADMIN_SUPPORT_STATUS_CREATE_FAIL";
export const ADMIN_SUPPORT_STATUS_CREATE_RESET =
  "ADMIN_SUPPORT_STATUS_CREATE_RESET";

//ADMIN SUPPORT STATUS LIST
export const ADMIN_SUPPORT_STATUS_LIST_REQUEST =
  "ADMIN_SUPPORT_STATUS_LIST_REQUEST";
export const ADMIN_SUPPORT_STATUS_LIST_SUCCESS =
  "ADMIN_SUPPORT_STATUS_LIST_SUCCESS";
export const ADMIN_SUPPORT_STATUS_LIST_FAIL = "ADMIN_SUPPORT_STATUS_LIST_FAIL";

//ADMIN SUPPORT STATUS DETAILS
export const ADMIN_SUPPORT_STATUS_DETAILS_REQUEST =
  "ADMIN_SUPPORT_STATUS_DETAILS_REQUEST";
export const ADMIN_SUPPORT_STATUS_DETAILS_SUCCESS =
  "ADMIN_SUPPORT_STATUS_DETAILS_SUCCESS";
export const ADMIN_SUPPORT_STATUS_DETAILS_FAIL =
  "ADMIN_SUPPORT_STATUS_DETAILS_FAIL";

//ADMIN SUPPORT STATUS EDIT
export const ADMIN_SUPPORT_STATUS_EDIT_REQUEST =
  "ADMIN_SUPPORT_STATUS_EDIT_REQUEST";
export const ADMIN_SUPPORT_STATUS_EDIT_SUCCESS =
  "ADMIN_SUPPORT_STATUS_EDIT_SUCCESS";
export const ADMIN_SUPPORT_STATUS_EDIT_FAIL = "ADMIN_SUPPORT_STATUS_EDIT_FAIL";
export const ADMIN_SUPPORT_STATUS_EDIT_RESET =
  "ADMIN_SUPPORT_STATUS_EDIT_RESET";

//ADMIN SUPPORT STATUS DELETE
export const ADMIN_SUPPORT_STATUS_DELETE_REQUEST =
  "ADMIN_SUPPORT_STATUS_DELETE_REQUEST";
export const ADMIN_SUPPORT_STATUS_DELETE_SUCCESS =
  "ADMIN_SUPPORT_STATUS_DELETE_SUCCESS";
export const ADMIN_SUPPORT_STATUS_DELETE_FAIL =
  "ADMIN_SUPPORT_STATUS_DELETE_FAIL";
export const ADMIN_SUPPORT_STATUS_DELETE_RESET =
  "ADMIN_SUPPORT_STATUS_DELETE_RESET";

//ADMIN UPDATE SUPPORT REQUEST
export const ADMIN_SUPPORT_UPDATE_REQUEST = "ADMIN_SUPPORT_UPDATE_REQUEST";
export const ADMIN_SUPPORT_UPDATE_SUCCESS = "ADMIN_SUPPORT_UPDATE_SUCCESS";
export const ADMIN_SUPPORT_UPDATE_FAIL = "ADMIN_SUPPORT_UPDATE_FAIL";
export const ADMIN_SUPPORT_UPDATE_RESET = "ADMIN_SUPPORT_UPDATE_RESET";

// A VISITOR OR USER FILL A FORM
export const SUPPORT_WEB_FORM_REQUEST = "SUPPORT_WEB_FORM_REQUEST";
export const SUPPORT_WEB_FORM_SUCCESS = "SUPPORT_WEB_FORM_SUCCESS";
export const SUPPORT_WEB_FORM_FAIL = "SUPPORT_WEB_FORM_FAIL";
export const SUPPORT_WEB_FORM_RESET = "SUPPORT_WEB_FORM_RESET";
