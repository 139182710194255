//ADMIN CREATE BRAND
export const ADMIN_BRAND_CREATE_REQUEST = "ADMIN_BRAND_CREATE_REQUEST";
export const ADMIN_BRAND_CREATE_SUCCESS = "ADMIN_BRAND_CREATE_SUCCESS";
export const ADMIN_BRAND_CREATE_FAIL = "ADMIN_BRAND_CREATE_FAIL";
export const ADMIN_BRAND_CREATE_RESET = "ADMIN_BRAND_CREATE_RESET";

//ADMIN BRAND LIST
export const ADMIN_BRAND_LIST_REQUEST = "ADMIN_BRAND_LIST_REQUEST";
export const ADMIN_BRAND_LIST_SUCCESS = "ADMIN_BRAND_LIST_SUCCESS";
export const ADMIN_BRAND_LIST_FAIL = "ADMIN_BRAND_LIST_FAIL";

//ADMIN BRAND DETAILS
export const ADMIN_BRAND_DETAILS_REQUEST = "ADMIN_BRAND_DETAILS_REQUEST";
export const ADMIN_BRAND_DETAILS_SUCCESS = "ADMIN_BRAND_DETAILS_SUCCESS";
export const ADMIN_BRAND_DETAILS_FAIL = "ADMIN_BRAND_DETAILS_FAIL";

//ADMIN BRAND EDIT
export const ADMIN_BRAND_EDIT_REQUEST = "ADMIN_BRAND_EDIT_REQUEST";
export const ADMIN_BRAND_EDIT_SUCCESS = "ADMIN_BRAND_EDIT_SUCCESS";
export const ADMIN_BRAND_EDIT_FAIL = "ADMIN_BRAND_EDIT_FAIL";
export const ADMIN_BRAND_EDIT_RESET = "ADMIN_BRAND_EDIT_RESET";

//ADMIN BRAND DELETE
export const ADMIN_BRAND_DELETE_REQUEST = "ADMIN_BRAND_DELETE_REQUEST";
export const ADMIN_BRAND_DELETE_SUCCESS = "ADMIN_BRAND_DELETE_SUCCESS";
export const ADMIN_BRAND_DELETE_FAIL = "ADMIN_BRAND_DELETE_FAIL";
export const ADMIN_BRAND_DELETE_RESET = "ADMIN_BRAND_DELETE_RESET";
