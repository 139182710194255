import React from "react";
import { useHistory } from "react-router-dom";
import useWidth from "../hooks/useWidth";

export default function GoBack() {
  const history = useHistory();
  const width = useWidth();

  const goBackDivStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    fontSize: width < 800 ? "1.25rem" : "0.85rem",
    position: "fixed",
    zIndex: "1",
    width: width < 800 ? "7rem" : "5%",
    cursor: "pointer",
    fontStyle: "arial",
    border: "solid 1px #ff9935",
    backgroundColor: "#f5f7fa",
    borderRadius: "0.2rem",
    color: "gray",
  };

  function handleGoBackButton() {
    history.goBack();
  }

  return (
    <div className="w100p h1r">
      <button type="button" style={goBackDivStyle} onClick={handleGoBackButton}>
        <i className="fas fa-backward beddo-color marq"></i>
        <p>Geri</p>
      </button>
    </div>
  );
}
