import { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";

// DETECTS URL AND SEARCH CHANGES AND REPORTS TO GOOGLE ANALYTICS 4
const RouteChangeTracker = () => {
  const history = useHistory();

  useEffect(() => {
    const trackPageView = (location) => {
      const pagePath = location.pathname + location.search;
      ReactGA.send({ hitType: "pageview", page: pagePath });
    };

    // Track the initial page load
    trackPageView(history.location);

    // Track subsequent route changes
    const unlisten = history.listen((location) => {
      trackPageView(location);
    });

    // Cleanup the listener on component unmount
    return () => unlisten();
  }, [history]);

  return null; // This component doesn't render anything visible
};

export default withRouter(RouteChangeTracker);
