import { useEffect, useState } from "react";
import { actionToGetWholeSaleOrdersListByAccount } from "../orders/ordersList/actions/actionToGetWholeSaleOrdersListByAccount";
import { useDispatch, useSelector } from "react-redux";

export function useOrdersListByAccount(
  id,
  createdOrder,
  updatedWholeSaleAccount,
  updatedWholeSaleOrderWithFreeGoods,
  updatedWholeSaleOrder,
  triggerOrdersList
) {
  const dispatch = useDispatch();

  // CHECK THE ORDERS TO GIVE INFO IN ORDERS WIDGET
  const [ordersInfo, setOrdersInfo] = useState({
    totalNumberOfOrders: 0,
    hasPendingBankTransfer: false,
    completedOrders: 0,
    freeGoodsAllocation: 0,
  });

  // DISPATCH A REQUEST TO GET ORDERS LIST FROM SERVER TO REDUX STORE
  useEffect(() => {
    if (id && triggerOrdersList) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
  }, [dispatch, id, triggerOrdersList]);

  useEffect(() => {
    if (id) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
  }, [dispatch, id]);
  useEffect(() => {
    if (createdOrder) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, createdOrder]);
  useEffect(() => {
    if (updatedWholeSaleAccount) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedWholeSaleAccount]);
  useEffect(() => {
    if (updatedWholeSaleOrderWithFreeGoods) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedWholeSaleOrderWithFreeGoods]);
  useEffect(() => {
    if (updatedWholeSaleOrder) {
      dispatch(actionToGetWholeSaleOrdersListByAccount(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedWholeSaleOrder]);

  // BRING IN ORDERS LIST FROM REDUX STORE
  const getWholeSaleOrdersListByAccount = useSelector(
    (state) => state.getWholeSaleOrdersListByAccount
  );
  const {
    loading: loadingOrdersList,
    error: errorOrdersList,
    orders,
  } = getWholeSaleOrdersListByAccount;

  // CREATE STATES THAT HOLD SMALL PIECES OF INFOS ABOUT ORDERS
  useEffect(() => {
    if (orders?.length > 0) {
      const totalNumberOfOrders = orders.length;
      const hasPendingBankTransfer = orders.some(
        (order) => order.orderStatus === "PENDING_BANK_TRANSFER"
      );
      const completedOrders = orders.filter(
        (order) => order.orderStatus === "DELIVERED"
      );
      const freeGoodsAllocation = orders
        .filter(
          (order) =>
            order.orderStatus !== "SHIPPED" && order.orderStatus !== "CANCELLED"
        )
        .reduce((a, c) => a + c.freeGoodsAllocation, 0);

      setOrdersInfo({
        totalNumberOfOrders,
        hasPendingBankTransfer,
        completedOrders,
        freeGoodsAllocation,
      });
    }
  }, [orders]);

  // RETURN REDUX STATES, ORDERS LIST AND ORDERS INFO
  return {
    loadingOrdersList,
    errorOrdersList,
    orders,
    ordersInfo,
  };
}
