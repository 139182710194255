import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { actionToLogout } from "../actions/userActions";
import ModalNavMenu from "./ModalNavMenu";
import "./NavBar.css";
import SearchBar from "./SearchBar";
// import GoBack from "../components/GoBack";

export default function NavBar(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const converterAppeal = location.search.includes("?appeal");

  //REDUX STATE THAT HOLDS CART SKU ITEMS
  const addOrRemoveSkuItemInCart = useSelector(
    (state) => state.addOrRemoveSkuItemInCart
  );
  const { cartSkuItems } = addOrRemoveSkuItemInCart;

  //REDUX STATE THAT HOLDS AUTHENTICATED USER INFO
  const authenticateUser = useSelector((state) => state.authenticateUser);
  const { authenticatedUser } = authenticateUser;

  //LOG OUT
  function logoutHandler() {
    dispatch(actionToLogout());
  }

  //NAV MENU MODAL LOGIC STARTS HERE
  //REACT STATE TO OPEN AND CLOSE MODAL MENU
  const [modalMenuOpen, setModalMenuOpen] = useState(false);
  //REF FOR CLICK OUTSIDE TO CLOSE FUNCTIONALITY
  const modalMenuOpenRef = useRef(null);
  //TOGGLE OPEN/CLOSE MODAL NAV MENU
  function handleModalMenu() {
    setModalMenuOpen(!modalMenuOpen);
  }
  // CLICK OUTSIDE TO CLOSE MODAL FORMS FOR REVIEW CREATE AND EDIT
  function handleClickOutside(event) {
    if (
      modalMenuOpenRef.current &&
      !modalMenuOpenRef.current.contains(event.target)
    ) {
      setModalMenuOpen(!setModalMenuOpen);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });

  // HANDLE HOMEPAGE LINK BASED ON LOCAL STORAGE(landing visitors should be directed to landing homepage during their visit)
  const [bottomBorder, setBottomBorder] = useState(false);
  function handleHomepageLink() {
    let storage = JSON.parse(sessionStorage.getItem("landingVisitor"));
    if ((storage && location.pathname.includes("/land/")) || converterAppeal) {
      // history.push(storage.url);
      setBottomBorder(true);
      return null;
    } else {
      history.push("/");
      setBottomBorder(true);
    }
  }

  // DO NOT DISPLAY CART IF IN LANDING PAGE
  const [cartDisplay, setCartDisplay] = useState(true);
  useEffect(() => {
    if (location.pathname.includes("/land/")) {
      setCartDisplay(false);
    }
  }, [location]);

  //location.pathname IS THE URL PATH
  return (
    <>
      {location.pathname ===
      "/odeme-sonucu" ? null : location.pathname.includes("admin") ? null : (
        <>
          {location.pathname.includes("/land/") || converterAppeal ? (
            <header className="header-area-mobile lightbck">
              <div className="col">
                <img
                  className="w60p"
                  src="/images/beddo-mern-logo.png"
                  alt="beddo logosu - beddo senin huzur senin"
                />
              </div>
            </header>
          ) : (
            <header className="header-area-mobile lightbck">
              <div className="row">
                <div className="w100p row-center">
                  <div
                    className="col bars pointer w10p"
                    onClick={handleModalMenu}
                  >
                    <i className="fas fa-bars fs15 beddo-color"></i>{" "}
                    <h6 className="fs-65 green">menü</h6>
                  </div>
                  <div
                    className="col logo w20p marside2"
                    onClick={handleHomepageLink}
                  >
                    <div className="w100p">
                      <img
                        className="w100p"
                        src="/images/beddo-mern-logo.png"
                        alt="beddo logosu - beddo senin huzur senin"
                      />
                    </div>
                  </div>
                  <div
                    className="col bars pointer  w10p"
                    onClick={handleModalMenu}
                  >
                    <i className="fas fa-search fs15 beddo-color"></i>
                  </div>
                </div>
                <div className="w100p row shadow-light">
                  <div className="row username  w40p marginleft1r">
                    {!authenticatedUser ? (
                      <h3 className="fs-85">
                        <Link
                          className="beddo-color"
                          to="/musteri-hizmetleri-giris"
                        >
                          <u>Müşteri Hizmetleri</u>
                        </Link>
                      </h3>
                    ) : (
                      <Link className="nodeco" to="/musteri-hizmetleri">
                        <h3 className="fs-85 beddo-color">
                          Merhaba{" "}
                          {`${
                            authenticatedUser.firstName
                          } ${authenticatedUser.lastName.substring(0, 1)}...`}
                        </h3>
                      </Link>
                    )}
                  </div>
                  {!cartDisplay ? (
                    ""
                  ) : cartSkuItems.length > 0 ? (
                    <div className="row-right cart-mobile w40p ">
                      <Link to="/sepet">
                        <i className="fas fa-cart-arrow-down fs15 beddo-color"></i>
                      </Link>
                      <div className="fs1 danger">
                        {cartSkuItems.reduce((a, c) => a + Number(c.qty), 0)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {modalMenuOpen ? (
                <ModalNavMenu
                  handleModalMenu={handleModalMenu}
                  logoutHandler={logoutHandler}
                  modalMenuOpenRef={modalMenuOpenRef}
                  modalMenuOpen={modalMenuOpen}
                  authenticatedUser={authenticatedUser}
                  handleHomepageLink={handleHomepageLink}
                  bottomBorder={bottomBorder}
                  setBottomBorder={setBottomBorder}
                />
              ) : (
                ""
              )}
            </header>
          )}

          <header className="header-area-desktop">
            <div className="cart-top">
              {location.pathname.includes("/land/") || converterAppeal ? (
                ""
              ) : (
                <>
                  {/*<div className="fixed" style={{ left: "0" }}>
                    <GoBack />
              </div>*/}
                  <div className="col username">
                    {!authenticatedUser ? (
                      <h3 className="h3c">
                        <Link
                          className="beddo-color"
                          to="/musteri-hizmetleri-giris"
                        >
                          <u>Müşteri Hizmetleri</u>
                        </Link>
                      </h3>
                    ) : (
                      <Link className="nodeco" to="/musteri-hizmetleri">
                        <h4 className="h4c beddo-color">Teşekkürler</h4>
                        <h4 className="h4c beddo-color">
                          {`${authenticatedUser.firstName} ${authenticatedUser.lastName}`}
                        </h4>
                      </Link>
                    )}
                  </div>
                </>
              )}
              {!cartDisplay
                ? ""
                : cartSkuItems.length > 0 && (
                    <>
                      <div className="cart-icon">
                        <Link to="/sepet">
                          <i className="fa fa-shopping-cart"></i>

                          <div className="cart-length">
                            {cartSkuItems.reduce(
                              (a, c) => a + Number(c.qty),
                              0
                            )}
                          </div>
                        </Link>
                      </div>
                      <div className="success">
                        <h3 className="h4c">Sepetinizde ürün var!</h3>
                      </div>
                    </>
                  )}
            </div>
            <nav className="navbar">
              <div
                className={
                  location.pathname.includes("/land/") || converterAppeal
                    ? "logo-box"
                    : "logo-box pointer"
                }
                onClick={handleHomepageLink}
              >
                <img
                  className="navbar-logo"
                  src="/images/beddo-mern-logo.png"
                  alt="beddo logosu - beddo senin huzur senin"
                />
                <figcaption className="h3c">
                  beddo™ Senin Huzur Senin
                </figcaption>

                <div className="menu-icon">
                  <i className="fa fa-bars"></i>
                </div>
              </div>
              {location.pathname.includes("/land/") || converterAppeal ? (
                ""
              ) : (
                <>
                  <div className="menu-box">
                    <ul className="nav-menu">
                      <li onClick={handleHomepageLink}>
                        <div
                          className={
                            bottomBorder
                              ? "fs125 pointer nav-links nav-links-active"
                              : "fs125 pointer"
                          }
                        >
                          Anasayfa
                        </div>
                      </li>
                      <li onClick={() => setBottomBorder(false)}>
                        <NavLink
                          exact
                          key="2"
                          activeClassName="nav-links-active"
                          className="nav-links"
                          to="/hakkimizda"
                        >
                          Hakkımızda
                        </NavLink>
                      </li>
                      <li onClick={() => setBottomBorder(false)}>
                        <NavLink
                          exact
                          key="3"
                          activeClassName="nav-links-active"
                          className="nav-links"
                          to="/iletisim"
                        >
                          İletişim
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="customer-support-box">
                    <SearchBar />
                    <ul>
                      <li onClick={() => setBottomBorder(false)}>
                        {!authenticatedUser ? (
                          <NavLink
                            exact
                            key="4"
                            activeClassName="nav-links-active"
                            className="nav-links"
                            to="/musteri-hizmetleri-giris"
                          >
                            Müşteri Hizmetleri
                          </NavLink>
                        ) : (
                          <NavLink
                            exact
                            key="10"
                            activeClassName="nav-links-active"
                            className="nav-links"
                            to="/musteri-hizmetleri-giris"
                          >
                            Müşteri Hizmetleri
                          </NavLink>
                        )}
                      </li>
                      {authenticatedUser && (
                        <>
                          <li onClick={() => setBottomBorder(false)}>
                            <NavLink
                              exact
                              key="5"
                              activeClassName="nav-links-active"
                              className="nav-links"
                              to="/profilim"
                            >
                              Profilim
                            </NavLink>
                          </li>
                          <li onClick={() => setBottomBorder(false)}>
                            <NavLink
                              exact
                              key="6"
                              activeClassName="nav-links-active"
                              className="nav-links"
                              to="/siparislerim"
                            >
                              Siparişlerim
                            </NavLink>
                          </li>
                          <li onClick={() => setBottomBorder(false)}>
                            <NavLink
                              exact
                              key="7"
                              activeClassName="nav-links-active"
                              className="nav-links"
                              to="/destek"
                            >
                              Destek
                            </NavLink>
                          </li>
                        </>
                      )}

                      <li onClick={() => setBottomBorder(false)}>
                        {authenticatedUser ? (
                          <NavLink
                            exact
                            key="8"
                            activeClassName="nav-links-active"
                            className="nav-links"
                            to="/musteri-hizmetleri-giris"
                            onClick={logoutHandler}
                          >
                            <i className="fas fa-sign-out-alt fa-flip-horizontal"></i>
                            Çıkış
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </nav>
          </header>
        </>
      )}
    </>
  );
}
