import React from "react";

export default function FreeGoodsThresHoldTable(props) {
  // PROPS COMING FROM NavigationTop
  const { purchaseLevelOne, purchaseLevelTwo, purchaseLevelThree } = props;

  const orange = {
    backgroundColor: "#FFD700",
  };
  const darkOrange = {
    backgroundColor: "#FFA500",
  };
  const darkerOrange = {
    backgroundColor: "#FF8C00",
  };

  return (
    <>
      <div className="row-nowrap-baseline">
        <p className="marq">*</p>
        <p className="row h4r navigation-border-bottom">
          Seviye 1, sipariş oluşturmak için gereken minimum tutardır.
        </p>
      </div>
      <div className="row-nowrap-baseline">
        <p className="marq">*</p>
        <p className="row h4r navigation-border-bottom">
          Seviye 2'de %{purchaseLevelTwo.extraGoods} oranında mal fazlası
          kazanılır.
        </p>
      </div>
      <div className="row-nowrap-baseline marb1">
        <p className="marq">*</p>
        <p className="row h4r txtleft">
          Ve seviye 3'de %{purchaseLevelThree.extraGoods} oranında mal fazlası
          kazanılır.{" "}
        </p>
      </div>

      {purchaseLevelOne && (
        <table className="w100p">
          <thead>
            <tr>
              <th></th>
              <th>Min. Tutar</th>
              <th>Oran %</th>
            </tr>
          </thead>
          <tbody>
            <tr style={orange}>
              <th>Seviye 1</th>
              <td className="txtleft">
                <i className="fs1 fas fa-chevron-right"></i>{" "}
                {purchaseLevelOne.amount} TL
              </td>
              <td className="txtleft">
                <i className="fs1 far fa-percent"></i>{" "}
                {purchaseLevelOne.extraGoods}
              </td>
            </tr>
            <tr style={darkOrange}>
              <th>Seviye 2</th>
              <td className="txtleft">
                <i className="fs1 fas fa-chevron-right"></i>{" "}
                {purchaseLevelTwo.amount} TL
              </td>
              <td className="txtleft">
                <i className="fs1 far fa-percent"></i>{" "}
                {purchaseLevelTwo.extraGoods}
              </td>
            </tr>
            <tr style={darkerOrange}>
              <th>Seviye 3</th>
              <td className="txtleft">
                <i className="fs1 fas fa-chevron-right"></i>{" "}
                {purchaseLevelThree.amount} TL
              </td>
              <td className="txtleft">
                <i className="fs1 far fa-percent"></i>{" "}
                {purchaseLevelThree.extraGoods}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}
