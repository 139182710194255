import {
  UNSUBMITTED_CREATE_FAIL,
  UNSUBMITTED_CREATE_REQUEST,
  UNSUBMITTED_CREATE_RESET,
  UNSUBMITTED_CREATE_SUCCESS,
} from "../constants/unSubmittedOrdersConstants";

// CREATE UNSUBMITTED ORDER
export const reducerToUnSubmittedOrderCreate = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case UNSUBMITTED_CREATE_REQUEST:
      return { loading: true };
    case UNSUBMITTED_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case UNSUBMITTED_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case UNSUBMITTED_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};
