import React from "react";
import "./SuspenseFallback.css";

export default function SuspenseFallback() {
  return (
    <div className="suspense-shadow-platform">
      <div className="row-center w100p">
        <div className="fs2 white">beddo</div>
        <div className="fs1 white">™</div>
      </div>
      <div className="row-center w100p">
        <i className="fa fa-spinner fa-spin fs-85 white"></i>
        <div className="fs1">Sayfa yükleniyor...</div>
      </div>
    </div>
  );
}
