import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetTrendyolCategoryTree = createAsyncThunk(
  "jobInactionToGetTrendyolCategoryTree",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/trendyol/getTrendyolCategoryTree",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getTrendyolCategoryTree = createSlice({
  name: "sliceToGetTrendyolCategoryTree",
  initialState: {
    categoryTree: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetTrendyolCategoryTree.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToGetTrendyolCategoryTree.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categoryTree = action.payload;
      })
      .addCase(actionToGetTrendyolCategoryTree.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const reducerToGetTrendyolCategoryTree = getTrendyolCategoryTree.reducer;

export default reducerToGetTrendyolCategoryTree;
