//ADMIN CREATE MODEL
export const ADMIN_MODEL_CREATE_REQUEST = "ADMIN_MODEL_CREATE_REQUEST";
export const ADMIN_MODEL_CREATE_SUCCESS = "ADMIN_MODEL_CREATE_SUCCESS";
export const ADMIN_MODEL_CREATE_FAIL = "ADMIN_MODEL_CREATE_FAIL";
export const ADMIN_MODEL_CREATE_RESET = "ADMIN_MODEL_CREATE_RESET";

//ADMIN MODEL LIST
export const ADMIN_MODEL_LIST_REQUEST = "ADMIN_MODEL_LIST_REQUEST";
export const ADMIN_MODEL_LIST_SUCCESS = "ADMIN_MODEL_LIST_SUCCESS";
export const ADMIN_MODEL_LIST_FAIL = "ADMIN_MODEL_LIST_FAIL";

//ADMIN MODEL DETAILS
export const ADMIN_MODEL_DETAILS_REQUEST = "ADMIN_MODEL_DETAILS_REQUEST";
export const ADMIN_MODEL_DETAILS_SUCCESS = "ADMIN_MODEL_DETAILS_SUCCESS";
export const ADMIN_MODEL_DETAILS_FAIL = "ADMIN_MODEL_DETAILS_FAIL";

//ADMIN MODEL EDIT
export const ADMIN_MODEL_EDIT_REQUEST = "ADMIN_MODEL_EDIT_REQUEST";
export const ADMIN_MODEL_EDIT_SUCCESS = "ADMIN_MODEL_EDIT_SUCCESS";
export const ADMIN_MODEL_EDIT_FAIL = "ADMIN_MODEL_EDIT_FAIL";
export const ADMIN_MODEL_EDIT_RESET = "ADMIN_MODEL_EDIT_RESET";

//ADMIN MODEL DELETE
export const ADMIN_MODEL_DELETE_REQUEST = "ADMIN_MODEL_DELETE_REQUEST";
export const ADMIN_MODEL_DELETE_SUCCESS = "ADMIN_MODEL_DELETE_SUCCESS";
export const ADMIN_MODEL_DELETE_FAIL = "ADMIN_MODEL_DELETE_FAIL";
export const ADMIN_MODEL_DELETE_RESET = "ADMIN_MODEL_DELETE_RESET";
