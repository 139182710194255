import { useEffect, useState } from "react";

export function useExtraDiscountShown(createdFingerPrint) {
  const [extraDiscountOpen, setExtraDiscountOpen] = useState("");
  const extraDiscountShown = localStorage.getItem("extraDiscountShown");
  useEffect(() => {
    if (extraDiscountShown !== "true") {
      setTimeout(() => {
        setExtraDiscountOpen("OPEN");
      }, 2000);
    } else {
      setExtraDiscountOpen("CLOSE");
    }
  }, [extraDiscountShown, createdFingerPrint?.isExtraDiscountShown]);

  return extraDiscountOpen;
}
