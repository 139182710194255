import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { actionToGetProductDetails } from "../../actions/productActions";
import { convertToUrl } from "../../libraries/ercLibrary";
import FourOFour from "../FourOFour";

export default function RedirectShortLinks() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  //REDUX STATE THAT HOLDS PRODUCT DETAILS
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  //GET PRODUCT DETAILS FROM DATABASE
  useEffect(() => {
    const modelId = location.pathname.replace("/", "");

    if (modelId.length > 2 && modelId.length < 5) {
      dispatch(actionToGetProductDetails(modelId));
    }
  }, [dispatch, location]);

  // REDIRECT TO PRODUCT PAGE
  useEffect(() => {
    if (!loading && !error && product) {
      history.push(`/urun/${convertToUrl(product.name)}/${product._id}`);
    }
  }, [product, history, error, loading]);

  //HIDE 404 UNTIL A RESPOSE RECEIVED FROM API
  const [showFourOfour, setShowFourOfour] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFourOfour(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return <>{showFourOfour && <FourOFour />}</>;
}
