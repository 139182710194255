import {
  USER_AUTHENTICATE_FAIL,
  USER_AUTHENTICATE_REQUEST,
  USER_AUTHENTICATE_SUCCESS,
  USER_AUTHENTICATE_LOGOUT,
  USER_SMS_CODE_FAIL,
  USER_SMS_CODE_REQUEST,
  USER_SMS_CODE_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_RESET,
  USER_SET_SCORE_REQUEST,
  USER_SET_SCORE_SUCCESS,
  USER_SET_SCORE_FAIL,
  USER_SET_SCORE_RESET,
} from "../constants/userconstants";

export function reducerToSendSmsCode(state = {}, action) {
  switch (action.type) {
    case USER_SMS_CODE_REQUEST:
      return { loading: true };
    case USER_SMS_CODE_SUCCESS:
      return { loading: false, data: action.payload };
    case USER_SMS_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export function reducerToAuthenticateUser(state = { loading: true }, action) {
  switch (action.type) {
    case USER_AUTHENTICATE_REQUEST:
      return { loading: true };
    case USER_AUTHENTICATE_SUCCESS:
      return {
        loading: false,
        authenticatedUser: action.payload,
        message: action.payload.message,
      };
    case USER_AUTHENTICATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_AUTHENTICATE_LOGOUT:
      return { loading: false };
    default:
      return state;
  }
}

export function reducerToGetUserDetails(state = { user: {} }, action) {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return (state = { user: {} });
    default:
      return state;
  }
}

export function reducerToUpdateUserProfile(state = {}, action) {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedUserProfile: action.payload,
      };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
}

// SET USER SCORE
export function reducerToSetUserScore(
  state = { loading: true, success: false },
  action
) {
  switch (action.type) {
    case USER_SET_SCORE_REQUEST:
      return { loading: true };
    case USER_SET_SCORE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_SET_SCORE_FAIL:
      return { loading: false, error: action.payload };
    case USER_SET_SCORE_RESET:
      return {};
    default:
      return state;
  }
}
