/* CRUD OPERATIONS FOR MATERIALS: Materials are parts that are used to produce a product */

// @desc Create a material
export const MATERIAL_CREATE_REQUEST = "MATERIAL_CREATE_REQUEST";
export const MATERIAL_CREATE_SUCCESS = "MATERIAL_CREATE_SUCCESS";
export const MATERIAL_CREATE_FAIL = "MATERIAL_CREATE_FAIL";
export const MATERIAL_CREATE_RESET = "MATERIAL_CREATE_RESET";

// @desc List materials
export const MATERIALS_LIST_REQUEST = "MATERIALS_LIST_REQUEST";
export const MATERIALS_LIST_SUCCESS = "MATERIALS_LIST_SUCCESS";
export const MATERIALS_LIST_FAIL = "MATERIALS_LIST_FAIL";
export const MATERIALS_LIST_RESET = "MATERIALS_LIST_RESET";

// @desc Material details
export const MATERIAL_DETAILS_REQUEST = "MATERIAL_DETAILS_REQUEST";
export const MATERIAL_DETAILS_SUCCESS = "MATERIAL_DETAILS_SUCCESS";
export const MATERIAL_DETAILS_FAIL = "MATERIAL_DETAILS_FAIL";

// @desc Edit material details
export const MATERIAL_EDIT_REQUEST = "MATERIAL_EDIT_REQUEST";
export const MATERIAL_EDIT_SUCCESS = "MATERIAL_EDIT_SUCCESS";
export const MATERIAL_EDIT_FAIL = "MATERIAL_EDIT_FAIL";
export const MATERIAL_EDIT_RESET = "MATERIAL_EDIT_RESET";

// @desc delete material
export const MATERIAL_DELETE_REQUEST = "MATERIAL_DELETE_REQUEST";
export const MATERIAL_DELETE_SUCCESS = "MATERIAL_DELETE_SUCCESS";
export const MATERIAL_DELETE_FAIL = "MATERIAL_DELETE_FAIL";
export const MATERIAL_DELETE_RESET = "MATERIAL_DELETE_RESET";

/* CRUD OPERATIONS FOR WAREHOUSES: Warehouses are places where materials and products are kept. */

// @desc Create a warehouse
export const WAREHOUSE_CREATE_REQUEST = "WAREHOUSE_CREATE_REQUEST";
export const WAREHOUSE_CREATE_SUCCESS = "WAREHOUSE_CREATE_SUCCESS";
export const WAREHOUSE_CREATE_FAIL = "WAREHOUSE_CREATE_FAIL";
export const WAREHOUSE_CREATE_RESET = "WAREHOUSE_CREATE_RESET";

// @desc List warehouses
export const WAREHOUSE_LIST_REQUEST = "WAREHOUSE_LIST_REQUEST";
export const WAREHOUSE_LIST_SUCCESS = "WAREHOUSE_LIST_SUCCESS";
export const WAREHOUSE_LIST_FAIL = "WAREHOUSE_LIST_FAIL";

// @desc Warehouse details
export const WAREHOUSE_DETAILS_REQUEST = "WAREHOUSE_DETAILS_REQUEST";
export const WAREHOUSE_DETAILS_SUCCESS = "WAREHOUSE_DETAILS_SUCCESS";
export const WAREHOUSE_DETAILS_FAIL = "WAREHOUSE_DETAILS_FAIL";

// @desc Edit warehouse details
export const WAREHOUSE_EDIT_REQUEST = "WAREHOUSE_EDIT_REQUEST";
export const WAREHOUSE_EDIT_SUCCESS = "WAREHOUSE_EDIT_SUCCESS";
export const WAREHOUSE_EDIT_FAIL = "WAREHOUSE_EDIT_FAIL";
export const WAREHOUSE_EDIT_RESET = "WAREHOUSE_EDIT_RESET";

// @desc delete warehouse
export const WAREHOUSE_DELETE_REQUEST = "WAREHOUSE_DELETE_REQUEST";
export const WAREHOUSE_DELETE_SUCCESS = "WAREHOUSE_DELETE_SUCCESS";
export const WAREHOUSE_DELETE_FAIL = "WAREHOUSE_DELETE_FAIL";
export const WAREHOUSE_DELETE_RESET = "WAREHOUSE_DELETE_RESET";

/* CRUD OPERATIONS FOR INVENTORY ACTIVITIES: Inventory activities are the in and out of the inventory */

// @desc Create a inventory
export const INVENTORY_ACTIVITY_CREATE_REQUEST =
  "INVENTORY_ACTIVITY_CREATE_REQUEST";
export const INVENTORY_ACTIVITY_CREATE_SUCCESS =
  "INVENTORY_ACTIVITY_CREATE_SUCCESS";
export const INVENTORY_ACTIVITY_CREATE_FAIL = "INVENTORY_ACTIVITY_CREATE_FAIL";
export const INVENTORY_ACTIVITY_CREATE_RESET =
  "INVENTORY_ACTIVITY_CREATE_RESET";

// @desc List inventory activities
export const INVENTORY_ACTIVITY_LIST_REQUEST =
  "INVENTORY_ACTIVITY_LIST_REQUEST";
export const INVENTORY_ACTIVITY_LIST_SUCCESS =
  "INVENTORY_ACTIVITY_LIST_SUCCESS";
export const INVENTORY_ACTIVITY_LIST_FAIL = "INVENTORY_ACTIVITY_LIST_FAIL";
export const INVENTORY_ACTIVITY_LIST_RESET = "INVENTORY_ACTIVITY_LIST_RESET";

// @desc inventory activity details
export const INVENTORY_ACTIVITY_DETAILS_REQUEST =
  "INVENTORY_ACTIVITY_DETAILS_REQUEST";
export const INVENTORY_ACTIVITY_DETAILS_SUCCESS =
  "INVENTORY_ACTIVITY_DETAILS_SUCCESS";
export const INVENTORY_ACTIVITY_DETAILS_FAIL =
  "INVENTORY_ACTIVITY_DETAILS_FAIL";

// @desc Edit inventory activity details
export const INVENTORY_ACTIVITY_EDIT_REQUEST =
  "INVENTORY_ACTIVITY_EDIT_REQUEST";
export const INVENTORY_ACTIVITY_EDIT_SUCCESS =
  "INVENTORY_ACTIVITY_EDIT_SUCCESS";
export const INVENTORY_ACTIVITY_EDIT_FAIL = "INVENTORY_ACTIVITY_EDIT_FAIL";
export const INVENTORY_ACTIVITY_EDIT_RESET = "INVENTORY_ACTIVITY_EDIT_RESET";

// @desc Get a list of paraşüt stock movement and create multiple inventory activities based on the movements
export const LIST_PARASUT_ACTIVITIES_REQUEST =
  "LIST_PARASUT_ACTIVITIES_REQUEST";
export const LIST_PARASUT_ACTIVITIES_SUCCESS =
  "LIST_PARASUT_ACTIVITIES_SUCCESS";
export const LIST_PARASUT_ACTIVITIES_FAIL = "LIST_PARASUT_ACTIVITIES_FAIL";
export const LIST_PARASUT_ACTIVITIES_RESET = "LIST_PARASUT_ACTIVITIES_RESET";

// @desc Mark the inventory activities as printed
export const MARK_INVENTORY_ACTIVITY_PRINTED_REQUEST =
  "MARK_INVENTORY_ACTIVITY_PRINTED_REQUEST";
export const MARK_INVENTORY_ACTIVITY_PRINTED_SUCCESS =
  "MARK_INVENTORY_ACTIVITY_PRINTED_SUCCESS";
export const MARK_INVENTORY_ACTIVITY_PRINTED_FAIL =
  "MARK_INVENTORY_ACTIVITY_PRINTED_FAIL";
export const MARK_INVENTORY_ACTIVITY_PRINTED_RESET =
  "MARK_INVENTORY_ACTIVITY_PRINTED_RESET";

// @desc Bring in an inventory activity based on the materialCode to find the latest pricing
export const LATEST_PRICING_INVENTORY_ACTIVITY_REQUEST =
  "LATEST_PRICING_INVENTORY_ACTIVITY_REQUEST";
export const LATEST_PRICING_INVENTORY_ACTIVITY_SUCCESS =
  "LATEST_PRICING_INVENTORY_ACTIVITY_SUCCESS";
export const LATEST_PRICING_INVENTORY_ACTIVITY_FAIL =
  "LATEST_PRICING_INVENTORY_ACTIVITY_FAIL";
export const LATEST_PRICING_INVENTORY_ACTIVITY_RESET =
  "LATEST_PRICING_INVENTORY_ACTIVITY_RESET";

// @desc Delete an inventory activity
export const DELETE_INVENTORY_ACTIVITY_REQUEST =
  "DELETE_INVENTORY_ACTIVITY_REQUEST";
export const DELETE_INVENTORY_ACTIVITY_SUCCESS =
  "DELETE_INVENTORY_ACTIVITY_SUCCESS";
export const DELETE_INVENTORY_ACTIVITY_FAIL = "DELETE_INVENTORY_ACTIVITY_FAIL";
export const DELETE_INVENTORY_ACTIVITY_RESET =
  "DELETE_INVENTORY_ACTIVITY_RESET";

// @desc Get sales report inventory activity
export const SET_IO_DATA = "SET_IO_DATA";
