import React from "react";
import "./Cart.css";
import { stylerDecimalPart } from "../../../../../utils/stylerDecimalPart";
import { useCartTotals } from "../hooks/useCartTotals";

export default function CartSummary({
  freeGoodsAllocationActive,
  orderNumber,
  privatePricing,
}) {
  const { cartTotalVatIncluded, cartTotalVatExcluded, cartQuantity } =
    useCartTotals();

  return (
    <div className="cart-summary-container">
      <div className="w100p white-border-right">
        <div className="col greenbcklight h2r">Toplam Ürün</div>
        <div className="col purplelightestbck h2r">{cartQuantity}</div>
      </div>
      <div className="w100p white-border-right">
        <div className="col greenbcklight h2r">
          {orderNumber
            ? "Sipariş Toplamı"
            : freeGoodsAllocationActive > 0
            ? "Sepet Toplamı"
            : "Tutar KDV Hariç"}{" "}
        </div>
        <div className="col purplelightestbck h2r">
          <p>
            {parseInt(cartTotalVatExcluded)}.
            {stylerDecimalPart(cartTotalVatExcluded)} TL
          </p>
        </div>
      </div>
      {freeGoodsAllocationActive > 0 ? (
        <div className="w100p">
          <div className="col greenbcklight h2r">Mal Fazlası</div>
          <div className="col purplelightestbck h2r">
            <p>
              {parseInt(freeGoodsAllocationActive)}.
              {stylerDecimalPart(freeGoodsAllocationActive)} TL
            </p>
          </div>
        </div>
      ) : (
        <div className="w100p">
          <div className="col greenbcklight h2r">Tutar KDV Dahil</div>
          <div className="col purplelightestbck h2r">
            <p>
              {parseInt(cartTotalVatIncluded)}.
              {stylerDecimalPart(cartTotalVatIncluded)} TL
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
