// modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
};

const modalSlice = createSlice({
  name: "openErcModal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    resetModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { openModal, closeModal, resetModal } = modalSlice.actions;

// Export the reducer
const reducerToToggleErcModal = modalSlice.reducer;

export { reducerToToggleErcModal };
