import Axios from "axios";
import {
  ADMIN_CREATE_POP_FAIL,
  ADMIN_CREATE_POP_REQUEST,
  ADMIN_CREATE_POP_SUCCESS,
  ADMIN_DETAILS_POP_FAIL,
  ADMIN_DETAILS_POP_REQUEST,
  ADMIN_DETAILS_POP_SUCCESS,
  ADMIN_EDIT_POP_FAIL,
  ADMIN_EDIT_POP_REQUEST,
  ADMIN_EDIT_POP_SUCCESS,
  ADMIN_LIST_POPS_FAIL,
  ADMIN_LIST_POPS_REQUEST,
  ADMIN_LIST_POPS_SUCCESS,
} from "../constants/adminPopsConstants";

// POST
// CREATE A DISCOUNT CODE
export const actionAdminToCreatePop =
  (popsPreferences) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_CREATE_POP_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        "/api/adminpops/create",
        popsPreferences,
        {
          headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
        }
      );
      dispatch({ type: ADMIN_CREATE_POP_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_CREATE_POP_FAIL, payload: message });
    }
  };

// GET
// LIST DISCOUNT CODES
export const actionAdminToListPops = () => async (dispatch) => {
  dispatch({ type: ADMIN_LIST_POPS_REQUEST });

  try {
    const { data } = await Axios.get("/api/adminpops/listpops");
    dispatch({ type: ADMIN_LIST_POPS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_LIST_POPS_FAIL, payload: message });
  }
};

//PUT
// UPDATE/EDIT POP
export const actionAdminToEditPop =
  (updateInfo) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_EDIT_POP_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.put(`/api/adminpops/edit`, updateInfo, {
        headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
      });
      dispatch({ type: ADMIN_EDIT_POP_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_EDIT_POP_FAIL, payload: message });
    }
  };

// GET
// GET DETAILS OF POP
export const actionAdminGetDetailsOfPop =
  (popId) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_DETAILS_POP_REQUEST });
    const {
      adminUserToLogin: { adminUserInfo },
    } = getState();
    try {
      const { data } = await Axios.get(`/api/adminpops/detailsofpop/${popId}`, {
        headers: { Authorization: `Bearer ${adminUserInfo?.token}` },
      });
      dispatch({ type: ADMIN_DETAILS_POP_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ADMIN_DETAILS_POP_FAIL, payload: message });
    }
  };
