import {
  ADSCOST_CREATE_FAIL,
  ADSCOST_CREATE_REQUEST,
  ADSCOST_CREATE_RESET,
  ADSCOST_CREATE_SUCCESS,
  ADSCOST_DETAILS_FAIL,
  ADSCOST_DETAILS_REQUEST,
  ADSCOST_DETAILS_SUCCESS,
  ADSCOST_EDIT_FAIL,
  ADSCOST_EDIT_REQUEST,
  ADSCOST_EDIT_RESET,
  ADSCOST_EDIT_SUCCESS,
  ADSCOST_LIST_FAIL,
  ADSCOST_LIST_REQUEST,
  ADSCOST_LIST_SUCCESS,
  ANALYZE_ORDER_LIST_FAIL,
  ANALYZE_ORDER_LIST_REQUEST,
  ANALYZE_ORDER_LIST_SUCCESS,
} from "../constants/adminAnalyticsConstants";

// @desc List orders to create reports
export function reduceAnalyzeOrders(state = {}, action) {
  switch (action.type) {
    case ANALYZE_ORDER_LIST_REQUEST:
      return { loading: true };
    case ANALYZE_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload };
    case ANALYZE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Post ads cost
export const reducerCreateAdsCost = (state = { success: false }, action) => {
  switch (action.type) {
    case ADSCOST_CREATE_REQUEST:
      return { loading: true };
    case ADSCOST_CREATE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADSCOST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADSCOST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// @desc List ads cost
export function reducerListAdsCosts(state = {}, action) {
  switch (action.type) {
    case ADSCOST_LIST_REQUEST:
      return { loading: true };
    case ADSCOST_LIST_SUCCESS:
      return {
        loading: false,
        adsCosts: action.payload.adsCostsList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADSCOST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Ads cost details
export function reducerAdsCostDetails(state = { loading: true }, action) {
  switch (action.type) {
    case ADSCOST_DETAILS_REQUEST:
      return { loading: true };
    case ADSCOST_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsAdsCost: action.payload,
      };
    case ADSCOST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Ads cost edit
export function reducerAdsCostEdit(state = { success: false }, action) {
  switch (action.type) {
    case ADSCOST_EDIT_REQUEST:
      return { loading: true };
    case ADSCOST_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADSCOST_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADSCOST_EDIT_RESET:
      return { success: false };
    default:
      return state;
  }
}
