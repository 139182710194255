import React from "react";

export default function LoadingBlackOut(props) {
  // PROPS COMING FROM ProductLandings, SmsCampaignScreen
  const {
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    stepFive,
    stepSix,
    stepLast,
    message,
  } = props;

  // STYLE LoadingBlackOut
  const LoadingBlackOut = {
    backgroundColor: "rgb(0, 0, 0, 0.75)",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    position: "fixed",
    zIndex: "1001",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={LoadingBlackOut}>
      <i className="fa fa-spinner fa-spin fs3 white mar1"></i>
      {message && <p className="fs1 white">{message}</p>}
      {stepLast ? (
        <p className="fs15 white">
          Hata : <span className="beddo-color">{stepLast}</span>
        </p>
      ) : stepSix ? (
        <p className="fs15 white">
          Adım 6 : <span className="beddo-color">{stepSix}</span>
        </p>
      ) : stepFive ? (
        <p className="fs15 white">
          Adım 5 : <span className="beddo-color">{stepFive}</span>
        </p>
      ) : stepFour ? (
        <p className="fs15 white">
          Adım 4 : <span className="beddo-color">{stepFour}</span>
        </p>
      ) : stepThree ? (
        <p className="fs15 white">
          ADIM 3: <span className="beddo-color">İşlenen veri {stepThree}</span>
        </p>
      ) : stepTwo ? (
        <p className="fs15 white">
          Adım 2 :{" "}
          <span className="beddo-color">İşlenecek toplam veri {stepTwo}</span>
        </p>
      ) : (
        stepOne && (
          <p className="fs15 white">
            Adım 1: <span className="beddo-color">İşlem Yürütülüyor...</span>
          </p>
        )
      )}
    </div>
  );
}
