import { useEffect, useState } from "react";
import calculateLimitedTimeDiscountValidity from "../utils/calculateLimitedTimeDiscountValidity";

export function useUpdateCartItemsWithDiscountInfo(
  cartSkuItems,
  firstVisitTime
) {
  const [cartSkuItemsWithDiscountInfo, setCartSkuItemsWithDiscountInfo] =
    useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const newDiscountInfo = cartSkuItems.map((item) => {
        const limitedDiscountInfo = calculateLimitedTimeDiscountValidity(
          item,
          firstVisitTime
        );
        return { item, limitedDiscountInfo };
      });

      setCartSkuItemsWithDiscountInfo(newDiscountInfo);
    };

    fetchData();
  }, [cartSkuItems, firstVisitTime]);

  return cartSkuItemsWithDiscountInfo;
}
