export function getTurkishSuffixForNumbers(num, suffix) {
  const suffixes =
    suffix === "e"
      ? {
          1: "e",
          2: "ye",
          3: "e",
          4: "e",
          5: "e",
          6: "ya",
          7: "ye",
          8: "e",
          9: "a",
          10: "a",
          11: "e",
          12: "ye",
          13: "e",
          14: "e",
          15: "e",
          16: "ya",
          17: "ye",
          18: "e",
          19: "a",
          20: "ye",
          21: "e",
          22: "ye",
          23: "e",
          24: "e",
          25: "e",
          26: "ya",
          27: "ye",
          28: "e",
          29: "a",
          30: "a",
          31: "e",
          32: "ye",
          33: "e",
          34: "e",
          35: "e",
          36: "ya",
          37: "ye",
          38: "e",
          39: "a",
          40: "a",
          41: "e",
          42: "ye",
          43: "e",
          44: "e",
          45: "e",
          46: "ya",
          47: "ye",
          48: "e",
          49: "a",
          50: "ye",
          51: "e",
          52: "ye",
          53: "e",
          54: "e",
          55: "e",
          56: "ya",
          57: "ye",
          58: "e",
          59: "a",
          60: "a",
          61: "e",
          62: "ye",
          63: "e",
          64: "e",
          65: "e",
          66: "ya",
          67: "ye",
          68: "e",
          69: "a",
          70: "e",
          71: "e",
          72: "ye",
          73: "e",
          74: "e",
          75: "e",
          76: "ya",
          77: "ye",
          78: "e",
          79: "a",
          80: "e",
          81: "e",
          82: "ye",
          83: "e",
          84: "e",
          85: "e",
          86: "ya",
          87: "ye",
          88: "e",
          89: "a",
          90: "a",
          91: "e",
          92: "ye",
          93: "e",
          94: "e",
          95: "e",
          96: "ya",
          97: "ye",
          98: "e",
          99: "a",
          100: "e",
        }
      : {
          1: "i",
          2: "si",
          3: "ü",
          4: "ü",
          5: "i",
          6: "sı",
          7: "si",
          8: "i",
          9: "u",
          10: "u",
          11: "i",
          12: "si",
          13: "ü",
          14: "ü",
          15: "i",
          16: "sı",
          17: "si",
          18: "i",
          19: "u",
          20: "si",
          21: "i",
          22: "si",
          23: "ü",
          24: "ü",
          25: "i",
          26: "sı",
          27: "si",
          28: "i",
          29: "u",
          30: "u",
          31: "i",
          32: "si",
          33: "ü",
          34: "ü",
          35: "i",
          36: "sı",
          37: "si",
          38: "i",
          39: "u",
          40: "ı",
          41: "i",
          42: "si",
          43: "ü",
          44: "ü",
          45: "i",
          46: "sı",
          47: "si",
          48: "i",
          49: "u",
          50: "si",
          51: "i",
          52: "si",
          53: "ü",
          54: "ü",
          55: "i",
          56: "sı",
          57: "si",
          58: "i",
          59: "u",
          60: "ı",
          61: "i",
          62: "si",
          63: "ü",
          64: "ü",
          65: "i",
          66: "sı",
          67: "si",
          68: "i",
          69: "u",
          70: "i",
          71: "i",
          72: "si",
          73: "ü",
          74: "ü",
          75: "i",
          76: "sı",
          77: "si",
          78: "i",
          79: "u",
          80: "i",
          81: "i",
          82: "si",
          83: "ü",
          84: "ü",
          85: "i",
          86: "sı",
          87: "si",
          88: "i",
          89: "u",
          90: "ı",
          91: "i",
          92: "si",
          93: "ü",
          94: "ü",
          95: "i",
          96: "sı",
          97: "si",
          98: "i",
          99: "u",
          100: "ü",
        };

  return "'" + (suffixes[num] || ""); // Default to an empty string if the number is not in the map.
}
