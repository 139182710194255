import {
  ADMIN_SECURED_ORDER_STATUS_CREATE_FAIL,
  ADMIN_SECURED_ORDER_STATUS_CREATE_REQUEST,
  ADMIN_SECURED_ORDER_STATUS_CREATE_RESET,
  ADMIN_SECURED_ORDER_STATUS_CREATE_SUCCESS,
  ADMIN_SECURED_ORDER_STATUS_DELETE_FAIL,
  ADMIN_SECURED_ORDER_STATUS_DELETE_REQUEST,
  ADMIN_SECURED_ORDER_STATUS_DELETE_RESET,
  ADMIN_SECURED_ORDER_STATUS_DELETE_SUCCESS,
  ADMIN_SECURED_ORDER_STATUS_DETAILS_FAIL,
  ADMIN_SECURED_ORDER_STATUS_DETAILS_REQUEST,
  ADMIN_SECURED_ORDER_STATUS_DETAILS_SUCCESS,
  ADMIN_SECURED_ORDER_STATUS_EDIT_FAIL,
  ADMIN_SECURED_ORDER_STATUS_EDIT_REQUEST,
  ADMIN_SECURED_ORDER_STATUS_EDIT_RESET,
  ADMIN_SECURED_ORDER_STATUS_EDIT_SUCCESS,
  ADMIN_SECURED_ORDER_STATUS_LIST_FAIL,
  ADMIN_SECURED_ORDER_STATUS_LIST_REQUEST,
  ADMIN_SECURED_ORDER_STATUS_LIST_SUCCESS,
} from "../constants/adminSecuredOrderStatusConstants";

//SAVE THE CREATED SECURED ORDER STATUS IN REDUX STATE
export const reducerAdminToCreateSecuredOrderStatus = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_SECURED_ORDER_STATUS_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SECURED_ORDER_STATUS_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        securedOrderStatus: action.payload,
      };
    case ADMIN_SECURED_ORDER_STATUS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SECURED_ORDER_STATUS_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF SECURED ORDER STATUS IN REDUX STATE
export function reducerAdminToListSecuredOrderStatus(
  state = { loading: true, securedOrderStatusList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_SECURED_ORDER_STATUS_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_SECURED_ORDER_STATUS_LIST_SUCCESS:
      return {
        loading: false,
        securedOrderStatusList: action.payload,
      };
    case ADMIN_SECURED_ORDER_STATUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF SECURED ORDER STATUS IN REDUX STATE
export function reducerAdminToDetailsSecuredOrderStatus(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_SECURED_ORDER_STATUS_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_SECURED_ORDER_STATUS_DETAILS_SUCCESS:
      return {
        loading: false,
        securedOrderStatusDetails: action.payload,
      };
    case ADMIN_SECURED_ORDER_STATUS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF SECURED ORDER STATUS IN REDUX STATE
export function reducerAdminToEditSecuredOrderStatus(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_SECURED_ORDER_STATUS_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_SECURED_ORDER_STATUS_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_SECURED_ORDER_STATUS_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SECURED_ORDER_STATUS_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF SECURED ORDER STATUS IN REDUX STATE
export function reducerAdminToDeleteSecuredOrderStatus(state = {}, action) {
  switch (action.type) {
    case ADMIN_SECURED_ORDER_STATUS_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SECURED_ORDER_STATUS_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_SECURED_ORDER_STATUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SECURED_ORDER_STATUS_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
