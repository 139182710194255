import {
  ADMIN_BRAND_CREATE_FAIL,
  ADMIN_BRAND_CREATE_REQUEST,
  ADMIN_BRAND_CREATE_RESET,
  ADMIN_BRAND_CREATE_SUCCESS,
  ADMIN_BRAND_DELETE_FAIL,
  ADMIN_BRAND_DELETE_REQUEST,
  ADMIN_BRAND_DELETE_RESET,
  ADMIN_BRAND_DELETE_SUCCESS,
  ADMIN_BRAND_DETAILS_FAIL,
  ADMIN_BRAND_DETAILS_REQUEST,
  ADMIN_BRAND_DETAILS_SUCCESS,
  ADMIN_BRAND_EDIT_FAIL,
  ADMIN_BRAND_EDIT_REQUEST,
  ADMIN_BRAND_EDIT_RESET,
  ADMIN_BRAND_EDIT_SUCCESS,
  ADMIN_BRAND_LIST_FAIL,
  ADMIN_BRAND_LIST_REQUEST,
  ADMIN_BRAND_LIST_SUCCESS,
} from "../constants/adminBrandConstants";

//SAVE THE CREATED BRAND IN REDUX STATE
export const reducerAdminBrandToCreateBrand = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_BRAND_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_BRAND_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        brand: action.payload,
      };
    case ADMIN_BRAND_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_BRAND_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF BRANDS IN REDUX STATE
export function reducerAdminBrandToListBrands(
  state = { loading: true, brandsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_BRAND_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_BRAND_LIST_SUCCESS:
      return {
        loading: false,
        brandsList: action.payload,
      };
    case ADMIN_BRAND_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF BRAND IN REDUX STATE
export function reducerAdminBrandToDetailsBrand(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_BRAND_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_BRAND_DETAILS_SUCCESS:
      return {
        loading: false,
        brandDetails: action.payload,
      };
    case ADMIN_BRAND_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF BRAND IN REDUX STATE
export function reducerAdminBrandToEditBrand(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_BRAND_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_BRAND_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_BRAND_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_BRAND_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF BRAND IN REDUX STATE
export function reducerAdminBrandToDeleteBrand(state = {}, action) {
  switch (action.type) {
    case ADMIN_BRAND_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_BRAND_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_BRAND_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_BRAND_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
