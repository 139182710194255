//ADMIN CREATE SMS CAMPAIGN
export const ADMIN_SMSCAMPAIGN_CREATE_REQUEST =
  "ADMIN_SMSCAMPAIGN_CREATE_REQUEST";
export const ADMIN_SMSCAMPAIGN_CREATE_SUCCESS =
  "ADMIN_SMSCAMPAIGN_CREATE_SUCCESS";
export const ADMIN_SMSCAMPAIGN_CREATE_FAIL = "ADMIN_SMSCAMPAIGN_CREATE_FAIL";
export const ADMIN_SMSCAMPAIGN_CREATE_RESET = "ADMIN_SMSCAMPAIGN_CREATE_RESET";

//ADMIN FILTERS CUSTOMERS FOR SMS CAMPAIGN
export const ADMIN_SMSCAMPAIGN_FILTER_REQUEST =
  "ADMIN_SMSCAMPAIGN_FILTER_REQUEST";
export const ADMIN_SMSCAMPAIGN_FILTER_SUCCESS =
  "ADMIN_SMSCAMPAIGN_FILTER_SUCCESS";
export const ADMIN_SMSCAMPAIGN_FILTER_FAIL = "ADMIN_SMSCAMPAIGN_FILTER_FAIL";
export const ADMIN_SMSCAMPAIGN_FILTER_RESET = "ADMIN_SMSCAMPAIGN_FILTER_RESET";

//ADMIN LIST SMS CAMPAIGN
export const ADMIN_SMSCAMPAIGN_LIST_REQUEST = "ADMIN_SMSCAMPAIGN_LIST_REQUEST";
export const ADMIN_SMSCAMPAIGN_LIST_SUCCESS = "ADMIN_SMSCAMPAIGN_LIST_SUCCESS";
export const ADMIN_SMSCAMPAIGN_LIST_FAIL = "ADMIN_SMSCAMPAIGN_LIST_FAIL";
export const ADMIN_SMSCAMPAIGN_LIST_RESET = "ADMIN_SMSCAMPAIGN_LIST_RESET";

//ADMIN VIEW SMS CAMPAIGN DETAILS
export const ADMIN_SMSCAMPAIGN_DETAILS_REQUEST =
  "ADMIN_SMSCAMPAIGN_DETAILS_REQUEST";
export const ADMIN_SMSCAMPAIGN_DETAILS_SUCCESS =
  "ADMIN_SMSCAMPAIGN_DETAILS_SUCCESS";
export const ADMIN_SMSCAMPAIGN_DETAILS_FAIL = "ADMIN_SMSCAMPAIGN_DETAILS_FAIL";
export const ADMIN_SMSCAMPAIGN_DETAILS_RESET =
  "ADMIN_SMSCAMPAIGN_DETAILS_RESET";

//ADMIN START SMS CAMPAIGN
export const ADMIN_SMSCAMPAIGN_START_REQUEST =
  "ADMIN_SMSCAMPAIGN_START_REQUEST";
export const ADMIN_SMSCAMPAIGN_START_SUCCESS =
  "ADMIN_SMSCAMPAIGN_START_SUCCESS";
export const ADMIN_SMSCAMPAIGN_START_FAIL = "ADMIN_SMSCAMPAIGN_START_FAIL";
export const ADMIN_SMSCAMPAIGN_START_RESET = "ADMIN_SMSCAMPAIGN_START_RESET";

//ADMIN GET SMS CAMPAIGN DELIVERY REPORT
export const ADMIN_SMSCAMPAIGN_REPORT_REQUEST =
  "ADMIN_SMSCAMPAIGN_REPORT_REQUEST";
export const ADMIN_SMSCAMPAIGN_REPORT_SUCCESS =
  "ADMIN_SMSCAMPAIGN_REPORT_SUCCESS";
export const ADMIN_SMSCAMPAIGN_REPORT_FAIL = "ADMIN_SMSCAMPAIGN_REPORT_FAIL";
export const ADMIN_SMSCAMPAIGN_REPORT_RESET = "ADMIN_SMSCAMPAIGN_REPORT_RESET";

//USER DISALLOW SMS CAMPAIGN
export const USER_SMSCAMPAIGN_CANCEL_REQUEST =
  "USER_SMSCAMPAIGN_CANCEL_REQUEST";
export const USER_SMSCAMPAIGN_CANCEL_SUCCESS =
  "USER_SMSCAMPAIGN_CANCEL_SUCCESS";
export const USER_SMSCAMPAIGN_CANCEL_FAIL = "USER_SMSCAMPAIGN_CANCEL_FAIL";
export const USER_SMSCAMPAIGN_CANCEL_RESET = "USER_SMSCAMPAIGN_CANCEL_RESET";
