// ADMIN CREATE PARASUT PRODUCT
export const ADMIN_SEND_PRODUCT_TO_PARASUT_REQUEST =
  "ADMIN_SEND_PRODUCT_TO_PARASUT_REQUEST";
export const ADMIN_SEND_PRODUCT_TO_PARASUT_SUCCESS =
  "ADMIN_SEND_PRODUCT_TO_PARASUT_SUCCESS";
export const ADMIN_SEND_PRODUCT_TO_PARASUT_FAIL =
  "ADMIN_SEND_PRODUCT_TO_PARASUT_FAIL";
export const ADMIN_SEND_PRODUCT_TO_PARASUT_RESET =
  "ADMIN_SEND_PRODUCT_TO_PARASUT_RESET";

// ADMIN GET STOCK COUNT
export const ADMIN_PARASUT_STOCK_COUNT_REQUEST =
  "ADMIN_PARASUT_STOCK_COUNT_REQUEST";
export const ADMIN_PARASUT_STOCK_COUNT_SUCCESS =
  "ADMIN_PARASUT_STOCK_COUNT_SUCCESS";
export const ADMIN_PARASUT_STOCK_COUNT_FAIL = "ADMIN_PARASUT_STOCK_COUNT_FAIL";
export const ADMIN_PARASUT_STOCK_COUNT_RESET =
  "ADMIN_PARASUT_STOCK_COUNT_RESET";

// ADMIN GET PRODUCTS
export const ADMIN_PARASUT_PRODUCTS_REQUEST = "ADMIN_PARASUT_PRODUCTS_REQUEST";
export const ADMIN_PARASUT_PRODUCTS_SUCCESS = "ADMIN_PARASUT_PRODUCTS_SUCCESS";
export const ADMIN_PARASUT_PRODUCTS_FAIL = "ADMIN_PARASUT_PRODUCTS_FAIL";
export const ADMIN_PARASUT_PRODUCTS_RESET = "ADMIN_PARASUT_PRODUCTS_RESET";
