import React from "react";
import CountDownTimerVisibilityAware from "../CountDownTimer/CountDownTimerVisibilityAware";
import { useCalculateDiscountEndTime } from "../../hooks/useCalculateDiscountEndTime";

export default function PopBannerRender(props) {
  // PROPS COMING FROM PopBanner
  const {
    refPop,
    pop,
    transition,
    intentPopOpen,
    handleClosePops,
    discountCodeState,
    isDiscountValid,
    remainingTime,
  } = props;

  const displayDate = useCalculateDiscountEndTime(remainingTime);

  return (
    <div className="pop-modal-background">
      <div
        ref={refPop}
        style={pop.modalFront}
        className={
          transition
            ? "pop-modal-front pop-modal-front-active"
            : intentPopOpen && "pop-modal-front"
        }
      >
        <ul className="w95p row beddo-border-bottom mar1">
          <li>
            <img
              className="w3r"
              src="/images/beddo-mern-logo.png"
              alt="beddo logosu - beddo senin huzur senin"
            />
          </li>
          <li>
            <i
              className="fas fa-times fs125 beddo-color pointer"
              title="Kapat"
              onClick={() => handleClosePops()}
            ></i>
          </li>
        </ul>

        <div style={pop.contentDiv}>
          <h1 className="beddo-border-bottom-5" style={pop.title.style}>
            {pop.title.content}
          </h1>
          {pop.popImage && pop.popImage.content.length > 0 && (
            <img
              style={pop.popImage.style}
              src={pop.popImage.content}
              alt="beddo™ İndirimler"
            />
          )}
          {isDiscountValid && pop.displayCountDownTimer && (
            <>
              <CountDownTimerVisibilityAware milliseconds={remainingTime} />
              <p className="lightredbck padh fs15">
                İndirim Bitiş Tarihi : {displayDate}
              </p>
            </>
          )}
          <div style={pop.paragraphDiv} className="pop-paragraph-div">
            {pop.existDiscount && (
              <div className="w40p beddo-border-dashed-3 lightbck marupside1-5">
                <h2 className="fs15 red-col txtcenter marupside1-5">
                  Dikkat: Şu andan itibaren{" "}
                  <span className="bold-font">{pop.discountExpiry}</span> dakika
                  geçerlidir
                </h2>
                <h2 className="txtcenter" style={pop.title.style}>
                  {discountCodeState}
                </h2>
                <h3 className="fs3 txtcenter">
                  <span className="beddo-border-bottom">
                    {pop.discountAmount} TL Değerindedir
                  </span>
                </h3>
              </div>
            )}
            <div>
              {pop.paragraphTitle.content.map((content, index) => (
                <h3 key={index + 100} style={pop.paragraphTitle.style}>
                  {content}
                </h3>
              ))}
              {pop.paragraph.content.map((content, index) => (
                <p key={index + 200} style={pop.paragraph.style}>
                  {content}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div style={pop.buttonDiv}>
          {pop.needButton && pop.buttonYes && (
            <button type="button" style={pop.buttonYes.style}>
              {pop.buttonYes.content}
            </button>
          )}
          {pop.needButton && pop.buttonNo && (
            <button type="button" style={pop.buttonNo.style}>
              {pop.buttonNo.content}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
