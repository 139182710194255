import { combineReducers /*applyMiddleware, compose*/ } from "redux";
import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
import {
  reducerAdminBrandToCreateBrand,
  reducerAdminBrandToDeleteBrand,
  reducerAdminBrandToDetailsBrand,
  reducerAdminBrandToEditBrand,
  reducerAdminBrandToListBrands,
} from "./reducers/adminBrandReducers";
import {
  reducerAdminCategoryToCreateCategory,
  reducerAdminCategoryToDeleteCategory,
  reducerAdminCategoryToDetailsCategory,
  reducerAdminCategoryToEditCategory,
  reducerAdminCategoryToListCategories,
  reducerAdminCategoryToRelatedListCategories,
} from "./reducers/adminCategoryReducers";
import {
  reducerAdminCategorySubToCreateCategorySub,
  reducerAdminCategorySubToDeleteCategorySub,
  reducerAdminCategorySubToDetailsCategorySub,
  reducerAdminCategorySubToEditCategorySub,
  reducerAdminCategorySubToListCategorySubs,
  reducerAdminCategorySubToRelatedListCategorySubs,
} from "./reducers/adminCategorySubReducers";
import {
  reducerAdminCategoryLowToCreateCategoryLow,
  reducerAdminCategoryLowToDeleteCategoryLow,
  reducerAdminCategoryLowToDetailsCategoryLow,
  reducerAdminCategoryLowToEditCategoryLow,
  reducerAdminCategoryLowToListCategoryLows,
  reducerAdminCategoryLowToRelatedListCategoryLows,
} from "./reducers/adminCategoryLowReducers";
import {
  reducerAdminDepartmentToCreateDepartment,
  reducerAdminDepartmentToDeleteDepartment,
  reducerAdminDepartmentToDetailsDepartment,
  reducerAdminDepartmentToEditDepartment,
  reducerAdminDepartmentToListDepartments,
} from "./reducers/adminDepartmentReducers";
import {
  reducerCreateCurrentAccount,
  reducerListCurrentAccounts,
  reducerCurrentAccountDetails,
  reducerCurrentAccountEdit,
  reducerDeleteCurrentAccount,
  reducerCreateCurrentAccountActivity,
  reducerListCurrentAccountsActivities,
  reducerCurrentAccountActivityDetails,
  reducerCurrentAccountActivityEdit,
  reducerDeleteCurrentAccountActivity,
} from "./reducers/currentAccountReducers";
import {
  reducerAdminModelToCreateModel,
  reducerAdminModelToDeleteModel,
  reducerAdminModelToDetailsModel,
  reducerAdminModelToEditModel,
  reducerAdminModelToListModels,
} from "./reducers/adminModelReducers";
import {
  reducerAdminOrderToCancelAnOrder,
  reducerAdminOrderToCancelAnOrderPartially,
  reducerAdminOrderToCancelPaymentOrder,
  reducerAdminOrderToGetOrderDetails,
  reducerAdminOrderToGetUpsellOrderDetails,
  reducerAdminOrderToListOrders,
  reducerAdminOrderToListOrdersPendingShipping,
  reducerAdminOrderToMarkOrderAsPrinted,
  reducerAdminOrderToSetDelivered,
  reducerAdminOrderToSetNote,
  reducerAdminOrderToSetPaymentMethod,
  reducerAdminOrderToSetRefundOrderDelivered,
  reducerAdminOrderToSetUndelivered,
  reducerAdminOrderToSetUpsTrackingCode,
  reducerAdminOrderToSetUpsTrackingCodeForRefundOrder,
  reducerAdminOrderToUpdateCancelledPayment,
  reducerAdminOrderToUpdateCompanyInvoice,
  reducerAdminOrderToUpdateDeliveryContact,
  reducerAdminOrderToUpdateIndividualInvoice,
  reducerAdminOrderToUpdateOrderAsPaid,
  reducerAdminOrderToUpdateProfilePhone,
  reducerAdminToAlertDuplicateOrders,
} from "./reducers/adminOrderReducers";
import {
  // reducerAdminOrderToListConverterAppeals,
  reducerAdminProductGetListForSmsCampaign,
  reducerAdminProductToActiveProduct,
  reducerAdminProductToCreateProduct,
  reducerAdminProductToCreateSku,
  reducerAdminProductToDeleteProduct,
  reducerAdminProductToDeleteSection,
  reducerAdminProductToEditProduct,
  reducerAdminProductToEditSku,
  reducerAdminProductToGetListForShippingMessage,
  reducerAdminProductToGetProductDetails,
  reducerAdminProductToGetProductList,
  reducerAdminProductToGetSectionDetails,
  reducerAdminProductToGetSkuDetails,
  reducerAdminProductToGetSkuList,
  reducerAdminProductUpdateShippingMessage,
  // reducerAdminToCreateRecipe,
  // reducerAdminToDeleteRecipeItem,
  // reducerAdminToSetRecipeCost,
  // reducerAdminUpdateRecipeItem,
  reducerBulkProductUpdate,
  // reducernAdminDetailsOfRecipeItem,
} from "./reducers/adminProductReducers";
import {
  reducerAdminUserToListUserOrders,
  reducerAdminUserToListUsers,
  reducerAdminUserToLogin,
  reducerAdminUserToRegister,
  reducerAdminUserToRequestUserDetails,
  reducerAdminUserToUpdateUserDetails,
} from "./reducers/adminUserReducers";
import { reducerToAddOrRemoveSkuItemInCart } from "./reducers/cartReducers";
import {
  reducerOrderToApplyDiscountCode,
  reducerOrderToCancelAnOrder,
  reducerOrderToCancelAnOrderPartially,
  reducerOrderToCancelPaymentOrder,
  reducerOrderToCancelRefund,
  reducerOrderToCheckIfThereIsApendingOrder,
  reducerOrderToCheckUnpaidOrders,
  reducerOrderToCollectIban,
  reducerOrderToDeleteOrder,
  reducerOrderToGetOrderDetails,
  reducerOrderToGetUpsellOrderDetails,
  reducerOrderToRefundOrder,
  reducerOrderToUpdateCancelledPayment,
  reducerOrderToUpdateDeliveryContact,
  reducerOrderToUpdateIndividualInvoice,
  reducerOrderToViewInvoice,
  reducerToCreateOrder,
  //reducerToGetIyzicoFormResult,
  reducerToListUserOrderHistory,
  reducerToMatchOrderPhone,
  //reducerToRequestIyzicoForm,
  reducerToUpdatePaymentInfo,
} from "./reducers/orderReducers";
import {
  reducerBringInSkuPrice,
  reducerClassifySkus,
  reducerToBringInCampaignedSku,
  reducerToGetCampaignedProductDetails,
  reducerToGetProductDetails,
  reducerToGetProductList,
  reducerToGetSideProductsList,
} from "./reducers/productReducers";

import {
  reducerToAuthenticateUser,
  reducerToGetUserDetails,
  reducerToSendSmsCode,
  reducerToSetUserScore,
  reducerToUpdateUserProfile,
} from "./reducers/userReducers";
import {
  reducerAdminToAddNewField,
  reducerAdminToCancelUpsShipment,
  reducerAdminToCreateUpsCity,
  reducerAdminToCreateUpsCodShipment,
  reducerAdminToCreateUpsShipment,
  reducerAdminToEditUpsCity,
  reducerAdminToGetUpsCityDetails,
  reducerAdminToListUpsCities,
} from "./reducers/adminCourierReducers";
import {
  reducerAdminToCreateSupportStatus,
  reducerAdminToDeleteSupportStatus,
  reducerAdminToDetailsSupportStatus,
  reducerAdminToEditSupportStatus,
  reducerAdminToListSupportStatus,
  reducerAdminToUpdateSupportRequest,
  reducerSupportAdminListUserRequests,
  reducerSupportAdminToListUserRequests,
  reducerSupportToAdminDeleteSentMessage,
  reducerSupportToAdminSendNewMessage,
  reducerSupportToAdminSendsMessageSms,
  reducerSupportToCreateSupportMessage,
  reducerSupportToCreateWebFormMessage,
  reducerSupportToGetSupportDetails,
  reducerSupportToListUserRequests,
  reducerSupportToSendNewMessage,
  reducerSupportToUserCloseRequest,
} from "./reducers/supportReducers";
import {
  reducerAdminToCreateSecuredOrderStatus,
  reducerAdminToDeleteSecuredOrderStatus,
  reducerAdminToDetailsSecuredOrderStatus,
  reducerAdminToEditSecuredOrderStatus,
  reducerAdminToListSecuredOrderStatus,
} from "./reducers/adminSecuredOrderStatusReducers";
import {
  reducerAdminToCancelInvoice,
  reducerAdminToCreateSingleInvoice,
  reducerAdminToGetEDocumentDetails,
  reducerAdminToGetInvoiceDetails,
  reducerAdminToRunInvoiceCreateSquence,
  reducerAdminToSetExpenseSlipIssued,
} from "./reducers/adminInvoiceReducers";
import {
  reducerAdminCreateDiscountCode,
  reducerAdminEditDiscountCode,
  reducerAdminGetDetailsOfDiscountCode,
  reducerAdminListDiscountCodes,
} from "./reducers/adminDiscountCodeReducers";
import { reducerToUnSubmittedOrderCreate } from "./reducers/unSubmittedOrdersReducers";
import {
  reducerAdminOrderToCheckYurtIciKargoOrders,
  reducerAdminOrderToListPendingYurtIciKargoOrders,
  reducerAdminToCreateMultiYurtShipment,
  reducerAdminToCreateYurtShipment,
  reducerToCancelYurtShipment,
  reducerToCancelYurtShipmentForRefunds,
  reducerToCreateYurtShipmentForRefunds,
} from "./reducers/adminCourierYurtReducers";
import {
  reducerAdminGetDetailsOfPop,
  reducerAdminToCreatePop,
  reducerAdminToEditPop,
  reducerAdminToListPops,
} from "./reducers/adminPopsReducers";
import {
  reduceAnalyzeOrders,
  reducerAdsCostDetails,
  reducerAdsCostEdit,
  reducerCreateAdsCost,
  reducerListAdsCosts,
} from "./reducers/adminAnalyticsReducers";
import {
  reducerAdminCreateAproductInGoogleMerchant,
  reducerAdminUpdateAproductInGoogleMerchant,
  reducerAdminUploadAllToGoogleMerchant,
} from "./reducers/adminGoogleMerchantReducers";
import {
  reducerAdminToCreateParasutProduct,
  reducerAdminToRequestParasutProducts,
  reducerAdminToRequestParasutStockCount,
} from "./reducers/adminParasutReducers";
import {
  reducerAdminFilterCustomersForSmsCampaign,
  reducerAdminGetSmsCampaignReport,
  reducerAdminListSmsCampaigns,
  reducerAdminSmsCampaignCreate,
  reducerAdminStartSmsCampaign,
  reducerAdminViewSmsCampaignDetails,
  reducerUserRemoveItselfFromSmsCampaignList,
} from "./reducers/adminSmsCampaignReducers";
import {
  reducerAdminListRefundCodes,
  reducerAdminTrackRefundParcels,
  reducerToCreateAdminRefundCode,
  refundAdminGetTrackingCodes,
} from "./reducers/adminRefundCodeReducers";
import {
  reducerProductToCreateReview,
  reducerProductToDeleteReview,
  reducerProductToEditReview,
  reducerProductToGetReviewDetails,
  reducerToGetReviewsList,
  reducerToRefundForImageReview,
} from "./reducers/reviewReducers";
import {
  reducerAdminToAnswerQna,
  reducerAdminToListQnas,
  reducerListQnas,
  reducerToCreateQna,
} from "./reducers/qnaReducers";
import {
  reducerAdminToCreateVariant,
  reducerAdminToDetailsVariant,
  reducerAdminToEditVariant,
  reducerAdminToListVariants,
} from "./reducers/adminVariantReducers";
import {
  reducerAdminGetDetailsOfTopBar,
  reducerAdminToCreateTopBar,
  reducerAdminToEditTopBar,
  reducerToListTopBars,
} from "./reducers/adminTopBarReducers";
import {
  reducerCreateMaterial,
  reducerCreateWarehouse,
  reducerCreateInventoryActivity,
  reducerDeleteMaterial,
  reducerDeleteWarehouse,
  reducerListMaterials,
  // reducerListParasutStockMovement,
  reducerListInventoryActivities,
  reducerListWarehouses,
  reducerMaterialDetails,
  reducerMaterialEdit,
  reducerInventoryActivityDetails,
  reducerInventoryActivityEdit,
  reducerWarehouseDetails,
  reducerWarehouseEdit,
  reducerMarkInventoryActivityPrinted,
  reducerFindMaterialLatestPricing,
  reducerDeleteInventoryActivity,
  reducerSalesReport,
} from "./reducers/inventoryReducers";
import {
  reducerListNotifications,
  reducerUpdateNotification,
} from "./reducers/notificationReducers";
import {
  reducerCreateManagedOrder,
  reducerListManagedOrders,
  reducerManagedOrderDetails,
  reducerUpdateManagedOrderStatus,
} from "./reducers/orderManagementReducers ";
import { socketSliceReducer } from "./screens/AdminAnalyticsVisitors/ReduxFunctions/sliceToCreateSocketConnection";
import reducerToUpdateExtraDiscountShown from "./screens/CartScreen/actionToUpdateExtraDiscountShown";
import reducerToBringInLastMonthsSales from "./components/LastMonthTotalSales/actionToBringInLastMonthsSales";
import { reducerToBringInProductSalesData } from "./screens/AdminProductAnalytics/actionToBringInProductSalesData";
import reducerToUploadAProductInTrendyol from "./marketplaces/trendyol/actionToUploadAProductInTrendyol";
import reducerToGetTrendyolBrandList from "./marketplaces/trendyol/actionToGetTrendyolBrandList";
import reducerToGetTrendyolSuppliersAddress from "./marketplaces/trendyol/actionToGetTrendyolSuppliersAddress";
import reducerToGetTrendyolCategoryTree from "./marketplaces/trendyol/actionToGetTrendyolCategoryTree";
import reducerToGetTrendyolCategoryAttributes from "./marketplaces/trendyol/actionToGetTrendyolCategoryAttributes";
import reducerToUpdateSkuWithTrendyolInfo from "./marketplaces/trendyol/actionToUpdateSkuWithTrendyolInfo";
import reducerToGetResultOfTrendyolUpload from "./marketplaces/trendyol/actionToGetResultOfTrendyolUpload";
import reducerToGetHepsiBuradaCategories from "./marketplaces/hepsiBurada/actionToGetHepsiBuradaCategories";
import reducerToGetHepsiBuradaCategoryAttributes from "./marketplaces/hepsiBurada/actionToGetHepsiBuradaCategoryAttributes";
import reducerToGetHepsiBuradaEnumValues from "./marketplaces/hepsiBurada/actionToGetHepsiBuradaEnumValues";
import reducerToUpdateSkuWithHepsiBuradaInfo from "./marketplaces/hepsiBurada/actionToUpdateSkuWithHepsiBuradaInfo";
import reducerToUploadAProductInHepsiBurada from "./marketplaces/hepsiBurada/actionToUploadAProductInHepsiBurada";
import reducerToGetResultOfHepsiBuradaUpload from "./marketplaces/hepsiBurada/actionToGetResultOfHepsiBuradaUpload";
import reducerToDeletePendingProductInHepsiBurada from "./marketplaces/hepsiBurada/actionToDeletePendingProductInHepsiBurada";
import { reducerToSendPhoneToReceiveSms } from "./components/SignInBySms/actions/actionToSendPhoneToReceiveSms";
import { reducerToSendSecurityCodeToSignIn } from "./components/SignInBySms/actions/actionToSendSecurityCodeToSignIn";
import { reducerToCreateWholeSaleCustomer } from "./components/SignUp/action/actionToCreateWholeSaleCustomer";
import { reducerToGetDetailsWholeSaleAccount } from "./modules/customerInterface/wholeSale/actions/actionToGetDetailsWholeSaleAccount";
import { reducerToUpdateWholeSaleAccount } from "./modules/customerInterface/wholeSale/account/accountHome/actions/actionToUpdateWholeSaleAccount";
import { reducerToGetProductsList } from "./modules/customerInterface/wholeSale/products/productList/actions/actionToGetProductsList";
import { reducerToGetCategories } from "./modules/customerInterface/actions/actionToGetCategories";
import { reducerToAddOrRemoveWholeSaleItemInCart } from "./modules/customerInterface/wholeSale/cart/actions/actionToAddOrRemoveWholeSaleItemInCart";
import { reducerToToggleErcModal } from "./modules/customerInterface/actions/actionToToggleErcModal";
import { reducerToPostCart } from "./modules/customerInterface/wholeSale/cart/actions/actionToPostCart";
import { reducerAdminToUpdateCurrentAccountPurchaseLimits } from "./modules/adminPanel/wholeSale/accounts/actions/actionAdminToUpdateCurrentAccountPurchaseLimits";
import { reducerToCreateDeliveryAddress } from "./modules/customerInterface/wholeSale/account/accountDeliveryAddress/actions/actionToCreateDeliveryAddress";
import { reducerToDeleteDeliveryAddress } from "./modules/customerInterface/wholeSale/account/accountDeliveryAddress/actions/actionToDeleteDeliveryAddress";
import { reducerToUpdateDeliveryAddress } from "./modules/customerInterface/wholeSale/account/accountDeliveryAddress/actions/actionToUpdateDeliveryAddress";
import { reducerToCreateWholeSaleOrder } from "./modules/customerInterface/wholeSale/orders/orderCreate/actions/actionToCreateWholeSaleOrder";
import { reducerToGetDetailsWholeSaleOrder } from "./modules/customerInterface/wholeSale/orders/orderDetails/actions/actionToGetDetailsWholeSaleOrder";
import { reducerToGetWholeSaleOrdersListByAccount } from "./modules/customerInterface/wholeSale/orders/ordersList/actions/actionToGetWholeSaleOrdersListByAccount";
import { reducerToUpdateWholeSaleOrderStatus } from "./modules/customerInterface/wholeSale/orders/orderDetails/actions/actionToUpdateWholeSaleOrderStatus";
import { reducerToUpdateWholeSaleOrderPaymentProof } from "./modules/customerInterface/wholeSale/orders/orderDetails/actions/actionToUpdateWholeSaleOrderPaymentProof";
import { reducerToUpdateWholeSaleOrderWithFreeGoods } from "./modules/customerInterface/wholeSale/orders/orderCreate/actions/actionToUpdateWholeSaleOrderWithFreeGoods";
import { reducerAdminToGetWholeSaleOrdersList } from "./modules/adminPanel/wholeSale/orders/ordersList/actions/actionAdminToGetWholeSaleOrdersList";
import { reducerAdminToGetDetailsWholeSaleOrder } from "./modules/adminPanel/wholeSale/orders/orderDetails/actions/actionAdminToGetDetailsWholeSaleOrder";
import { reducerAdminToGetDetailsWholeSaleAccount } from "./modules/adminPanel/actions/actionAdminToGetDetailsWholeSaleAccount";
import { reducerAdminToUpdateWholeSaleOrderStatus } from "./modules/adminPanel/wholeSale/orders/orderDetails/actions/actionAdminToUpdateWholeSaleOrderStatus";
import { reducerAdminToUpdateWholeSaleOrderPrintStatus } from "./modules/adminPanel/wholeSale/print/printOrder/actions/actionAdminToUpdateWholeSaleOrderPrintStatus";
import { reducerToGetIyzicoInstallmentPlan } from "./modules/customerInterface/components/paymentCreditCard/actions/actionToGetIyzicoInstallmentPlan";
import { reducerToInitiateIyzicoThreeDsPayment } from "./modules/customerInterface/components/paymentCreditCard/actions/actionToInitiateIyzicoThreeDsPayment";
import { reducerAdminToUpdateCurrentAccountAllowedPaymentMethods } from "./modules/adminPanel/wholeSale/accounts/actions/actionAdminToUpdateCurrentAccountAllowedPaymentMethods";
import { reducerAdminToUpdateCurrentAccountWholeSalePricing } from "./modules/adminPanel/wholeSale/accounts/actions/actionAdminToUpdateCurrentAccountWholeSalePricing";

const initialState = {
  addOrRemoveSkuItemInCart: {
    cartSkuItems: localStorage.getItem("cartSkuItems")
      ? JSON.parse(localStorage.getItem("cartSkuItems"))
      : [],
    taxPrice: 0,
  },
  addOrRemoveWholeSaleItemInCart: {
    wholeSaleCartItems: sessionStorage.getItem("wholeSaleCartItems")
      ? JSON.parse(sessionStorage.getItem("wholeSaleCartItems"))
      : [],
    taxPrice: 0,
  },
  createOrder: {
    order: localStorage.getItem("order")
      ? JSON.parse(localStorage.getItem("order"))
      : null,
  },
  authenticateUser: {
    authenticatedUser: localStorage.getItem("authenticatedUser")
      ? JSON.parse(localStorage.getItem("authenticatedUser"))
      : null,
  },
  adminUserToLogin: {
    adminUserInfo: localStorage.getItem("adminUserInfo")
      ? JSON.parse(localStorage.getItem("adminUserInfo"))
      : null,
  },
  sendSecurityCodeToSignIn: {
    authenticatedUser: localStorage.getItem("authenticatedWholeSaleUser")
      ? JSON.parse(localStorage.getItem("authenticatedWholeSaleUser"))
      : null,
  },
};

const allReducers = combineReducers({
  //PRODUCT REDUCERS
  productList: reducerToGetProductList,
  productDetails: reducerToGetProductDetails,
  campaignedProductDetails: reducerToGetCampaignedProductDetails,
  bringInSkuPrice: reducerBringInSkuPrice,
  bringInCampaignedSku: reducerToBringInCampaignedSku,
  classifySkus: reducerClassifySkus,
  sideProductsList: reducerToGetSideProductsList,

  //REVIEW REDUCERS
  productToCreateReview: reducerProductToCreateReview,
  productToGetReviewDetails: reducerProductToGetReviewDetails,
  productToEditReview: reducerProductToEditReview,
  productToDeleteReview: reducerProductToDeleteReview,
  getReviewsList: reducerToGetReviewsList,
  refundForImageReview: reducerToRefundForImageReview,

  //QNA REDUCERS
  createQna: reducerToCreateQna,
  adminListQnas: reducerAdminToListQnas,
  adminAnswerQna: reducerAdminToAnswerQna,
  listQnas: reducerListQnas,

  //CART ACTIONS
  addOrRemoveSkuItemInCart: reducerToAddOrRemoveSkuItemInCart,
  addOrRemoveWholeSaleItemInCart: reducerToAddOrRemoveWholeSaleItemInCart,

  //USER ACTIONS
  sendSmsCode: reducerToSendSmsCode,
  authenticateUser: reducerToAuthenticateUser,
  getUserDetails: reducerToGetUserDetails,
  updateUserProfile: reducerToUpdateUserProfile,
  setUserScore: reducerToSetUserScore,
  // NEW
  sendPhoneToReceiveSms: reducerToSendPhoneToReceiveSms,
  sendSecurityCodeToSignIn: reducerToSendSecurityCodeToSignIn,
  createWholeSaleCustomer: reducerToCreateWholeSaleCustomer,

  //ORDER REDUCERS
  createOrder: reducerToCreateOrder,
  unSubmittedOrderCreate: reducerToUnSubmittedOrderCreate,
  updatePaymentInfo: reducerToUpdatePaymentInfo,
  matchOrderPhone: reducerToMatchOrderPhone,
  //requestIyzicoForm: reducerToRequestIyzicoForm,
  //getIyzicoFormResult: reducerToGetIyzicoFormResult,
  listUserOrderHistory: reducerToListUserOrderHistory,
  checkUnpaidOrders: reducerOrderToCheckUnpaidOrders,
  cancelAnOrder: reducerOrderToCancelAnOrder,
  cancelPaymentOrder: reducerOrderToCancelPaymentOrder,
  updateCancelledPayment: reducerOrderToUpdateCancelledPayment,
  cancelAnOrderPartially: reducerOrderToCancelAnOrderPartially,
  updateDeliveryContact: reducerOrderToUpdateDeliveryContact,
  updateIndividualInvoice: reducerOrderToUpdateIndividualInvoice,
  orderToGetOrderDetails: reducerOrderToGetOrderDetails,
  orderToGetUpsellOrderDetails: reducerOrderToGetUpsellOrderDetails,
  orderToDeleteOrder: reducerOrderToDeleteOrder,
  orderToRefundOrder: reducerOrderToRefundOrder,
  orderToCancelRefund: reducerOrderToCancelRefund,
  orderToCollectIban: reducerOrderToCollectIban,
  orderToApplyDiscountCode: reducerOrderToApplyDiscountCode,
  orderToViewInvoice: reducerOrderToViewInvoice,
  checkIfThereIsApendingOrder: reducerOrderToCheckIfThereIsApendingOrder,

  //SUPPORT REDUCERS
  createSupportMessage: reducerSupportToCreateSupportMessage,
  listUserRequests: reducerSupportToListUserRequests,
  userCloseRequest: reducerSupportToUserCloseRequest,
  getSupportDetails: reducerSupportToGetSupportDetails,
  sendNewMessage: reducerSupportToSendNewMessage,
  adminListUserRequests: reducerSupportAdminListUserRequests, // LIST ALL REQUESTS
  adminToListUserRequests: reducerSupportAdminToListUserRequests, // LIST REQUEST PER USER
  adminSendNewMessage: reducerSupportToAdminSendNewMessage,
  adminSendsMessageSms: reducerSupportToAdminSendsMessageSms,
  adminDeleteSentMessage: reducerSupportToAdminDeleteSentMessage,
  adminToCreateSupportStatus: reducerAdminToCreateSupportStatus,
  adminToListSupportStatus: reducerAdminToListSupportStatus,
  adminToDetailsSupportStatus: reducerAdminToDetailsSupportStatus,
  adminToEditSupportStatus: reducerAdminToEditSupportStatus,
  adminToDeleteSupportStatus: reducerAdminToDeleteSupportStatus,
  adminToUpdateSupportRequest: reducerAdminToUpdateSupportRequest,
  webFormMessage: reducerSupportToCreateWebFormMessage,

  //ADMIN USER REDUCERS
  adminUserToRegister: reducerAdminUserToRegister,
  adminUserToLogin: reducerAdminUserToLogin,
  adminUserToListUsers: reducerAdminUserToListUsers,
  adminUserToRequestUserDetails: reducerAdminUserToRequestUserDetails,
  adminUserToUpdateUserDetails: reducerAdminUserToUpdateUserDetails,
  adminUserToListUserOrders: reducerAdminUserToListUserOrders,

  //ADMIN ORDER REDUCERS
  adminOrderToListOrders: reducerAdminOrderToListOrders,
  adminOrderToGetOrderDetails: reducerAdminOrderToGetOrderDetails,
  adminOrderToGetUpsellOrderDetails: reducerAdminOrderToGetUpsellOrderDetails,
  adminOrderToCancelAnOrder: reducerAdminOrderToCancelAnOrder,
  adminOrderToCancelAnOrderPartially: reducerAdminOrderToCancelAnOrderPartially,
  adminOrderToCancelPaymentOrder: reducerAdminOrderToCancelPaymentOrder,
  adminOrderToUpdateCancelledPayment: reducerAdminOrderToUpdateCancelledPayment,
  adminOrderToUpdateDeliveryContact: reducerAdminOrderToUpdateDeliveryContact,
  adminOrderToUpdateIndividualInvoice:
    reducerAdminOrderToUpdateIndividualInvoice,
  adminOrderToUpdateCompanyInvoice: reducerAdminOrderToUpdateCompanyInvoice,
  adminOrderToMarkOrderAsPrinted: reducerAdminOrderToMarkOrderAsPrinted,
  adminOrderToUpdateOrderAsPaid: reducerAdminOrderToUpdateOrderAsPaid,
  adminOrderToSetPaymentMethod: reducerAdminOrderToSetPaymentMethod,
  adminOrderToSetNote: reducerAdminOrderToSetNote,
  adminOrderToSetUpsTrackingCode: reducerAdminOrderToSetUpsTrackingCode,
  adminOrderToSetUpsTrackingCodeForRefundOrder:
    reducerAdminOrderToSetUpsTrackingCodeForRefundOrder,
  adminOrderToSetDelivered: reducerAdminOrderToSetDelivered,
  adminOrderToSetUndelivered: reducerAdminOrderToSetUndelivered,
  adminOrderToSetRefundOrderDelivered:
    reducerAdminOrderToSetRefundOrderDelivered,
  alertDuplicateOrders: reducerAdminToAlertDuplicateOrders,
  adminUpdateProfilePhone: reducerAdminOrderToUpdateProfilePhone,
  adminListOrdersPendingShipping: reducerAdminOrderToListOrdersPendingShipping,

  //ADMIN SECURED ORDER STATUS REDUCERS
  adminToCreateSecuredOrderStatus: reducerAdminToCreateSecuredOrderStatus,
  adminToListSecuredOrderStatus: reducerAdminToListSecuredOrderStatus,
  adminToDetailsSecuredOrderStatus: reducerAdminToDetailsSecuredOrderStatus,
  adminToEditSecuredOrderStatus: reducerAdminToEditSecuredOrderStatus,
  adminToDeleteSecuredOrderStatus: reducerAdminToDeleteSecuredOrderStatus,

  //ADMIN PRODUCT REDUCERS
  adminProductToCreateProduct: reducerAdminProductToCreateProduct,
  adminProductToCreateSku: reducerAdminProductToCreateSku,
  adminProductToGetSkuList: reducerAdminProductToGetSkuList,
  adminProductToGetProductList: reducerAdminProductToGetProductList,
  adminProductToGetProductDetails: reducerAdminProductToGetProductDetails,
  adminProductToDeleteProduct: reducerAdminProductToDeleteProduct,
  adminProductToActiveProduct: reducerAdminProductToActiveProduct,
  adminProductToEditProduct: reducerAdminProductToEditProduct,
  adminProductToEditSku: reducerAdminProductToEditSku,
  adminProductToGetSkuDetails: reducerAdminProductToGetSkuDetails,
  adminProductToGetSectionDetails: reducerAdminProductToGetSectionDetails,
  adminProductToDeleteSection: reducerAdminProductToDeleteSection,
  // createRecipe: reducerAdminToCreateRecipe,
  // recipeCost: reducerAdminToSetRecipeCost,
  // deleteRecipeItem: reducerAdminToDeleteRecipeItem,
  // detailsOfRecipeItem: reducernAdminDetailsOfRecipeItem,
  // updateRecipeItem: reducerAdminUpdateRecipeItem,
  adminProductToGetListForShippingMessage:
    reducerAdminProductToGetListForShippingMessage,
  updateShippingMessage: reducerAdminProductUpdateShippingMessage,
  productListForSmsCampaign: reducerAdminProductGetListForSmsCampaign,
  bulkProductUpdate: reducerBulkProductUpdate,

  //ADMIN DEPARTMENT REDUCERS
  adminDepartmentToCreateDepartment: reducerAdminDepartmentToCreateDepartment,
  adminDepartmentToListDepartments: reducerAdminDepartmentToListDepartments,
  adminDepartmentToDetailsDepartment: reducerAdminDepartmentToDetailsDepartment,
  adminDepartmentToEditDepartment: reducerAdminDepartmentToEditDepartment,
  adminDepartmentToDeleteDepartment: reducerAdminDepartmentToDeleteDepartment,

  //ADMIN CATEGORY REDUCERS
  adminCategoryToCreateCategory: reducerAdminCategoryToCreateCategory,
  adminCategoryToListCategories: reducerAdminCategoryToListCategories,
  adminCategoryToDetailsCategory: reducerAdminCategoryToDetailsCategory,
  adminCategoryToEditCategory: reducerAdminCategoryToEditCategory,
  adminCategoryToDeleteCategory: reducerAdminCategoryToDeleteCategory,
  adminCategoryToRelatedListCategories:
    reducerAdminCategoryToRelatedListCategories,

  //ADMIN CATEGORY-SUB REDUCERS
  adminCategorySubToCreateCategorySub:
    reducerAdminCategorySubToCreateCategorySub,
  adminCategorySubToListCategorySubs: reducerAdminCategorySubToListCategorySubs,
  adminCategorySubToDetailsCategorySub:
    reducerAdminCategorySubToDetailsCategorySub,
  adminCategorySubToEditCategorySub: reducerAdminCategorySubToEditCategorySub,
  adminCategorySubToDeleteCategorySub:
    reducerAdminCategorySubToDeleteCategorySub,
  adminCategorySubToRelatedListCategorySubs:
    reducerAdminCategorySubToRelatedListCategorySubs,

  //ADMIN CATEGORY-LOW REDUCERS
  adminCategoryLowToCreateCategoryLow:
    reducerAdminCategoryLowToCreateCategoryLow,
  adminCategoryLowToListCategoryLows: reducerAdminCategoryLowToListCategoryLows,
  adminCategoryLowToDetailsCategoryLow:
    reducerAdminCategoryLowToDetailsCategoryLow,
  adminCategoryLowToEditCategoryLow: reducerAdminCategoryLowToEditCategoryLow,
  adminCategoryLowToDeleteCategoryLow:
    reducerAdminCategoryLowToDeleteCategoryLow,
  adminCategoryLowToRelatedListCategoryLows:
    reducerAdminCategoryLowToRelatedListCategoryLows,

  //ADMIN BRAND REDUCERS
  adminBrandToCreateBrand: reducerAdminBrandToCreateBrand,
  adminBrandToListBrands: reducerAdminBrandToListBrands,
  adminBrandToDetailsBrand: reducerAdminBrandToDetailsBrand,
  adminBrandToEditBrand: reducerAdminBrandToEditBrand,
  adminBrandToDeleteBrand: reducerAdminBrandToDeleteBrand,

  //ADMIN BRAND REDUCERS
  adminCreateVariant: reducerAdminToCreateVariant,
  adminListVariants: reducerAdminToListVariants,
  adminDetailsVariant: reducerAdminToDetailsVariant,
  adminEditVariant: reducerAdminToEditVariant,

  //ADMIN MODEL REDUCERS
  adminModelToCreateModel: reducerAdminModelToCreateModel,
  adminModelToListModels: reducerAdminModelToListModels,
  adminModelToDetailsModel: reducerAdminModelToDetailsModel,
  adminModelToEditModel: reducerAdminModelToEditModel,
  adminModelToDeleteModel: reducerAdminModelToDeleteModel,

  //ADMIN UPS COURIER REDUCERS
  addNewField: reducerAdminToAddNewField,
  createUpsCity: reducerAdminToCreateUpsCity,
  listUpsCities: reducerAdminToListUpsCities,
  getUpsCityDetails: reducerAdminToGetUpsCityDetails,
  editUpsCity: reducerAdminToEditUpsCity,
  createUpsShipment: reducerAdminToCreateUpsShipment,
  createUpsCodShipment: reducerAdminToCreateUpsCodShipment,
  cancelUpsShipment: reducerAdminToCancelUpsShipment, // ADMIN AND USER SHARE THIS

  //ADMIN YURT COURIER REDUCERS
  createYurtShipment: reducerAdminToCreateYurtShipment,
  listPendingYurtIciKargoOrders:
    reducerAdminOrderToListPendingYurtIciKargoOrders,
  checkYurtIciKargoOrders: reducerAdminOrderToCheckYurtIciKargoOrders,
  cancelYurtShipment: reducerToCancelYurtShipment,
  createYurtShipmentForRefunds: reducerToCreateYurtShipmentForRefunds,
  cancelYurtShipmentForRefunds: reducerToCancelYurtShipmentForRefunds,
  createMultiYurtShipment: reducerAdminToCreateMultiYurtShipment,

  // ADMIN INVOICE REDUCERS
  invoiceDetails: reducerAdminToGetInvoiceDetails,
  eDocumentDetails: reducerAdminToGetEDocumentDetails,
  runInvoiceCreateSquence: reducerAdminToRunInvoiceCreateSquence,
  cancelInvoice: reducerAdminToCancelInvoice,
  expenseSlipIssued: reducerAdminToSetExpenseSlipIssued,
  createSingleInvoice: reducerAdminToCreateSingleInvoice,

  // ADMIN DISCOUNT REDUCERS
  createDiscountCode: reducerAdminCreateDiscountCode,
  adminListDiscountCodes: reducerAdminListDiscountCodes,
  getDetailsOfDiscountCode: reducerAdminGetDetailsOfDiscountCode,
  editDiscountCode: reducerAdminEditDiscountCode,

  // ADMIN POPS REDUCERS
  createPop: reducerAdminToCreatePop,
  listPops: reducerAdminToListPops,
  editPop: reducerAdminToEditPop,
  detailsOfPop: reducerAdminGetDetailsOfPop,

  // ADMIN TOP BARS REDUCERS
  createTopBar: reducerAdminToCreateTopBar,
  listTopBars: reducerToListTopBars,
  editTopBar: reducerAdminToEditTopBar,
  detailsOfTopBar: reducerAdminGetDetailsOfTopBar,
  // ADMIN ANALYTICS
  analyzeOrders: reduceAnalyzeOrders,
  createAdsCost: reducerCreateAdsCost,
  listAdsCosts: reducerListAdsCosts,
  adsCostDetails: reducerAdsCostDetails,
  adsCostEdit: reducerAdsCostEdit,

  // GOOGLE MERCHANT
  uploadAllToGoogleMerchant: reducerAdminUploadAllToGoogleMerchant,
  updateAproductInGoogleMerchant: reducerAdminUpdateAproductInGoogleMerchant,
  createAproductInGoogleMerchant: reducerAdminCreateAproductInGoogleMerchant,

  // ADMIN PARASUT REDUCERS
  createParasutProduct: reducerAdminToCreateParasutProduct,
  parasutStockCount: reducerAdminToRequestParasutStockCount,
  parasutProducts: reducerAdminToRequestParasutProducts,

  // ADMIN SMS CAMPAIGN REDUCERS
  smsCampaignCreate: reducerAdminSmsCampaignCreate,
  filterCustomersForSmsCampaign: reducerAdminFilterCustomersForSmsCampaign,
  listSmsCampaigns: reducerAdminListSmsCampaigns,
  viewSmsCampaignDetails: reducerAdminViewSmsCampaignDetails,
  startSmsCampaign: reducerAdminStartSmsCampaign,
  getSmsCampaignReport: reducerAdminGetSmsCampaignReport,
  userRemoveItselfFromSmsCampaignList:
    reducerUserRemoveItselfFromSmsCampaignList,

  // ADMIN REFUND CODES
  createAdminRefundCode: reducerToCreateAdminRefundCode,
  adminListRefundCodes: reducerAdminListRefundCodes,
  getTrackingCodes: refundAdminGetTrackingCodes,
  trackRefundParcels: reducerAdminTrackRefundParcels,

  // MATERIAL REDUCERS
  createMaterial: reducerCreateMaterial,
  listMaterials: reducerListMaterials,
  materialDetails: reducerMaterialDetails,
  materialEdit: reducerMaterialEdit,
  deleteMaterial: reducerDeleteMaterial,

  // WAREHOUSE REDUCERS
  createWarehouse: reducerCreateWarehouse,
  listWarehouses: reducerListWarehouses,
  warehouseDetails: reducerWarehouseDetails,
  warehouseEdit: reducerWarehouseEdit,
  deleteWarehouse: reducerDeleteWarehouse,

  // INVENTORY ACTIVITY REDUCERS
  createInventoryActivity: reducerCreateInventoryActivity,
  listInventoryActivities: reducerListInventoryActivities,
  inventoryActivityDetails: reducerInventoryActivityDetails,
  inventoryActivityEdit: reducerInventoryActivityEdit,
  // listParasutStockMovement: reducerListParasutStockMovement,
  markInventoryActivityPrinted: reducerMarkInventoryActivityPrinted,
  findMaterialLatestPricing: reducerFindMaterialLatestPricing,
  deleteInventoryActivity: reducerDeleteInventoryActivity,

  // CURRENT ACCOUNT REDUCERS
  createCurrentAccount: reducerCreateCurrentAccount,
  listCurrentAccounts: reducerListCurrentAccounts,
  currentAccountDetails: reducerCurrentAccountDetails,
  currentAccountEdit: reducerCurrentAccountEdit,
  deleteCurrentAccount: reducerDeleteCurrentAccount,

  // CURRENT ACCOUNT ACTIVITY REDUCERS
  createCurrentAccountActivity: reducerCreateCurrentAccountActivity,
  listCurrentAccountsActivities: reducerListCurrentAccountsActivities,
  currentAccountActivityDetails: reducerCurrentAccountActivityDetails,
  currentAccountActivityEdit: reducerCurrentAccountActivityEdit,
  deleteCurrentAccountActivity: reducerDeleteCurrentAccountActivity,
  salesReport: reducerSalesReport,

  // NOTIFICATIONS REDUCERS
  listNotifications: reducerListNotifications,
  updateNotification: reducerUpdateNotification,

  // ORDER MANAGEMENT REDUCERS
  createManagedOrder: reducerCreateManagedOrder,
  listManagedOrders: reducerListManagedOrders,
  updateManagedOrderStatus: reducerUpdateManagedOrderStatus,
  managedOrderDetails: reducerManagedOrderDetails,

  // VISITOR ANALYTICS
  socket: socketSliceReducer,

  // USER IDETIFIER REDUCERS
  updateExtraDiscountShown: reducerToUpdateExtraDiscountShown,

  // UI SITE SALES CALCULATOR
  bringInLastMonthsSales: reducerToBringInLastMonthsSales,

  // PRODUCT ANALYTICS
  bringInProductSalesData: reducerToBringInProductSalesData,

  // TRENDYOL
  uploadAProductInTrendyol: reducerToUploadAProductInTrendyol,
  getTrendyolBrandList: reducerToGetTrendyolBrandList,
  getTrendyolSuppliersAddress: reducerToGetTrendyolSuppliersAddress,
  getTrendyolCategoryTree: reducerToGetTrendyolCategoryTree,
  getTrendyolCategoryAttributes: reducerToGetTrendyolCategoryAttributes,
  updateSkuWithTrendyolInfo: reducerToUpdateSkuWithTrendyolInfo,
  getResultOfTrendyolUpload: reducerToGetResultOfTrendyolUpload,

  // HEPSIBURADA
  getHepsiBuradaCategories: reducerToGetHepsiBuradaCategories,
  getHepsiBuradaCategoryAttributes: reducerToGetHepsiBuradaCategoryAttributes,
  getHepsiBuradaEnumValues: reducerToGetHepsiBuradaEnumValues,
  updateSkuWithHepsiBuradaInfo: reducerToUpdateSkuWithHepsiBuradaInfo,
  uploadAProductInHepsiBurada: reducerToUploadAProductInHepsiBurada,
  getResultOfHepsiBuradaUpload: reducerToGetResultOfHepsiBuradaUpload,
  deletePendingProductInHepsiBurada: reducerToDeletePendingProductInHepsiBurada,

  // WHOLESALE USER ACTIONS
  getDetailsWholeSaleAccount: reducerToGetDetailsWholeSaleAccount,
  updateWholeSaleAccount: reducerToUpdateWholeSaleAccount,
  getProductsList: reducerToGetProductsList,
  getCategories: reducerToGetCategories,
  postCart: reducerToPostCart,
  createDeliveryAddress: reducerToCreateDeliveryAddress,
  deleteDeliveryAddress: reducerToDeleteDeliveryAddress,
  updateDeliveryAddress: reducerToUpdateDeliveryAddress,
  createWholeSaleOrder: reducerToCreateWholeSaleOrder,
  getDetailsWholeSaleOrder: reducerToGetDetailsWholeSaleOrder,
  getWholeSaleOrdersListByAccount: reducerToGetWholeSaleOrdersListByAccount,
  updateWholeSaleOrderStatus: reducerToUpdateWholeSaleOrderStatus,
  updateWholeSaleOrderPaymentProof: reducerToUpdateWholeSaleOrderPaymentProof,
  updateWholeSaleOrderWithFreeGoods: reducerToUpdateWholeSaleOrderWithFreeGoods,

  // WHOLESALE ADMIN USER ACTIONS
  updateCurrentAccountPurchaseLimits:
    reducerAdminToUpdateCurrentAccountPurchaseLimits,
  updateCurrentAccountAllowedPaymentMethods:
    reducerAdminToUpdateCurrentAccountAllowedPaymentMethods,
  updateCurrentAccountWholeSalePricing:
    reducerAdminToUpdateCurrentAccountWholeSalePricing,
  adminGetWholeSaleOrdersList: reducerAdminToGetWholeSaleOrdersList,
  adminGetDetailsWholeSaleOrder: reducerAdminToGetDetailsWholeSaleOrder,
  adminGetDetailsWholeSaleAccount: reducerAdminToGetDetailsWholeSaleAccount,
  adminUpdateWholeSaleOrderStatus: reducerAdminToUpdateWholeSaleOrderStatus,
  adminUpdateWholeSaleOrderPrintStatus:
    reducerAdminToUpdateWholeSaleOrderPrintStatus,

  // MODAL ACTIONS
  toggleErcModal: reducerToToggleErcModal,

  // IYZICO ACTIONS
  getIyzicoInstallmentPlan: reducerToGetIyzicoInstallmentPlan,
  initiateIyzicoThreeDsPayment: reducerToInitiateIyzicoThreeDsPayment,
});

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// composeEnhancer(applyMiddleware(thunk))

const store = configureStore({
  reducer: allReducers,
  preloadedState: initialState,
});

export default store;
