import React from "react";

export default function RatingOnHoverJr(props) {
  // PROPS COMING FROM Rating
  const { value } = props;

  return (
    <div className="ratingonhoverjr">
      <p className="txtcenter">{value}</p>
      <p className="fs-75 grey txtcenter">Puan</p>
    </div>
  );
}
