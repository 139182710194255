import {
  ORDER_MANAGE_CREATE_FAIL,
  ORDER_MANAGE_CREATE_REQUEST,
  ORDER_MANAGE_CREATE_RESET,
  ORDER_MANAGE_CREATE_SUCCESS,
  ORDER_MANAGE_DETAILS_FAIL,
  ORDER_MANAGE_DETAILS_REQUEST,
  ORDER_MANAGE_DETAILS_RESET,
  ORDER_MANAGE_DETAILS_SUCCESS,
  ORDER_MANAGE_LIST_FAIL,
  ORDER_MANAGE_LIST_REQUEST,
  ORDER_MANAGE_LIST_RESET,
  ORDER_MANAGE_LIST_SUCCESS,
  ORDER_MANAGE_STATUS_FAIL,
  ORDER_MANAGE_STATUS_REQUEST,
  ORDER_MANAGE_STATUS_RESET,
  ORDER_MANAGE_STATUS_SUCCESS,
} from "../constants/orderManagementConstants";

// @desc Create an order for a material
export const reducerCreateManagedOrder = (
  state = { loading: false, managedOrder: {} },
  action
) => {
  switch (action.type) {
    case ORDER_MANAGE_CREATE_REQUEST:
      return { loading: true };
    case ORDER_MANAGE_CREATE_SUCCESS:
      return { loading: false, managedOrder: action.payload };
    case ORDER_MANAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_MANAGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// @desc List materials
export function reducerListManagedOrders(
  state = { loading: true, managedOrdersList: [] },
  action
) {
  switch (action.type) {
    case ORDER_MANAGE_LIST_REQUEST:
      return { loading: true, ...state };
    case ORDER_MANAGE_LIST_SUCCESS:
      return {
        loading: false,
        managedOrdersList: action.payload.managedOrdersList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ORDER_MANAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_MANAGE_LIST_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Update managed order status
export function reducerUpdateManagedOrderStatus(
  state = { loading: false, success: false },
  action
) {
  switch (action.type) {
    case ORDER_MANAGE_STATUS_REQUEST:
      return { loading: true };
    case ORDER_MANAGE_STATUS_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_MANAGE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_MANAGE_STATUS_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
}

// @desc Managed order details
export function reducerManagedOrderDetails(state = { loading: false }, action) {
  switch (action.type) {
    case ORDER_MANAGE_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_MANAGE_DETAILS_SUCCESS:
      return {
        loading: false,
        detailManagedOrder: action.payload,
      };
    case ORDER_MANAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_MANAGE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}
