import {
  ADMIN_UPLOAD_ALL_GM_FAIL,
  ADMIN_UPLOAD_ALL_GM_REQUEST,
  ADMIN_UPLOAD_ALL_GM_SUCCESS,
  ADMIN_UPDATE_A_PRODUCT_REQUEST,
  ADMIN_UPDATE_A_PRODUCT_FAIL,
  ADMIN_UPDATE_A_PRODUCT_SUCCESS,
  ADMIN_CREATE_A_PRODUCT_REQUEST,
  ADMIN_CREATE_A_PRODUCT_SUCCESS,
  ADMIN_CREATE_A_PRODUCT_FAIL,
} from "../constants/adminGoogleMerchantConstants";

// INSERT PRODUCTS TO GOOGLE MERCHANT
export function reducerAdminUploadAllToGoogleMerchant(state = {}, action) {
  switch (action.type) {
    case ADMIN_UPLOAD_ALL_GM_REQUEST:
      return { loading: true };
    case ADMIN_UPLOAD_ALL_GM_SUCCESS:
      return { loading: false, success: action.payload };
    case ADMIN_UPLOAD_ALL_GM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// UPDATE A PRODUCT IN GOOGLE MERCHANT
export function reducerAdminUpdateAproductInGoogleMerchant(state = {}, action) {
  switch (action.type) {
    case ADMIN_UPDATE_A_PRODUCT_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_A_PRODUCT_SUCCESS:
      return { loading: false, success: action.payload };
    case ADMIN_UPDATE_A_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// CREATE A PRODUCT IN GOOGLE MERCHANT
export function reducerAdminCreateAproductInGoogleMerchant(state = {}, action) {
  switch (action.type) {
    case ADMIN_CREATE_A_PRODUCT_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_A_PRODUCT_SUCCESS:
      return { loading: false, success: action.payload };
    case ADMIN_CREATE_A_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
