import {
  ADMIN_MODEL_CREATE_FAIL,
  ADMIN_MODEL_CREATE_REQUEST,
  ADMIN_MODEL_CREATE_RESET,
  ADMIN_MODEL_CREATE_SUCCESS,
  ADMIN_MODEL_DELETE_FAIL,
  ADMIN_MODEL_DELETE_REQUEST,
  ADMIN_MODEL_DELETE_RESET,
  ADMIN_MODEL_DELETE_SUCCESS,
  ADMIN_MODEL_DETAILS_FAIL,
  ADMIN_MODEL_DETAILS_REQUEST,
  ADMIN_MODEL_DETAILS_SUCCESS,
  ADMIN_MODEL_EDIT_FAIL,
  ADMIN_MODEL_EDIT_REQUEST,
  ADMIN_MODEL_EDIT_RESET,
  ADMIN_MODEL_EDIT_SUCCESS,
  ADMIN_MODEL_LIST_FAIL,
  ADMIN_MODEL_LIST_REQUEST,
  ADMIN_MODEL_LIST_SUCCESS,
} from "../constants/adminModelConstants";

//SAVE THE CREATED MODEL IN REDUX STATE
export const reducerAdminModelToCreateModel = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_MODEL_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_MODEL_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        model: action.payload,
      };
    case ADMIN_MODEL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_MODEL_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF MODEL IN REDUX STATE
export function reducerAdminModelToListModels(
  state = { loading: true, modelsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_MODEL_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_MODEL_LIST_SUCCESS:
      return {
        loading: false,
        modelsList: action.payload,
      };
    case ADMIN_MODEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF MODEL IN REDUX STATE
export function reducerAdminModelToDetailsModel(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_MODEL_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_MODEL_DETAILS_SUCCESS:
      return {
        loading: false,
        modelDetails: action.payload,
      };
    case ADMIN_MODEL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF MODEL IN REDUX STATE
export function reducerAdminModelToEditModel(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_MODEL_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_MODEL_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_MODEL_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_MODEL_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF MODEL IN REDUX STATE
export function reducerAdminModelToDeleteModel(state = {}, action) {
  switch (action.type) {
    case ADMIN_MODEL_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_MODEL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_MODEL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_MODEL_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
