import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToPostCart = createAsyncThunk(
  "wholeSale/jobInActionToPostCart",
  async ({ id, cart, cartId }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/wholesale/postCart",
        { id, cart, cartId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  cart: [],
  loading: false,
  error: null,
};

// Create a slice using createSlice
const postCartSlice = createSlice({
  name: "postCart",
  initialState,
  reducers: {
    resetPostCart: (state) => {
      state.cart = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToPostCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToPostCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cart = action.payload;
      })
      .addCase(actionToPostCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetPostCart } = postCartSlice.actions;

// Export the reducer
const reducerToPostCart = postCartSlice.reducer;

// Export the async action creator for use in components
export { reducerToPostCart, resetPostCart };
