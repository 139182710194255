import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetTrendyolSuppliersAddress = createAsyncThunk(
  "jobInactionToGetSuppliersAddress",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/trendyol/getTrendyolSuppliersAddress",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getTrendyolSuppliersAddress = createSlice({
  name: "sliceToGetTrendyolSuppliersAddress",
  initialState: {
    addressesList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetTrendyolSuppliersAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actionToGetTrendyolSuppliersAddress.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.addressesList = action.payload;
        }
      )
      .addCase(
        actionToGetTrendyolSuppliersAddress.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

const reducerToGetTrendyolSuppliersAddress =
  getTrendyolSuppliersAddress.reducer;

export default reducerToGetTrendyolSuppliersAddress;
