import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CART_ADD_SKU_ITEM, CART_EMPTY_SKU } from "../constants/cartConstants";

export function useUpdateCartItemsOnRemovingItem(
  cartSkuItems,
  removedItemIndex,
  isDiscountValid,
  setTrigger
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cartSkuItems?.length > 0 && removedItemIndex === 0 && isDiscountValid) {
      setTrigger(true);

      const revisedItems = cartSkuItems.map((item, index) => {
        const numQty = Number(item.qty);
        const price = item.price;
        const priceForOne = item.priceForOne;
        const priceGap = item.priceGap;
        const discountAmount =
          item.timeLimitedDiscount.discountAmount > 0
            ? item.timeLimitedDiscount.discountAmount
            : 0;

        const originalPrice = priceForOne - discountAmount;

        const originalItemsTotalPrice =
          index === 0 && numQty === 1
            ? priceForOne
            : numQty > 1 && priceForOne + priceGap * (numQty - 1);

        return {
          ...item,
          price: price === 0 ? 0 : originalPrice,
          itemsTotalPrice:
            price === 0
              ? 0
              : index === 0
              ? originalItemsTotalPrice - discountAmount
              : discountAmount
              ? priceGap * numQty
              : priceForOne * numQty,
        };
      });

      dispatch({ type: CART_EMPTY_SKU });

      localStorage.setItem("cartSkuItems", JSON.stringify(revisedItems));

      revisedItems.forEach((item) => {
        dispatch({ type: CART_ADD_SKU_ITEM, payload: item });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiscountValid, removedItemIndex, cartSkuItems.length]);
}
