// @desc List orders to create reports
export const ANALYZE_ORDER_LIST_REQUEST = "ANALYZE_ORDER_LIST_REQUEST";
export const ANALYZE_ORDER_LIST_SUCCESS = "ANALYZE_ORDER_LIST_SUCCESS";
export const ANALYZE_ORDER_LIST_FAIL = "ANALYZE_ORDER_LIST_FAIL";

// @desc Post ads cost
export const ADSCOST_CREATE_REQUEST = "ADSCOST_CREATE_REQUEST";
export const ADSCOST_CREATE_SUCCESS = "ADSCOST_CREATE_SUCCESS";
export const ADSCOST_CREATE_FAIL = "ADSCOST_CREATE_FAIL";
export const ADSCOST_CREATE_RESET = "ADSCOST_CREATE_RESET";

// @desc List ads cost
export const ADSCOST_LIST_REQUEST = "ADSCOST_LIST_REQUEST";
export const ADSCOST_LIST_SUCCESS = "ADSCOST_LIST_SUCCESS";
export const ADSCOST_LIST_FAIL = "ADSCOST_LIST_FAIL";

// @desc Ad cost details
export const ADSCOST_DETAILS_REQUEST = "ADSCOST_DETAILS_REQUEST";
export const ADSCOST_DETAILS_SUCCESS = "ADSCOST_DETAILS_SUCCESS";
export const ADSCOST_DETAILS_FAIL = "ADSCOST_DETAILS_FAIL";

// @desc Edit Ad cost
export const ADSCOST_EDIT_REQUEST = "ADSCOST_EDIT_REQUEST";
export const ADSCOST_EDIT_SUCCESS = "ADSCOST_EDIT_SUCCESS";
export const ADSCOST_EDIT_FAIL = "ADSCOST_EDIT_FAIL";
export const ADSCOST_EDIT_RESET = "ADSCOST_EDIT_RESET";
