import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToDeletePendingProductInHepsiBurada = createAsyncThunk(
  "jobInActionToDeletePendingProductInHepsiBurada",
  async (merchantSku, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/hepsiBurada/deletePendingProductInHepsiBurada",
        merchantSku,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      const { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const deletePendingProductInHepsiBurada = createSlice({
  name: "sliceToDeletePendingProductInHepsiBurada",
  initialState: {
    isDeleted: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetDeletePendingProductInHepsiBuradaIsDeleted: (state) => {
      state.isDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToDeletePendingProductInHepsiBurada.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actionToDeletePendingProductInHepsiBurada.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.isDeleted = true;
        }
      )
      .addCase(
        actionToDeletePendingProductInHepsiBurada.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetDeletePendingProductInHepsiBuradaIsDeleted } =
  deletePendingProductInHepsiBurada.actions;

const reducerToDeletePendingProductInHepsiBurada =
  deletePendingProductInHepsiBurada.reducer;

export default reducerToDeletePendingProductInHepsiBurada;
