import { useEffect, useState } from "react";
import { undoDiscountsInCart } from "../utils/undoDiscountsInCart";
import { restoreTimeLimitedDiscountInCart } from "../utils/restoreTimeLimitedDiscountInCart";

export function useUpdateCartItemsWhenGrandUpsellExpired(
  isPending,
  cartSkuItems,
  isDiscountValid,
  dispatch
) {
  // CLEAR CART IF NO PENDING ORDER AND INDEX 0 PRICED AT DISCOUNTED PRICE
  const [popOnMount, setPopOnMount] = useState("CLOSE");

  useEffect(() => {
    // Check if isDiscountValid is undefined, and if so, return without doing anything
    if (typeof isDiscountValid === "undefined") {
      return;
    }

    if (
      isPending &&
      isPending.isTherePending === "NO" &&
      cartSkuItems &&
      cartSkuItems.length > 0 &&
      cartSkuItems[0].price === cartSkuItems[0].priceGap
    ) {
      if (isDiscountValid) {
        restoreTimeLimitedDiscountInCart(dispatch);
        setPopOnMount("OPEN");
      } else {
        undoDiscountsInCart(dispatch); // This function updates cart items do there is no discount left at all
        setPopOnMount("OPEN");
      }
    }
  }, [isPending, cartSkuItems, isDiscountValid, dispatch]);

  return popOnMount;
}
