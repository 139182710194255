export function errorMessage(error) {
  let message = "Unknown error"; // Default message

  if (error && error.response && error.response.data) {
    if (error.response.data.errors && error.response.data.errors.length > 0) {
      message = error.response.data.errors[0].message;
    } else if (error.response.data.message) {
      message = error.response.data.message;
    } else {
      message = JSON.stringify(error.response.data);
    }
  } else if (error && error.message) {
    if (typeof error.message === "string") {
      message = error.message;
    } else if (typeof error.message === "object") {
      if (
        error.message.errors &&
        error.message.errors.length > 0 &&
        error.message.errors[0].message
      ) {
        message = error.message.errors[0].message;
      } else if (error.message.detail) {
        message = error.message.detail;
      } else {
        message = JSON.stringify(error.message);
      }
    }
  } else if (error && error.error && typeof error.error === "string") {
    message = error.error;
  } else if (error && error.errors && error.errors.length > 0) {
    message = error.errors[0].message;
  } else if (typeof error === "object") {
    message = JSON.stringify(error);
  }

  const location = error.stack || error.name || "Unknown location";

  return { message, location };
}

/*export function errorMessage(error) {
  const message =
    error &&
    error.response &&
    (error.response.data.message || error.response.data)
      ? error.response.data.message || error.response.data
      : error.message;

  const location = error.stack || error.name || "Unknown location";

  return { message, location };
}*/
