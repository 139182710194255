import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import "./MessageUnpaidOrders.css";
import LoadingBox from "../modules/shared/components/LoadingBox";
import MessageBox from "../modules/shared/components/MessageBox";
import {
  // actionOrderToCancelAnOrder,
  actionOrderToCheckUnpaidOrders,
  actionOrderToDeleteOrder,
} from "../actions/orderActions";
import {
  ORDER_APPLY_DISCOUNTCODE_RESET,
  ORDER_CREATE_RESET,
  ORDER_DELETE_RESET,
} from "../constants/orderConstants";
import useWidth from "../hooks/useWidth";

export default function MessageUnpaidOrders(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const width = useWidth();

  /* 000 - LOGIC STARTS HERE - DISPLAY A MESSAGE IF THERE IS UNPAID ORDER */

  //LOCATION IS USED TO DETERMINE PATHNAME
  const location = useLocation();

  // PROPS COMING FROM APP
  const { hasAnOrder, isTopBarActive } = props;

  //SET APPEAL ID LOCATION IS LANDINGPAGE
  /*const [appeal, setAppeal] = useState("");
  useEffect(() => {
    if (location.pathname.includes("/land/")) {
      setAppeal(location.pathname.split("/")[4]);
    }
  }, [location]);*/

  //REACT STATE TO SAVE MESSAGE VALUE
  //IF TRUE MESSAGE IS DISPLAYED
  //IF FALSE UNDISPLAYED
  const [message, setMessage] = useState(false);
  // REACT STATE TO MANAGE REDIRECT TO HOMEPAGE ON MESSAGE SET TO FALSE
  const [redirectToHome, setRedirectToHome] = useState(false);

  //REDUX STORE THAT HOLDS UNPAID ORDER INFORMATION
  const checkUnpaidOrders = useSelector((state) => state.checkUnpaidOrders);
  const { loading, error, unpaidOrders } = checkUnpaidOrders;

  // REDUX STATE THAT HOLDS IF DELETE IS A SUCCESS INFO
  const orderToDeleteOrder = useSelector((state) => state.orderToDeleteOrder);
  const { success } = orderToDeleteOrder;

  //REDUX STATE THAT HOLDS CART SKU ITEMS
  const addOrRemoveSkuItemInCart = useSelector(
    (state) => state.addOrRemoveSkuItemInCart
  );
  const { cartSkuItems } = addOrRemoveSkuItemInCart;

  // IF THERE IS NO UNPAID ORDER GO BACK TO HOMEPAGE
  useEffect(() => {
    let storage = JSON.parse(sessionStorage.getItem("landingVisitor"));
    if (
      storage &&
      error === "There is no unpaid order" &&
      location.pathname.substring(0, 27) === "/siparis-bilgileri-adim-1/2" &&
      cartSkuItems.length === 0
    ) {
      history.push(storage.url);
    } else if (
      !storage &&
      error === "There is no unpaid order" &&
      location.pathname.substring(0, 27) === "/siparis-bilgileri-adim-1/2" &&
      cartSkuItems.length === 0
    ) {
      history.push("/");
    }
  }, [error, history, location, cartSkuItems, message]);

  //IF THERE ARE UNPAID ORDERS REACT STATE SET TRUE
  useEffect(() => {
    if (!loading && !error && unpaidOrders && unpaidOrders.length > 0) {
      setMessage(true);
    } else if (
      !loading &&
      !error &&
      unpaidOrders &&
      unpaidOrders.length === 0
    ) {
      setMessage(false);
      localStorage.removeItem("checkUnpaid");
    } else if (error) {
      localStorage.removeItem("checkUnpaid");
    }
  }, [unpaidOrders, loading, error]);

  //LOCATION PATHNAME IS USED AS A DEPENDENCY TO TRIGGER USEFFECT
  //SO THAT WE CAN CHECK LOCAL STORAGE AND IF NULL SET REACT STATE FALSE
  useEffect(() => {
    if (
      location.pathname[0] === "/" &&
      localStorage.getItem("checkUnpaid") === null
    ) {
      setMessage(false);
    }
  }, [location, message]);

  //MANUAL CLOSE OF MESSAGE WILL SET A LOCAL STORAGE ITEM
  //AND REMOVE PHONE ITEM
  function handleClose() {
    setMessage(false);
    localStorage.setItem(
      "keepMessageClosed",
      "Unpaid orders message will remain closed."
    );
    localStorage.removeItem("checkUnpaid");
    setRedirectToHome(true);
    //window.location.reload();
  }

  // RESET redirectToHome
  useEffect(() => {
    if (location.pathname.substring(0, 27) !== "/siparis-bilgileri-adim-1/2") {
      setRedirectToHome(false);
    }
  }, [location, cartSkuItems]);
  // IF CLOSE BUTTON HIT GO BACK TO HOMEPAGE
  useEffect(() => {
    let storage = JSON.parse(sessionStorage.getItem("landingVisitor"));
    if (
      storage &&
      location.pathname.substring(0, 27) === "/siparis-bilgileri-adim-1/2" &&
      redirectToHome &&
      cartSkuItems.length === 0
    ) {
      history.push(storage.url);
    } else if (
      !storage &&
      location.pathname.substring(0, 27) === "/siparis-bilgileri-adim-1/2" &&
      redirectToHome &&
      cartSkuItems.length === 0
    ) {
      history.push("/");
    }
  }, [history, location, redirectToHome, cartSkuItems]);

  //AFTER MANUALLY CLOSED IT WILL STAY CLOSED WITH THIS FUNCTION
  useEffect(() => {
    const shouldStayClosed = localStorage.getItem("keepMessageClosed");
    if (shouldStayClosed) {
      setMessage(false);
    }
  }, []);
  /* 000 - LOGIC ENDS HERE */

  //DISPATCH A REQUEST TO MAKE A PAYMENT
  function payHandler(id, totalPrice, appeal) {
    dispatch({ type: ORDER_CREATE_RESET });
    dispatch({ type: ORDER_APPLY_DISCOUNTCODE_RESET });

    history.push(
      `/odeme-adimi-2/2/?unpaidid=${id}?totalprice=${totalPrice}${
        appeal && location.pathname.includes("/land/")
          ? `?appeal=${appeal}`
          : ""
      }`
    );
  }
  // DISPATCH A REQUEST TO CANCEL UNPAID ORDER
  const [deletedOrderId, setDeletedOrderId] = useState("");
  function deleteOrderHandler(deleteOrderId) {
    dispatch(actionOrderToDeleteOrder(deleteOrderId));
    setDeletedOrderId(deleteOrderId);
  }
  // CHECK UNPAID ORDER
  useEffect(() => {
    if (success) {
      const phoneNumber = JSON.parse(localStorage.getItem("checkUnpaid"));
      if (phoneNumber && unpaidOrders && unpaidOrders.length >= 1) {
        dispatch(actionOrderToCheckUnpaidOrders(phoneNumber));
      }
    }
    return () => {
      dispatch({ type: ORDER_DELETE_RESET });
    };
  }, [dispatch, success, unpaidOrders]);

  // VIEW ORDERS FUNCTIONALITY
  const [viewOrders, setViewOrders] = useState(false);

  // IF IN OrderStepOnePage VIEW ORDERS IN FULL
  useEffect(() => {
    if (
      location.pathname.substring(0, 27) === "/siparis-bilgileri-adim-1/2" &&
      cartSkuItems.length === 0
    ) {
      setViewOrders(true);
    }
    return () => {
      setViewOrders(false);
    };
  }, [location, cartSkuItems]);

  return (
    <>
      {location.pathname.substring(0, 13) === "/siparislerim" ||
      location.pathname.substring(0, 12) === "/odeme-adimi" ||
      location.pathname.substring(0, 12) === "/tesekkurler" ||
      location.pathname.substring(0, 33) ===
        "/odeme-sonrasi-musteri-hizmetleri" ||
      location.pathname.substring(0, 19) === "/musteri-hizmetleri" ||
      location.pathname.substring(0, 7) === "/destek" ||
      location.pathname.substring(0, 9) === "/profilim" ||
      location.pathname.includes("hakkimizda") ||
      location.pathname.includes("iletisim") ? (
        ""
      ) : (
        <>
          {message && (
            <>
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox></MessageBox>
              ) : (
                unpaidOrders &&
                unpaidOrders.length > 0 && (
                  <div className="user-screen">
                    <div
                      className="unpaid-orders-message-area"
                      style={{
                        marginTop:
                          !location.pathname.includes("admin") &&
                          width > 800 &&
                          (hasAnOrder || isTopBarActive)
                            ? "4rem"
                            : "5rem",
                      }}
                    >
                      <div className="unpaid-orders-message-flex">
                        <div className="w15p">
                          <i className="far fa-envelope beddo-color fs2 marh txtleft"></i>
                        </div>
                        <div className="w70p col">
                          <div>
                            <h3 className="h3l">
                              {`Sayın ${
                                unpaidOrders[0].deliveryContact.firstName
                              } ${unpaidOrders[0].deliveryContact.lastName.substring(
                                0,
                                1
                              )}... , 
                            ödeme işlemi yarıda kalmış`}{" "}
                              <span className="fs15">
                                {unpaidOrders.length}{" "}
                              </span>{" "}
                              adet siparişiniz var.
                            </h3>
                            {viewOrders ? (
                              ""
                            ) : (
                              <button
                                className="unpaid-orders-view-order"
                                type="button"
                                onClick={() => setViewOrders(!viewOrders)}
                              >
                                {unpaidOrders.length === 1
                                  ? "Siparişi Şimdi Görüntüleyin"
                                  : "Siparişleri Şimdi Görüntüleyin"}
                              </button>
                            )}
                          </div>
                        </div>
                        <div
                          title="Kapat"
                          onClick={handleClose}
                          className="close marh pointer w5p txtright"
                        >
                          <i className="fas fa-times beddo-color fs15"></i>
                        </div>
                      </div>
                      {viewOrders && (
                        <div className="unpaid-orders-area">
                          {unpaidOrders.map((order, index) => {
                            return success && order._id === deletedOrderId ? (
                              ""
                            ) : (
                              <table
                                key={order._id}
                                className="unpaid-orders-box"
                              >
                                <thead>
                                  <tr>
                                    <th className="unpaid-headline" colSpan="2">
                                      Kayıtlı Sipariş No - {order.orderId}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th colSpan="2">
                                      <button
                                        className="unpaid-order-pay"
                                        type="button"
                                        onClick={() => {
                                          payHandler(
                                            order._id,
                                            order.orderItems
                                              ?.reduce(
                                                (a, c) => a + c.itemsTotalPrice,
                                                0
                                              )
                                              .toFixed(2),
                                            order.converterAppeal
                                          );
                                        }}
                                      >
                                        Ödeme Yap
                                      </button>
                                    </th>
                                  </tr>
                                  <tr className="h0-5r"></tr>
                                  <tr title="Siparişten vazgeçtiğiniz ürünü çıkarabilirsiniz.">
                                    <th colSpan="2">
                                      <button
                                        className="unpaid-order-edit"
                                        type="button"
                                        onClick={() =>
                                          history.push("/siparislerim")
                                        }
                                      >
                                        Düzenle Ve Ödeme Yap
                                      </button>
                                    </th>
                                  </tr>
                                  <tr className="h0-5r"></tr>
                                  <tr>
                                    <th colSpan="2">
                                      <button
                                        className="unpaid-order-delete"
                                        type="button"
                                        onClick={() =>
                                          deleteOrderHandler(order._id)
                                        }
                                      >
                                        Siparişi İptal Et
                                      </button>
                                    </th>
                                  </tr>
                                </thead>

                                {order.orderItems.map((item, index) => {
                                  return (
                                    <tbody
                                      key={item._id}
                                      className={
                                        index % 2 === 0
                                          ? "unpaid-item-block"
                                          : "unpaid-item-block lighter"
                                      }
                                    >
                                      <tr>
                                        <td
                                          colSpan="2"
                                          className="unpaid-item-name"
                                        >
                                          {item.name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan="2"
                                          className="unpaid-item-spec"
                                        >
                                          -
                                          {`${
                                            item.target === "Pass"
                                              ? ""
                                              : item.target
                                          } ${
                                            item.type === "Pass"
                                              ? ""
                                              : item.type
                                          } ${
                                            item.size === "Pass"
                                              ? ""
                                              : item.size
                                          } ${
                                            item.color === "Pass"
                                              ? ""
                                              : item.color
                                          }`}
                                          -
                                        </td>
                                      </tr>
                                      <tr className="unpaid-image-row">
                                        <td className="unpaid-image">
                                          <img
                                            className="small-image"
                                            src={item.image}
                                            alt={item.name}
                                          />
                                        </td>

                                        <td className="unpaid-price-offer">
                                          {item.price === 0 ? (
                                            <>
                                              <h4
                                                className={
                                                  item.qty > 1 ||
                                                  item.price === 0 ||
                                                  (item.qty === 1 &&
                                                    index !== 0)
                                                    ? "unpaid-orders-qty"
                                                    : ""
                                                }
                                              >
                                                {item.qty}{" "}
                                                {item.productUnit
                                                  ? item.productUnit
                                                  : " Adet"}
                                              </h4>
                                              <h4>
                                                <strike className="h3c red-col">
                                                  {Math.floor(
                                                    Number(
                                                      item.priceForOne *
                                                        item.qty
                                                    )
                                                  )}
                                                  TL
                                                </strike>
                                                {Number(item.price)} TL
                                              </h4>
                                            </>
                                          ) : item.qty > 1 ? (
                                            <>
                                              <h4
                                                className={
                                                  item.qty > 1 ||
                                                  item.price === 0 ||
                                                  (item.qty === 1 &&
                                                    index !== 0)
                                                    ? "unpaid-orders-qty"
                                                    : ""
                                                }
                                              >
                                                {item.qty}{" "}
                                                {item.productUnit
                                                  ? item.productUnit
                                                  : " Adet"}
                                              </h4>
                                              <h4>
                                                <strike className="h3c red-col">
                                                  {Math.floor(
                                                    Number(
                                                      item.priceForOne *
                                                        item.qty
                                                    )
                                                  )}
                                                  TL
                                                </strike>{" "}
                                                {item.price +
                                                  (item.qty - 1) *
                                                    item.priceGap.toFixed(
                                                      2
                                                    )}{" "}
                                                TL
                                              </h4>
                                            </>
                                          ) : item.price * item.qty <
                                            item.priceForOne * item.qty ? (
                                            <>
                                              {" "}
                                              <h4
                                                className={
                                                  item.qty > 1 ||
                                                  item.price === 0 ||
                                                  (item.qty === 1 &&
                                                    index !== 0)
                                                    ? "unpaid-orders-qty"
                                                    : ""
                                                }
                                              >
                                                {item.qty}{" "}
                                                {item.productUnit
                                                  ? item.productUnit
                                                  : " Adet"}
                                              </h4>
                                              <h4>
                                                <strike className="h3c red-col">
                                                  {Math.floor(
                                                    Number(
                                                      item.priceForOne *
                                                        item.qty
                                                    )
                                                  )}
                                                  TL
                                                </strike>
                                                {Number(item.price)} TL
                                              </h4>
                                            </>
                                          ) : (
                                            <>
                                              <h4
                                                className={
                                                  item.qty > 1 ||
                                                  item.price === 0 ||
                                                  (item.qty === 1 &&
                                                    index !== 0)
                                                    ? "unpaid-orders-qty"
                                                    : ""
                                                }
                                              >
                                                {item.qty}{" "}
                                                {item.productUnit
                                                  ? item.productUnit
                                                  : " Adet"}
                                              </h4>
                                              <h4>
                                                {Number(item.price).toFixed(2)}{" "}
                                                TL
                                              </h4>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                      <tr className="unpaid-orders-empty-row">
                                        <td colSpan="2"></td>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                              </table>
                            );
                          })}
                        </div>
                      )}
                      {!viewOrders ? (
                        ""
                      ) : (
                        <div className="unpaid-orders-unview-area">
                          <button
                            className="unpaid-orders-unview-order"
                            type="button"
                            onClick={() => setViewOrders(!viewOrders)}
                          >
                            Kapat
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
