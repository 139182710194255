import { useCallback, useEffect, useRef, useState } from "react";
import { removeDuplicatesByProperty } from "../libraries/ercLibrary";
import { useDispatch } from "react-redux";

export function useInfiniteScroll(
  items,
  loading,
  pages,
  action,
  keyword,
  activeProductCount,
  allProducts
) {
  const dispatch = useDispatch();

  // INFINITE SCROLL LOGIC
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsList, setItemsList] = useState("");
  const [cachedList, setCachedList] = useState("");
  const [reStartScroll, setRestartScroll] = useState(false);
  const prevPageNumberRef = useRef(pageNumber);
  const prevProductsRef = useRef(items);

  // SET ITEMS IN LOCAL STORAGE UNTILL IT REACHES THE TOTAL NUMBER OF PRODUCT
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const cached = JSON.parse(localStorage.getItem("cached"));

    if (activeProductCount && !cached) {
      localStorage.setItem("cached", JSON.stringify(itemsList));
    } else if (
      activeProductCount &&
      cached.length < activeProductCount &&
      itemsList
    ) {
      const ids = cached.map((item) => item._id);
      const newItems = itemsList.filter((item) => !ids.includes(item._id));

      if (newItems.length > 0) {
        const merged = [...cached, ...newItems];
        setRestartScroll(true);
        localStorage.setItem("cached", JSON.stringify(merged));
      }
    } else if (
      activeProductCount &&
      cached.length > activeProductCount &&
      itemsList
    ) {
      const ids = itemsList.map((item) => item._id);
      const removedMissingItems = cached.filter((item) =>
        ids.includes(item._id)
      );

      if (removedMissingItems.length > 0) {
        setRestartScroll(true);
        localStorage.setItem("cached", JSON.stringify(removedMissingItems));
      }
    }

    return () => setRestartScroll(false);
  });

  // DETECT IF LAST ITEM IN LIST IS IN VIEW AND IF SO SET THE PAGE NUMBER
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          (cachedList?.length || itemsList?.length)
        ) {
          setPageNumber((prev) => (prev < pages ? prev + 1 : prev));
        }
      });
      if (node) observer.current.observe(node);

      // Cleanup function for the IntersectionObserver
      return () => {
        if (observer.current) observer.current.disconnect();
      };
    },
    [loading, pages, cachedList?.length, itemsList?.length]
  );

  useEffect(() => {
    dispatch(action(keyword, keyword ? 1 : pageNumber));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, keyword]);

  useEffect(() => {
    // Check if there is a change in pageNumber or products.
    if (
      prevPageNumberRef.current !== pageNumber ||
      prevProductsRef.current !== items
    ) {
      // Update the ref with the current values.
      prevPageNumberRef.current = pageNumber;
      prevProductsRef.current = items;

      // Now update the productsList state with the new products.
      if (keyword) {
        setItemsList(items);
      } else if (pageNumber && items) {
        setItemsList((prevList) =>
          !prevList
            ? removeDuplicatesByProperty(items, "_id")
            : removeDuplicatesByProperty([...prevList, ...items], "_id")
        );

        // setNextBatchRequested(false);
      }
    }
  }, [pageNumber, items, keyword]);

  // SORT ITEMS BY _id
  useEffect(() => {
    if (itemsList.length > 0) {
      const sortedList = itemsList.sort((a, b) => a._id.localeCompare(b._id));
      setItemsList(sortedList);
    }
  }, [itemsList]);

  // GET ITEMS FROM LOCAL STORAGE
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cached"));
    setCachedList(cache);
  }, [reStartScroll]);

  // COMPARE CACHE AND DATA FROM SERVER TO DECIDE IF THERE ARE UPDATES. IF UPDATES ARE DETECTED REFRESH AND UPDATE CACHE
  useEffect(() => {
    if (allProducts) {
      // GET CACHED ITEMS FROM LOCAL STORAGE
      const cachedItems = JSON.parse(localStorage.getItem("cached"));

      if (!cachedItems) return;

      // SIMPLIFY allProducts ARRAY BY CREATING A NEW ARRAY OF OBJECTS WITH LESS PROPERTY
      const liveItems = allProducts.map((item) => ({
        id: item._id,
        name: item.name,
        defaultPrice: item.defaultPrice,
        defaultPriceGap: item.defaultPriceGap,
        numReviews: item.numReviews,
        defaultImages: item.defaultImages, // array of strings
        buyOneGetOneFree: item.buyOneGetOneFree,
        skus: item.skus && item.skus,
        countInStock: item.skus.some((sku) => sku.countInStock > 0),
      }));

      // ITERATE liveItems WHICH IS THE SIMPLIFIED VERSION OF ALL PRODUCTS
      for (let i = 0; i < liveItems.length; i++) {
        // REPRESENTS THE ITEM IN CURRENT ITERATION
        const liveItem = liveItems[i];

        // FIND ITEM INDEX IN cachedItems THAT MATCHED THE ITEM IN
        const cachedItemIndex = cachedItems.findIndex(
          (item) => item._id === liveItem.id
        );

        // REPRESENTS THE MATCHED ITEM IN cachedItems IN CURRENT ITERATION OF liveItems
        const cachedItem = cachedItems[cachedItemIndex];

        // IF THERE IS NO MATCH LOOK FOR THE NEXT ONE IN ARRAY
        if (cachedItemIndex === -1) {
          continue;
        }

        // CHECK CACHED ITEM countInStock
        const cachedCountInStock = cachedItem.skus.some(
          (sku) => sku.countInStock > 0
        );

        // VARIABLE TO DETECT IF THERE IS A PROPERTY FOUND TO BE UPDATED
        let needsUpdate = false;

        // COMPARE PROPERTIES IN cachedItem WITH liveItem
        if (JSON.stringify(liveItem.name) !== JSON.stringify(cachedItem.name)) {
          console.log("Objects at index", i, "have different names");
          cachedItem.name = liveItem.name;
          needsUpdate = true;
        } else if (
          JSON.stringify(liveItem.defaultPrice) !==
          JSON.stringify(cachedItem.defaultPrice)
        ) {
          console.log("Objects at index", i, "have different prices");
          cachedItem.defaultPrice = liveItem.defaultPrice;
          needsUpdate = true;
        } else if (
          JSON.stringify(liveItem.defaultPriceGap) !==
          JSON.stringify(cachedItem.defaultPriceGap)
        ) {
          console.log("Objects at index", i, "have different priceGaps");
          cachedItem.defaultPriceGap = liveItem.defaultPriceGap;
          needsUpdate = true;
        } else if (
          JSON.stringify(liveItem.numReviews) !==
          JSON.stringify(cachedItem.numReviews)
        ) {
          console.log("Objects at index", i, "have different numReviews");
          cachedItem.numReviews = liveItem.numReviews;
          needsUpdate = true;
        } else if (
          JSON.stringify(liveItem.defaultImages) !==
          JSON.stringify(cachedItem.defaultImages)
        ) {
          console.log("Objects at index", i, "have different images");
          cachedItem.defaultImages = liveItem.defaultImages;
          needsUpdate = true;
        } else if (
          JSON.stringify(liveItem.buyOneGetOneFree) !==
          JSON.stringify(cachedItem.buyOneGetOneFree)
        ) {
          console.log("Objects at index", i, "have different buyOneGetOneFree");
          cachedItem.buyOneGetOneFree = liveItem.buyOneGetOneFree;
          needsUpdate = true;
        } else if (liveItem.countInStock !== cachedCountInStock) {
          console.log("Objects at index", i, "have different countInStock");
          cachedItem.skus = liveItem.skus;
          needsUpdate = true;
        }

        if (needsUpdate) {
          cachedItems[cachedItemIndex] = cachedItem; // Update the item in the copy
        }
      }

      setCachedList(cachedItems);
      localStorage.setItem("cached", JSON.stringify(cachedItems));
    }
  }, [allProducts]);

  return {
    itemsList: cachedList?.length > 0 ? cachedList : itemsList,
    lastElementRef:
      cachedList && cachedList.length === activeProductCount
        ? null
        : lastElementRef,
  };
}
