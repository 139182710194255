import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionToListTopBars } from "../../actions/adminTopBarActions";
import "../../components/ClientHasAyetToShippedOrder/ClientHasAYetToShippedOrder.css";
import TopBarRender from "./TopBarRender";
import useGetLimitedTimeDiscountInfo from "../../hooks/useGetLimitedTimeDiscountInfo";

export default function TopBar(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  // PROPS COMING FROM App
  const { hasAnOrder, setIsTopBarActive, createdFingerPrint } = props;

  const { isDiscountValid, remainingTime } = useGetLimitedTimeDiscountInfo(
    createdFingerPrint,
    createdFingerPrint
  );

  const [resultData, setResultData] = useState("");
  useEffect(() => {
    if (createdFingerPrint) {
      // Total hours passed after first visit of the visitor
      let resultData = JSON.parse(sessionStorage.getItem("resultData"));

      setResultData(resultData);
    }
  }, [createdFingerPrint]);

  // BRING IN POPS LIST FROM REDUX STORE
  const listTopBars = useSelector((state) => state.listTopBars);
  const { loading, error, topBarsList } = listTopBars;
  // DISPATCH ACTION TO LIST POPS
  useEffect(() => {
    dispatch(actionToListTopBars("client")); // BRINGS ONLY THE ACTIVE TOP BARS
  }, [dispatch]);

  // SET SESSION STORAGE TO MAKE SURE THERE IS AN ACTIVE
  const [nowInMiliseconds, setNowInMiliseconds] = useState("");
  useEffect(() => {
    if (topBarsList && topBarsList.length > 0) {
      const nowInMiliseconds = new Date().getTime();
      setNowInMiliseconds(nowInMiliseconds);

      const shouldTopBarBeActiveNegative = topBarsList.filter((topBar) =>
        topBar.doNotDisplayHere.find((free) => location.pathname.includes(free))
      );
      const shouldTopBarBeActivePositive = topBarsList.filter((topBar) =>
        topBar.displayOnlyHere.find((free) => location.pathname.includes(free))
      );

      if (
        shouldTopBarBeActiveNegative &&
        shouldTopBarBeActiveNegative.length === 0
      ) {
        // console.log("hello1");
        sessionStorage.setItem("topBar", "ACTIVE");
        setIsTopBarActive(true);
      } else if (
        shouldTopBarBeActiveNegative &&
        shouldTopBarBeActivePositive &&
        shouldTopBarBeActivePositive.length >
          shouldTopBarBeActiveNegative.length
      ) {
        // console.log("hello2");
        sessionStorage.setItem("topBar", "ACTIVE");
        setIsTopBarActive(true);
      } else {
        // console.log("hello3");
        sessionStorage.removeItem("topBar");
        setIsTopBarActive(false);
      }

      // CHECK IF TOP BAR IS TIME RESTRICTED
    } else {
      // console.log("hello4");
      setIsTopBarActive(false);
      sessionStorage.removeItem("topBar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBarsList, location]);

  return (
    <>
      {loading
        ? ""
        : error
        ? ""
        : hasAnOrder
        ? ""
        : location.pathname.includes("ust-bar")
        ? ""
        : topBarsList &&
          topBarsList.length > 0 &&
          topBarsList.map((topBar, index) => {
            return topBar.isActive &&
              topBar.isTemporary &&
              nowInMiliseconds < Number(topBar.startDateInMiliseconds) &&
              nowInMiliseconds > Number(topBar.endDateInMiliseconds) &&
              topBar.doNotDisplayHere.find((ban) =>
                location.pathname.includes(ban)
              )
              ? ""
              : topBar.isActive &&
                topBar.doNotDisplayHere.find((ban) =>
                  location.pathname.includes(ban)
                )
              ? ""
              : topBar.displayOnlyHere.find((free) =>
                  location.pathname.includes(free)
                ) && (
                  <TopBarRender
                    key={topBar.title}
                    topBar={
                      // Visitors coming back between 36 and 48 hours
                      resultData?.passedHoursAfterFirstVisit > 36 &&
                      resultData?.passedHoursAfterFirstVisit < 48
                        ? topBarsList.find(
                            (bar) =>
                              bar.topBarName === "passedHoursDiscountLevelOne"
                          )
                        : // Visitors coming back after 48 hours
                        resultData?.passedHoursAfterFirstVisit >= 48 &&
                          resultData?.passedHoursAfterFirstVisit <= 72
                        ? topBarsList.find(
                            (bar) =>
                              bar.topBarName === "passedHoursDiscountLevelTwo"
                          )
                        : // If non of the above and discount is valid, time limited discount is shown
                        isDiscountValid
                        ? topBarsList.find((bar) => bar.displayCountDownTimer)
                        : topBarsList.find(
                            (bar) => bar.topBarName === "default"
                          )
                    }
                    isDiscountValid={isDiscountValid}
                    remainingTime={remainingTime}
                  />
                );
          })}
    </>
  );
}
