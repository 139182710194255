// @desc Create a current account
export const CURRENT_ACCOUNT_CREATE_REQUEST = "CURRENT_ACCOUNT_CREATE_REQUEST";
export const CURRENT_ACCOUNT_CREATE_SUCCESS = "CURRENT_ACCOUNT_CREATE_SUCCESS";
export const CURRENT_ACCOUNT_CREATE_FAIL = "CURRENT_ACCOUNT_CREATE_FAIL";
export const CURRENT_ACCOUNT_CREATE_RESET = "CURRENT_ACCOUNT_CREATE_RESET";

// @desc List current accounts
export const CURRENT_ACCOUNT_LIST_REQUEST = "CURRENT_ACCOUNT_LIST_REQUEST";
export const CURRENT_ACCOUNT_LIST_SUCCESS = "CURRENT_ACCOUNT_LIST_SUCCESS";
export const CURRENT_ACCOUNT_LIST_FAIL = "CURRENT_ACCOUNT_LIST_FAIL";
export const CURRENT_ACCOUNT_LIST_RESET = "CURRENT_ACCOUNT_LIST_RESET";

// @desc Get details of a current account
export const CURRENT_ACCOUNT_DETAILS_REQUEST =
  "CURRENT_ACCOUNT_DETAILS_REQUEST";
export const CURRENT_ACCOUNT_DETAILS_SUCCESS =
  "CURRENT_ACCOUNT_DETAILS_SUCCESS";
export const CURRENT_ACCOUNT_DETAILS_FAIL = "CURRENT_ACCOUNT_DETAILS_FAIL";

// @desc Edit a current account
export const CURRENT_ACCOUNT_EDIT_REQUEST = "CURRENT_ACCOUNT_EDIT_REQUEST";
export const CURRENT_ACCOUNT_EDIT_SUCCESS = "CURRENT_ACCOUNT_EDIT_SUCCESS";
export const CURRENT_ACCOUNT_EDIT_FAIL = "CURRENT_ACCOUNT_EDIT_FAIL";
export const CURRENT_ACCOUNT_EDIT_RESET = "CURRENT_ACCOUNT_EDIT_RESET";

// @desc Delete a current account
export const CURRENT_ACCOUNT_DELETE_REQUEST = "CURRENT_ACCOUNT_DELETE_REQUEST";
export const CURRENT_ACCOUNT_DELETE_SUCCESS = "CURRENT_ACCOUNT_DELETE_SUCCESS";
export const CURRENT_ACCOUNT_DELETE_FAIL = "CURRENT_ACCOUNT_DELETE_FAIL";
export const CURRENT_ACCOUNT_DELETE_RESET = "CURRENT_ACCOUNT_DELETE_RESET";

/////////////////////////////////////////////-----currentAccountActivities-----\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// @desc Create a current account activity
export const CURRENT_ACCOUNT_ACTIVITY_CREATE_REQUEST =
  "CURRENT_ACCOUNT_ACTIVITY_CREATE_REQUEST";
export const CURRENT_ACCOUNT_ACTIVITY_CREATE_SUCCESS =
  "CURRENT_ACCOUNT_ACTIVITY_CREATE_SUCCESS";
export const CURRENT_ACCOUNT_ACTIVITY_CREATE_FAIL =
  "CURRENT_ACCOUNT_ACTIVITY_CREATE_FAIL";
export const CURRENT_ACCOUNT_ACTIVITY_CREATE_RESET =
  "CURRENT_ACCOUNT_ACTIVITY_CREATE_RESET";

// @desc List current accounts activities
export const CURRENT_ACCOUNT_ACTIVITY_LIST_REQUEST =
  "CURRENT_ACCOUNT_ACTIVITY_LIST_REQUEST";
export const CURRENT_ACCOUNT_ACTIVITY_LIST_SUCCESS =
  "CURRENT_ACCOUNT_ACTIVITY_LIST_SUCCESS";
export const CURRENT_ACCOUNT_ACTIVITY_LIST_FAIL =
  "CURRENT_ACCOUNT_ACTIVITY_LIST_FAIL";

// @desc Get details of a current account activity
export const CURRENT_ACCOUNT_ACTIVITY_DETAILS_REQUEST =
  "CURRENT_ACCOUNT_ACTIVITY_DETAILS_REQUEST";
export const CURRENT_ACCOUNT_ACTIVITY_DETAILS_SUCCESS =
  "CURRENT_ACCOUNT_ACTIVITY_DETAILS_SUCCESS";
export const CURRENT_ACCOUNT_ACTIVITY_DETAILS_FAIL =
  "CURRENT_ACCOUNT_ACTIVITY_DETAILS_FAIL";

// @desc Edit a current account activity
export const CURRENT_ACCOUNT_ACTIVITY_EDIT_REQUEST =
  "CURRENT_ACCOUNT_ACTIVITY_EDIT_REQUEST";
export const CURRENT_ACCOUNT_ACTIVITY_EDIT_SUCCESS =
  "CURRENT_ACCOUNT_ACTIVITY_EDIT_SUCCESS";
export const CURRENT_ACCOUNT_ACTIVITY_EDIT_FAIL =
  "CURRENT_ACCOUNT_ACTIVITY_EDIT_FAIL";
export const CURRENT_ACCOUNT_ACTIVITY_EDIT_RESET =
  "CURRENT_ACCOUNT_ACTIVITY_EDIT_RESET";

// @desc Delete a current account activity
export const CURRENT_ACCOUNT_ACTIVITY_DELETE_REQUEST =
  "CURRENT_ACCOUNT_ACTIVITY_DELETE_REQUEST";
export const CURRENT_ACCOUNT_ACTIVITY_DELETE_SUCCESS =
  "CURRENT_ACCOUNT_ACTIVITY_DELETE_SUCCESS";
export const CURRENT_ACCOUNT_ACTIVITY_DELETE_FAIL =
  "CURRENT_ACCOUNT_ACTIVITY_DELETE_FAIL";
export const CURRENT_ACCOUNT_ACTIVITY_DELETE_RESET =
  "CURRENT_ACCOUNT_ACTIVITY_DELETE_RESET";
