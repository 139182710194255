export function calculatePriceInCartAction(
  timeLimitedDiscount,
  discounted,
  data,
  isThereApendingOrder,
  free
) {
  const price = timeLimitedDiscount
    ? discounted
      ? data.skus[0].priceGap
      : isThereApendingOrder === "YES" && free
      ? 0
      : isThereApendingOrder === "YES"
      ? data.skus[0].priceGap
      : free
      ? 0
      : data.skus[0].price - timeLimitedDiscount
    : isThereApendingOrder === "YES" && free
    ? 0
    : isThereApendingOrder === "YES"
    ? data.skus[0].priceGap
    : free
    ? 0
    : data.skus[0].price;

  return price;
}
