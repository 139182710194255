import { useEffect } from "react";

export function useScrollRestorationInPop(elementId) {
  useEffect(() => {
    if (elementId) {
      const scrollIfPossible = () => {
        const itemElement = document.getElementById(elementId);

        itemElement.scrollIntoView({ behavior: "smooth" });
      };
      scrollIfPossible();
    }
  }, [elementId]); // Empty dependency array means this effect runs once when the component mounts
}
