import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./ClientHasAYetToShippedOrder.css";
import { actionOrderToCheckIfThereIsApendingOrder } from "../../actions/orderActions";
import { ORDER_IS_PENDING_RESET } from "../../constants/orderConstants";

export default function ClientHasAYetToShippedOrder(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  // PROPS CMOING FROM App
  const { orderId } = props;

  // REDUX STATE THAT HOLDS INFO IF THERE IS A PENDING ORDER OF CLIENT
  const checkIfThereIsApendingOrder = useSelector(
    (state) => state.checkIfThereIsApendingOrder
  );
  const { loading, error, isPending } = checkIfThereIsApendingOrder;

  // BRING IN ORDERS MADE BY CLIENT
  useEffect(() => {
    if (orderId) {
      dispatch(actionOrderToCheckIfThereIsApendingOrder(orderId));
    }
  }, [dispatch, orderId, location]);

  // BRING IN ORDERS MADE BY CLIENT
  useEffect(() => {
    if (isPending && isPending.isTherePending === "NO") {
      setTimeout(() => {
        localStorage.removeItem("tChAo");
        dispatch({ type: ORDER_IS_PENDING_RESET });
      }, 2000);
    }
  }, [dispatch, isPending]);

  return (
    <>
      {loading
        ? ""
        : error
        ? ""
        : isPending &&
          isPending.isTherePending === "YES" &&
          !location.pathname.includes("admin") && (
            <div className="clienthasayettoshippedorder row-center-nowrap">
              <h1>İNDİRİMLİ EK SİPARİŞ OLUŞTUR</h1>
              <ul>
                <li className="row-left-nowrap">
                  <i className="fas fa-square fs-45"></i>
                  <p>
                    Mevcut siparişiniz paketlenene kadar indirimli ek siparişler
                    oluşturabilirsiniz.
                  </p>
                </li>
                <li className="row-left-nowrap">
                  <i className="fas fa-square fs-45"></i>
                  <p>
                    Siparişiniz saat 10:00'dan itibaren bir kaç dakika ya da bir
                    kaç saat içinde paketlenebilir.
                  </p>
                </li>
                <li className="row-left-nowrap">
                  <i className="fas fa-square fs-45"></i>
                  İndirimi kaçırmamak için ek siparişlerinizi hızlı oluşturun.
                </li>
              </ul>
            </div>
          )}
    </>
  );
}
