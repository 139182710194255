import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToCreateDeliveryAddress = createAsyncThunk(
  "wholeSale/jobInActionToCreateDeliveryAddress",
  async (addressData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/wholesale/createDeliveryAddress",
        addressData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  accountWithNewDeliveryAddress: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const createDeliveryAddressSlice = createSlice({
  name: "createDeliveryAddress",
  initialState,
  reducers: {
    resetCreateDeliveryAddress: (state) => {
      state.accountWithNewDeliveryAddress = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToCreateDeliveryAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToCreateDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.accountWithNewDeliveryAddress = action.payload;
      })
      .addCase(actionToCreateDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetCreateDeliveryAddress } = createDeliveryAddressSlice.actions;

// Export the reducer
const reducerToCreateDeliveryAddress = createDeliveryAddressSlice.reducer;

// Export the async action creator for use in components
export { reducerToCreateDeliveryAddress, resetCreateDeliveryAddress };
