import React, { useState } from "react";
import "./ScrollToTopIcon.css";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { scrollToTop } from "../libraries/ercLibrary";

export default function ScrollToTopIcon() {
  const [scrollHeight, setScrollHeight] = useState("");
  useScrollPosition(({ prevPos, currPos }) => {
    setScrollHeight(currPos.y);
  });

  return (
    <>
      {!scrollHeight
        ? ""
        : scrollHeight < "-1200" && (
            <i
              className="far fa-caret-square-up scrolltotop"
              onClick={scrollToTop}
            ></i>
          )}
    </>
  );
}
