export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_EMPTY = "CART_EMPTY";

export const CART_ADD_SKU_ITEM = "CART_ADD_SKU_ITEM";
export const CART_REMOVE_SKU_ITEM = "CART_REMOVE_SKU_ITEM";
export const CART_EMPTY_SKU = "CART_EMPTY_SKU";

export const CALCULATE_ITEMS_PRICE = "CALCULATE_ITEMS_PRICE";
export const UPDATE_SHIPPING_PRICE = "UPDATE_SHIPPING_PRICE";
export const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE";
export const CALCULATE_TAX_PRICE = "CALCULATE_TAX_PRICE";

//SHOW PRODUCT DETAILS PAGE ON CLICK
export const CART_SKU_DETAILS_REQUEST = "CART_SKU_DETAILS_REQUEST";
export const CART_SKU_DETAILS_SUCCESS = "CART_SKU_DETAILS_SUCCESS";
export const CART_SKU_DETAILS_FAIL = "CART_SKU_DETAILS_FAIL";
