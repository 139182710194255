export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";
export const ORDER_CREATE_SUCCESS_RESET = "ORDER_CREATE_SUCCESS_RESET";

export const ORDER_PHONE_CHECK_REQUEST = "ORDER_PHONE_CHECK_REQUEST";
export const ORDER_PHONE_CHECK_SUCCESS = "ORDER_PHONE_CHECK_SUCCESS";
export const ORDER_PHONE_CHECK_FAIL = "ORDER_PHONE_CHECK_FAIL";
export const ORDER_PHONE_CHECK_RESET = "ORDER_PHONE_CHECK_RESET";

/*export const ORDER_IYZICO_FORM_REQUEST = "ORDER_IYZICO_FORM_REQUEST";
export const ORDER_IYZICO_FORM_SUCCESS = "ORDER_IYZICO_FORM_SUCCESS";
export const ORDER_IYZICO_FORM_FAIL = "ORDER_IYZICO_FORM_FAIL";
export const ORDER_IYZICO_FORM_RESET = "ORDER_IYZICO_FORM_RESET";

export const ORDER_IYZICO_FORM_RESULT_REQUEST =
  "ORDER_IYZICO_FORM_RESULT_REQUEST";
export const ORDER_IYZICO_FORM_RESULT_SUCCESS =
  "ORDER_IYZICO_FORM_RESULT_SUCCESS";
export const ORDER_IYZICO_FORM_RESULT_FAIL = "ORDER_IYZICO_FORM_RESULT_FAIL";*/

export const ORDER_UPDATE_PAYMENT_INFO_REQUEST =
  "ORDER_UPDATE_PAYMENT_INFO_REQUEST";
export const ORDER_UPDATE_PAYMENT_INFO_SUCCESS =
  "ORDER_UPDATE_PAYMENT_INFO_SUCCESS";
export const ORDER_UPDATE_PAYMENT_INFO_FAIL = "ORDER_UPDATE_PAYMENT_INFO_FAIL";
export const ORDER_UPDATE_PAYMENT_INFO_RESET =
  "ORDER_UPDATE_PAYMENT_INFO_RESET";

export const ORDER_LIST_USER_REQUEST = "ORDER_LIST_USER_REQUEST";
export const ORDER_LIST_USER_SUCCESS = "ORDER_LIST_USER_SUCCESS";
export const ORDER_LIST_USER_FAIL = "ORDER_LIST_USER_FAIL";

export const ORDER_CHECK_UNPAID_REQUEST = "ORDER_CHECK_UNPAID_REQUEST";
export const ORDER_CHECK_UNPAID_SUCCESS = "ORDER_CHECK_UNPAID_SUCCESS";
export const ORDER_CHECK_UNPAID_FAIL = "ORDER_CHECK_UNPAID_FAIL";
export const ORDER_CHECK_UNPAID_RESET = "ORDER_CHECK_UNPAID_RESET";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";
export const ORDER_CANCEL_RESET = "ORDER_CANCEL_RESET";

export const ORDER_CANCEL_PAYMENT_REQUEST = "ORDER_CANCEL_PAYMENT_REQUEST";
export const ORDER_CANCEL_PAYMENT_SUCCESS = "ORDER_CANCEL_PAYMENT_SUCCESS";
export const ORDER_CANCEL_PAYMENT_FAIL = "ORDER_CANCEL_PAYMENT_FAIL";
export const ORDER_CANCEL_PAYMENT_RESET = "ORDER_CANCEL_PAYMENT_RESET";

export const ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST =
  "ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST";
export const ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS =
  "ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS";
export const ORDER_UPDATE_CANCELLED_PAYMENT_FAIL =
  "ORDER_UPDATE_CANCELLED_PAYMENT_FAIL";

export const ORDER_CANCEL_PARTIALLY_REQUEST = "ORDER_CANCEL_PARTIALLY_REQUEST";
export const ORDER_CANCEL_PARTIALLY_SUCCESS = "ORDER_CANCEL_PARTIALLY_SUCCESS";
export const ORDER_CANCEL_PARTIALLY_FAIL = "ORDER_CANCEL_PARTIALLY_FAIL";
export const ORDER_CANCEL_PARTIALLY_RESET = "ORDER_CANCEL_PARTIALLY_RESET";

export const ORDER_UPDATE_DELIVERY_CONTACT_REQUEST =
  "ORDER_UPDATE_DELIVERY_CONTACT_REQUEST";
export const ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS =
  "ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS";
export const ORDER_UPDATE_DELIVERY_CONTACT_FAIL =
  "ORDER_UPDATE_DELIVERY_CONTACT_FAIL";
export const ORDER_UPDATE_DELIVERY_CONTACT_RESET =
  "ORDER_UPDATE_DELIVERY_CONTACT_RESET";

export const ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST =
  "ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST";
export const ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS =
  "ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS";
export const ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL =
  "ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL";
export const ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET =
  "ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET";

export const ORDER_INVOICE_DETAILS_REQUEST = "ORDER_INVOICE_DETAILS_REQUEST";
export const ORDER_INVOICE_DETAILS_SUCCESS = "ORDER_INVOICE_DETAILS_SUCCESS";
export const ORDER_INVOICE_DETAILS_FAIL = "ORDER_INVOICE_DETAILS_FAIL";

//GET ORDER DETAILS
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

//GET UPSELL ORDER DETAILS
export const UPSELL_ORDER_DETAILS_REQUEST = "UPSELL_ORDER_DETAILS_REQUEST";
export const UPSELL_ORDER_DETAILS_SUCCESS = "UPSELL_ORDER_DETAILS_SUCCESS";
export const UPSELL_ORDER_DETAILS_FAIL = "UPSELL_ORDER_DETAILS_FAIL";
export const UPSELL_ORDER_DETAILS_RESET = "UPSELL_ORDER_DETAILS_RESET";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

// CREATE REFUND REQUEST
export const ORDER_REFUND_REQUEST = "ORDER_REFUND_REQUEST";
export const ORDER_REFUND_SUCCESS = "ORDER_REFUND_SUCCESS";
export const ORDER_REFUND_FAIL = "ORDER_REFUND_FAIL";
export const ORDER_REFUND_RESET = "ORDER_REFUND_RESET";

// CANCEL REFUND REQUEST
export const ORDER_REFUND_CANCEL_REQUEST = "ORDER_REFUND_CANCEL_REQUEST";
export const ORDER_REFUND_CANCEL_SUCCESS = "ORDER_REFUND_CANCEL_SUCCESS";
export const ORDER_REFUND_CANCEL_FAIL = "ORDER_REFUND_CANCEL_FAIL";
export const ORDER_REFUND_CANCEL_RESET = "ORDER_REFUND_CANCEL_RESET";

// COLLECT REFUND IBAN
export const ORDER_REFUND_IBAN_REQUEST = "ORDER_REFUND_IBAN_REQUEST";
export const ORDER_REFUND_IBAN_SUCCESS = "ORDER_REFUND_IBAN_SUCCESS";
export const ORDER_REFUND_IBAN_FAIL = "ORDER_REFUND_IBAN_FAIL";
export const ORDER_REFUND_IBAN_RESET = "ORDER_REFUND_IBAN_RESET";

// APPLY DISCOUNT
export const ORDER_APPLY_DISCOUNTCODE_REQUEST =
  "ORDER_APPLY_DISCOUNTCODE_REQUEST";
export const ORDER_APPLY_DISCOUNTCODE_SUCCESS =
  "ORDER_APPLY_DISCOUNTCODE_SUCCESS";
export const ORDER_APPLY_DISCOUNTCODE_FAIL = "ORDER_APPLY_DISCOUNTCODE_FAIL";
export const ORDER_APPLY_DISCOUNTCODE_RESET = "ORDER_APPLY_DISCOUNTCODE_RESET";

// VIEW INVOICE
export const ORDER_VIEW_INVOICE_REQUEST = "ORDER_VIEW_INVOICE_REQUEST";
export const ORDER_VIEW_INVOICE_SUCCESS = "ORDER_VIEW_INVOICE_SUCCESS";
export const ORDER_VIEW_INVOICE_FAIL = "ORDER_VIEW_INVOICE_FAIL";
export const ORDER_VIEW_INVOICE_RESET = "ORDER_VIEW_INVOICE_RESET";

// CHECK PENDING ORDER
export const ORDER_IS_PENDING_REQUEST = "ORDER_IS_PENDING_REQUEST";
export const ORDER_IS_PENDING_SUCCESS = "ORDER_IS_PENDING_SUCCESS";
export const ORDER_IS_PENDING_FAIL = "ORDER_IS_PENDING_FAIL";
export const ORDER_IS_PENDING_RESET = "ORDER_IS_PENDING_RESET";
