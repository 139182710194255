import {
  ADMIN_CREATE_TOPBAR_FAIL,
  ADMIN_CREATE_TOPBAR_REQUEST,
  ADMIN_CREATE_TOPBAR_RESET,
  ADMIN_CREATE_TOPBAR_SUCCESS,
  ADMIN_DETAILS_TOPBAR_FAIL,
  ADMIN_DETAILS_TOPBAR_REQUEST,
  ADMIN_DETAILS_TOPBAR_SUCCESS,
  ADMIN_EDIT_TOPBAR_FAIL,
  ADMIN_EDIT_TOPBAR_REQUEST,
  ADMIN_EDIT_TOPBAR_RESET,
  ADMIN_EDIT_TOPBAR_SUCCESS,
  LIST_TOPBARS_FAIL,
  LIST_TOPBARS_REQUEST,
  LIST_TOPBARS_SUCCESS,
} from "../constants/adminTopBarConstants";

// CREATE A TOP BAR
export const reducerAdminToCreateTopBar = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_CREATE_TOPBAR_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_TOPBAR_SUCCESS:
      return {
        loading: false,
        success: true,
        topBar: action.payload,
      };
    case ADMIN_CREATE_TOPBAR_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_TOPBAR_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// LIST TOP BARS
export const reducerToListTopBars = (state = { loading: true }, action) => {
  switch (action.type) {
    case LIST_TOPBARS_REQUEST:
      return { loading: true };
    case LIST_TOPBARS_SUCCESS:
      return {
        loading: false,
        topBarsList: action.payload,
      };
    case LIST_TOPBARS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE/EDIT TOP BAR
export function reducerAdminToEditTopBar(state = { loading: false }, action) {
  switch (action.type) {
    case ADMIN_EDIT_TOPBAR_REQUEST:
      return { loading: true };
    case ADMIN_EDIT_TOPBAR_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_EDIT_TOPBAR_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_EDIT_TOPBAR_RESET:
      return { ...state, success: "", error: "", loading: false };
    default:
      return state;
  }
}

// GET DETAILS OF TOP BAR
export const reducerAdminGetDetailsOfTopBar = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_DETAILS_TOPBAR_REQUEST:
      return { loading: true };
    case ADMIN_DETAILS_TOPBAR_SUCCESS:
      return {
        loading: false,
        topBarDetails: action.payload,
      };
    case ADMIN_DETAILS_TOPBAR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
