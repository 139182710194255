//ADMIN CREATE CATEGORY-LOW
export const ADMIN_CATEGORY_LOW_CREATE_REQUEST =
  "ADMIN_CATEGORY_LOW_CREATE_REQUEST";
export const ADMIN_CATEGORY_LOW_CREATE_SUCCESS =
  "ADMIN_CATEGORY_LOW_CREATE_SUCCESS";
export const ADMIN_CATEGORY_LOW_CREATE_FAIL = "ADMIN_CATEGORY_LOW_CREATE_FAIL";
export const ADMIN_CATEGORY_LOW_CREATE_RESET =
  "ADMIN_CATEGORY_LOW_CREATE_RESET";

//ADMIN CATEGORY-LOW LIST
export const ADMIN_CATEGORY_LOW_LIST_REQUEST =
  "ADMIN_CATEGORY_LOW_LIST_REQUEST";
export const ADMIN_CATEGORY_LOW_LIST_SUCCESS =
  "ADMIN_CATEGORY_LOW_LIST_SUCCESS";
export const ADMIN_CATEGORY_LOW_LIST_FAIL = "ADMIN_CATEGORY_LOW_LIST_FAIL";

//ADMIN CATEGORY-LOW RELATED LIST
export const ADMIN_CATEGORY_LOW_RELATED_LIST_REQUEST =
  "ADMIN_CATEGORY_LOW_RELATED_LIST_REQUEST";
export const ADMIN_CATEGORY_LOW_RELATED_LIST_SUCCESS =
  "ADMIN_CATEGORY_LOW_RELATED_LIST_SUCCESS";
export const ADMIN_CATEGORY_LOW_RELATED_LIST_FAIL =
  "ADMIN_CATEGORY_LOW_RELATED_LIST_FAIL";

//ADMIN CATEGORY-LOW DETAILS
export const ADMIN_CATEGORY_LOW_DETAILS_REQUEST =
  "ADMIN_CATEGORY_LOW_DETAILS_REQUEST";
export const ADMIN_CATEGORY_LOW_DETAILS_SUCCESS =
  "ADMIN_CATEGORY_LOW_DETAILS_SUCCESS";
export const ADMIN_CATEGORY_LOW_DETAILS_FAIL =
  "ADMIN_CATEGORY_LOW_DETAILS_FAIL";

//ADMIN CATEGORY-LOW EDIT
export const ADMIN_CATEGORY_LOW_EDIT_REQUEST =
  "ADMIN_CATEGORY_LOW_EDIT_REQUEST";
export const ADMIN_CATEGORY_LOW_EDIT_SUCCESS =
  "ADMIN_CATEGORY_LOW_EDIT_SUCCESS";
export const ADMIN_CATEGORY_LOW_EDIT_FAIL = "ADMIN_CATEGORY_LOW_EDIT_FAIL";
export const ADMIN_CATEGORY_LOW_EDIT_RESET = "ADMIN_CATEGORY_LOW_EDIT_RESET";

//ADMIN CATEGORY-LOW DELETE
export const ADMIN_CATEGORY_LOW_DELETE_REQUEST =
  "ADMIN_CATEGORY_LOW_DELETE_REQUEST";
export const ADMIN_CATEGORY_LOW_DELETE_SUCCESS =
  "ADMIN_CATEGORY_LOW_DELETE_SUCCESS";
export const ADMIN_CATEGORY_LOW_DELETE_FAIL = "ADMIN_CATEGORY_LOW_DELETE_FAIL";
export const ADMIN_CATEGORY_LOW_DELETE_RESET =
  "ADMIN_CATEGORY_LOW_DELETE_RESET";
