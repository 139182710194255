import { useEffect, useState } from "react";
import calculateLimitedTimeDiscountValidity from "../utils/calculateLimitedTimeDiscountValidity";
import { undoDiscountsInCart } from "../utils/undoDiscountsInCart";
import { useDispatch } from "react-redux";

export function useUpdateCartItemsAfterDiscountExpired(
  firstVisitTime,
  cartSkuItems,
  isPending
) {
  const dispatch = useDispatch();
  const { isDiscountValid } = calculateLimitedTimeDiscountValidity(
    cartSkuItems.find((item) => item.timeLimitedDiscount?.discountAmount > 0),
    firstVisitTime
  );

  const [isDiscountDeleted, setIsDiscountDeleted] = useState(false);

  useEffect(() => {
    // Check if isPending is undefined, and if so, return without doing anything
    if (typeof isPending === "undefined") {
      return;
    }

    if (
      !isDiscountValid &&
      cartSkuItems?.length > 0 &&
      isPending.isTherePending !== "YES"
    ) {
      // CHECK IF CART CONTAINS AN ITEM THAT IS PRICED AT PRICE GAP
      const isThereDiscountPriceGap = cartSkuItems.find(
        (item) => item.itemsTotalPrice / Number(item.qty) === item.priceGap
      );

      const isThereDiscountTimeLimetedAmount = cartSkuItems.find(
        (item) =>
          item.priceForOne - item.price ===
          Number(item.timeLimitedDiscount?.discountAmount)
      );

      const isThereDiscountItemsTotalPrice = cartSkuItems.find(
        (item) =>
          item.itemsTotalPrice - item.price / Number(item.qty) === item.priceGap
      );

      // UPDATE CART ITEMS
      if (
        isThereDiscountPriceGap ||
        isThereDiscountTimeLimetedAmount ||
        isThereDiscountItemsTotalPrice
      ) {
        undoDiscountsInCart(dispatch);
        setIsDiscountDeleted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiscountValid, dispatch, isPending]);

  return isDiscountDeleted;
}
