//GET

import {
  ADMIN_LIST_REFUNDCODES_FAIL,
  ADMIN_LIST_REFUNDCODES_REQUEST,
  ADMIN_LIST_REFUNDCODES_RESET,
  ADMIN_LIST_REFUNDCODES_SUCCESS,
  ADMIN_REFUNDCODE_FAIL,
  ADMIN_REFUNDCODE_REQUEST,
  ADMIN_REFUNDCODE_RESET,
  ADMIN_REFUNDCODE_SUCCESS,
  ADMIN_REFUND_TRACKINGCODE_FAIL,
  ADMIN_REFUND_TRACKINGCODE_REQUEST,
  ADMIN_REFUND_TRACKINGCODE_RESET,
  ADMIN_REFUND_TRACKINGCODE_SUCCESS,
  ADMIN_REFUND_TRACKING_FAIL,
  ADMIN_REFUND_TRACKING_REQUEST,
  ADMIN_REFUND_TRACKING_RESET,
  ADMIN_REFUND_TRACKING_SUCCESS,
} from "../constants/adminRefundCodeConstants";

//POST
//ADMIN CREATE YURT REFUND CODE
export function reducerToCreateAdminRefundCode(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_REFUNDCODE_REQUEST:
      return { loading: true };
    case ADMIN_REFUNDCODE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case ADMIN_REFUNDCODE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_REFUNDCODE_RESET:
      return {};
    default:
      return state;
  }
}

//ADMIN LIST REFUND CODES
export function reducerAdminListRefundCodes(
  state = { loading: true, refundCodes: [] },
  action
) {
  switch (action.type) {
    case ADMIN_LIST_REFUNDCODES_REQUEST:
      return { loading: true };
    case ADMIN_LIST_REFUNDCODES_SUCCESS:
      return {
        loading: false,
        refundCodes: action.payload.refundCodes,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_LIST_REFUNDCODES_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_LIST_REFUNDCODES_RESET:
      return { loading: true, refundCodes: [] };
    default:
      return state;
  }
}

//ADMIN GET TRACKING CODES BY REFUND CODE QUERY
export function refundAdminGetTrackingCodes(
  state = { loading: true, codes: [] },
  action
) {
  switch (action.type) {
    case ADMIN_REFUND_TRACKINGCODE_REQUEST:
      return { loading: true };
    case ADMIN_REFUND_TRACKINGCODE_SUCCESS:
      return {
        loading: false,
        codes: action.payload,
      };
    case ADMIN_REFUND_TRACKINGCODE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_REFUND_TRACKINGCODE_RESET:
      return { loading: true, codes: [] };
    default:
      return state;
  }
}

//ADMIN GET TRACKING CODES BY REFUND CODE QUERY
export function reducerAdminTrackRefundParcels(
  state = { loading: true, trackResults: [] },
  action
) {
  switch (action.type) {
    case ADMIN_REFUND_TRACKING_REQUEST:
      return { loading: true };
    case ADMIN_REFUND_TRACKING_SUCCESS:
      return {
        loading: false,
        trackResults: action.payload,
      };
    case ADMIN_REFUND_TRACKING_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_REFUND_TRACKING_RESET:
      return { loading: true, codes: [] };
    default:
      return state;
  }
}
