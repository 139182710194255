import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { actionToGetDetailsWholeSaleAccount } from "../actions/actionToGetDetailsWholeSaleAccount";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart } from "../cart/actions/actionToAddOrRemoveWholeSaleItemInCart";

export function useWholeSaleUserInfo(
  accountWithNewDeliveryAddress,
  updatedWholeSaleAccount
) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  // useEffect RE-RUN CONDITIONS
  const reRunUpdatedWholeSaleAccount =
    /*pathname !== "/toptan-firma-bilgileri" &&*/ updatedWholeSaleAccount;
  const reRunAccountWithNewDeliveryAddress =
    pathname !== "/toptan-teslimat-adresleri" && accountWithNewDeliveryAddress;

  // STATE TO HOLD WHOLESALE USER STATUS
  const [isActive, setIsActive] = useState(null);
  const [name, setName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [companyProvince, setCompanyProvince] = useState(null);
  const [companyDistrict, setCompanyDistrict] = useState(null);
  const [companyTaxAuthority, setCompanyTaxAuthority] = useState(null);
  const [companyTaxNumber, setCompanyTaxNumber] = useState(null);
  const [wholeSaleType, setWholeSaleType] = useState(null);
  const [limits, setLimits] = useState(null);
  const [deliveryAddressCount, setDeliveryAddressCount] = useState(0);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState(null);
  const [privatePricing, setPrivatePricing] = useState(null);

  // BRING IN AUTHENTICATION INFO FROM REDUX STORE
  const sendSecurityCodeToSignIn = useSelector(
    (state) => state.sendSecurityCodeToSignIn
  );
  const { authenticatedUser } = sendSecurityCodeToSignIn;

  // BRING IN COMPANY DETAILS (authenticatedUser)
  useEffect(() => {
    if (authenticatedUser) {
      dispatch(actionToGetDetailsWholeSaleAccount(authenticatedUser._id));
    }
  }, [
    dispatch,
    authenticatedUser,
    reRunAccountWithNewDeliveryAddress,
    reRunUpdatedWholeSaleAccount,
  ]);

  const getDetailsWholeSaleAccount = useSelector(
    (state) => state.getDetailsWholeSaleAccount
  );
  const { loading, error, detailsWholeSaleAccount } =
    getDetailsWholeSaleAccount;

  // SET ACCOUNT INFO
  useEffect(() => {
    if (detailsWholeSaleAccount && detailsWholeSaleAccount.reSeller) {
      setIsActive(
        detailsWholeSaleAccount?.reSeller?.isWholeSaleEndUser ||
          detailsWholeSaleAccount?.reSeller?.isWholeSaleReSeller
      );
      setName(detailsWholeSaleAccount.currentAccountContactPersonName);
      setCompanyName(detailsWholeSaleAccount.currentAccountName);
      setCompanyAddress(detailsWholeSaleAccount.currentAccountAddress);
      setCompanyProvince(detailsWholeSaleAccount.currentAccountProvince);
      setCompanyDistrict(detailsWholeSaleAccount.currentAccountDistrict);
      setCompanyTaxAuthority(
        detailsWholeSaleAccount.currentAccountTaxAuthority
      );
      setCompanyTaxNumber(detailsWholeSaleAccount.currentAccountTaxNumber);
      setLimits(detailsWholeSaleAccount?.reSeller?.limits);
      setDeliveryAddressCount(
        detailsWholeSaleAccount?.reSeller?.addresses?.length
      );
      setDeliveryAddresses(detailsWholeSaleAccount?.reSeller?.addresses);
      setAllowedPaymentMethods({
        // isAlloweds
        isCardAllowed:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods.creditCard,
        /////////
        isBankTransferAllowed:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods.bankTransfer,
        /////////
        isBankCheckAllowed:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods.bankCheck
            .isAllowed,
        /////////
        isBillAllowed:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .billOfExchange.isAllowed,
        /////////
        isDueAllowed:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .dueUponInvoice.isAllowed,
        // paymentTerms
        bankCheckTerm:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods.bankCheck
            .paymentTerm,
        /////////
        billTerm:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .billOfExchange.paymentTerm,
        /////////
        dueTerm:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .dueUponInvoice.paymentTerm,
        // downPaymentPercentages
        bankCheckDown:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods.bankCheck
            .downPaymentPercentage,
        /////////
        billDown:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .billOfExchange.downPaymentPercentage,
        /////////
        dueDown:
          detailsWholeSaleAccount?.reSeller?.allowedPaymentMethods
            .dueUponInvoice.downPaymentPercentage,
      });

      // IF THERE IS AN ACCOUNT LEVEL PRICING THAT HAS NOT YET BEEN EXPIRED
      // Define now
      const currentMilliseconds = Date.now();
      // Find the expiry set on the private pricing if exists
      const expiryMiliseconds =
        detailsWholeSaleAccount?.reSeller?.privatePricing?.expiry > 0 &&
        detailsWholeSaleAccount?.reSeller?.privatePricing?.expiry;
      // Calculate the remaining days
      const remainingDays =
        expiryMiliseconds &&
        parseInt((expiryMiliseconds - currentMilliseconds) / 86400000);
      // If expiry is valid create privatePricing obj
      if (currentMilliseconds < expiryMiliseconds) {
        setPrivatePricing({
          remainingDays,
          skus: detailsWholeSaleAccount?.reSeller?.privatePricing.skus,
        });
      }
    }
  }, [detailsWholeSaleAccount]);

  // SET WHOLESALE TYPE
  useEffect(() => {
    if (detailsWholeSaleAccount) {
      const isWholeSaleEndUser = detailsWholeSaleAccount.isWholeSaleEndUser;

      if (isWholeSaleEndUser) {
        setWholeSaleType("wholeSaleEndUser");
      } else {
        setWholeSaleType("wholeSaleReSeller");
      }
    }
  }, [detailsWholeSaleAccount]);

  // SET CART IN SESSION STORAGE
  /* If there are items in user's cart, they are retrieved from user details and saved in both sessionStorage and redux */
  useEffect(() => {
    if (
      detailsWholeSaleAccount &&
      detailsWholeSaleAccount.reSeller &&
      detailsWholeSaleAccount.reSeller.cart?.cartItems.length > 0
    ) {
      const sessionItems = sessionStorage.getItem("wholeSaleCartItems");

      if (!sessionItems) {
        const cart = detailsWholeSaleAccount.reSeller.cart.cartItems;
        cart.forEach((item) => {
          dispatch(addItemToCart(item));
        });

        sessionStorage.setItem(
          "wholeSaleCartItems",
          JSON.stringify(detailsWholeSaleAccount.reSeller.cart.cartItems)
        );
      }
    }
  }, [dispatch, detailsWholeSaleAccount]);

  return {
    authenticatedUser,
    loading,
    error,
    detailsWholeSaleAccount,
    isActive,
    name,
    companyName,
    companyAddress,
    companyProvince,
    companyDistrict,
    companyTaxAuthority,
    companyTaxNumber,
    wholeSaleType,
    limits,
    deliveryAddressCount,
    deliveryAddresses,
    isCardAllowed: allowedPaymentMethods?.isCardAllowed,
    isBankTransferAllowed: allowedPaymentMethods?.isBankTransferAllowed,
    isBankCheckAllowed: allowedPaymentMethods?.isBankCheckAllowed,
    isBillAllowed: allowedPaymentMethods?.isBillAllowed,
    isDueAllowed: allowedPaymentMethods?.isDueAllowed,
    bankCheckTerm: allowedPaymentMethods?.bankCheckTerm,
    billTerm: allowedPaymentMethods?.billTerm,
    dueTerm: allowedPaymentMethods?.dueTerm,
    bankCheckDown: allowedPaymentMethods?.bankCheckDown,
    billDown: allowedPaymentMethods?.billDown,
    dueDown: allowedPaymentMethods?.dueDown,
    privatePricing,
  };
}
