export const USER_LOGOUT = "USER_LOGOUT";
export const USER_AUTHENTICATE_LOGOUT = "USER_AUTHENTICATE_LOGOUT";

export const USER_SMS_CODE_REQUEST = "USER_SMS_CODE_REQUEST";
export const USER_SMS_CODE_SUCCESS = "USER_SMS_CODE_SUCCESS";
export const USER_SMS_CODE_FAIL = "USER_SMS_CODE_FAIL";

export const USER_AUTHENTICATE_REQUEST = "USER_AUTHENTICATE_REQUEST";
export const USER_AUTHENTICATE_SUCCESS = "USER_AUTHENTICATE_SUCCESS";
export const USER_AUTHENTICATE_FAIL = "USER_AUTHENTICATE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

// SET USER SCORE
export const USER_SET_SCORE_REQUEST = "USER_SET_SCORE_REQUEST";
export const USER_SET_SCORE_SUCCESS = "USER_SET_SCORE_SUCCESS";
export const USER_SET_SCORE_FAIL = "USER_SET_SCORE_FAIL";
export const USER_SET_SCORE_RESET = "USER_SET_SCORE_RESET";
