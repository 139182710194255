import React from "react";
import { NavLink } from "react-router-dom";
import SearchBar from "./SearchBar";

export default function ModalNavMenu(props) {
  //PROPS COMING FROM 'NavBar'
  const {
    handleModalMenu,
    logoutHandler,
    modalMenuOpenRef,
    authenticatedUser,
    modalMenuOpen,
    handleHomepageLink,
    bottomBorder,
    setBottomBorder,
  } = props;

  return (
    <div className={modalMenuOpen ? "modal fade-in" : "modal"}>
      <div ref={modalMenuOpenRef} className="modal-front col">
        <div
          onClick={handleModalMenu}
          className="row-right-top mar1 pointer w100p"
        >
          <i className="fas fa-times beddo-color w10p fs1"></i>
        </div>
        <div className="modal-content">
          <SearchBar handleModalMenu={handleModalMenu} />
          <ul className="col-left w90p" onClick={handleModalMenu}>
            <li
              onClick={handleHomepageLink}
              className={
                bottomBorder
                  ? "pupdown w100p lh2 row-nowrap fs125 pointer alternative-navlinkactive-effect"
                  : "pupdown w100p lh2 row-nowrap fs125 pointer"
              }
            >
              Anasayfa <i className="fas fa-chevron-right footer-chevron"></i>
            </li>
            <NavLink
              exact
              key="2"
              activeClassName="nav-links-active"
              className="nav-links"
              to="/hakkimizda"
              onClick={() => setBottomBorder(false)}
            >
              <li className="pupdown  lh2 row-nowrap">
                Hakkımızda
                <i className="fas fa-chevron-right footer-chevron"></i>
              </li>
            </NavLink>

            <NavLink
              exact
              key="3"
              activeClassName="nav-links-active"
              className="nav-links"
              to="/iletisim"
              onClick={() => setBottomBorder(false)}
            >
              <li className="pupdown  lh2 row-nowrap">
                İletişim <i className="fas fa-chevron-right footer-chevron"></i>
              </li>
            </NavLink>

            {authenticatedUser && (
              <>
                <NavLink
                  exact
                  key="5"
                  activeClassName="nav-links-active"
                  className="nav-links"
                  to="/profilim"
                  onClick={() => setBottomBorder(false)}
                >
                  <li className="pupdown lh2 row-nowrap">
                    Profilim
                    <i className="fas fa-chevron-right footer-chevron"></i>
                  </li>
                </NavLink>

                <NavLink
                  exact
                  key="6"
                  activeClassName="nav-links-active"
                  className="nav-links"
                  to="/siparislerim"
                  onClick={() => setBottomBorder(false)}
                >
                  <li className="pupdown  lh2 row-nowrap">
                    Siparişlerim
                    <i className="fas fa-chevron-right footer-chevron"></i>
                  </li>
                </NavLink>

                <NavLink
                  exact
                  key="7"
                  activeClassName="nav-links-active"
                  className="nav-links"
                  to="/destek"
                  onClick={() => setBottomBorder(false)}
                >
                  <li className="pupdown lh2 row-nowrap">
                    Destek
                    <i className="fas fa-chevron-right footer-chevron"></i>
                  </li>
                </NavLink>
              </>
            )}

            {!authenticatedUser ? (
              <NavLink
                exact
                key="8"
                activeClassName="nav-links-active"
                className="nav-links"
                to="/musteri-hizmetleri-giris"
                onClick={() => setBottomBorder(false)}
              >
                <li className="pupdown lh2 row-nowrap">
                  Müşteri Hizmetleri
                  <i className="fas fa-chevron-right footer-chevron"></i>
                </li>
              </NavLink>
            ) : (
              <NavLink
                exact
                key="8"
                activeClassName="nav-links-active"
                className="nav-links"
                to="/musteri-hizmetleri-giris"
                onClick={() => setBottomBorder(false)}
              >
                <li className="pupdown lh2 row-nowrap">
                  Müşteri Hizmetleri
                  <i className="fas fa-chevron-right footer-chevron"></i>
                </li>
              </NavLink>
            )}

            <li className="pupdown lh2 row-nowrap">
              {authenticatedUser ? (
                <NavLink
                  exact
                  key="9"
                  activeClassName="nav-links-active"
                  className="nav-links"
                  to="/musteri-hizmetleri-giris"
                  onClick={logoutHandler}
                >
                  <i className="fas fa-sign-out-alt fa-flip-horizontal"></i>
                  Çıkış
                </NavLink>
              ) : (
                ""
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
