import React from "react";
import { Link } from "react-router-dom";
import MessageBox from "../modules/shared/components/MessageBox";

export default function SearchResultsInfo(props) {
  // PROPS COMING FROM "HomeScreen"
  const { products } = props;

  return (
    <>
      {products.length > 0 ? (
        <div className="w100p">
          <MessageBox variant="success">
            <h2 className="h2c">{products.length} Ürün Bulundu</h2>
          </MessageBox>
          <h3 className="h3c">
            <Link to="/">Anasayafa Dön</Link>
          </h3>
        </div>
      ) : (
        <div className="w100p">
          <MessageBox variant="danger">
            <h2 className="h2c">Hiç ürün bulunamadı</h2>
          </MessageBox>
          <h3 className="h3c">
            <Link to="/">Anasayafa Dön</Link>
          </h3>
        </div>
      )}
    </>
  );
}
