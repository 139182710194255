//ADMIN CREATE REFUND CODE
export const ADMIN_REFUNDCODE_REQUEST = "ADMIN_REFUNDCODE_REQUEST";
export const ADMIN_REFUNDCODE_SUCCESS = "ADMIN_REFUNDCODE_SUCCESS";
export const ADMIN_REFUNDCODE_FAIL = "ADMIN_REFUNDCODE_FAIL";
export const ADMIN_REFUNDCODE_RESET = "ADMIN_REFUNDCODE_RESET";

//ADMIN LIST REFUND CODES
export const ADMIN_LIST_REFUNDCODES_REQUEST = "ADMIN_LIST_REFUNDCODES_REQUEST";
export const ADMIN_LIST_REFUNDCODES_SUCCESS = "ADMIN_LIST_REFUNDCODES_SUCCESS";
export const ADMIN_LIST_REFUNDCODES_FAIL = "ADMIN_LIST_REFUNDCODES_FAIL";
export const ADMIN_LIST_REFUNDCODES_RESET = "ADMIN_LIST_REFUNDCODES_RESET";

//ADMIN GET TRACKING CODES BY REFUND CODE QUERY
export const ADMIN_REFUND_TRACKINGCODE_REQUEST =
  "ADMIN_REFUND_TRACKINGCODE_REQUEST";
export const ADMIN_REFUND_TRACKINGCODE_SUCCESS =
  "ADMIN_REFUND_TRACKINGCODE_SUCCESS";
export const ADMIN_REFUND_TRACKINGCODE_FAIL = "ADMIN_REFUND_TRACKINGCODE_FAIL";
export const ADMIN_REFUND_TRACKINGCODE_RESET =
  "ADMIN_REFUND_TRACKINGCODE_RESET";

//ADMIN TRACK TRACKING CODES
export const ADMIN_REFUND_TRACKING_REQUEST = "ADMIN_REFUND_TRACKING_REQUEST";
export const ADMIN_REFUND_TRACKING_SUCCESS = "ADMIN_REFUND_TRACKING_SUCCESS";
export const ADMIN_REFUND_TRACKING_FAIL = "ADMIN_REFUND_TRACKING_FAIL";
export const ADMIN_REFUND_TRACKING_RESET = "ADMIN_REFUND_TRACKING_RESET";
