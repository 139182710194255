import { useEffect, useState } from "react";
import { getFbBrowserId } from "../libraries/ercLibrary";

// DETECT FACEBOOK BROWSER ID(_fbp cookie)
export default function useFbp() {
  // DETECT AN _fbp IF ANY
  const [fbp, setFbp] = useState("");
  useEffect(() => {
    const browserID = getFbBrowserId();
    if (browserID) {
      setFbp(browserID);
    }
  }, []);
  return fbp;
}
