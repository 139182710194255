// @desc List notifications
export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAIL = "NOTIFICATION_LIST_FAIL";
export const NOTIFICATION_LIST_RESET = "NOTIFICATION_LIST_RESET";

// @desc Update notifications
export const NOTIFICATION_STATUS_UPDATE_REQUEST =
  "NOTIFICATION_STATUS_UPDATE_REQUEST";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAIL =
  "NOTIFICATION_STATUS_UPDATE_FAIL";
export const NOTIFICATION_STATUS_UPDATE_RESET =
  "NOTIFICATION_STATUS_UPDATE_RESET";
