import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetProductsList = createAsyncThunk(
  "wholeSale/jobInActionToGetProductsList",
  async (_, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get("/api/wholesale/getProductsList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  products: [],
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getProductsListSlice = createSlice({
  name: "getDetailsWholeSaleAccount",
  initialState,
  reducers: {
    resetGetProductsList: (state) => {
      state.products = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetProductsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToGetProductsList.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(actionToGetProductsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetGetProductsList } = getProductsListSlice.actions;

// Export the reducer
const reducerToGetProductsList = getProductsListSlice.reducer;

// Export the async action creator for use in components
export { reducerToGetProductsList, resetGetProductsList };
