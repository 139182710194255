import React, { useEffect } from "react";
import CartSummary from "./CartSummary";
import ThresholdDisplayPurchase from "./ThresholdDisplayPurchase";
import ReturnShoppingOrCheckOut from "./ReturnShoppingOrCheckOut";
import { useCartMessagesToUser } from "../hooks/useCartMessagesToUser";
import ThresholdDisplayFreeGoods from "./ThresholdDisplayFreeGoods";
import { useOrderLimitThresholds } from "../hooks/useOrderLimitThresholds";

export default function CartFooter(props) {
  // PROPS COMING FROM NavigationTop && NavigationBottom
  const {
    wholeSaleCartItems,
    setCloseOnClick,
    freeGoodsAllocationActive,
    orderNumber,
    limits,
    cartTotalVatExcluded,
    privatePricing,
  } = props;

  // CHECK LIMIT THRESHOLDS
  const { isMinOrderLimitReached, levelPercentages } = useOrderLimitThresholds(
    limits,
    cartTotalVatExcluded
  );
  // CREATE MESSAGES BASED ON LIMITS
  const { cartMessagesToUser } = useCartMessagesToUser(
    limits,
    cartTotalVatExcluded,
    freeGoodsAllocationActive
  );

  const styling = {
    paddingTop: "1rem",
    width: "96%",
    height: "13rem",
    position: "absolute",
    left: "8px",
    bottom: "10px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  };

  // RESET closeOnClick WHEN COMPONENT UNMOUNT
  useEffect(() => {
    return () => setCloseOnClick(false);
  }, [setCloseOnClick]);

  return (
    <>
      {wholeSaleCartItems.length > 0 && (
        <div style={styling}>
          <CartSummary
            freeGoodsAllocationActive={freeGoodsAllocationActive}
            privatePricing={privatePricing}
          />
          {freeGoodsAllocationActive > 0 ? (
            <ThresholdDisplayFreeGoods
              cartTotalVatExcluded={cartTotalVatExcluded}
              freeGoodsAllocationActive={freeGoodsAllocationActive}
              cartMessagesToUser={cartMessagesToUser}
            />
          ) : (
            <ThresholdDisplayPurchase
              limits={limits}
              cartTotalVatExcluded={cartTotalVatExcluded}
              cartMessagesToUser={cartMessagesToUser}
              levelPercentages={levelPercentages}
            />
          )}
          <ReturnShoppingOrCheckOut
            setCloseOnClick={setCloseOnClick}
            isMinOrderLimitReached={isMinOrderLimitReached}
            freeGoodsAllocationActive={freeGoodsAllocationActive}
            orderNumber={orderNumber}
          />
        </div>
      )}
    </>
  );
}
