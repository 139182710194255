import {
  ADMIN_CREATE_DISCOUNT_FAIL,
  ADMIN_CREATE_DISCOUNT_REQUEST,
  ADMIN_CREATE_DISCOUNT_RESET,
  ADMIN_CREATE_DISCOUNT_SUCCESS,
  ADMIN_DETAILS_DISCOUNT_FAIL,
  ADMIN_DETAILS_DISCOUNT_REQUEST,
  ADMIN_DETAILS_DISCOUNT_SUCCESS,
  ADMIN_EDIT_DISCOUNT_FAIL,
  ADMIN_EDIT_DISCOUNT_REQUEST,
  ADMIN_EDIT_DISCOUNT_RESET,
  ADMIN_EDIT_DISCOUNT_SUCCESS,
  ADMIN_LIST_DISCOUNTS_FAIL,
  ADMIN_LIST_DISCOUNTS_REQUEST,
  ADMIN_LIST_DISCOUNTS_SUCCESS,
} from "../constants/adminDiscountCodeConstants";

// CREATE A DISCOUNT CODE
export const reducerAdminCreateDiscountCode = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_CREATE_DISCOUNT_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_DISCOUNT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        discountCode: action.payload,
      };
    case ADMIN_CREATE_DISCOUNT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_DISCOUNT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// LIST DISCOUNT CODES
export const reducerAdminListDiscountCodes = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_LIST_DISCOUNTS_REQUEST:
      return { loading: true };
    case ADMIN_LIST_DISCOUNTS_SUCCESS:
      return {
        loading: false,
        discountCodeList: action.payload.discountCodes,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_LIST_DISCOUNTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET DETAILS OF DISCOUNT CODES
export const reducerAdminGetDetailsOfDiscountCode = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_DETAILS_DISCOUNT_REQUEST:
      return { loading: true };
    case ADMIN_DETAILS_DISCOUNT_SUCCESS:
      return {
        loading: false,
        detailsOfDiscountCode: action.payload,
      };
    case ADMIN_DETAILS_DISCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE/EDIT DISCOUNT CODE
export function reducerAdminEditDiscountCode(
  state = { loading: false },
  action
) {
  switch (action.type) {
    case ADMIN_EDIT_DISCOUNT_REQUEST:
      return { loading: true };
    case ADMIN_EDIT_DISCOUNT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_EDIT_DISCOUNT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_EDIT_DISCOUNT_RESET:
      return { ...state, success: "", error: "", loading: false };
    default:
      return state;
  }
}
