import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RatingOnHover from "./RatingOnHover";
import RatingOnHoverJr from "./RatingOnHoverJr";

export default function Rating(props) {
  const location = useLocation();

  // PROPS COMING FROM ProductScreen AND HomeScreen Product Component AND ProductReviewsScreen ANA QnaScreen
  const { value, text, reviews, hover } = props;

  // HANDLE MOUSE OVER
  const [displayHover, setDisplayHover] = useState(false);
  function handleMouseOver() {
    setDisplayHover(true);
  }
  function handleMouseLeave() {
    setDisplayHover(false);
  }

  // CHECK LOCATION
  const [isProductPage, setIsProductPage] = useState(false);
  const [isProducrReviewsPage, setIsProductReviewsPage] = useState(false);
  const [isQnaPage, setIsQnaPage] = useState(false);
  useEffect(() => {
    if (location.pathname.includes("/urun/")) {
      setIsProductPage(true);
    }
    if (location.pathname.includes("/yorumlar/")) {
      setIsProductReviewsPage(true);
    }
    if (location.pathname.includes("/sorucevap/")) {
      setIsQnaPage(true);
    }
  }, [location]);

  return (
    <div
      className="rating fs1"
      onMouseOver={() => handleMouseOver()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {displayHover &&
      hover &&
      (isProductPage || isProducrReviewsPage || isQnaPage) ? (
        <RatingOnHover reviews={reviews} value={value.toFixed(1)} /> // DETAILED RATING BOARD ON PRODUCT DETAIL PAGE
      ) : (
        displayHover && <RatingOnHoverJr value={value.toFixed(1)} /> // SMALL RATING DISPLAY ON CARDS IN HOMEPAGE
      )}
      <span>
        <i
          className={
            value >= 1
              ? "fa fa-star"
              : value >= 0.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
          style={{ color: "#f0c040" }}
        ></i>
      </span>
      <span>
        <i
          className={
            value >= 2
              ? "fa fa-star"
              : value >= 1.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
          style={{ color: "#f0c040" }}
        ></i>
      </span>
      <span>
        <i
          className={
            value >= 3
              ? "fa fa-star"
              : value >= 2.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
          style={{ color: "#f0c040" }}
        ></i>
      </span>
      <span>
        <i
          className={
            value >= 4
              ? "fa fa-star"
              : value >= 3.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
          style={{ color: "#f0c040" }}
        ></i>
      </span>
      <span>
        <i
          className={
            value >= 5
              ? "fa fa-star"
              : value >= 4.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
          style={{ color: "#f0c040" }}
        ></i>
      </span>
      <span className="rating-text">{text && text}</span>
    </div>
  );
}
