import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CART_EMPTY_SKU } from "../constants/cartConstants";

export function useUpdateCartToClearIfFreeItemLeftAlone(cartSkuItems) {
  const dispatch = useDispatch();

  // IN CASE THAT FREE PRODUCT LEFT ALONE IN THE CART, THIS PIECE OF CODE DELETES IT
  // (IF SAME SKU ADDED IN CART IT MAY CAUSE FREE PRODUCT LEFT ALONE IN CART AFTER REMOVING SOME CHOICES FROM CART)
  useEffect(() => {
    if (cartSkuItems?.length === 1 && cartSkuItems[0].price === 0) {
      dispatch({ type: CART_EMPTY_SKU });
    }
  }, [dispatch, cartSkuItems]);
}
