import {
  ADMIN_EXPENSE_SLIP_PRINTED_FAIL,
  ADMIN_EXPENSE_SLIP_PRINTED_REQUEST,
  ADMIN_EXPENSE_SLIP_PRINTED_RESET,
  ADMIN_EXPENSE_SLIP_PRINTED_SUCCESS,
  ADMIN_E_DOCUMENT_DETAILS_FAIL,
  ADMIN_E_DOCUMENT_DETAILS_REQUEST,
  ADMIN_E_DOCUMENT_DETAILS_SUCCESS,
  ADMIN_INVOICES_CREATE_FAIL,
  ADMIN_INVOICES_CREATE_REQUEST,
  ADMIN_INVOICES_CREATE_SUCCESS,
  ADMIN_INVOICE_CANCEL_FAIL,
  ADMIN_INVOICE_CANCEL_REQUEST,
  ADMIN_INVOICE_CANCEL_RESET,
  ADMIN_INVOICE_CANCEL_SUCCESS,
  ADMIN_INVOICE_CREATE_FAIL,
  ADMIN_INVOICE_CREATE_REQUEST,
  ADMIN_INVOICE_CREATE_SUCCESS,
  ADMIN_INVOICE_DETAILS_FAIL,
  ADMIN_INVOICE_DETAILS_REQUEST,
  ADMIN_INVOICE_DETAILS_SUCCESS,
} from "../constants/adminInvoiceConstants";

//SAVE THE DETAILS OF INVOICE IN REDUX STATE
export function reducerAdminToGetInvoiceDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_INVOICE_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_INVOICE_DETAILS_SUCCESS:
      return {
        loading: false,
        parasutInvoiceDetails: action.payload,
      };
    case ADMIN_INVOICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF E-DOCUMENT IN REDUX STATE
export function reducerAdminToGetEDocumentDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_E_DOCUMENT_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_E_DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        parasutEDocumentDetails: action.payload,
      };
    case ADMIN_E_DOCUMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF E-DOCUMENT IN REDUX STATE
export function reducerAdminToRunInvoiceCreateSquence(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_INVOICES_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_INVOICES_CREATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case ADMIN_INVOICES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//CANCEL INVOICE
export function reducerAdminToCancelInvoice(state = { loading: true }, action) {
  switch (action.type) {
    case ADMIN_INVOICE_CANCEL_REQUEST:
      return { loading: true };
    case ADMIN_INVOICE_CANCEL_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case ADMIN_INVOICE_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_INVOICE_CANCEL_RESET:
      return {};
    default:
      return state;
  }
}

// SET INVOICE STATUS "Expense slip issued" AFTER PRINTING
export function reducerAdminToSetExpenseSlipIssued(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_EXPENSE_SLIP_PRINTED_REQUEST:
      return { loading: true };
    case ADMIN_EXPENSE_SLIP_PRINTED_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_EXPENSE_SLIP_PRINTED_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_EXPENSE_SLIP_PRINTED_RESET:
      return {};
    default:
      return state;
  }
}

// ADMIN RUN CREATE SINGLE INVOICE
export function reducerAdminToCreateSingleInvoice(
  state = { loading: false },
  action
) {
  switch (action.type) {
    case ADMIN_INVOICE_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_INVOICE_CREATE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_INVOICE_CREATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
