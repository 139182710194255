import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRouteWholeSaleUser({
  component: Component,
  render,
  ...rest
}) {
  //REDUX STATE -  USER LOGIN
  const sendSecurityCodeToSignIn = useSelector(
    (state) => state.sendSecurityCodeToSignIn
  );
  const { authenticatedUser } = sendSecurityCodeToSignIn;

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticatedUser ? (
          render ? (
            render(props)
          ) : (
            <Component {...props}></Component>
          )
        ) : (
          <Redirect to="/toptan-giris" />
        )
      }
    />
  );
}
