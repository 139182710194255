import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionAdminToUpdateWholeSaleOrderPrintStatus = createAsyncThunk(
  "wholeSale/adminUpdateWholeSaleOrderPrintStatus",
  async ({ orderId }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/adminUpdateWholeSaleOrderPrintStatus",
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  success: false,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleOrderPrintStatusSlice = createSlice({
  name: "updateWholeSaleOrderPrintStatus",
  initialState,
  reducers: {
    adminResetUpdateWholeSaleOrderPrintStatus: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        actionAdminToUpdateWholeSaleOrderPrintStatus.pending,
        (state) => {
          state.loading = true;
        }
      )
      .addCase(
        actionAdminToUpdateWholeSaleOrderPrintStatus.fulfilled,
        (state, action) => {
          state.loading = false;
          state.success = action.payload.success;
        }
      )
      .addCase(
        actionAdminToUpdateWholeSaleOrderPrintStatus.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { adminResetUpdateWholeSaleOrderPrintStatus } =
  updateWholeSaleOrderPrintStatusSlice.actions;

// Export the reducer
const reducerAdminToUpdateWholeSaleOrderPrintStatus =
  updateWholeSaleOrderPrintStatusSlice.reducer;

// Export the async action creator for use in components
export {
  reducerAdminToUpdateWholeSaleOrderPrintStatus,
  adminResetUpdateWholeSaleOrderPrintStatus,
};
