// slices/userIdentifierSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

// Async action to get last months sales
export const actionToBringInLastMonthsSales = createAsyncThunk(
  "orders/bringInLastMonthsSales",
  async ({ productId, lastMonth }, thunkAPI) => {
    try {
      // Call your API function here to get the last months sales
      const { data } = await axios.get(
        "/api/lastMonthSales/bringInLastMonthsSales",
        { params: { productId, lastMonth } }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      // Handle error scenarios, for example, show a notification
      // You can also reject the thunk with an error message here
      const { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

const bringInLastMonthsSalesSlice = createSlice({
  name: "bringInLastMonthsSales",
  initialState: {
    lastMonthsTotalSale: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToBringInLastMonthsSales.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToBringInLastMonthsSales.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.lastMonthsTotalSale = action.payload.count; // Update the extraDiscountShown property
      })
      .addCase(actionToBringInLastMonthsSales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store the error message for display, if needed
      });
  },
});

const reducerToBringInLastMonthsSales = bringInLastMonthsSalesSlice.reducer;

export default reducerToBringInLastMonthsSales;
