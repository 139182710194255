import React from "react";
import { Link } from "react-router-dom";
import { convertToUrl } from "../libraries/ercLibrary";
import Rating from "./Rating";
import "./Product.css";
import { useDispatch } from "react-redux";
import { PRODUCT_DETAILS_CAMPAIGNED_RESET } from "../constants/productConstants";
import useWidth from "../hooks/useWidth";
import useGetLimitedTimeDiscountInfo from "../hooks/useGetLimitedTimeDiscountInfo";
import { calculateDisplayedPrice } from "../utils/calculateDisplayedPrice";

export default function Product(props) {
  const dispatch = useDispatch();
  const width = useWidth();

  // PROPS COMING FROM HomeScreen
  const {
    product,
    cartSkuItems,
    isPending,
    createdFingerPrint,
    lastElementRef,
  } = props;

  // GET LIMITED TIME DISCOUNT INFO
  const { isDiscountValid } = useGetLimitedTimeDiscountInfo(
    product,
    createdFingerPrint
  );

  // RESET CAMPAIGNED PRODUCT DETAILS REDUX
  function handleResetCampaignedProduct() {
    dispatch({ type: PRODUCT_DETAILS_CAMPAIGNED_RESET });
  }

  return (
    <>
      <div
        className="w100p"
        onClick={() => {
          handleResetCampaignedProduct();
          sessionStorage.setItem("scrollId", product._id);
        }}
      >
        <div>
          <Link
            className="col-jus"
            to={`/urun/${convertToUrl(product.name)}/${product._id}`}
          >
            <img
              className="w90p rad5r"
              src={product.defaultImages[0]}
              alt={product.name}
            />
          </Link>
        </div>
        <div
          title={product.name.length > 60 ? product.name : ""}
          className="product-name"
        >
          <Link
            className="nodeco black"
            to={`/urun/${convertToUrl(product.name)}/${product._id}`}
          >
            <h2 className="h2c">
              {width > 600 && product.name.length > 60
                ? `${product.name.slice(0, 61)}...`
                : product.name}
            </h2>
          </Link>
        </div>
        <div className="col">
          <div className="rating">
            <Rating value={product.rating} text={` (${product.numReviews})`} />
          </div>
          <div className="price">
            {isPending?.isTherePending === "YES" ? (
              <>
                <strike className="fs15 marginright0-25r red-col">
                  {calculateDisplayedPrice(product.defaultPrice)}{" "}
                </strike>
                <span>
                  {calculateDisplayedPrice(product.defaultPriceGap)} TL
                </span>
              </>
            ) : cartSkuItems.length >= 1 && isDiscountValid ? (
              <>
                <strike className="fs15 marginright0-25r red-col">
                  {calculateDisplayedPrice(product.defaultPrice)} TL
                </strike>
                <span>
                  {calculateDisplayedPrice(product.defaultPriceGap)} TL
                </span>
              </>
            ) : (
              <span>{calculateDisplayedPrice(product.defaultPrice)} TL</span>
            )}
          </div>
          {product.defaultCountInStock ||
          Array.from(product.skus.map((sku) => sku.countInStock)).reduce(
            (a, c) => a + c,
            0
          ) > 0 ? (
            <div className="countinstock">
              <span className="success">Stokta var</span>
            </div>
          ) : (
            <div className="countinstock">
              <span className="danger">Stokta yok</span>
            </div>
          )}
          <div className="w100p txtcenter" ref={lastElementRef}>
            <Link to={`/urun/${convertToUrl(product.name)}/${product._id}`}>
              <button className="primary w95p" type="button">
                <h3 className="h3c">Şimdi İncele</h3>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
