import {
  ADMIN_PARASUT_PRODUCTS_FAIL,
  ADMIN_PARASUT_PRODUCTS_REQUEST,
  ADMIN_PARASUT_PRODUCTS_RESET,
  ADMIN_PARASUT_PRODUCTS_SUCCESS,
  ADMIN_PARASUT_STOCK_COUNT_FAIL,
  ADMIN_PARASUT_STOCK_COUNT_REQUEST,
  ADMIN_PARASUT_STOCK_COUNT_RESET,
  ADMIN_PARASUT_STOCK_COUNT_SUCCESS,
  ADMIN_SEND_PRODUCT_TO_PARASUT_FAIL,
  ADMIN_SEND_PRODUCT_TO_PARASUT_REQUEST,
  ADMIN_SEND_PRODUCT_TO_PARASUT_RESET,
  ADMIN_SEND_PRODUCT_TO_PARASUT_SUCCESS,
} from "../constants/adminParasutConstants";

// ADMIN CREATE PARASUT PRODUCT
export function reducerAdminToCreateParasutProduct(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_SEND_PRODUCT_TO_PARASUT_REQUEST:
      return { loading: true };
    case ADMIN_SEND_PRODUCT_TO_PARASUT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_SEND_PRODUCT_TO_PARASUT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SEND_PRODUCT_TO_PARASUT_RESET:
      return {};
    default:
      return state;
  }
}

// ADMIN GET PARASUT STOCK COUNT
export function reducerAdminToRequestParasutStockCount(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_PARASUT_STOCK_COUNT_REQUEST:
      return { loading: true };
    case ADMIN_PARASUT_STOCK_COUNT_SUCCESS:
      return {
        loading: false,
        stockCount: action.payload,
      };
    case ADMIN_PARASUT_STOCK_COUNT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PARASUT_STOCK_COUNT_RESET:
      return {};
    default:
      return state;
  }
}

// ADMIN GET PRODUCTS
export function reducerAdminToRequestParasutProducts(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_PARASUT_PRODUCTS_REQUEST:
      return { loading: true };
    case ADMIN_PARASUT_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case ADMIN_PARASUT_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PARASUT_PRODUCTS_RESET:
      return {};
    default:
      return state;
  }
}
