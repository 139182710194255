import React from "react";

export default function LoadingBoxWithoutText(props) {
  const { unStyled } = props;

  return (
    <div className={unStyled ? "loading" : "loading h3c"}>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  );
}
