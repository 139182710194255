import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import useFbc from "../../hooks/useFbc";
import useFbp from "../../hooks/useFbp";
import { getClientIp } from "../../libraries/ercLibrary";

export default function DetectFBCookie() {
  const location = useLocation();
  const userAgent = new UAParser().getUA();
  const fbp = useFbp();
  const fbc = useFbc();

  // SAVE IP IN STATE
  const [ip, setIp] = useState("");
  useEffect(() => {
    if (!ip && !location.pathname.includes("admin-panel")) {
      getClientIp().then((response) => setIp(response));
    }
  }, [ip, location]);

  // POST DATA TO SERVER IF ANY
  useEffect(() => {
    if ((fbp || fbc) && ip && !location.pathname.includes("admin-panel")) {
      const date = Date.now();
      // FACEBOOK PIXEL BASED EVENT TRIGGER
      window.fbq("track", "PageView", { eventID: date });
      // POST CompleteRegistration EVENT
      axios.post("/api/facebookCapi/PageView", {
        country: "TR",
        userAgent,
        fbp,
        fbc,
        ip,
        location: location.pathname,
        eventId: date,
      });
    } else {
      // FACEBOOK PIXEL BASED EVENT TRIGGER
      window.fbq("track", "PageView");
    }
  }, [fbp, fbc, location, userAgent, ip]);

  return <div></div>;
}
