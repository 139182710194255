import {
  // ADMIN_DELETE_RECIPE_ITEM_FAIL,
  // ADMIN_DELETE_RECIPE_ITEM_REQUEST,
  // ADMIN_DELETE_RECIPE_ITEM_RESET,
  // ADMIN_DELETE_RECIPE_ITEM_SUCCESS,
  // ADMIN_ORDER_CONVERTER_APPEAL_FAIL,
  // ADMIN_ORDER_CONVERTER_APPEAL_REQUEST,
  // ADMIN_ORDER_CONVERTER_APPEAL_SUCCESS,
  ADMIN_PRODUCT_ACTIVE_FAIL,
  ADMIN_PRODUCT_ACTIVE_REQUEST,
  ADMIN_PRODUCT_ACTIVE_RESET,
  ADMIN_PRODUCT_ACTIVE_SUCCESS,
  ADMIN_PRODUCT_BULK_UPDATE_FAIL,
  ADMIN_PRODUCT_BULK_UPDATE_REQUEST,
  ADMIN_PRODUCT_BULK_UPDATE_RESET,
  ADMIN_PRODUCT_BULK_UPDATE_SUCCESS,
  ADMIN_PRODUCT_CREATE_FAIL,
  ADMIN_PRODUCT_CREATE_REQUEST,
  ADMIN_PRODUCT_CREATE_RESET,
  ADMIN_PRODUCT_CREATE_SUCCESS,
  ADMIN_PRODUCT_DELETE_FAIL,
  ADMIN_PRODUCT_DELETE_REQUEST,
  ADMIN_PRODUCT_DELETE_RESET,
  ADMIN_PRODUCT_DELETE_SUCCESS,
  ADMIN_PRODUCT_DETAILS_FAIL,
  ADMIN_PRODUCT_DETAILS_REQUEST,
  ADMIN_PRODUCT_DETAILS_RESET,
  ADMIN_PRODUCT_DETAILS_SUCCESS,
  ADMIN_PRODUCT_EDIT_FAIL,
  ADMIN_PRODUCT_EDIT_REQUEST,
  ADMIN_PRODUCT_EDIT_RESET,
  ADMIN_PRODUCT_EDIT_SUCCESS,
  ADMIN_PRODUCT_LIST_FAIL,
  ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_FAIL,
  ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_REQUEST,
  ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_SUCCESS,
  ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_FAIL,
  ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_REQUEST,
  ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_SUCCESS,
  ADMIN_PRODUCT_LIST_REQUEST,
  ADMIN_PRODUCT_LIST_SUCCESS,
  ADMIN_PRODUCT_SKU_CREATE_FAIL,
  ADMIN_PRODUCT_SKU_CREATE_REQUEST,
  ADMIN_PRODUCT_SKU_CREATE_RESET,
  ADMIN_PRODUCT_SKU_CREATE_SUCCESS,
  ADMIN_PRODUCT_SKU_DETAILS_FAIL,
  ADMIN_PRODUCT_SKU_DETAILS_REQUEST,
  ADMIN_PRODUCT_SKU_DETAILS_RESET,
  ADMIN_PRODUCT_SKU_DETAILS_SUCCESS,
  ADMIN_PRODUCT_SKU_EDIT_FAIL,
  ADMIN_PRODUCT_SKU_EDIT_REQUEST,
  ADMIN_PRODUCT_SKU_EDIT_RESET,
  ADMIN_PRODUCT_SKU_EDIT_SUCCESS,
  ADMIN_PRODUCT_SKU_LIST_FAIL,
  ADMIN_PRODUCT_SKU_LIST_REQUEST,
  ADMIN_PRODUCT_SKU_LIST_SUCCESS,
  // ADMIN_RECIPE_COST_FAIL,
  // ADMIN_RECIPE_COST_REQUEST,
  // ADMIN_RECIPE_COST_RESET,
  // ADMIN_RECIPE_COST_SUCCESS,
  // ADMIN_RECIPE_CREATE_FAIL,
  // ADMIN_RECIPE_CREATE_REQUEST,
  // ADMIN_RECIPE_CREATE_RESET,
  // ADMIN_RECIPE_CREATE_SUCCESS,
  // ADMIN_RECIPE_ITEM_DETAIL_FAIL,
  // ADMIN_RECIPE_ITEM_DETAIL_REQUEST,
  // ADMIN_RECIPE_ITEM_DETAIL_RESET,
  // ADMIN_RECIPE_ITEM_DETAIL_SUCCESS,
  ADMIN_SECTION_DELETE_FAIL,
  ADMIN_SECTION_DELETE_REQUEST,
  ADMIN_SECTION_DELETE_RESET,
  ADMIN_SECTION_DELETE_SUCCESS,
  ADMIN_SECTION_DETAILS_FAIL,
  ADMIN_SECTION_DETAILS_REQUEST,
  ADMIN_SECTION_DETAILS_RESET,
  ADMIN_SECTION_DETAILS_SUCCESS,
  // ADMIN_UPDATE_RECIPE_ITEM_FAIL,
  // ADMIN_UPDATE_RECIPE_ITEM_REQUEST,
  // ADMIN_UPDATE_RECIPE_ITEM_RESET,
  // ADMIN_UPDATE_RECIPE_ITEM_SUCCESS,
  ADMIN_UPDATE_SHIPPING_MESSAGE_FAIL,
  ADMIN_UPDATE_SHIPPING_MESSAGE_REQUEST,
  ADMIN_UPDATE_SHIPPING_MESSAGE_RESET,
  ADMIN_UPDATE_SHIPPING_MESSAGE_SUCCESS,
} from "../constants/adminProductConstants";

//SAVE THE CREATED PRODUCT IN REDUX STATE
export const reducerAdminProductToCreateProduct = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        product: action.payload,
      };
    case ADMIN_PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE CREATED PRODUCT IN REDUX STATE
export const reducerAdminProductToCreateSku = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_SKU_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_SKU_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
        sku: action.payload,
      };
    case ADMIN_PRODUCT_SKU_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_SKU_CREATE_RESET:
      return { success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF SKUS IN REDUX STATE
export const reducerAdminProductToGetSkuList = (
  state = { loading: true, skus: [] },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_SKU_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_PRODUCT_SKU_LIST_SUCCESS:
      return { loading: false, skus: action.payload.skus };
    case ADMIN_PRODUCT_SKU_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//SAVE THE LIST OF PRODUCTS IN REDUX STATE
export const reducerAdminProductToGetProductList = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//SAVE THE DETAILS OF PRODUCT IN REDUX STATE
export const reducerAdminProductToGetProductDetails = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_DETAILS_SUCCESS:
      return { loading: false, productDetails: action.payload };
    case ADMIN_PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE ACTIVE PRODUCT IN REDUX STATE
export const reducerAdminProductToActiveProduct = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_ACTIVE_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_ACTIVE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        active: action.payload,
      };
    case ADMIN_PRODUCT_ACTIVE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_ACTIVE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE EDITTED DETAILS OF PRODUCT IN REDUX STATE
export function reducerAdminProductToEditProduct(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_PRODUCT_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_PRODUCT_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF PRODUCT SKU IN REDUX STATE
export const reducerAdminProductToGetSkuDetails = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_SKU_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_SKU_DETAILS_SUCCESS:
      return { loading: false, skuDetails: action.payload };
    case ADMIN_PRODUCT_SKU_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_SKU_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE EDITTED DETAILS OF PRODUCT SKU IN REDUX STATE
export function reducerAdminProductToEditSku(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_PRODUCT_SKU_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_SKU_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_PRODUCT_SKU_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_SKU_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF PRODUCT IN REDUX STATE
export function reducerAdminProductToDeleteProduct(state = {}, action) {
  switch (action.type) {
    case ADMIN_PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE THE DETAILS OF SECTION IN REDUX STATE
export const reducerAdminProductToGetSectionDetails = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_SECTION_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_SECTION_DETAILS_SUCCESS:
      return {
        loading: false,
        sectionDetails: action.payload,
      };
    case ADMIN_SECTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SECTION_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE DELETE STATUS OF SECTION IN REDUX STATE
export function reducerAdminProductToDeleteSection(state = {}, action) {
  switch (action.type) {
    case ADMIN_SECTION_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SECTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_SECTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_SECTION_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE THE CREATED RECIPE OF PRODUCT IN REDUX STATE
/*export function reducerAdminToCreateRecipe(state = { success: false }, action) {
  switch (action.type) {
    case ADMIN_RECIPE_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_RECIPE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_RECIPE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_RECIPE_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE RECIPE COST IN REDUX STATE
export function reducerAdminToSetRecipeCost(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_RECIPE_COST_REQUEST:
      return { loading: true };
    case ADMIN_RECIPE_COST_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_RECIPE_COST_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_RECIPE_COST_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE RECIPE COST IN REDUX STATE
export function reducerAdminToDeleteRecipeItem(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_DELETE_RECIPE_ITEM_REQUEST:
      return { loading: true };
    case ADMIN_DELETE_RECIPE_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_DELETE_RECIPE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DELETE_RECIPE_ITEM_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE RECIPE ITEM DETAILS IN REDUX STATE
export function reducernAdminDetailsOfRecipeItem(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_RECIPE_ITEM_DETAIL_REQUEST:
      return { loading: true };
    case ADMIN_RECIPE_ITEM_DETAIL_SUCCESS:
      return {
        loading: false,
        itemDetails: action.payload,
      };
    case ADMIN_RECIPE_ITEM_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_RECIPE_ITEM_DETAIL_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE THE UPDATED RECIPE INFO IN REDUX STATE
export function reducerAdminUpdateRecipeItem(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_UPDATE_RECIPE_ITEM_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_RECIPE_ITEM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_UPDATE_RECIPE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPDATE_RECIPE_ITEM_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}*/

//ADMIN PRODUCT LIST FOR SHIPPING MESSAGE
export const reducerAdminProductToGetListForShippingMessage = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_REQUEST:
      return { loading: true, ...state };
    case ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_SUCCESS:
      return { loading: false, products: action.payload };
    case ADMIN_PRODUCT_LIST_FOR_SHIPPING_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//ADMIN UPDATE SHIPPING MESSAGE
export function reducerAdminProductUpdateShippingMessage(state = {}, action) {
  switch (action.type) {
    case ADMIN_UPDATE_SHIPPING_MESSAGE_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_SHIPPING_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case ADMIN_UPDATE_SHIPPING_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPDATE_SHIPPING_MESSAGE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//ADMIN PRODUCT LIST FOR SMS CAMPAIGN
export const reducerAdminProductGetListForSmsCampaign = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_REQUEST:
      return { loading: true, ...state };
    case ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_SUCCESS:
      return { loading: false, products: action.payload };
    case ADMIN_PRODUCT_LIST_FOR_SMS_CAMPAIGN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// @desc Update multiple products at same time
export function reducerBulkProductUpdate(state = {}, action) {
  switch (action.type) {
    case ADMIN_PRODUCT_BULK_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_PRODUCT_BULK_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_PRODUCT_BULK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_PRODUCT_BULK_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
