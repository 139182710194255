import React from "react";

export default function ThresholdDisplayPurchase(props) {
  // PROPS COMING FROM CartFooter
  const { limits, cartMessagesToUser, levelPercentages } = props;

  const stylingLevelOne = {
    position: "absolute",
    top: "0",
    width: `${levelPercentages.levelOne}%`,
    background: "linear-gradient(to right, lightblue 0%, lightgreen 90%)",
    zIndex: 1,
  };
  const stylingLevelTwo = {
    position: "absolute",
    top: "0",
    width: `${levelPercentages.levelTwo}%`,
    background: "linear-gradient(to right, lightgreen 0%, green 50%)",
  };
  const stylingLevelThree = {
    position: "absolute",
    top: "0",
    width: `${levelPercentages.levelThree}%`,
    background: "linear-gradient(to right, green 0%, darkgreen 50%)",
  };
  const paragraphStyle = {
    position: "relative", // Ensure z-index works
    zIndex: 2, // Make paragraphs appear above the backgrounds
  };

  return (
    <div className="w100p h4r">
      {limits && (
        <div className="row-center-nowrap w100p mar h0-75r">
          <div
            title={`${limits.purchaseLevelOne.amount} TL`}
            className="relative w100p h100p txtcenter blue-border"
          >
            <p style={paragraphStyle} className="lh-75">
              {limits.purchaseLevelOne.amount} TL
            </p>
            <div style={stylingLevelOne} className="row-left h100p"></div>
          </div>
          {limits.purchaseLevelTwo.amount > 0 && (
            <div className="relative w100p h100p txtcenter blue-border">
              <p style={paragraphStyle} className="lh-75">
                {limits.purchaseLevelTwo.amount} TL
              </p>
              <div style={stylingLevelTwo} className="row-left h100p"></div>
            </div>
          )}
          {limits.purchaseLevelThree.amount > 0 && (
            <div className="relative w100p h100p txtcenter blue-border">
              <p style={paragraphStyle} className="lh-75">
                {limits.purchaseLevelThree.amount} TL
              </p>
              <div style={stylingLevelThree} className="row-left h100p"></div>
            </div>
          )}
        </div>
      )}
      {cartMessagesToUser && (
        <div className="txtleft lh1 fs-85 purplelighterbck padq marupsideh">
          {cartMessagesToUser}
        </div>
      )}
    </div>
  );
}
