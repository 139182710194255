//ADMIN CREATE CATEGORY-SUB
export const ADMIN_CATEGORY_SUB_CREATE_REQUEST =
  "ADMIN_CATEGORY_SUB_CREATE_REQUEST";
export const ADMIN_CATEGORY_SUB_CREATE_SUCCESS =
  "ADMIN_CATEGORY_SUB_CREATE_SUCCESS";
export const ADMIN_CATEGORY_SUB_CREATE_FAIL = "ADMIN_CATEGORY_SUB_CREATE_FAIL";
export const ADMIN_CATEGORY_SUB_CREATE_RESET =
  "ADMIN_CATEGORY_SUB_CREATE_RESET";

//ADMIN CATEGORY-SUB LIST
export const ADMIN_CATEGORY_SUB_LIST_REQUEST =
  "ADMIN_CATEGORY_SUB_LIST_REQUEST";
export const ADMIN_CATEGORY_SUB_LIST_SUCCESS =
  "ADMIN_CATEGORY_SUB_LIST_SUCCESS";
export const ADMIN_CATEGORY_SUB_LIST_FAIL = "ADMIN_CATEGORY_SUB_LIST_FAIL";

//ADMIN CATEGORY-SUB RELATED LIST
export const ADMIN_CATEGORY_SUB_RELATED_LIST_REQUEST =
  "ADMIN_CATEGORY_SUB_RELATED_LIST_REQUEST";
export const ADMIN_CATEGORY_SUB_RELATED_LIST_SUCCESS =
  "ADMIN_CATEGORY_SUB_RELATED_LIST_SUCCESS";
export const ADMIN_CATEGORY_SUB_RELATED_LIST_FAIL =
  "ADMIN_CATEGORY_SUB_RELATED_LIST_FAIL";

//ADMIN CATEGORY-SUB DETAILS
export const ADMIN_CATEGORY_SUB_DETAILS_REQUEST =
  "ADMIN_CATEGORY_SUB_DETAILS_REQUEST";
export const ADMIN_CATEGORY_SUB_DETAILS_SUCCESS =
  "ADMIN_CATEGORY_SUB_DETAILS_SUCCESS";
export const ADMIN_CATEGORY_SUB_DETAILS_FAIL =
  "ADMIN_CATEGORY_SUB_DETAILS_FAIL";

//ADMIN CATEGORY-SUB EDIT
export const ADMIN_CATEGORY_SUB_EDIT_REQUEST =
  "ADMIN_CATEGORY_SUB_EDIT_REQUEST";
export const ADMIN_CATEGORY_SUB_EDIT_SUCCESS =
  "ADMIN_CATEGORY_SUB_EDIT_SUCCESS";
export const ADMIN_CATEGORY_SUB_EDIT_FAIL = "ADMIN_CATEGORY_SUB_EDIT_FAIL";
export const ADMIN_CATEGORY_SUB_EDIT_RESET = "ADMIN_CATEGORY_SUB_EDIT_RESET";

//ADMIN CATEGORY-SUB DELETE
export const ADMIN_CATEGORY_SUB_DELETE_REQUEST =
  "ADMIN_CATEGORY_SUB_DELETE_REQUEST";
export const ADMIN_CATEGORY_SUB_DELETE_SUCCESS =
  "ADMIN_CATEGORY_SUB_DELETE_SUCCESS";
export const ADMIN_CATEGORY_SUB_DELETE_FAIL = "ADMIN_CATEGORY_SUB_DELETE_FAIL";
export const ADMIN_CATEGORY_SUB_DELETE_RESET =
  "ADMIN_CATEGORY_SUB_DELETE_RESET";
