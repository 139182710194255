import { useSelector } from "react-redux";

export function useGetUserFromStore() {
  const authenticateUser = useSelector((state) => state.authenticateUser);
  if (authenticateUser && authenticateUser.authenticatedUser) {
    const { loading, error, authenticatedUser } = authenticateUser;

    const user = {
      ...authenticatedUser,
      name: authenticatedUser.firstName + " " + authenticatedUser.lastName,
      loading,
      error,
    };
    return user;
  } else {
    return null;
  }
}
