import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionAdminToUpdateWholeSaleOrderStatus = createAsyncThunk(
  "wholeSale/adminOrderStatus",
  async ({ id, statusData }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/adminUpdateWholeSaleOrderStatus",
        { id, statusData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  updatedWholeSaleOrder: null,
  successMessage: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleOrderStatusSlice = createSlice({
  name: "updateWholeSaleAccount",
  initialState,
  reducers: {
    adminResetUpdateWholeSaleOrderStatus: (state) => {
      state.updatedWholeSaleOrder = null;
      state.successMessage = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionAdminToUpdateWholeSaleOrderStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionAdminToUpdateWholeSaleOrderStatus.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updatedWholeSaleOrder = action.payload.updatedWholeSaleOrder;
          state.successMessage = action.payload.successMessage;
        }
      )
      .addCase(
        actionAdminToUpdateWholeSaleOrderStatus.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { adminResetUpdateWholeSaleOrderStatus } =
  updateWholeSaleOrderStatusSlice.actions;

// Export the reducer
const reducerAdminToUpdateWholeSaleOrderStatus =
  updateWholeSaleOrderStatusSlice.reducer;

// Export the async action creator for use in components
export {
  reducerAdminToUpdateWholeSaleOrderStatus,
  adminResetUpdateWholeSaleOrderStatus,
};
