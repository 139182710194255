import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToUploadAProductInHepsiBurada = createAsyncThunk(
  "jobInActionToUploadAProductInHepsiBurada",
  async (productId, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/hepsiBurada/uploadAProductInHepsiBurada",
        { productId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      const { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const uploadAProductInHepsiBurada = createSlice({
  name: "sliceToUploadAProductInHepsiBurada",
  initialState: {
    isUploaded: false,
    batchRequestId: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetUploadAProductInHepsiBuradaIsUploaded: (state) => {
      state.isUploaded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUploadAProductInHepsiBurada.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actionToUploadAProductInHepsiBurada.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.isUploaded = true;
          state.batchRequestId = action.payload;
        }
      )
      .addCase(
        actionToUploadAProductInHepsiBurada.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetUploadAProductInHepsiBuradaIsUploaded } =
  uploadAProductInHepsiBurada.actions;

const reducerToUploadAProductInHepsiBurada =
  uploadAProductInHepsiBurada.reducer;

export default reducerToUploadAProductInHepsiBurada;
