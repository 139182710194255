import { useSelector } from "react-redux";

export function useGetIsPendingFromStore() {
  // REDUX STATE THAT HOLDS INFO IF THERE IS A PENDING ORDER OF CLIENT
  const checkIfThereIsApendingOrder = useSelector(
    (state) => state.checkIfThereIsApendingOrder
  );
  const { isPending } = checkIfThereIsApendingOrder;

  return isPending;
}
