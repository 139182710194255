import { useEffect, useState } from "react";
import { default as FingerprintJS } from "@fingerprintjs/fingerprintjs";
import { errorMessage } from "../utils/errorMessage";

export function useGetBrowserFingerPrint() {
  const [visitorId, setVisitorId] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [retryCount, setRetryCount] = useState(1);
  const MAX_RETRY_ATTEMPTS = 3; // Maximum number of retry attempts
  const RETRY_INTERVAL_MS = 30000; // Interval between retry attempts in milliseconds
  const hasGeneratedFingerprint = sessionStorage.getItem("visitorId");

  const generateFingerprint = async () => {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      setVisitorId(result.visitorId);
    } catch (error) {
      console.log(
        `Failed to generate visitorId. Retrying (${retryCount}/${MAX_RETRY_ATTEMPTS})...`
      );
      const { message } = errorMessage(error);
      setErrMessage(message);
      setRetryCount(retryCount + 1);
    }
  };

  useEffect(() => {
    if (retryCount === 1 && !hasGeneratedFingerprint) {
      generateFingerprint();
    } else if (retryCount <= MAX_RETRY_ATTEMPTS && !hasGeneratedFingerprint) {
      setTimeout(() => {
        generateFingerprint();
      }, RETRY_INTERVAL_MS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryCount, setVisitorId]);

  return { visitorId, errMessage };
}
