import {
  ADMIN_USER_LOGIN_FAIL,
  ADMIN_USER_LOGIN_REQUEST,
  ADMIN_USER_LOGIN_SUCCESS,
  ADMIN_USER_LOGOUT,
  ADMIN_USER_REGISTER_FAIL,
  ADMIN_USER_REGISTER_REQUEST,
  ADMIN_USER_REGISTER_SUCCESS,
  ADMIN_USER_USERSLIST_FAIL,
  ADMIN_USER_USERSLIST_REQUEST,
  ADMIN_USER_USERSLIST_SUCCESS,
  ADMIN_USER_USERDETAILS_REQUEST,
  ADMIN_USER_USERDETAILS_SUCCESS,
  ADMIN_USER_USERDETAILS_FAIL,
  ADMIN_USER_UPDATEUSER_REQUEST,
  ADMIN_USER_UPDATEUSER_SUCCESS,
  ADMIN_USER_UPDATEUSER_FAIL,
  ADMIN_USER_UPDATEUSER_RESET,
  ADMIN_USER_ORDERLIST_REQUEST,
  ADMIN_USER_ORDERLIST_SUCCESS,
  ADMIN_USER_ORDERLIST_FAIL,
} from "../constants/adminUserConstants";

//ADMIN REGISTER REDUCER
export function reducerAdminUserToRegister(state = {}, action) {
  switch (action.type) {
    case ADMIN_USER_REGISTER_REQUEST:
      return { loading: true };
    case ADMIN_USER_REGISTER_SUCCESS:
      return { loading: false, adminUserRegister: action.payload };
    case ADMIN_USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN LOGIN REDUCER
export function reducerAdminUserToLogin(state = {}, action) {
  switch (action.type) {
    case ADMIN_USER_LOGIN_REQUEST:
      return { loading: true };
    case ADMIN_USER_LOGIN_SUCCESS:
      return { loading: false, adminUserInfo: action.payload };
    case ADMIN_USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

//ADMIN SAVING USER LIST
export function reducerAdminUserToListUsers(
  state = { loading: true, userList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_USER_USERSLIST_REQUEST:
      return { loading: true };
    case ADMIN_USER_USERSLIST_SUCCESS:
      return {
        loading: false,
        userList: action.payload.users,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_USER_USERSLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN USER DETAILS REQUEST REDUCER
export function reducerAdminUserToRequestUserDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_USER_USERDETAILS_REQUEST:
      return { loading: true };
    case ADMIN_USER_USERDETAILS_SUCCESS:
      return { loading: false, userDetails: action.payload };
    case ADMIN_USER_USERDETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN USER DETAILS UPDATE REQUEST REDUCER
export function reducerAdminUserToUpdateUserDetails(
  state = { loading: true, success: false },
  action
) {
  switch (action.type) {
    case ADMIN_USER_UPDATEUSER_REQUEST:
      return { loading: true };
    case ADMIN_USER_UPDATEUSER_SUCCESS:
      return {
        loading: false,
        success: true,
        adminUpdatedUserDetails: action.payload,
      };
    case ADMIN_USER_UPDATEUSER_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_USER_UPDATEUSER_RESET:
      return { success: false };
    default:
      return state;
  }
}

//ADMIN SAVING USER ORDER LIST
export function reducerAdminUserToListUserOrders(
  state = { loading: true, userOrderList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_USER_ORDERLIST_REQUEST:
      return { loading: true };
    case ADMIN_USER_ORDERLIST_SUCCESS:
      return { loading: false, userOrderList: action.payload };
    case ADMIN_USER_ORDERLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
