import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToSendPhoneToReceiveSms = createAsyncThunk(
  "signIn/jobInActionToSendPhoneToReceiveSms",
  async ({ phone, collectionName, property, captchaValue }, thunkAPI) => {
    try {
      // Call your API function here to get the last months sales
      const { data } = await axios.post("/api/signIn/sendPhoneToReceiveSms", {
        phone,
        collectionName,
        property,
        captchaValue,
      });
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  data: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const sendPhoneToReceiveSmsSlice = createSlice({
  name: "sendPhoneToReceiveSms",
  initialState,
  reducers: {
    resetSendPhoneToReceiveSms: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToSendPhoneToReceiveSms.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToSendPhoneToReceiveSms.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(actionToSendPhoneToReceiveSms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetSendPhoneToReceiveSms } = sendPhoneToReceiveSmsSlice.actions;

// Export the reducer
const reducerToSendPhoneToReceiveSms = sendPhoneToReceiveSmsSlice.reducer;

// Export the async action creator for use in components
export { reducerToSendPhoneToReceiveSms, resetSendPhoneToReceiveSms };
