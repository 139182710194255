import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../../modules/shared/components/MessageBox";
import "./CartScreen.css";
import { CART_EMPTY_SKU } from "../../constants/cartConstants";
import LoadingBlackOut from "../../modules/shared/components/LoadingBlackOut";
import useScrollToTopReact from "../../hooks/useScrollToTopReact";
import {
  actionToRemoveSkuItemInCart,
  calculateItemsPriceAction,
  calculateTaxPriceAction,
  updateShippingPriceAction,
  updateTotalPriceAction,
} from "../../actions/cartActions";
import { actionToCreateOrder } from "../../actions/orderActions";
import ErcDialog from "../../modules/shared/components/ErcDialog";
import ErcModal from "../../modules/shared/components/ErcModal";
import CartScreenCart from "./CartScreenCart";
import CartSummary from "./CartSummary";
import { useCalculateExtraDiscount } from "../../hooks/useCalculateExtraDiscount";
import { useUpdateCartExtraDiscountPrice } from "../../hooks/useUpdateCartExtraDiscountPrice";
import { useCalculateCartTotal } from "../../hooks/useCalculateCartTotal";
import useGetLimitedTimeDiscountInfo from "../../hooks/useGetLimitedTimeDiscountInfo";
import { useUpdateCartItemsAfterDiscountExpired } from "../../hooks/useUpdateCartItemsAfterDiscountExpired";
// import { useUpdateCartItemsWithIndexId } from "../../hooks/useUpdateCartItemsWithIndexId";
import { useUpdateCartItemsOnRemovingItem } from "../../hooks/useUpdateCartItemsOnRemovingItem";
import { useUpdateCartItemsWithDiscountInfo } from "../../hooks/useUpdateCartItemsWithDiscountInfo";
import { useUpdateCartItemsWhenGrandUpsellExpired } from "../../hooks/useUpdateCartItemsWhenGrandUpsellExpired";
import { useFbPixelFire } from "../../hooks/useFbPixelFire";
import { useUpdateCartToClearIfFreeItemLeftAlone } from "../../hooks/useUpdateCartToClearIfFreeItemLeftAlone";
import { actionToUpdateExtraDiscountShown } from "./actionToUpdateExtraDiscountShown";
import { useExtraDiscountShown } from "./useExtraDiscountShown";

export default function CartScreen(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  useScrollToTopReact();
  let discounted = true;

  // PROPS COMING FROM App
  const { firstVisitTime, createdFingerPrint } = props;

  // STATE TO REFRESH CART SCREEN BY CHANGING STATE FROM CountDownTimerVisibilityAware
  const [trigger, setTrigger] = useState(false);

  // STATE TO HOLD REMOVE ITEM INDEX INFO
  const [removedItemIndex, setRemovedItemIndex] = useState(null);

  const { isDiscountValid } = useGetLimitedTimeDiscountInfo(
    createdFingerPrint,
    createdFingerPrint,
    trigger
  );

  // REDUX STATE THAT HOLDS INFO IF THERE IS A PENDING ORDER OF CLIENT
  const checkIfThereIsApendingOrder = useSelector(
    (state) => state.checkIfThereIsApendingOrder
  );
  const { isPending } = checkIfThereIsApendingOrder;

  //REDUX STORE THAT HOLDS SKU CART ITEMS
  const addOrRemoveSkuItemInCart = useSelector(
    (state) => state.addOrRemoveSkuItemInCart
  );
  const { cartSkuItems, loading: cartSkuItemsLoading } =
    addOrRemoveSkuItemInCart;

  // ADD UP INDEX ID IN CART SKU ITEMS
  // useUpdateCartItemsWithIndexId(cartSkuItems);

  /* Calculate the discount loss for user that occurs because most of the discounted product is in the first index of cart array. 
  When most discounted product first in the array, discount is not applied. */
  const { /*discountCompansation, prevDiscount,*/ extraDiscountAmount } =
    useCalculateExtraDiscount(
      cartSkuItems,
      isPending,
      createdFingerPrint,
      isDiscountValid
    );

  // CHECK IF THERE ARE DISCOUNTED ITEMS IN CART WHILE THE DISCOUNT IS EXPIRED AND UPDATE THEM
  useUpdateCartItemsAfterDiscountExpired(
    firstVisitTime,
    cartSkuItems,
    isPending
  );

  // UPDATE CART SKU ITEM IF THERE IS AN EXTRA DISCOUTN
  useUpdateCartExtraDiscountPrice(
    cartSkuItems,
    extraDiscountAmount,
    isDiscountValid,
    trigger
  );

  // IF INDEX ZERO REMOVED FROM CART, UPDATE NEW PRICE BASED ON THE DISCOUNT CRITERIAS
  useUpdateCartItemsOnRemovingItem(
    cartSkuItems,
    removedItemIndex,
    isDiscountValid,
    setTrigger
  );

  // CALCULATE LATEST CART TOTAL
  const cartTotal = useCalculateCartTotal(
    cartSkuItems,
    firstVisitTime,
    isPending,
    extraDiscountAmount,
    trigger,
    isDiscountValid
  );

  useEffect(() => {
    dispatch(calculateTaxPriceAction(addOrRemoveSkuItemInCart.cartSkuItems));
  }, [dispatch, addOrRemoveSkuItemInCart.cartSkuItems]);

  useEffect(() => {
    dispatch(
      calculateItemsPriceAction(
        addOrRemoveSkuItemInCart.cartSkuItems,
        firstVisitTime
      )
    );
  }, [firstVisitTime, addOrRemoveSkuItemInCart.cartSkuItems, dispatch]);

  const shippingPrice = 0;
  useEffect(() => {
    dispatch(updateShippingPriceAction(shippingPrice));
  }, [dispatch, shippingPrice]);

  useEffect(() => {
    dispatch(updateTotalPriceAction(cartTotal?.totalAmount));
  }, [dispatch, cartTotal?.totalAmount]);

  //REDUX STORE THAT HOLDS CREATED ORDER INFO
  const createOrder = useSelector((state) => state.createOrder);
  const { loading, success } = createOrder;

  //GO TO CHECK OUT BUTTON
  /* If it is a grandupsell order, user will be directly directed from cart screen to payment screen */
  function checkoutHandler() {
    if (isPending && isPending.isTherePending === "YES") {
      setDialogOpen(false);
      const conversationId = Math.floor(Math.random() * 1000000000).toString();
      const orderId = new Date().getTime().toString().slice(6);
      const date = new Date();
      const dateFormat = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()} , ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

      dispatch(
        actionToCreateOrder({
          isUpsell: true,
          converterAppeal: "grandupsell",
          discountCode: false,
          discountCodeName: "",
          extraDiscountCodeName: "",
          extraDiscountAmount: 0,
          discountAmount: 0,
          iyzicoConversationId: conversationId,
          orderId: isPending.pendingOrders[0].orderId + "/" + orderId,
          orderStatus: "NEW",
          orderUpdate: "Pending",
          refundUpdate: "",

          //IF THERE IS AN AUTHENTICATED USER ORDER PHONE IS SET TO AUTHENTICATED USER PHONE
          orderPhone: isPending.pendingOrders[0].orderPhone,
          orderTime: dateFormat,
          refundGuaranteeStartedAt:
            isPending.pendingOrders[0].refundGuaranteeStartedAt,
          deliveryContact: {
            firstName: isPending.pendingOrders[0].deliveryContact.firstName,
            lastName: isPending.pendingOrders[0].deliveryContact.lastName,
            phone: isPending.pendingOrders[0].deliveryContact.phone,
            email: "",
            ip: isPending.pendingOrders[0].deliveryContact.ip,
          },
          orderItems: addOrRemoveSkuItemInCart.cartSkuItems,
          shippingAddress: {
            country: isPending.pendingOrders[0].shippingAddress.country,
            address: isPending.pendingOrders[0].shippingAddress.address,
            province: isPending.pendingOrders[0].shippingAddress.province,
            district: isPending.pendingOrders[0].shippingAddress.district,
          },
          individualInvoice: {
            firstName: isPending.pendingOrders[0].individualInvoice.firstName,
            lastName: isPending.pendingOrders[0].individualInvoice.lastName,
            email: "",
            province: isPending.pendingOrders[0].individualInvoice.province,
            district: isPending.pendingOrders[0].individualInvoice.district,
            address: isPending.pendingOrders[0].individualInvoice.address,
            identityNumber: "",
          },
          paymentMethod:
            addOrRemoveSkuItemInCart.totalPrice <= 400
              ? isPending.pendingOrders[0].paymentMethod
              : "CreditCard",
          itemsPrice: addOrRemoveSkuItemInCart.itemsPrice,
          shippingPrice: addOrRemoveSkuItemInCart.shippingPrice,
          taxPrice: addOrRemoveSkuItemInCart.taxPrice,
          totalPrice: addOrRemoveSkuItemInCart.totalPrice,
          userAgent: isPending.pendingOrders[0].userAnalytics.userAgent,
          userBrowser: isPending.pendingOrders[0].userAnalytics.userBrowser,
          userDevice: isPending.pendingOrders[0].userAnalytics.userDevice,
          userEngine: isPending.pendingOrders[0].userAnalytics.userEngine,
          userOS: isPending.pendingOrders[0].userAnalytics.userOS,
          userCPU: isPending.pendingOrders[0].userAnalytics.userCPU,
        })
      );
      localStorage.setItem(
        "checkUnpaid",
        JSON.stringify(isPending.pendingOrders[0].orderPhone)
      );
    } else {
      history.push(`/siparis-bilgileri-adim-1/2`);
    }
  }

  // DIRECT TO PAYMENT FORM AFTER ORDER IS SUCCESSFULLY CREATED
  useEffect(() => {
    if (success) {
      history.push(`/odeme-adimi-2/2?type=grandupsell`);
    }
  }, [success, props, history]);
  // GRAND UPSELL COD PAYMENT ADD TO ORDER DIALOG
  const [dialogOpen, setDialogOpen] = useState(false);
  function handleGrandUpsellDialog() {
    setDialogOpen(true);
  }

  //DELETE AN ITEM N THE CART
  function removeFromCardHandler(index, indexId) {
    sessionStorage.setItem("stopDiscount", "true"); // If item removed from cart, extra discount is set to zero in useCalculateExtraDiscount
    setRemovedItemIndex(index);

    const item = cartSkuItems.find((item) => item.indexId === indexId);
    const nextItem = cartSkuItems.find((item) => item.indexId === indexId + 1);
    const prevItem = cartSkuItems.find((item) => item.indexId === indexId - 1);

    if (!nextItem && !prevItem) {
      dispatch({ type: CART_EMPTY_SKU });
    } else if (!nextItem) {
      return [dispatch(actionToRemoveSkuItemInCart(item.indexId))];
    } else if (nextItem?.price === 0) {
      return [
        dispatch(actionToRemoveSkuItemInCart(item.indexId)),
        dispatch(actionToRemoveSkuItemInCart(nextItem.indexId)),
      ];
    } else {
      return [dispatch(actionToRemoveSkuItemInCart(item.indexId))];
    }
  }

  // IN CASE THAT FREE PRODUCT LEFT ALONE IN THE CART, THIS PIECE OF CODE DELETES IT
  // (IF SAME SKU ADDED IN CART IT MAY CAUSE FREE PRODUCT LEFT ALONE IN CART AFTER REMOVING SOME CHOICES FROM CART)
  useUpdateCartToClearIfFreeItemLeftAlone(cartSkuItems);

  //CONTINUE SHOPPING BUTTON IS LINKED TO BROWSER BACK BUTTON
  function continueShoppingHandler() {
    window.history.back();
  }

  // UPDATE CART ITEMS WHEN GRAND UPSELL OFFER IS EXPIRED
  const popOnMount = useUpdateCartItemsWhenGrandUpsellExpired(
    isPending,
    cartSkuItems,
    isDiscountValid,
    dispatch
  );

  const cartSkuItemsWithDiscountInfo = useUpdateCartItemsWithDiscountInfo(
    cartSkuItems,
    firstVisitTime
  );

  // FACEBOOK PIXEL FIRED
  useFbPixelFire(cartSkuItems, cartTotal);

  // POP extraDiscount
  const extraDiscountOpen = useExtraDiscountShown(createdFingerPrint);

  return (
    <>
      {(loading || cartSkuItemsLoading) && <LoadingBlackOut />}

      <ErcModal
        modalTitleDivWidth={"85%"}
        modalTitle={() => ""}
        modalTitleFontSize={"1rem"}
        modalTitleLineHeight={"2rem"}
        contentTitleFontSize={"1.35rem"}
        contentTitle={"Ana Siparişiniz Paketlendi"}
        content={[
          "Ana siparişiniz paketlendiği için sepetiniz güncellendi.",
          "Alışverişi tamamlayarak yeni bir sipariş oluşturun.",
          "Mümkün olan en kısa sürede yeni siparişinizi Yurtiçi kargoya teslim edeceğiz.",
        ]}
        modalParagFontSize={"1.25rem"}
        modalParagBorderBottom={"1px solid #ff9935"}
        disableModalTitle={true}
        popOnMount={popOnMount} // IF OPEN MODAL POPS IF CLOSE MODAL CLOSE
        clickOutsideShouldWork={false}
      />

      {extraDiscountAmount > 0 && (
        <ErcModal
          modalTitleDivWidth={"85%"}
          modalTitle={() => ""}
          modalTitleFontSize={"1rem"}
          modalTitleLineHeight={"2rem"}
          contentTitleFontSize={"1.35rem"}
          contentTitle={
            extraDiscountAmount &&
            extraDiscountAmount !== 0 &&
            `${extraDiscountAmount} TL Sürpriz indirim Kazandınız!`
          }
          content={
            extraDiscountAmount &&
            extraDiscountAmount !== 0 && [
              `İNDİRİMİ KAÇIRMAMAK İÇİN LÜTFEN OKUYUN`,
              `* Sepete ürün eklemeye devam edebilirsiniz. Ancak, eklediğiniz bir ürünü geri kaldırırsanız indirim iptal olur.`,
              `* İndirim hakkı şu an geçerlidir. Siparişi şimdi tamamlamazsanız indirim iptal olur.`,
            ]
          }
          modalParagFontSize={"1.25rem"}
          disableModalTitle={true}
          popOnMount={extraDiscountOpen} // IF OPEN MODAL POPS IF CLOSE MODAL CLOSE
          clickOutsideShouldWork={false}
          callThisFunctionInCloseModalFunction={() => {
            const isExtraDiscountShown = true;
            localStorage.setItem("extraDiscountShown", "true");
            dispatch(
              actionToUpdateExtraDiscountShown({
                visitorId: createdFingerPrint?.visitorId,
                isExtraDiscountShown,
              })
            );
          }}
        />
      )}

      {dialogOpen && (
        <ErcDialog
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
          contentTitle={"Siparişime Ekle"}
          content={[
            `${cartTotal?.totalAmount} TL tutarlı ürün/ürünler kapıda ödeme yöntemi ile siparişinize eklenecektir. Onaylıyor musunuz?`,
          ]}
          yesNoButtons={true}
          goAhead={"Ekle"}
          giveUp={"Vazgeç"}
          handleGoAhead={checkoutHandler}
        />
      )}

      <Helmet>
        <title>beddo™ Sepet | Alışverişe Başlayın</title>
        <meta
          name="description"
          content="beddo™ ürünlerini sepete atın ve üye olmadan kolayca siparişi tamamlayın."
        />
        <meta name="keywords" content="beddo, beddo ürünleri" />
        <meta name="author" content="beddo™ Marketing Team" />
        <meta name="application-name" content="beddo™ ERP" />
      </Helmet>

      <div className="cart-shadow-platform-for-heading">
        <h2 className="h2c txtcenter">Alışveriş Sepetiniz</h2>
      </div>
      <div className="cart-shadow-platform helvetica">
        {cartSkuItems.length === 0 ? (
          <MessageBox variant="danger">
            <h3 className="h3c">
              Alışveriş sepetiniz boş <Link to="/">Alışverişe Devam Edin</Link>
            </h3>
          </MessageBox>
        ) : (
          <>
            <div className="cart-shadow-table-platform-holder">
              {cartSkuItemsWithDiscountInfo.map((data, index) => {
                return (
                  <CartScreenCart
                    key={index + 100}
                    index={index}
                    item={data.item}
                    isPending={isPending}
                    cartSkuItems={cartSkuItems}
                    discounted={discounted}
                    removeFromCardHandler={removeFromCardHandler}
                    firstVisitTime={firstVisitTime}
                    setTrigger={setTrigger}
                  />
                );
              })}
            </div>

            <CartSummary
              isDiscountApplied={cartTotal?.isDiscountApplied}
              isPending={isPending}
              cartSkuItems={cartSkuItems}
              extraDiscountAmount={extraDiscountAmount}
              continueShoppingHandler={continueShoppingHandler}
              addOrRemoveSkuItemInCart={addOrRemoveSkuItemInCart}
              handleGrandUpsellDialog={handleGrandUpsellDialog}
              checkoutHandler={checkoutHandler}
              cartTotal={cartTotal}
            />
          </>
        )}
      </div>
    </>
  );
}
