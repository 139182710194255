import {
  ADMIN_YURT_CREATE_SHIPMENT_FAIL,
  ADMIN_YURT_CREATE_SHIPMENT_REQUEST,
  ADMIN_YURT_CREATE_SHIPMENT_RESET,
  ADMIN_YURT_CREATE_SHIPMENT_SUCCESS,
  ADMIN_YURT_EXCEL_FAIL,
  ADMIN_YURT_EXCEL_REQUEST,
  ADMIN_YURT_EXCEL_RESET,
  ADMIN_YURT_EXCEL_SUCCESS,
  ADMIN_YURT_UPDATE_AS_FAIL,
  ADMIN_YURT_UPDATE_AS_REQUEST,
  ADMIN_YURT_UPDATE_AS_SUCCESS,
  YURT_CANCEL_REFUND_FAIL,
  YURT_CANCEL_REFUND_REQUEST,
  YURT_CANCEL_REFUND_RESET,
  YURT_CANCEL_REFUND_SUCCESS,
  YURT_CANCEL_SHIPMENT_FAIL,
  YURT_CANCEL_SHIPMENT_REQUEST,
  YURT_CANCEL_SHIPMENT_RESET,
  YURT_CANCEL_SHIPMENT_SUCCESS,
  YURT_CREATE_MULTI_FAIL,
  YURT_CREATE_MULTI_REQUEST,
  YURT_CREATE_MULTI_RESET,
  YURT_CREATE_MULTI_SUCCESS,
  YURT_REFUND_SHIPMENT_FAIL,
  YURT_REFUND_SHIPMENT_REQUEST,
  YURT_REFUND_SHIPMENT_RESET,
  YURT_REFUND_SHIPMENT_SUCCESS,
} from "../constants/adminCourierYurtConstants";

//SAVE CREATE YURT SHIPMENT
export function reducerAdminToCreateYurtShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_YURT_CREATE_SHIPMENT_REQUEST:
      return { loading: true };
    case ADMIN_YURT_CREATE_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_YURT_CREATE_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_YURT_CREATE_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}

//ADMIN REQUESTS A LIST OF PENDING ORDERS TO EXPORT YURT ICI KARGO LIST
export function reducerAdminOrderToListPendingYurtIciKargoOrders(
  state = { loading: true, yurtIciOrdersList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_YURT_EXCEL_REQUEST:
      return { loading: true };
    case ADMIN_YURT_EXCEL_SUCCESS:
      return {
        loading: false,
        yurtIciOrdersList: action.payload,
      };
    case ADMIN_YURT_EXCEL_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_YURT_EXCEL_RESET:
      return { loading: true, yurtIciOrdersList: [] };
    default:
      return state;
  }
}

//ADMIN REQUESTS CHECK ORDERS AS SHIPPED WITH YURT ICI KARGO
export function reducerAdminOrderToCheckYurtIciKargoOrders(
  state = { loading: true, success: false },
  action
) {
  switch (action.type) {
    case ADMIN_YURT_UPDATE_AS_REQUEST:
      return { loading: true };
    case ADMIN_YURT_UPDATE_AS_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_YURT_UPDATE_AS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE CANCEL UPS SHIPMENT
export function reducerToCancelYurtShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case YURT_CANCEL_SHIPMENT_REQUEST:
      return { loading: true };
    case YURT_CANCEL_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case YURT_CANCEL_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case YURT_CANCEL_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE CREATE YURT SHIPMENT REFUND
export function reducerToCreateYurtShipmentForRefunds(
  state = { success: false },
  action
) {
  switch (action.type) {
    case YURT_REFUND_SHIPMENT_REQUEST:
      return { loading: true };
    case YURT_REFUND_SHIPMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload.order,
        refundShipmentNoYurt: action.payload.refundShipmentNoYurt,
      };
    case YURT_REFUND_SHIPMENT_FAIL:
      return { loading: false, error: action.payload };
    case YURT_REFUND_SHIPMENT_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE CANCEL YURT SHIPMENT REFUND CODE
export function reducerToCancelYurtShipmentForRefunds(
  state = { success: false },
  action
) {
  switch (action.type) {
    case YURT_CANCEL_REFUND_REQUEST:
      return { loading: true };
    case YURT_CANCEL_REFUND_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case YURT_CANCEL_REFUND_FAIL:
      return { loading: false, error: action.payload };
    case YURT_CANCEL_REFUND_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE CREATE MULTI YURT SHIPMENT
export function reducerAdminToCreateMultiYurtShipment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case YURT_CREATE_MULTI_REQUEST:
      return { loading: true };
    case YURT_CREATE_MULTI_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case YURT_CREATE_MULTI_FAIL:
      return { loading: false, error: action.payload };
    case YURT_CREATE_MULTI_RESET:
      return {};
    default:
      return state;
  }
}
