import React, { useEffect, useState } from "react";
import "./CountDownTimer.css";
import { usePageVisibility } from "../../hooks/usePageVisibility";
import { undoDiscountsInCart } from "../../utils/undoDiscountsInCart";
import { useDispatch } from "react-redux";

const CountDownTimerVisibilityAware = ({
  milliseconds,
  topBarStyle,
  setTrigger,
}) => {
  const isVisible = usePageVisibility();
  const dispatch = useDispatch();

  const [remainingTime, setRemainingTime] = useState(
    Math.floor(milliseconds / 1000)
  );

  const [goneOff, setGoneOff] = useState(0);
  const [elapsed, setElapsed] = useState(0);
  const [invisibleAt, setInvisibleAt] = useState(0);

  useEffect(() => {
    let timer;
    // COUNT DOWN BY SUBSRIBING 1 FROM PREVTIME EVERY 1 SEC WHICH IS MANAGED BY SET INTERVAL WHILE THE USER IS VIEWING THE SITE
    if (remainingTime > 0 && isVisible) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      // SAVE THE REMANING TIME WHEN THE USER IS GONE
    } else if (remainingTime > 0 && !isVisible) {
      setInvisibleAt(remainingTime);
    }

    if (remainingTime <= 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime, elapsed, isVisible, invisibleAt, dispatch]);

  // CALCULATE THE ELAPSED TIME WHILE THE USER IS GONE
  useEffect(() => {
    if (!isVisible) {
      setGoneOff(Date.now());
    } else if (goneOff && isVisible) {
      setElapsed(Math.floor((Date.now() - goneOff) / 1000));
    }
  }, [isVisible, elapsed, goneOff]);

  // CALCULATE THE REMAINING TIME AFTER USER STARTS TO VIEW THE SITE AGAIN
  useEffect(() => {
    if (invisibleAt > 0 && isVisible) {
      setRemainingTime(invisibleAt - elapsed > 0 ? invisibleAt - elapsed : 0);
    }
  }, [elapsed, invisibleAt, isVisible]);

  useEffect(() => {
    if (remainingTime <= 0 && setTrigger) {
      undoDiscountsInCart(dispatch);
      setTrigger(true);
    }
  }, [dispatch, remainingTime, setTrigger]);

  // FORMAT TIME DISPLAY
  const formattedDays = Math.floor(remainingTime / (60 * 60 * 24));
  const formattedHours = Math.floor(
    (remainingTime % (60 * 60 * 24)) / (60 * 60)
  );
  const formattedMinutes = String(
    Math.floor((remainingTime % (60 * 60)) / 60)
  ).padStart(2, "0");
  const formattedSeconds = String(remainingTime % 60).padStart(2, "0");

  return (
    <div
      key={isVisible ? Math.random() : 1}
      className={
        formattedDays > 0
          ? topBarStyle
            ? "topBarStyle"
            : "countdown-timer countdown-timer-withday"
          : formattedHours === 0
          ? topBarStyle
            ? "topBarStyle"
            : "countdown-timer countdown-timer-withouthour"
          : topBarStyle
          ? "topBarStyle"
          : "countdown-timer"
      }
    >
      {formattedDays > 0 && (
        <>
          <span className="days">{formattedDays} gün</span>{" "}
          <span className="separator">:</span>
        </>
      )}
      {formattedHours > 0 && (
        <>
          <span className="hours">{formattedHours} saat</span>
          <span className="separator">:</span>
        </>
      )}
      <span className="minutes">{formattedMinutes} dk</span>
      <span className="separator">:</span>
      <span className="seconds">{formattedSeconds} sn</span>
    </div>
  );
};

export default CountDownTimerVisibilityAware;
