import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToCreateWholeSaleCustomer = createAsyncThunk(
  "signIn/jobInActionToCreateWholeSaleCustomer",
  async ({ companyName, email, name, phone, captchaValue }, thunkAPI) => {
    try {
      // Call your API function here to get the last months sales
      const { data } = await axios.post("/api/signUp/createWholeSaleCustomer", {
        companyName,
        email,
        name,
        phone,
        captchaValue,
      });
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  data: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const createWholeSaleCustomerSlice = createSlice({
  name: "createWholeSaleCustomer",
  initialState,
  reducers: {
    resetCreateWholeSaleCustomer: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToCreateWholeSaleCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToCreateWholeSaleCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(actionToCreateWholeSaleCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reset method
const { resetCreateWholeSaleCustomer } = createWholeSaleCustomerSlice.actions;

// Export the reducer
const reducerToCreateWholeSaleCustomer = createWholeSaleCustomerSlice.reducer;

// Export the async action creator for use in components
export { reducerToCreateWholeSaleCustomer, resetCreateWholeSaleCustomer };
