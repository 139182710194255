import {
  ADMIN_ANSWER_QNA_FAIL,
  ADMIN_ANSWER_QNA_REQUEST,
  ADMIN_ANSWER_QNA_SUCCESS,
  ADMIN_LIST_QNA_FAIL,
  ADMIN_LIST_QNA_REQUEST,
  ADMIN_LIST_QNA_SUCCESS,
  CREATE_QNA_FAIL,
  CREATE_QNA_REQUEST,
  CREATE_QNA_RESET,
  CREATE_QNA_SUCCESS,
  LIST_QNA_FAIL,
  LIST_QNA_REQUEST,
  LIST_QNA_SUCCESS,
} from "../constants/qnaConstants";

//POST A REQUEST TO CREATE QNA
export const reducerToCreateQna = (state = {}, action) => {
  switch (action.type) {
    case CREATE_QNA_REQUEST:
      return { loading: true };
    case CREATE_QNA_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
      };
    case CREATE_QNA_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_QNA_RESET:
      return {};
    default:
      return state;
  }
};

// ADMIN GET QNA LIST
export const reducerAdminToListQnas = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_QNA_REQUEST:
      return { loading: true };
    case ADMIN_LIST_QNA_SUCCESS:
      return {
        loading: false,
        adminQnaList: action.payload.adminQnaList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_LIST_QNA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//ADMIN POST A REQUEST TO ANSWER QNA
export const reducerAdminToAnswerQna = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ANSWER_QNA_REQUEST:
      return { loading: true };
    case ADMIN_ANSWER_QNA_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ANSWER_QNA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// VISITOR GET QNA LIST
export const reducerListQnas = (state = {}, action) => {
  switch (action.type) {
    case LIST_QNA_REQUEST:
      return { loading: true };
    case LIST_QNA_SUCCESS:
      return {
        loading: false,
        qna: { list: action.payload.list, count: action.payload.count },
      };
    case LIST_QNA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
