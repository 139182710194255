import { useEffect, useState } from "react";
import { stylerDecimalPart } from "../../../../../utils/stylerDecimalPart";
import { getTurkishSuffixForNumbers } from "../../../../../utils/getTurkishSuffixForNumbers";
import { calculatePercentage } from "../../../../../utils/calculatePercentage";

export function useCartMessagesToUser(
  limits,
  cartTotalVatExcluded,
  freeGoodsAllocationActive
) {
  const [cartMessagesToUser, setCartMessagesToUser] = useState(null);
  const [totalFreeGoodsAmount, setTotalFreeGoodsAmount] = useState(0);

  useEffect(() => {
    if (freeGoodsAllocationActive) {
      const remainingFreeGoodsAmount =
        freeGoodsAllocationActive - cartTotalVatExcluded;

      const message =
        remainingFreeGoodsAmount > 0 ? (
          <>
            Mal fazlası hakkınızın tamamını kullanmak için{" "}
            {parseInt(remainingFreeGoodsAmount)}.
            {stylerDecimalPart(remainingFreeGoodsAmount)} TL değerinde daha ürün
            ekleyebilirsiniz. Kullanamadığınız mal fazlası tutarları sonraki
            siparişinize aktarılır.
          </>
        ) : (
          "Mal fazlası hakkınızın tamamını kullanıyorusunuz."
        );

      setCartMessagesToUser(message);
    } else if (cartTotalVatExcluded > 0 && limits) {
      const purchaseLevelOne = limits.purchaseLevelOne.amount;
      const levelOneExtraGoods = limits.purchaseLevelOne.extraGoods;
      const purchaseLevelTwo = limits.purchaseLevelTwo.amount;
      const levelTwoExtraGoods = limits.purchaseLevelTwo.extraGoods;
      const purchaseLevelThree = limits.purchaseLevelThree.amount;
      const levelThreeExtraGoods = limits.purchaseLevelThree.extraGoods;

      // RETURNS PURCHASE LEVEL ONE MESSAGE
      if (cartTotalVatExcluded < purchaseLevelOne) {
        console.log("Helloooooo1");

        const formattedInteger = Math.abs(
          parseInt(cartTotalVatExcluded - purchaseLevelOne)
        );
        const formattedDecimal = stylerDecimalPart(
          cartTotalVatExcluded - purchaseLevelOne
        );
        const message =
          levelOneExtraGoods === 0 ? (
            <p className="lh1">
              Sipariş oluşturmak için sepete en az{" "}
              <span className="beddo-border-bottom">
                {formattedInteger}.{formattedDecimal}
              </span>{" "}
              TL tutarında daha ürün ekleyiniz.
            </p>
          ) : (
            <p className="lh1">
              Sipariş oluşturmak ve %{levelOneExtraGoods} mal fazlası kazanmak
              için sepete en az{" "}
              <span className="beddo-border-bottom">
                {formattedInteger}.{formattedDecimal}
              </span>{" "}
              TL tutarında daha ürün ekleyiniz.
            </p>
          );
        setCartMessagesToUser(message);

        // RETURNS PURCHASE LEVEL TWO MESSAGE
      } else if (
        purchaseLevelTwo > 0 &&
        cartTotalVatExcluded >= purchaseLevelOne &&
        cartTotalVatExcluded < purchaseLevelTwo
      ) {
        console.log("Helloooooo2");

        const formattedInteger = Math.abs(
          parseInt(cartTotalVatExcluded - purchaseLevelTwo)
        );
        const formattedDecimal = stylerDecimalPart(
          cartTotalVatExcluded - purchaseLevelTwo
        );
        const formattedExtraInteger = Math.abs(
          parseInt(
            calculatePercentage(cartTotalVatExcluded, levelOneExtraGoods)
          )
        );
        const formattedExtraDecimal = stylerDecimalPart(
          calculatePercentage(cartTotalVatExcluded, levelOneExtraGoods)
        );
        const message =
          levelOneExtraGoods === 0 ? (
            <p className="lh1">
              %{levelTwoExtraGoods} mal fazlası kazanmak için sepete en az{" "}
              <span className="beddo-border-bottom">
                {formattedInteger}.{formattedDecimal}
              </span>{" "}
              TL tutarında daha ürün ekleyiniz.
            </p>
          ) : (
            <p className="lh1">
              Tebrikler! %{levelOneExtraGoods} oranında ve{" "}
              <span className="beddo-border-bottom">
                {formattedExtraInteger}.{formattedExtraDecimal}
              </span>{" "}
              TL değerinde mal fazlası kazandınız. Mal fazlasını %
              {levelTwoExtraGoods}
              {getTurkishSuffixForNumbers(levelTwoExtraGoods, "e")} yükseltmek
              için sepete en az{" "}
              <span className="beddo-border-bottom">
                {formattedInteger}.{formattedDecimal}
              </span>{" "}
              TL tutarında daha ürün ekleyiniz.
            </p>
          );

        setCartMessagesToUser(message);
        setTotalFreeGoodsAmount(formattedExtraInteger);

        // RETURNS PURCHASE LEVEL THREE MESSAGE
      } else if (
        purchaseLevelTwo > 0 &&
        cartTotalVatExcluded >= purchaseLevelTwo &&
        cartTotalVatExcluded < purchaseLevelThree
      ) {
        console.log("Helloooooo3");

        const formattedInteger = Math.abs(
          parseInt(cartTotalVatExcluded - purchaseLevelThree)
        );
        const formattedDecimal = stylerDecimalPart(
          cartTotalVatExcluded - purchaseLevelThree
        );
        const formattedExtraInteger = Math.abs(
          parseInt(
            calculatePercentage(cartTotalVatExcluded, levelTwoExtraGoods)
          )
        );
        const formattedExtraDecimal = stylerDecimalPart(
          calculatePercentage(cartTotalVatExcluded, levelTwoExtraGoods)
        );
        const message = (
          <p className="lh1">
            Tebrikler! %{levelTwoExtraGoods} oranında ve{" "}
            <span className="beddo-border-bottom">
              {formattedExtraInteger}.{formattedExtraDecimal}
            </span>{" "}
            TL değerinde mal fazlası kazandınız. Mal fazlasını %
            {levelThreeExtraGoods}
            {getTurkishSuffixForNumbers(levelThreeExtraGoods, "e")} yükseltmek
            için sepete en az{" "}
            <span className="beddo-border-bottom">
              {formattedInteger}.{formattedDecimal}
            </span>{" "}
            TL tutarında daha ürün ekleyiniz.
          </p>
        );

        setCartMessagesToUser(message);
        setTotalFreeGoodsAmount(formattedExtraInteger);

        // RETURNS PURCHASE LEVEL THREE MESSAGE
      } else if (
        purchaseLevelThree > 0 &&
        cartTotalVatExcluded >= purchaseLevelThree
      ) {
        console.log("Helloooooo4");

        const formattedExtraInteger = Math.abs(
          parseInt(
            calculatePercentage(cartTotalVatExcluded, levelThreeExtraGoods)
          )
        );
        const formattedExtraDecimal = stylerDecimalPart(
          calculatePercentage(cartTotalVatExcluded, levelThreeExtraGoods)
        );
        const message = (
          <p className="lh1">
            Müthiş! %{levelThreeExtraGoods} oranında ve{" "}
            <span className="beddo-border-bottom">
              {formattedExtraInteger}.{formattedExtraDecimal}
            </span>{" "}
            TL değerinde mal fazlası kazandınız. Mal fazlası tutarını artırmak
            için sepete ürün eklemeye devam edebilirsiniz.
          </p>
        );

        setCartMessagesToUser(message);
        setTotalFreeGoodsAmount(formattedExtraInteger);
      } else if (
        purchaseLevelTwo === 0 &&
        purchaseLevelThree === 0 &&
        cartTotalVatExcluded >= purchaseLevelOne
      ) {
        console.log("Helloooooo5");

        setCartMessagesToUser(null);
      } else if (
        purchaseLevelTwo > 0 &&
        purchaseLevelThree === 0 &&
        cartTotalVatExcluded >= purchaseLevelTwo
      ) {
        console.log("Helloooooo6");

        const formattedExtraInteger = Math.abs(
          parseInt(
            calculatePercentage(cartTotalVatExcluded, levelTwoExtraGoods)
          )
        );
        const formattedExtraDecimal = stylerDecimalPart(
          calculatePercentage(cartTotalVatExcluded, levelTwoExtraGoods)
        );
        const message = (
          <p className="lh1">
            Tebrikler! %{levelTwoExtraGoods} oranında ve{" "}
            <span className="beddo-border-bottom">
              {formattedExtraInteger}.{formattedExtraDecimal}
            </span>{" "}
            TL değerinde mal fazlası kazandınız.
          </p>
        );
        setCartMessagesToUser(message);
      }
    }
  }, [limits, cartTotalVatExcluded, freeGoodsAllocationActive]);

  return { cartMessagesToUser, totalFreeGoodsAmount };
}
