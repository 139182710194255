import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionToGetCampaignedProductDetails } from "../actions/productActions";
import "./MessageProductFeature.css";

export default function MessageProductFeature(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  // PROPS ARE COMING FROM "ProductScreen" AND HomeScreen
  const { product } = props;

  //REQUEST CAMPAIGNED PRODUCT DETAILS FROM SERVER
  const campaignedProductDetails = useSelector(
    (state) => state.campaignedProductDetails
  );
  const {
    loading: loadingCampaign,
    error: errorCampaign,
    product: productCampaign,
  } = campaignedProductDetails;

  useEffect(() => {
    if (
      location.pathname === "/" &&
      product &&
      product.freeProductId &&
      product._id !== product.freeProductId
    ) {
      dispatch(actionToGetCampaignedProductDetails(product.freeProductId));
    }
  }, [dispatch, product, location]);

  const [arrayProductCampaign, arraySetProductCampaign] = useState(""); // CREATE AN ARRAY BY THE STATES COMING FROM REDUX productCampaign
  useEffect(() => {
    if (!loadingCampaign && !errorCampaign && productCampaign) {
      arraySetProductCampaign((prev) => [
        ...prev,
        {
          name: productCampaign.name,
          id: productCampaign._id,
          skus: productCampaign.skus,
        },
      ]);
    }
  }, [loadingCampaign, errorCampaign, productCampaign]);

  // REACT STATE THAT HOLDS PRODUCT FEATURE
  const [productFeature, setProductFeature] = useState("");

  // SET PRODUCT FEATURE
  useEffect(() => {
    if (
      product.buyOneGetOneFree &&
      product._id !== product.freeProductId &&
      arrayProductCampaign &&
      arrayProductCampaign.length > 0
    ) {
      if (
        !loadingCampaign &&
        !errorCampaign &&
        productCampaign &&
        productCampaign.name
      ) {
        const inStocks = arrayProductCampaign.filter(
          (product) => product.skus.reduce((a, c) => a + c.countInStock, 0) > 0
        );

        if (inStocks && inStocks.length > 0) {
          setProductFeature(
            `1 Alana 1 ${
              arrayProductCampaign
                .map(
                  (camp) =>
                    product.freeProductId === camp.id && camp.name.split("|")[1] // MAP THROUGH ARRAY AND MATCH RELATED DATA WITH REDUX STATE ARRAY
                )
                .filter((product) => product !== false)[0]
            } Bedava`
          );
        }
      }
    }
    if (product.buyOneGetOneFree && product._id === product.freeProductId) {
      setProductFeature("1 Alana 1 Bedava");
    }
    if (product.name.indexOf("Yeni") !== -1) {
      return setProductFeature("Yeni");
    }
    if (product.name.indexOf("Büyük Boy") !== -1) {
      return setProductFeature("Büyük Boy");
    }
    if (product.name.indexOf("Yumuşak") !== -1) {
      return setProductFeature("Yumuşak");
    }
  }, [
    product,
    loadingCampaign,
    errorCampaign,
    productCampaign,
    arrayProductCampaign,
  ]);

  return (
    <div className="w100p h2r col">
      {productFeature && (
        <div className="product-feature">{productFeature}</div>
      )}
    </div>
  );
}
