//CREATE YURT SHIPMENT
export const ADMIN_YURT_CREATE_SHIPMENT_REQUEST =
  "ADMIN_YURT_CREATE_SHIPMENT_REQUEST";
export const ADMIN_YURT_CREATE_SHIPMENT_SUCCESS =
  "ADMIN_YURT_CREATE_SHIPMENT_SUCCESS";
export const ADMIN_YURT_CREATE_SHIPMENT_FAIL =
  "ADMIN_YURT_CREATE_SHIPMENT_FAIL";
export const ADMIN_YURT_CREATE_SHIPMENT_RESET =
  "ADMIN_YURT_CREATE_SHIPMENT_RESET";

// EXPORT YURT ICI KARGO EXCEL LIST FOR PENDING SHIPPING ORDERS
export const ADMIN_YURT_EXCEL_REQUEST = "ADMIN_YURT_EXCEL_REQUEST";
export const ADMIN_YURT_EXCEL_SUCCESS = "ADMIN_YURT_EXCEL_SUCCESS";
export const ADMIN_YURT_EXCEL_FAIL = "ADMIN_YURT_EXCEL_FAIL";
export const ADMIN_YURT_EXCEL_RESET = "ADMIN_YURT_EXCEL_RESET";

// CHECK EXPORTED YURT ICI KARGO ORDERS
export const ADMIN_YURT_UPDATE_AS_REQUEST = "ADMIN_YURT_UPDATE_AS_REQUEST";
export const ADMIN_YURT_UPDATE_AS_SUCCESS = "ADMIN_YURT_UPDATE_AS_SUCCESS";
export const ADMIN_YURT_UPDATE_AS_FAIL = "ADMIN_YURT_UPDATE_AS_FAIL";
export const ADMIN_YURT_UPDATE_AS_RESET = "ADMIN_YURT_UPDATE_AS_RESET";

//CANCEL YURT SHIPMENT
export const YURT_CANCEL_SHIPMENT_REQUEST = "YURT_CANCEL_SHIPMENT_REQUEST";
export const YURT_CANCEL_SHIPMENT_SUCCESS = "YURT_CANCEL_SHIPMENT_SUCCESS";
export const YURT_CANCEL_SHIPMENT_FAIL = "YURT_CANCEL_SHIPMENT_FAIL";
export const YURT_CANCEL_SHIPMENT_RESET = "YURT_CANCEL_SHIPMENT_RESET";

//CREATE YURT SHIPMENT REFUND
export const YURT_REFUND_SHIPMENT_REQUEST = "YURT_REFUND_SHIPMENT_REQUEST";
export const YURT_REFUND_SHIPMENT_SUCCESS = "YURT_REFUND_SHIPMENT_SUCCESS";
export const YURT_REFUND_SHIPMENT_FAIL = "YURT_REFUND_SHIPMENT_FAIL";
export const YURT_REFUND_SHIPMENT_RESET = "YURT_REFUND_SHIPMENT_RESET";

//CANCEL YURT REFUND CODE
export const YURT_CANCEL_REFUND_REQUEST = "YURT_CANCEL_REFUND_REQUEST";
export const YURT_CANCEL_REFUND_SUCCESS = "YURT_CANCEL_REFUND_SUCCESS";
export const YURT_CANCEL_REFUND_FAIL = "YURT_CANCEL_REFUND_FAIL";
export const YURT_CANCEL_REFUND_RESET = "YURT_CANCEL_REFUND_RESET";

//CREATE MULTI YURT SHIPMENT
export const YURT_CREATE_MULTI_REQUEST = "YURT_CREATE_MULTI_REQUEST";
export const YURT_CREATE_MULTI_SUCCESS = "YURT_CREATE_MULTI_SUCCESS";
export const YURT_CREATE_MULTI_FAIL = "YURT_CREATE_MULTI_FAIL";
export const YURT_CREATE_MULTI_RESET = "YURT_CREATE_MULTI_RESET";
