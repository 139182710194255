//ADD A NEW FIELD TO AN EXISTING DOCUMENT
export const ADMIN_ADD_FIELD_REQUEST = "ADMIN_ADD_FIELD_REQUEST";
export const ADMIN_ADD_FIELD_SUCCESS = "ADMIN_ADD_FIELD_SUCCESS";
export const ADMIN_ADD_FIELD_FAIL = "ADMIN_ADD_FIELD_FAIL";
export const ADMIN_ADD_FIELD_RESET = "ADMIN_ADD_FIELD_RESET";

//CREATE UPS CITY LIST
export const ADMIN_CREATE_UPS_CITY_REQUEST = "ADMIN_CREATE_UPS_CITY_REQUEST";
export const ADMIN_CREATE_UPS_CITY_SUCCESS = "ADMIN_CREATE_UPS_CITY_SUCCESS";
export const ADMIN_CREATE_UPS_CITY_FAIL = "ADMIN_CREATE_UPS_CITY_FAIL";
export const ADMIN_CREATE_UPS_CITY_RESET = "ADMIN_CREATE_UPS_CITY_RESET";

//GET UPS CITY LIST
export const ADMIN_UPS_CITY_LIST_REQUEST = "ADMIN_UPS_CITY_LIST_REQUEST";
export const ADMIN_UPS_CITY_LIST_SUCCESS = "ADMIN_UPS_CITY_LIST_SUCCESS";
export const ADMIN_UPS_CITY_LIST_FAIL = "ADMIN_UPS_CITY_LIST_FAIL";

//GET UPS CITY DETAILS
export const ADMIN_UPS_CITY_DETAILS_REQUEST = "ADMIN_UPS_CITY_DETAILS_REQUEST";
export const ADMIN_UPS_CITY_DETAILS_SUCCESS = "ADMIN_UPS_CITY_DETAILS_SUCCESS";
export const ADMIN_UPS_CITY_DETAILS_FAIL = "ADMIN_UPS_CITY_DETAILS_FAIL";
export const ADMIN_UPS_CITY_DETAILS_RESET = "ADMIN_UPS_CITY_DETAILS_RESET";

//UPS CITY EDIT
export const ADMIN_UPS_CITY_EDIT_REQUEST = "ADMIN_UPS_CITY_EDIT_REQUEST";
export const ADMIN_UPS_CITY_EDIT_SUCCESS = "ADMIN_UPS_CITY_EDIT_SUCCESS";
export const ADMIN_UPS_CITY_EDIT_FAIL = "ADMIN_UPS_CITY_EDIT_FAIL";
export const ADMIN_UPS_CITY_EDIT_RESET = "ADMIN_UPS_CITY_EDIT_RESET";

//CREATE UPS SHIPMENT
export const ADMIN_UPS_CREATE_SHIPMENT_REQUEST =
  "ADMIN_UPS_CREATE_SHIPMENT_REQUEST";
export const ADMIN_UPS_CREATE_SHIPMENT_SUCCESS =
  "ADMIN_UPS_CREATE_SHIPMENT_SUCCESS";
export const ADMIN_UPS_CREATE_SHIPMENT_FAIL = "ADMIN_UPS_CREATE_SHIPMENT_FAIL";
export const ADMIN_UPS_CREATE_SHIPMENT_RESET =
  "ADMIN_UPS_CREATE_SHIPMENT_RESET";
//CREATE UPS COD SHIPMENT
export const ADMIN_UPS_CREATE_COD_SHIPMENT_REQUEST =
  "ADMIN_UPS_CREATE_COD_SHIPMENT_REQUEST";
export const ADMIN_UPS_CREATE_COD_SHIPMENT_SUCCESS =
  "ADMIN_UPS_CREATE_COD_SHIPMENT_SUCCESS";
export const ADMIN_UPS_CREATE_COD_SHIPMENT_FAIL =
  "ADMIN_UPS_CREATE_COD_SHIPMENT_FAIL";
export const ADMIN_UPS_CREATE_COD_SHIPMENT_RESET =
  "ADMIN_UPS_CREATE_COD_SHIPMENT_RESET";

//CANCEL UPS SHIPMENT
export const UPS_CANCEL_SHIPMENT_REQUEST = "UPS_CANCEL_SHIPMENT_REQUEST";
export const UPS_CANCEL_SHIPMENT_SUCCESS = "UPS_CANCEL_SHIPMENT_SUCCESS";
export const UPS_CANCEL_SHIPMENT_FAIL = "UPS_CANCEL_SHIPMENT_FAIL";
export const UPS_CANCEL_SHIPMENT_RESET = "UPS_CANCEL_SHIPMENT_RESET";
