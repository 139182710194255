//ADMIN CREATE BRAND
export const ADMIN_VARIANT_CREATE_REQUEST = "ADMIN_VARIANT_CREATE_REQUEST";
export const ADMIN_VARIANT_CREATE_SUCCESS = "ADMIN_VARIANT_CREATE_SUCCESS";
export const ADMIN_VARIANT_CREATE_FAIL = "ADMIN_VARIANT_CREATE_FAIL";
export const ADMIN_VARIANT_CREATE_RESET = "ADMIN_VARIANT_CREATE_RESET";

//ADMIN BRAND LIST
export const ADMIN_VARIANT_LIST_REQUEST = "ADMIN_VARIANT_LIST_REQUEST";
export const ADMIN_VARIANT_LIST_SUCCESS = "ADMIN_VARIANT_LIST_SUCCESS";
export const ADMIN_VARIANT_LIST_FAIL = "ADMIN_VARIANT_LIST_FAIL";

//ADMIN BRAND DETAILS
export const ADMIN_VARIANT_DETAILS_REQUEST = "ADMIN_VARIANT_DETAILS_REQUEST";
export const ADMIN_VARIANT_DETAILS_SUCCESS = "ADMIN_VARIANT_DETAILS_SUCCESS";
export const ADMIN_VARIANT_DETAILS_FAIL = "ADMIN_VARIANT_DETAILS_FAIL";

//ADMIN BRAND EDIT
export const ADMIN_VARIANT_EDIT_REQUEST = "ADMIN_VARIANT_EDIT_REQUEST";
export const ADMIN_VARIANT_EDIT_SUCCESS = "ADMIN_VARIANT_EDIT_SUCCESS";
export const ADMIN_VARIANT_EDIT_FAIL = "ADMIN_VARIANT_EDIT_FAIL";
export const ADMIN_VARIANT_EDIT_RESET = "ADMIN_VARIANT_EDIT_RESET";
