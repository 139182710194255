import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToAddOrRemoveWholeSaleItemInCart = createAsyncThunk(
  "wholeSale/jobInActionToAddOrRemoveWholeSaleItemInCart",
  async (
    { productId, skuCode, qty, buttonLocation, free, priceObj },
    thunkAPI
  ) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        `/api/products/skudetails/${productId}/${skuCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      /* const wholeSaleCartItems =
        thunkAPI.getState().addOrRemoveWholeSaleItemInCart.wholeSaleCartItems;*/

      // Check if free product is same product or another product
      function isBuyOneGetOtherProductFree(data) {
        if (
          data.skus[0]?.buyOneGetOneFree &&
          data.skus[0]?.freeProductId !== data.skus[0]?.productId?.toString()
        ) {
          return true;
        } else {
          return false;
        }
      }

      // ADD ITEM IN CART
      thunkAPI.dispatch(
        addOrRemoveWholeSaleItemInCartSlice.actions.addItemToCart({
          addToCartButtonLocation: buttonLocation,
          categoryPath: data.skus[0].categoryPath,
          name: data.skus[0].name,
          target: data.skus[0].target,
          type: data.skus[0].type,
          size: data.skus[0].size,
          color: data.skus[0].color,
          sku: data.skus[0].sku,
          skuId: data.skus[0]._id,
          product: data._id,
          image: data.skus[0].skuImages[0],
          buyOneGetOneFree: data.skus[0].buyOneGetOneFree,
          buyOneGetOtherProductFree: isBuyOneGetOtherProductFree(data),
          freeProducts: [],
          price: free ? 0 : priceObj?.wholeSalePrice,
          itemsTotalPrice: priceObj?.wholeSalePrice * Number(qty),
          vat: data.skus[0].vat,
          qty: Number(qty),
          productUnit: data.skus[0].productUnit,
        })
      );

      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  loading: false,
  error: null,
  itemAddedInCart: false,
  wholeSaleCartItems: [],
  shippingPrice: 0,
  totalPrice: 0,
  taxPrice: 0,
};

// Create a slice using createSlice
const addOrRemoveWholeSaleItemInCartSlice = createSlice({
  name: "addOrRemoveWholeSaleItemInCart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const { payload } = action;

      // RETURNS INDEX IF EXIST AND RETURNS -1 IF IT DOESNT EXIST
      const existingItemIndex = state.wholeSaleCartItems.findIndex(
        (item) => item.skuId === payload.skuId
      );

      const newItem = { ...payload }; // Create a new item to avoid mutations

      if (payload.price === 0) {
        const cartLength = state.wholeSaleCartItems.length;

        // It is a free product and free products are added in cart just after the free product provider product. Therefore we push the free product in the item which is 1 index previous than free product
        state.wholeSaleCartItems[cartLength - 1].freeProducts.push(newItem);
      } else if (existingItemIndex !== -1) {
        // If the item already exists in the cart, replace it with the new payload
        state.wholeSaleCartItems[existingItemIndex] = newItem;
      } else {
        // If the item doesn't exist, add it to the cart
        state.wholeSaleCartItems.push(newItem);
      }

      // Update localStorage with the modified state
      sessionStorage.setItem(
        "wholeSaleCartItems",
        JSON.stringify(state.wholeSaleCartItems)
      );
    },

    incrementQty: (state, action) => {
      const { payload } = action;
      const itemId = payload;

      // Find the item by itemId and increment its quantity
      const itemsToIncrement = state.wholeSaleCartItems.filter(
        (item) => item.skuId === itemId
      );

      if (itemsToIncrement.length > 0) {
        itemsToIncrement.forEach((item) => {
          if (item.freeProducts.length > 0) {
            item.freeProducts.forEach((item) => (item.qty += 1));
          }
          return [
            (item.qty += 1),
            (item.itemsTotalPrice = item.qty * item.price),
          ];
        });

        sessionStorage.setItem(
          "wholeSaleCartItems",
          JSON.stringify(state.wholeSaleCartItems)
        );
      } else {
        console.error(`Item with itemId ${itemId} not found.`);
      }
    },
    decrementQty: (state, action) => {
      const { payload } = action;
      const itemId = payload;

      // Find the item by itemId and increment its quantity
      const itemsToIncrement = state.wholeSaleCartItems.filter(
        (item) => item.skuId === itemId
      );

      if (itemsToIncrement.length > 0) {
        itemsToIncrement.forEach((item) => {
          if (item.freeProducts.length > 0) {
            item.freeProducts.forEach(
              (item) => item.qty > 1 && (item.qty -= 1)
            );
          }

          return (
            item.qty > 1 && [
              (item.qty -= 1),
              (item.itemsTotalPrice = item.qty * item.price),
            ]
          );
        });

        sessionStorage.setItem(
          "wholeSaleCartItems",
          JSON.stringify(state.wholeSaleCartItems)
        );
      } else {
        console.error(`Item with itemId ${itemId} not found.`);
      }
    },
    deleteItem: (state, action) => {
      const { payload } = action;
      const deleteItemId = payload;

      // Filter out the item to be deleted
      state.wholeSaleCartItems = state.wholeSaleCartItems.filter(
        (item) => item.skuId !== deleteItemId
      );

      sessionStorage.setItem(
        "wholeSaleCartItems",
        JSON.stringify(state.wholeSaleCartItems)
      );
    },
    emptyCart: (state, action) => {
      state.wholeSaleCartItems = [];
      sessionStorage.removeItem("wholeSaleCartItems");
      sessionStorage.removeItem("cartId");
    },
    resetItemAddedInCart: (state) => {
      state.itemAddedInCart = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToAddOrRemoveWholeSaleItemInCart.pending, (state) => {
        state.loading = true;
        state.itemAddedInCart = false;
      })
      .addCase(
        actionToAddOrRemoveWholeSaleItemInCart.fulfilled,
        (state, action) => {
          const { freeProvider } = action.meta.arg;

          state.loading = false;
          state.itemAddedInCart = freeProvider ? false : true;
        }
      )
      .addCase(
        actionToAddOrRemoveWholeSaleItemInCart.rejected,
        (state, action) => {
          state.loading = false;
          state.itemAddedInCart = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const {
  addItemToCart,
  incrementQty,
  decrementQty,
  deleteItem,
  emptyCart,
  resetItemAddedInCart,
} = addOrRemoveWholeSaleItemInCartSlice.actions;

// Export the reducer
const reducerToAddOrRemoveWholeSaleItemInCart =
  addOrRemoveWholeSaleItemInCartSlice.reducer;

// Export the async action creator for use in components
export {
  reducerToAddOrRemoveWholeSaleItemInCart,
  addItemToCart,
  incrementQty,
  decrementQty,
  deleteItem,
  emptyCart,
  resetItemAddedInCart,
};
