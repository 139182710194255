import {
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_SKU_PRICE_REQUEST,
  PRODUCT_SKU_PRICE_SUCCESS,
  PRODUCT_SKU_PRICE_FAIL,
  PRODUCT_SKU_CLASSIFY_REQUEST,
  PRODUCT_SKU_CLASSIFY_SUCCESS,
  PRODUCT_SKU_CLASSIFY_FAIL,
  PRODUCT_SKU_PRICE_RESET,
  PRODUCT_DETAILS_CAMPAIGNED_REQUEST,
  PRODUCT_DETAILS_CAMPAIGNED_SUCCESS,
  PRODUCT_DETAILS_CAMPAIGNED_FAIL,
  PRODUCT_SKU_COMPAIGNED_REQUEST,
  PRODUCT_SKU_COMPAIGNED_SUCCESS,
  PRODUCT_SKU_COMPAIGNED_FAIL,
  PRODUCT_SKU_COMPAIGNED_RESET,
  PRODUCT_DETAILS_CAMPAIGNED_RESET,
  PRODUCT_SIDE_LIST_REQUEST,
  PRODUCT_SIDE_LIST_SUCCESS,
  PRODUCT_SIDE_LIST_FAIL,
  PRODUCT_DETAILS_RESET,
  PRODUCT_SIDE_LIST_RESET,
} from "../constants/productConstants";

export const reducerToGetProductList = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        activeProductCount: action.payload.activeProductCount,
        allProducts: action.payload.allProducts,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reducerToGetProductDetails = (
  state = { loading: true, product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};
// CAMPAINGNED PRODUCT DETAILS
export const reducerToGetCampaignedProductDetails = (
  state = { loading: true, product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_CAMPAIGNED_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_CAMPAIGNED_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_CAMPAIGNED_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_CAMPAIGNED_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE SKU PRICE IN REDUX STATE
export const reducerBringInSkuPrice = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_SKU_PRICE_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_SKU_PRICE_SUCCESS:
      return { loading: false, skuData: action.payload };
    case PRODUCT_SKU_PRICE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SKU_PRICE_RESET:
      return {};
    default:
      return state;
  }
};
//CAMPAIGNED PRODUCT SKU
export const reducerToBringInCampaignedSku = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_SKU_COMPAIGNED_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_SKU_COMPAIGNED_SUCCESS:
      return { loading: false, skuData: action.payload };
    case PRODUCT_SKU_COMPAIGNED_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SKU_COMPAIGNED_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE THE SKU PRICE IN REDUX STATE
export const reducerClassifySkus = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCT_SKU_CLASSIFY_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_SKU_CLASSIFY_SUCCESS:
      return { loading: false, skusClassify: action.payload };
    case PRODUCT_SKU_CLASSIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//LIST SIDE PRODUCTS IF ANY
export const reducerToGetSideProductsList = (
  state = { loading: true, sideProducts: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SIDE_LIST_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_SIDE_LIST_SUCCESS:
      return {
        loading: false,
        sideProducts: action.payload,
      };
    case PRODUCT_SIDE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_SIDE_LIST_RESET:
      return { sideProducts: [] };
    default:
      return state;
  }
};
