import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToUpdateWholeSaleOrderPaymentProof = createAsyncThunk(
  "wholeSale/jobInActionToUpdateWholeSaleOrderPaymentProof",
  async ({ id, documentUrl }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/updateWholeSaleOrderPaymentProof",
        { id, documentUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  updatedWholeSaleOrder: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleOrderPaymentProofSlice = createSlice({
  name: "updateWholeSaleAccount",
  initialState,
  reducers: {
    resetUpdateWholeSaleOrderPaymentProof: (state) => {
      state.updatedWholeSaleOrder = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateWholeSaleOrderPaymentProof.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionToUpdateWholeSaleOrderPaymentProof.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updatedWholeSaleOrder = action.payload;
        }
      )
      .addCase(
        actionToUpdateWholeSaleOrderPaymentProof.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetUpdateWholeSaleOrderPaymentProof } =
  updateWholeSaleOrderPaymentProofSlice.actions;

// Export the reducer
const reducerToUpdateWholeSaleOrderPaymentProof =
  updateWholeSaleOrderPaymentProofSlice.reducer;

// Export the async action creator for use in components
export {
  reducerToUpdateWholeSaleOrderPaymentProof,
  resetUpdateWholeSaleOrderPaymentProof,
};
