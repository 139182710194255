import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionAdminToUpdateCurrentAccountWholeSalePricing =
  createAsyncThunk(
    "updateCurrentAccountWholeSalePricing",
    async (updateData, thunkAPI) => {
      // GET JWT TOKEN FROM REDUX STORE
      const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

      try {
        // Call your API function here
        const { data } = await axios.put(
          "/api/wholesale/updateCurrentAccountWholeSalePricing",
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Assuming the API response contains the data
        return data;
      } catch (error) {
        let { message } = errorMessage(error);
        throw new Error(message);
      }
    }
  );

// Define an initial state for the slice
const initialState = {
  updatedCurrentAccountWholeSalePricing: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateCurrentAccountWholeSalePricingSlice = createSlice({
  name: "updateCurrentAccountWholeSalePricing",
  initialState,
  reducers: {
    resetUpdateCurrentAccountWholeSalePricing: (state) => {
      state.updatedCurrentAccountWholeSalePricing = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        actionAdminToUpdateCurrentAccountWholeSalePricing.pending,
        (state) => {
          state.loading = true;
        }
      )
      .addCase(
        actionAdminToUpdateCurrentAccountWholeSalePricing.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updatedCurrentAccountWholeSalePricing = action.payload;
        }
      )
      .addCase(
        actionAdminToUpdateCurrentAccountWholeSalePricing.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetUpdateCurrentAccountWholeSalePricing } =
  updateCurrentAccountWholeSalePricingSlice.actions;

// Export the reducer
const reducerAdminToUpdateCurrentAccountWholeSalePricing =
  updateCurrentAccountWholeSalePricingSlice.reducer;

// Export the async action creator for use in components
export {
  reducerAdminToUpdateCurrentAccountWholeSalePricing,
  resetUpdateCurrentAccountWholeSalePricing,
};
