// slices/userIdentifierSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

// Async action to update isExtraDiscountShown property
export const actionToUpdateExtraDiscountShown = createAsyncThunk(
  "userIdentifier/updateExtraDiscountShown",
  async (updateObject, thunkAPI) => {
    try {
      // Call your API function here to update the isExtraDiscountShown property
      const { data } = await axios.put(
        "/api/userIdentification/updateExtraDiscountShown",
        updateObject
      );
      // Assuming the API response contains the updated user data
      return data;
    } catch (error) {
      // Handle error scenarios, for example, show a notification
      // You can also reject the thunk with an error message here
      const { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

const updateExtraDiscountShownSlice = createSlice({
  name: "extraDiscountShownUpdater",
  initialState: {
    extraDiscountShown: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateExtraDiscountShown.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToUpdateExtraDiscountShown.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.extraDiscountShown = action.payload; // Update the extraDiscountShown property
      })
      .addCase(actionToUpdateExtraDiscountShown.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store the error message for display, if needed
      });
  },
});

const reducerToUpdateExtraDiscountShown = updateExtraDiscountShownSlice.reducer;

export default reducerToUpdateExtraDiscountShown;
