import React from "react";

export default function CartSmallInfos(props) {
  // PROPS COMING FROM CartScreenCart
  const { title, message } = props;

  return (
    <div className="lightbck marq padq">
      <h3 className="inline beddo-border-bottom padupsideq marb-q bold-font">
        {title}
      </h3>
      <p>{message}</p>
    </div>
  );
}
