import React from "react";
import { stylerDecimalPart } from "../../../../../utils/stylerDecimalPart";

export default function ThresholdDisplayFreeGoods(props) {
  // PROPS COMING FROM CartFooter
  const {
    cartTotalVatExcluded,
    freeGoodsAllocationActive,
    cartMessagesToUser,
  } = props;

  const widthPercentage =
    (cartTotalVatExcluded / freeGoodsAllocationActive) * 100;

  const stylingLevelOne = {
    position: "absolute",
    top: "0",
    width: `${widthPercentage}%`,
    background: "linear-gradient(to right, lightblue 0%, lightgreen 90%)",
    zIndex: 1,
  };

  const paragraphStyle = {
    position: "relative", // Ensure z-index works
    fontSize: "0.75rem",
    zIndex: 2, // Make paragraphs appear above the backgrounds
  };

  console.log("cartTotalVatExcluded : " + cartTotalVatExcluded);
  console.log("freeGoodsAllocationActive : " + freeGoodsAllocationActive);
  console.log("widthPercentage : " + widthPercentage);

  return (
    <div className="w100p h4r">
      <div className="row-center-nowrap w100p mar h0-75r">
        <div
          title={`${freeGoodsAllocationActive.toFixed(2)} TL`}
          className="relative w100p h100p txtcenter blue-border"
        >
          <p style={paragraphStyle}>
            {parseInt(freeGoodsAllocationActive)}.
            {stylerDecimalPart(freeGoodsAllocationActive)} TL
          </p>
          <div style={stylingLevelOne} className="row-left h100p"></div>
        </div>
      </div>

      {cartMessagesToUser && (
        <div className="txtleft lh1 fs-85 purplelighterbck padq marupsideh">
          {cartMessagesToUser}
        </div>
      )}
    </div>
  );
}
