import React, { Suspense, useEffect } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import ReactGA from "react-ga4";
import { UAParser } from "ua-parser-js";
import NavBar from "../NavBar";
import MessageUnpaidOrders from "../MessageUnpaidOrders";
import MessageAnnounces from "../MessageAnnounces";
import PopsComponent from "../PopAnnouncesAndOffers/PopsComponent";
import DetectFBCookie from "../FacebookCookieDetect/DetectFBCookie";
import SuspenseFallback from "../SuspenseFallback";
import GoBack from "../GoBack";
import useWidth from "../../hooks/useWidth";
import useScrollDirection from "../../hooks/useScrollDirection";
import HomeScreen from "../../screens/HomeScreen/HomeScreen";
import PrivateRoute from "../PrivateRoute";
import Footer from "../Footer";
import ScrollToTopIcon from "../ScrollToTopIcon";
import CartScreen from "../../screens/CartScreen/CartScreen";
import FourOFour from "../FourOFour";
import RouteChangeTracker from "../RouteChangeTracker";

const ProductScreenNew = React.lazy(() =>
  import("../../screens/NewProductScreen/ProductScreenNew")
);
const CustomerServiceSigninScreen = React.lazy(() =>
  import("../../screens/CustomerService/CustomerServiceSigninScreen")
);
const CustomerServiceScreen = React.lazy(() =>
  import("../../screens/CustomerService/CustomerServiceScreen")
);
const ProfileScreen = React.lazy(() => import("../../screens/ProfileScreen"));
const OrdersScreen = React.lazy(() =>
  import("../../screens/NewOrderHistory/OrdersScreen")
);
const OrderDetailsScreen = React.lazy(() =>
  import("../../screens/NewOrderHistory/OrderDetailsScreen")
);
const SupportScreen = React.lazy(() => import("../../screens/SupportScreen"));
const ContactScreen = React.lazy(() => import("../../screens/ContactScreen"));
const AboutScreen = React.lazy(() => import("../../screens/AboutScreen"));
const ProductReviewsScreen = React.lazy(() =>
  import("../../screens/ProductReviewsScreen")
);
const QnaScreen = React.lazy(() => import("../../screens/Qna/QnaScreen"));
const CreditCardPaymentScreen = React.lazy(() =>
  import("../../screens/CreditCardPaymentScreen")
);
const OrderScreen = React.lazy(() =>
  import("../../screens/OrderScreen/OrderScreen")
);
const ThankYouScreen = React.lazy(() => import("../../screens/ThankYouScreen"));
const SponsoredScreen = React.lazy(() =>
  import("../../screens/SponsoredScreen")
);
const HumanResourcesScreen = React.lazy(() =>
  import("../../screens/HumanResourcesScreen")
);
const DistanceSaleScreen = React.lazy(() =>
  import("../../screens/DistanceSaleScreen")
);
const PrivacyAgreementScreen = React.lazy(() =>
  import("../../screens/PrivacyAgreementScreen")
);
const WithdrawalRightScreen = React.lazy(() =>
  import("../../screens/WithdrawalRightScreen")
);
const CreateRefundCodeScreen = React.lazy(() =>
  import("../../screens/RefundScreen/CreateRefundCodeScreen")
);
const CreateRefundInfoScreen = React.lazy(() =>
  import("../../screens/RefundScreen/CreateRefundInfoScreen")
);
const HowDiscountWorks = React.lazy(() =>
  import("../../modules/customerInterface/retail/mainLand/HowDiscountWorks")
);
const DealerApplicationScreen = React.lazy(() =>
  import("../../screens/WebForms/DealerApplicationScreen")
);
const SupplierApplicationScreen = React.lazy(() =>
  import("../../screens/WebForms/SupplierApplicationScreen")
);
const WholesaleApplicationScreen = React.lazy(() =>
  import("../../screens/WebForms/WholesaleApplicationScreen")
);
const ExportApplicationScreen = React.lazy(() =>
  import("../../screens/WebForms/ExportApplicationScreen")
);
const SmsCampaignRemoveFromListScreen = React.lazy(() =>
  import("../../screens/AdminSmsCampaign/SmsCampaignRemoveFromListScreen")
);
const ProductLandings = React.lazy(() =>
  import("../../screens/AdminCampaign/ProductLandings")
);
const WholeSaleExplainerLandingPage = React.lazy(() =>
  import(
    "../../modules/customerInterface/retail/mainLand/WholeSaleExplainerLandingPage"
  )
);

export default function AppUser(props) {
  const location = useLocation();
  //DETECTS URL AND SEARCH CHANGES AND REPORT TO GOOGLE ANALYTICS
  const width = useWidth();
  const scrollDirection = useScrollDirection();
  const userBrowser = new UAParser().getBrowser();

  // PROPS
  const {
    hasAnOrder,
    isTopBarActive,
    createdFingerPrint,
    cartTotal,
    isPending,
    RedirectShortLinks,
  } = props;

  useEffect(() => {
    // Initialize GA4 if not already initialized
    if (!ReactGA.isInitialized && process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-XL50QFGLQ9"); // Replace with your GA4 Measurement ID
    }

    // Track page views on location change
    const pagePath = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: pagePath });
  }, [location]);

  return (
    <>
      <NavBar />
      <MessageUnpaidOrders
        hasAnOrder={hasAnOrder}
        isTopBarActive={isTopBarActive}
      />
      <MessageAnnounces />
      <PopsComponent createdFingerPrint={createdFingerPrint} />
      <DetectFBCookie />

      <>
        <Suspense fallback={<SuspenseFallback></SuspenseFallback>}>
          <RouteChangeTracker />
          {width < 800 &&
          scrollDirection === "up" &&
          location.pathname !== "/" &&
          !location.pathname.includes("/land/") &&
          userBrowser.name === "Instagram" ? (
            <div
              style={{
                bottom: "1.15rem",
                left: "0.15rem",
                position: "fixed",
                zIndex: "1500",
              }}
            >
              <GoBack />
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              paddingTop:
                !location.pathname.includes("admin") &&
                width < 800 &&
                (hasAnOrder || isTopBarActive)
                  ? "4rem"
                  : "",
            }}
            className={
              location.pathname.includes("/land/") && width < 600
                ? "user-screen-land"
                : "user-screen"
            }
          >
            <Switch>
              <Route path={"/ara/:keyword"} component={HomeScreen} />
              <Route
                exact
                path={"/sayfa/:pageNumber"}
                render={(routeProps) => (
                  <HomeScreen
                    createdFingerPrint={createdFingerPrint}
                    cartTotal={cartTotal}
                    {...routeProps}
                  />
                )}
              />
              <Route
                exact
                path={"/ara/:keyword/sayfa/:pageNumber"}
                render={(routeProps) => (
                  <HomeScreen
                    createdFingerPrint={createdFingerPrint}
                    cartTotal={cartTotal}
                    {...routeProps}
                  />
                )}
              />
              <Route
                exact
                path={"/"}
                render={(routeProps) => (
                  <HomeScreen
                    createdFingerPrint={createdFingerPrint}
                    cartTotal={cartTotal}
                    {...routeProps}
                  />
                )}
              />
              <Route
                exact
                path="/sepet/:id?"
                render={(routeProps) => (
                  <CartScreen
                    firstVisitTime={createdFingerPrint?.firstVisitTime}
                    createdFingerPrint={createdFingerPrint}
                    cartTotal={cartTotal}
                    {...routeProps}
                  />
                )}
              />
              <Route exact path={"/hakkimizda"} component={AboutScreen} />
              <Route exact path={"/iletisim"} component={ContactScreen} />
              <Route
                exact
                path="/land/:title/:id/:urlExt"
                render={(routeProps) => (
                  <ProductLandings
                    createdFingerPrint={createdFingerPrint}
                    cartTotal={cartTotal}
                    {...routeProps}
                  />
                )}
              />
              <Route
                exact
                path="/urun/:title/:id"
                render={(routeProps) => (
                  <ProductScreenNew
                    createdFingerPrint={createdFingerPrint}
                    {...routeProps}
                  />
                )}
              />
              <Route
                path="/yorumlar/:title/:id"
                component={ProductReviewsScreen}
              />
              <Route path="/sorucevap/:title/:id" component={QnaScreen} />
              <Route
                path="/siparis-bilgileri-adim-1/2"
                render={(routeProps) => (
                  <OrderScreen
                    createdFingerPrint={createdFingerPrint}
                    isTherePending={isPending?.isTherePending}
                    isPending={isPending}
                    firstVisitTime={createdFingerPrint?.firstVisitTime}
                    {...routeProps}
                  />
                )}
              />
              <Route
                exact
                path="/odeme-adimi-2/2"
                render={(routeProps) => (
                  <CreditCardPaymentScreen
                    createdFingerPrint={createdFingerPrint}
                    isTherePending={isPending?.isTherePending}
                    isPending={isPending}
                    firstVisitTime={createdFingerPrint?.firstVisitTime}
                    {...routeProps}
                  />
                )}
              />
              <Route exact path="/tesekkurler" component={ThankYouScreen} />
              <PrivateRoute
                exact
                path="/musteri-hizmetleri"
                component={CustomerServiceScreen}
              />
              <PrivateRoute exact path="/profilim" component={ProfileScreen} />
              <PrivateRoute
                exact
                path="/siparis-detaylari/:id/:orderId"
                component={OrderDetailsScreen}
              />
              <PrivateRoute
                exact
                path="/siparislerim"
                component={OrdersScreen}
              />
              <Route exact path="/destek" component={SupportScreen} />
              <Route
                path="/musteri-hizmetleri-giris"
                component={CustomerServiceSigninScreen}
              />
              <Route path="/sponsorluklar" component={SponsoredScreen} />
              <Route
                path="/insan-kaynaklari"
                component={HumanResourcesScreen}
              />
              <Route
                path="/mesafeli-satis-sozlesmesi"
                component={DistanceSaleScreen}
              />
              <Route
                path="/gizlilik-sozlesmesi"
                component={PrivacyAgreementScreen}
              />
              <Route path="/cayma-hakki" component={WithdrawalRightScreen} />
              <Route
                exact
                path="/kolay-iade-baslat"
                component={CreateRefundCodeScreen}
              />
              <Route
                exact
                path="/kolay-iade-baslat/:shortOrderId/:id/:index"
                component={CreateRefundCodeScreen}
              />
              <Route
                exact
                path="/iade-bilgileri/:id"
                component={CreateRefundInfoScreen}
              />
              <Route
                exact
                path="/mainland/beddo-ile-nasil-indirimli-alisveri-yapiliyor"
                component={HowDiscountWorks}
              />
              <Route
                exact
                path="/mainland/kurumsal-kayit-aciklamasi"
                component={WholeSaleExplainerLandingPage}
              />

              <Route
                exact
                path="/bayi-basvuru-formu"
                component={DealerApplicationScreen}
              />
              <Route
                exact
                path="/tedarikci-basvuru-formu"
                component={SupplierApplicationScreen}
              />
              <Route
                exact
                path="/kurumsal-ve-toptan-siparis-talep-formu"
                component={WholesaleApplicationScreen}
              />
              <Route
                exact
                path="/export-inquiries-form"
                component={ExportApplicationScreen}
              />
              <Route
                exact
                path="/x/:phone"
                component={SmsCampaignRemoveFromListScreen}
              />

              <Route exact path="/:modelId" component={RedirectShortLinks} />
              <Route exact component={FourOFour} />
            </Switch>
          </div>
        </Suspense>
        <ScrollToTopIcon />
        <Footer />
      </>
    </>
  );
}
