import {
  ADMIN_CATEGORY_LOW_CREATE_FAIL,
  ADMIN_CATEGORY_LOW_CREATE_REQUEST,
  ADMIN_CATEGORY_LOW_CREATE_RESET,
  ADMIN_CATEGORY_LOW_CREATE_SUCCESS,
  ADMIN_CATEGORY_LOW_DELETE_FAIL,
  ADMIN_CATEGORY_LOW_DELETE_REQUEST,
  ADMIN_CATEGORY_LOW_DELETE_RESET,
  ADMIN_CATEGORY_LOW_DELETE_SUCCESS,
  ADMIN_CATEGORY_LOW_DETAILS_FAIL,
  ADMIN_CATEGORY_LOW_DETAILS_REQUEST,
  ADMIN_CATEGORY_LOW_DETAILS_SUCCESS,
  ADMIN_CATEGORY_LOW_EDIT_FAIL,
  ADMIN_CATEGORY_LOW_EDIT_REQUEST,
  ADMIN_CATEGORY_LOW_EDIT_RESET,
  ADMIN_CATEGORY_LOW_EDIT_SUCCESS,
  ADMIN_CATEGORY_LOW_LIST_FAIL,
  ADMIN_CATEGORY_LOW_LIST_REQUEST,
  ADMIN_CATEGORY_LOW_LIST_SUCCESS,
  ADMIN_CATEGORY_LOW_RELATED_LIST_FAIL,
  ADMIN_CATEGORY_LOW_RELATED_LIST_REQUEST,
  ADMIN_CATEGORY_LOW_RELATED_LIST_SUCCESS,
} from "../constants/adminCategoryLowConstants";

//SAVE THE CREATED CATEGORY-LOW IN REDUX STATE
export const reducerAdminCategoryLowToCreateCategoryLow = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_LOW_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        categoryLow: action.payload,
      };
    case ADMIN_CATEGORY_LOW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_LOW_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF CATEGORRY-LOWS IN REDUX STATE
export function reducerAdminCategoryLowToListCategoryLows(
  state = { loading: true, categoryLowsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_LOW_LIST_SUCCESS:
      return {
        loading: false,
        categoryLowsList: action.payload,
      };
    case ADMIN_CATEGORY_LOW_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE RELATED LIST OF CATEGORY-LOWS IN REDUX STATE
export function reducerAdminCategoryLowToRelatedListCategoryLows(
  state = { loading: true, categoryLowsRelatedList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_RELATED_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CATEGORY_LOW_RELATED_LIST_SUCCESS:
      return {
        loading: false,
        categoryLowsRelatedList: action.payload,
      };
    case ADMIN_CATEGORY_LOW_RELATED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY-LOW IN REDUX STATE
export function reducerAdminCategoryLowToDetailsCategoryLow(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_LOW_DETAILS_SUCCESS:
      return {
        loading: false,
        categoryLowDetails: action.payload,
      };
    case ADMIN_CATEGORY_LOW_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF CATEGORY-LOW IN REDUX STATE
export function reducerAdminCategoryLowToEditCategoryLow(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_LOW_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_CATEGORY_LOW_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_LOW_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF CATEGORY-LOW IN REDUX STATE
export function reducerAdminCategoryLowToDeleteCategoryLow(state = {}, action) {
  switch (action.type) {
    case ADMIN_CATEGORY_LOW_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_CATEGORY_LOW_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_CATEGORY_LOW_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CATEGORY_LOW_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
