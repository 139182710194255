import {
  ADMIN_DEPARTMENT_CREATE_FAIL,
  ADMIN_DEPARTMENT_CREATE_REQUEST,
  ADMIN_DEPARTMENT_CREATE_RESET,
  ADMIN_DEPARTMENT_CREATE_SUCCESS,
  ADMIN_DEPARTMENT_DELETE_FAIL,
  ADMIN_DEPARTMENT_DELETE_REQUEST,
  ADMIN_DEPARTMENT_DELETE_RESET,
  ADMIN_DEPARTMENT_DELETE_SUCCESS,
  ADMIN_DEPARTMENT_DETAILS_FAIL,
  ADMIN_DEPARTMENT_DETAILS_REQUEST,
  ADMIN_DEPARTMENT_DETAILS_SUCCESS,
  ADMIN_DEPARTMENT_EDIT_FAIL,
  ADMIN_DEPARTMENT_EDIT_REQUEST,
  ADMIN_DEPARTMENT_EDIT_RESET,
  ADMIN_DEPARTMENT_EDIT_SUCCESS,
  ADMIN_DEPARTMENT_LIST_FAIL,
  ADMIN_DEPARTMENT_LIST_REQUEST,
  ADMIN_DEPARTMENT_LIST_SUCCESS,
} from "../constants/adminDepartmentConstants";

//SAVE THE CREATED DEPARTMENT IN REDUX STATE
export const reducerAdminDepartmentToCreateDepartment = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case ADMIN_DEPARTMENT_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_DEPARTMENT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        department: action.payload,
      };
    case ADMIN_DEPARTMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DEPARTMENT_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//SAVE THE LIST OF DEPARTMENTS IN REDUX STATE
export function reducerAdminDepartmentToListDepartments(
  state = { loading: true, departmentsList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_DEPARTMENT_LIST_REQUEST:
      return { loading: true, ...state };
    case ADMIN_DEPARTMENT_LIST_SUCCESS:
      return {
        loading: false,
        departmentsList: action.payload,
      };
    case ADMIN_DEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE DETAILS OF DEPARTMENT IN REDUX STATE
export function reducerAdminDepartmentToDetailsDepartment(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_DEPARTMENT_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_DEPARTMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        departmentDetails: action.payload,
      };
    case ADMIN_DEPARTMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVE THE EDITTED DETAILS OF DEPARTMENT IN REDUX STATE
export function reducerAdminDepartmentToEditDepartment(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_DEPARTMENT_EDIT_REQUEST:
      return { loading: true };
    case ADMIN_DEPARTMENT_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case ADMIN_DEPARTMENT_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DEPARTMENT_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE DELETE STATUS OF DEPARTMENT IN REDUX STATE
export function reducerAdminDepartmentToDeleteDepartment(state = {}, action) {
  switch (action.type) {
    case ADMIN_DEPARTMENT_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_DEPARTMENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        inUseMessage: action.payload.inUseMessage,
      };
    case ADMIN_DEPARTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DEPARTMENT_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
