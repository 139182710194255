import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { vatCalculator } from "../../../../../libraries/ercLibrary";

export function useCartTotals() {
  // BRING IN CART INFO FROM REDUX STORE
  const addOrRemoveWholeSaleItemInCart = useSelector(
    (state) => state.addOrRemoveWholeSaleItemInCart
  );
  const { wholeSaleCartItems } = addOrRemoveWholeSaleItemInCart;

  const [cartTotalVatIncluded, setCartTotalVatIncluded] = useState(0);
  const [cartTotalVatExcluded, setCartTotalVatExcluded] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [blinkAgain, setBlinkAgain] = useState(false);

  useEffect(() => {
    if (wholeSaleCartItems.length > 0) {
      // CALCULATE CART TOTAL AMOUNT
      const totalVatIncluded = wholeSaleCartItems.reduce(
        (a, c) => a + c.price * c.qty,
        0
      );
      setCartTotalVatIncluded(totalVatIncluded);
      const totalVatExcluded = wholeSaleCartItems.reduce(
        (a, c) => a + Number(vatCalculator(c.vat, c.price * c.qty, "excluded")),
        0
      );
      setCartTotalVatExcluded(totalVatExcluded);

      // FIND FREE PRODUCST IF ANY
      const freeProducts = wholeSaleCartItems.map((item) => item.freeProducts);
      const merged = freeProducts.concat(wholeSaleCartItems).flat();
      const totalQuantity = merged.reduce((a, c) => a + c.qty, 0);
      setCartQuantity(totalQuantity);

      // BLING WHEN ADDED IN CART
      setBlinkAgain(true);
    } else {
      setCartQuantity(0);
      setCartTotalVatIncluded(0);
      setCartTotalVatExcluded(0);
    }
  }, [wholeSaleCartItems, cartQuantity]);

  return {
    wholeSaleCartItems,
    cartTotalVatIncluded,
    cartTotalVatExcluded,
    cartQuantity,
    blinkAgain,
    setBlinkAgain,
  };
}
