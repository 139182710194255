//ADMIN CREATE SECURED ORDER STATUS
export const ADMIN_SECURED_ORDER_STATUS_CREATE_REQUEST =
  "ADMIN_SECURED_ORDER_STATUS_CREATE_REQUEST";
export const ADMIN_SECURED_ORDER_STATUS_CREATE_SUCCESS =
  "ADMIN_SECURED_ORDER_STATUS_CREATE_SUCCESS";
export const ADMIN_SECURED_ORDER_STATUS_CREATE_FAIL =
  "ADMIN_SECURED_ORDER_STATUS_CREATE_FAIL";
export const ADMIN_SECURED_ORDER_STATUS_CREATE_RESET =
  "ADMIN_SECURED_ORDER_STATUS_CREATE_RESET";

//ADMIN SECURED ORDER STATUS LIST
export const ADMIN_SECURED_ORDER_STATUS_LIST_REQUEST =
  "ADMIN_SECURED_ORDER_STATUS_LIST_REQUEST";
export const ADMIN_SECURED_ORDER_STATUS_LIST_SUCCESS =
  "ADMIN_SECURED_ORDER_STATUS_LIST_SUCCESS";
export const ADMIN_SECURED_ORDER_STATUS_LIST_FAIL =
  "ADMIN_SECURED_ORDER_STATUS_LIST_FAIL";

//ADMIN SECURED ORDER STATUS DETAILS
export const ADMIN_SECURED_ORDER_STATUS_DETAILS_REQUEST =
  "ADMIN_SECURED_ORDER_STATUS_DETAILS_REQUEST";
export const ADMIN_SECURED_ORDER_STATUS_DETAILS_SUCCESS =
  "ADMIN_SECURED_ORDER_STATUS_DETAILS_SUCCESS";
export const ADMIN_SECURED_ORDER_STATUS_DETAILS_FAIL =
  "ADMIN_SECURED_ORDER_STATUS_DETAILS_FAIL";

//ADMIN SECURED ORDER STATUS EDIT
export const ADMIN_SECURED_ORDER_STATUS_EDIT_REQUEST =
  "ADMIN_SECURED_ORDER_STATUS_EDIT_REQUEST";
export const ADMIN_SECURED_ORDER_STATUS_EDIT_SUCCESS =
  "ADMIN_SECURED_ORDER_STATUS_EDIT_SUCCESS";
export const ADMIN_SECURED_ORDER_STATUS_EDIT_FAIL =
  "ADMIN_SECURED_ORDER_STATUS_EDIT_FAIL";
export const ADMIN_SECURED_ORDER_STATUS_EDIT_RESET =
  "ADMIN_SECURED_ORDER_STATUS_EDIT_RESET";

//ADMIN SECURED ORDER STATUS DELETE
export const ADMIN_SECURED_ORDER_STATUS_DELETE_REQUEST =
  "ADMIN_SECURED_ORDER_STATUS_DELETE_REQUEST";
export const ADMIN_SECURED_ORDER_STATUS_DELETE_SUCCESS =
  "ADMIN_SECURED_ORDER_STATUS_DELETE_SUCCESS";
export const ADMIN_SECURED_ORDER_STATUS_DELETE_FAIL =
  "ADMIN_SECURED_ORDER_STATUS_DELETE_FAIL";
export const ADMIN_SECURED_ORDER_STATUS_DELETE_RESET =
  "ADMIN_SECURED_ORDER_STATUS_DELETE_RESET";
