import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CART_ADD_SKU_ITEM, CART_EMPTY_SKU } from "../constants/cartConstants";

export function useUpdateCartExtraDiscountPrice(
  cartSkuItems,
  extraDiscountAmount,
  isDiscountValid,
  trigger
) {
  const dispatch = useDispatch();
  const cartSkuItemQty = cartSkuItems[0]?.qty;
  const cartSkuItemsTotalPrice = cartSkuItems[0]?.itemsTotalPrice;
  // Use useRef to keep track of the previous extraDiscountAmount
  const prevExtraDiscountRef = useRef(null);

  useEffect(() => {
    if (extraDiscountAmount > 0) {
      prevExtraDiscountRef.current = extraDiscountAmount;
    }
  }, [extraDiscountAmount]);

  // Access the previous positive value of extraDiscountAmount using prevExtraDiscountRef.current
  const prevExtraDiscount = prevExtraDiscountRef.current;

  // UPDATE CART SKU ITEM IF THERE IS AN EXTRA DISCOUTN
  useEffect(() => {
    // Check if isDiscountValid is undefined, and if so, return without doing anything
    if (typeof isDiscountValid === "undefined") {
      return;
    }

    if (prevExtraDiscount > 0 && extraDiscountAmount === 0) {
      const revisedItems = cartSkuItems.map((item, index) => {
        const numQty = Number(item.qty);
        const price = item.price;
        const priceGap = item.priceGap;
        const priceForOne = item.priceForOne;
        const itemsTotalPrice = item.itemsTotalPrice;

        const originalItemsTotalPrice = isDiscountValid
          ? index === 0 && numQty === 1
            ? price
            : numQty > 1 && price + priceGap * (numQty - 1)
          : index === 0 && priceForOne * numQty;

        return {
          ...item,
          itemsTotalPrice:
            index === 0 ? originalItemsTotalPrice : itemsTotalPrice,
        };
      });

      dispatch({ type: CART_EMPTY_SKU });

      localStorage.setItem("cartSkuItems", JSON.stringify(revisedItems));

      revisedItems.forEach((item) => {
        dispatch({ type: CART_ADD_SKU_ITEM, payload: item });
      });

      // RESET REF
      prevExtraDiscountRef.current = null;
    } else if (extraDiscountAmount > 0) {
      const revisedItems = cartSkuItems.map((item, index) => {
        const numQty = Number(item.qty);
        const price = item.price;
        const priceGap = item.priceGap;
        const priceForOne = item.priceForOne;
        const itemsTotalPrice = item.itemsTotalPrice;
        const discountAmount = extraDiscountAmount ? extraDiscountAmount : 0;

        const originalItemsTotalPrice = isDiscountValid
          ? index === 0 && numQty === 1
            ? price
            : numQty > 1 && price + priceGap * (numQty - 1)
          : index === 0 && priceForOne * numQty;

        return {
          ...item,
          itemsTotalPrice:
            index === 0
              ? discountAmount
                ? originalItemsTotalPrice - discountAmount
                : originalItemsTotalPrice
              : itemsTotalPrice,
        };
      });

      dispatch({ type: CART_EMPTY_SKU });

      localStorage.setItem("cartSkuItems", JSON.stringify(revisedItems));
      localStorage.setItem(
        "extraDiscount",
        JSON.stringify({ discountAmount: extraDiscountAmount })
      );

      revisedItems.forEach((item) => {
        dispatch({ type: CART_ADD_SKU_ITEM, payload: item });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    extraDiscountAmount,
    cartSkuItemQty,
    cartSkuItemsTotalPrice,
    isDiscountValid,
    trigger,
    prevExtraDiscount,
  ]);
}
