//ADMIN CREATE DEPARTMENT
export const ADMIN_DEPARTMENT_CREATE_REQUEST =
  "ADMIN_DEPARTMENT_CREATE_REQUEST";
export const ADMIN_DEPARTMENT_CREATE_SUCCESS =
  "ADMIN_DEPARTMENT_CREATE_SUCCESS";
export const ADMIN_DEPARTMENT_CREATE_FAIL = "ADMIN_DEPARTMENT_CREATE_FAIL";
export const ADMIN_DEPARTMENT_CREATE_RESET = "ADMIN_DEPARTMENT_CREATE_RESET";

//ADMIN DEPARTMENT LIST
export const ADMIN_DEPARTMENT_LIST_REQUEST = "ADMIN_DEPARTMENT_LIST_REQUEST";
export const ADMIN_DEPARTMENT_LIST_SUCCESS = "ADMIN_DEPARTMENT_LIST_SUCCESS";
export const ADMIN_DEPARTMENT_LIST_FAIL = "ADMIN_DEPARTMENT_LIST_FAIL";

//ADMIN DEPARTMENT DETAILS
export const ADMIN_DEPARTMENT_DETAILS_REQUEST =
  "ADMIN_DEPARTMENT_DETAILS_REQUEST";
export const ADMIN_DEPARTMENT_DETAILS_SUCCESS =
  "ADMIN_DEPARTMENT_DETAILS_SUCCESS";
export const ADMIN_DEPARTMENT_DETAILS_FAIL = "ADMIN_DEPARTMENT_DETAILS_FAIL";

//ADMIN DEPARTMENT EDIT
export const ADMIN_DEPARTMENT_EDIT_REQUEST = "ADMIN_DEPARTMENT_EDIT_REQUEST";
export const ADMIN_DEPARTMENT_EDIT_SUCCESS = "ADMIN_DEPARTMENT_EDIT_SUCCESS";
export const ADMIN_DEPARTMENT_EDIT_FAIL = "ADMIN_DEPARTMENT_EDIT_FAIL";
export const ADMIN_DEPARTMENT_EDIT_RESET = "ADMIN_DEPARTMENT_EDIT_RESET";

//ADMIN DEPARTMENT DELETE
export const ADMIN_DEPARTMENT_DELETE_REQUEST =
  "ADMIN_DEPARTMENT_DELETE_REQUEST";
export const ADMIN_DEPARTMENT_DELETE_SUCCESS =
  "ADMIN_DEPARTMENT_DELETE_SUCCESS";
export const ADMIN_DEPARTMENT_DELETE_FAIL = "ADMIN_DEPARTMENT_DELETE_FAIL";
export const ADMIN_DEPARTMENT_DELETE_RESET = "ADMIN_DEPARTMENT_DELETE_RESET";
