import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {
  const authenticateUser = useSelector(state => state.authenticateUser);
  const { authenticatedUser } = authenticateUser;

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticatedUser ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to="/musteri-hizmetleri-giris" />
        )
      }
    ></Route>
  );
}