import React from "react";
import { Link } from "react-router-dom";

export default function PopRefundGuarantee({ product }) {
  return (
    <div className="fs1125 helvetica">
      <h3 className="fs125 lh2 beddobck  txtcenter w100p white">
        {product?.refundGuarantee
          ? `${product.refundGuarantee} Gün İade Garantisi`
          : "90 Gün İade Garantisi"}
      </h3>
      <p className="w100p margintop-1">Değerli Ziyaretçimiz,</p>
      <p className="w100p margintop-1">
        Herhangi bir neden ile ya da hiç nedensiz, satın aldığınız ürünü{" "}
        {product?.refundGuarantee ? <u>{product.refundGuarantee}</u> : "90"} gün
        içinde iade edebilirsiniz.
      </p>
      <p className="w100p margintop-1">
        Riskiniz koca bir - 0 - Ya üründen memnun kalacaksınız ya da paranızı
        geri alacaksınız.
      </p>{" "}
      <p className="w100p margintop-1">
        İade başlatmak için{" "}
        <Link className="beddo-color" to="/musteri-hizmetleri">
          Müşteri Hizmetleri
        </Link>{" "}
        sayfasını ziyaret edebilirsiniz.
      </p>
      <div className="signature margintop-1 w100p">
        <h3>Saygılarımla</h3>
        <h3>Tuğba Ö.</h3>
        <h3>Müşteri Hizmetleri Yöneticisi</h3>
      </div>
      <p className="fs1 margintop-1">
        <span className="bold-font">Not:</span> 90 gün iade garantisi sadece{" "}
        <span className="bold-font">beddo.com</span> sitemizden yapacağınız
        alışverişlerde geçerlidir. Trendyol vb. pazaryeri sitelerinden ya da
        medikal mağazalardan aldığınız beddo™ ürünlerinde, pazaryeri sitesinin
        ve mağazanın iade koşulları geçerli olur.
      </p>
    </div>
  );
}
