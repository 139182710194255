import {
  ADMIN_CREATE_POP_FAIL,
  ADMIN_CREATE_POP_REQUEST,
  ADMIN_CREATE_POP_RESET,
  ADMIN_CREATE_POP_SUCCESS,
  ADMIN_DETAILS_POP_FAIL,
  ADMIN_DETAILS_POP_REQUEST,
  ADMIN_DETAILS_POP_SUCCESS,
  ADMIN_EDIT_POP_FAIL,
  ADMIN_EDIT_POP_REQUEST,
  ADMIN_EDIT_POP_RESET,
  ADMIN_EDIT_POP_SUCCESS,
  ADMIN_LIST_POPS_FAIL,
  ADMIN_LIST_POPS_REQUEST,
  ADMIN_LIST_POPS_SUCCESS,
} from "../constants/adminPopsConstants";

// CREATE A DISCOUNT CODE
export const reducerAdminToCreatePop = (state = { success: false }, action) => {
  switch (action.type) {
    case ADMIN_CREATE_POP_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_POP_SUCCESS:
      return {
        loading: false,
        success: true,
        pop: action.payload,
      };
    case ADMIN_CREATE_POP_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_POP_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// LIST DISCOUNT CODES
export const reducerAdminToListPops = (state = { loading: true }, action) => {
  switch (action.type) {
    case ADMIN_LIST_POPS_REQUEST:
      return { loading: true };
    case ADMIN_LIST_POPS_SUCCESS:
      return {
        loading: false,
        popsList: action.payload,
      };
    case ADMIN_LIST_POPS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE/EDIT DISCOUNT CODE
export function reducerAdminToEditPop(state = { loading: false }, action) {
  switch (action.type) {
    case ADMIN_EDIT_POP_REQUEST:
      return { loading: true };
    case ADMIN_EDIT_POP_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_EDIT_POP_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_EDIT_POP_RESET:
      return { ...state, success: "", error: "", loading: false };
    default:
      return state;
  }
}

// GET DETAILS OF DISCOUNT CODES
export const reducerAdminGetDetailsOfPop = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_DETAILS_POP_REQUEST:
      return { loading: true };
    case ADMIN_DETAILS_POP_SUCCESS:
      return {
        loading: false,
        popDetails: action.payload,
      };
    case ADMIN_DETAILS_POP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
