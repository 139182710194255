import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../components/SignInBySms/actions/actionToSendSecurityCodeToSignIn";
import useWidth from "../../../../hooks/useWidth";
import ErcModal from "../../../shared/components/ErcModal";
import { resetModal } from "../../actions/actionToToggleErcModal";
import ItemList from "../../wholeSale/cart/components/ItemList";
import CartFooter from "../../wholeSale/cart/components/CartFooter";
import { resetItemAddedInCart } from "../../wholeSale/cart/actions/actionToAddOrRemoveWholeSaleItemInCart";
import FreeGoodsDisplay from "./FreeGoodsDisplay";
import FreeGoodsThresHoldTable from "../freeGoods/FreeGoodsThresHoldTable";

export default function NavigationTop(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const width = useWidth();

  // PROPS COMING FROM AppWholeSaleUser
  const {
    freeGoodsAllocationActive,
    setFreeGoodsAllocationActive,
    orderNumber,
    detailsWholeSaleAccount,
    limits,
    cartTotalVatExcluded,
    wholeSaleCartItems,
    cartQuantity,
    blinkAgain,
    setBlinkAgain,
    ordersInfo,
    isModalOpen,
    privatePricing,
  } = props;

  // STYLES
  const navContainerMain = {
    width: "100%",
    height: "4rem",
    position: "fixed",
    top: "0",
    backgroundColor: "#ffeede",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "1",
  };

  const navContainer = {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const freeGoodsContainer = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  };

  // STATE TO CONTROL add another product BUTTON
  const [closeOnClick, setCloseOnClick] = useState(false);

  return (
    <div style={navContainerMain}>
      <div style={navContainer}>
        <img
          title="Toptan ürünler sayfasına git"
          className="w5r pointer"
          src="/images/beddo-mern-logo.png"
          alt="beddo logosu - beddo senin huzur senin"
          onClick={() => history.push("/toptan-urunler")}
        />

        {width > 600 && (
          <ErcModal
            modalTitleDivWidth={"85%"}
            modalTitle={() => (
              <div style={freeGoodsContainer}>
                <FreeGoodsDisplay
                  ordersInfo={ordersInfo}
                  freeGoodsAllocationActive={freeGoodsAllocationActive}
                  setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                  detailsWholeSaleAccount={detailsWholeSaleAccount}
                />
              </div>
            )}
            modalTitleFontSize={""}
            modalTitleLineHeight={"1rem"}
            contentTitleFontSize={"1.35rem"}
            contentTitle={"Mal Fazlası Seviye Tablosu"}
            content={[
              <FreeGoodsThresHoldTable
                purchaseLevelOne={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelOne
                }
                purchaseLevelTwo={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelTwo
                }
                purchaseLevelThree={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelThree
                }
              />,
            ]}
            modalParagFontSize={"1.25rem"}
            disableToggle={ordersInfo.freeGoodsAllocation > 0}
          />
        )}

        {width > 600 ? (
          <>
            <table className="w25p">
              <thead
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid grey",
                      minWidth: "4.2rem",
                    }}
                  >
                    <ErcModal
                      modalTitle={() => (
                        <>
                          <i
                            title={
                              cartQuantity > 0
                                ? `Sepetinizde ${cartQuantity} ürün var`
                                : "Sepetiniz boş"
                            }
                            className={`relative fs15 fad fa-shopping-cart green pointer ${
                              blinkAgain && "blink-effect-3"
                            }`}
                            onAnimationEnd={() => setBlinkAgain(false)}
                          >
                            <p
                              style={{
                                position: "absolute",
                                right:
                                  cartQuantity < 10
                                    ? "20px"
                                    : cartQuantity > 9 && "25px",
                                color: "red",
                              }}
                            >
                              {cartQuantity > 0 && cartQuantity}
                            </p>
                          </i>
                        </>
                      )}
                      modalFrontBackgroundColor={
                        freeGoodsAllocationActive > 0 && "#E6FFE6"
                      }
                      modalTitleFontSize={"0.65rem"}
                      modalTitleLineHeight={"0.85rem"}
                      contentTitleFontSize={"1.5rem"}
                      modalParagBorderBottom={""}
                      modalParagFontSize={""}
                      modalContentMargin={"1rem 1rem 6rem 1rem"}
                      modalContentHeight={"70vh"}
                      contentTitle={
                        cartQuantity > 0 ? "Toptan Sepetiniz" : "Sepetiniz Boş"
                      }
                      content={[
                        <ItemList
                          freeGoodsAllocationActive={freeGoodsAllocationActive}
                          wholeSaleCartItems={wholeSaleCartItems}
                          privatePricing={privatePricing}
                        />,
                      ]}
                      positionFixedContent={[
                        <CartFooter
                          wholeSaleCartItems={wholeSaleCartItems}
                          setCloseOnClick={setCloseOnClick}
                          freeGoodsAllocationActive={freeGoodsAllocationActive}
                          orderNumber={orderNumber}
                          limits={limits}
                          cartTotalVatExcluded={cartTotalVatExcluded}
                          privatePricing={privatePricing}
                        />,
                      ]}
                      openOnClick={isModalOpen}
                      closeOnClick={closeOnClick}
                      clickOutsideShouldWork={false}
                      callThisFunctionInCloseModalFunction={() => {
                        return [
                          dispatch(resetModal()),
                          dispatch(resetItemAddedInCart()),
                        ];
                      }}
                    />
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid grey",
                      width: "4.2rem",
                    }}
                  >
                    <i
                      className={`fs125 fas fa-store ${
                        location === "/toptan-urunler" ? "beddo-color" : "green"
                      }  marb-q pointer `}
                      onClick={() => history.push("/toptan-urunler")}
                    ></i>
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid grey",
                      width: "4.2rem",
                    }}
                  >
                    <ErcModal
                      modalTitle={() => (
                        <i className="fs125 fal fa-list-alt green pointer"></i>
                      )}
                      modalTitleFontSize={"0.65rem"}
                      modalTitleLineHeight={"0.85rem"}
                      contentTitle={"Kategoriler hazırlanıyor"}
                      contentTitleFontSize={"1.5rem"}
                      content={[""]}
                      modalParagBorderBottom={""}
                      modalParagFontSize={""}
                      clickOutsideShouldWork={false}
                    />
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid grey",
                      width: "4.2rem",
                    }}
                  >
                    <i
                      className={`fs15 fas fa-user-circle ${
                        location === "/toptan-hesabi" ? "beddo-color" : "green"
                      }  marb-q pointer `}
                      onClick={() => history.push("/toptan-hesabi")}
                    ></i>
                  </td>

                  {location === "/toptan-hesabi" ? (
                    <td
                      style={{
                        borderRight: "1px solid grey",

                        width: "4.2rem",
                      }}
                    >
                      <i
                        className="fas fa-door-open fs125 red-col pointer"
                        onClick={() => dispatch(signOut())}
                      ></i>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              </thead>
              <tfoot
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid grey",
                      color: location === "/toptan-sepetim" ? "#ff9935" : "",
                    }}
                  >
                    Sepetim
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid grey",
                      color: location === "/toptan-urunler" ? "#ff9935" : "",
                    }}
                  >
                    Mağaza
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid grey",
                    }}
                  >
                    Kategoriler
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid grey",
                      color: location === "/toptan-hesabi" ? "#ff9935" : "",
                    }}
                  >
                    Hesabım
                  </td>
                  {location === "/toptan-hesabi" ? (
                    <td
                      style={{
                        borderRight: "1px solid grey",
                      }}
                    >
                      Çıkış Yap
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              </tfoot>
            </table>
          </>
        ) : width <= 600 && location === "/toptan-hesabi" ? (
          <div className="w100p row-center-nowrap">
            <ErcModal
              modalTitleDivWidth={"85%"}
              modalTitle={() => (
                <div style={freeGoodsContainer}>
                  <FreeGoodsDisplay
                    ordersInfo={ordersInfo}
                    freeGoodsAllocationActive={freeGoodsAllocationActive}
                    setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                    detailsWholeSaleAccount={detailsWholeSaleAccount}
                  />
                </div>
              )}
              modalTitleFontSize={"1rem"}
              modalTitleLineHeight={"1rem"}
              contentTitleFontSize={"1.35rem"}
              contentTitle={"Mal Fazlası Seviye Tablosu"}
              content={[
                <FreeGoodsThresHoldTable
                  purchaseLevelOne={
                    detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelOne
                  }
                  purchaseLevelTwo={
                    detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelTwo
                  }
                  purchaseLevelThree={
                    detailsWholeSaleAccount?.reSeller?.limits
                      ?.purchaseLevelThree
                  }
                />,
              ]}
              modalParagFontSize={"1.25rem"}
              disableToggle={ordersInfo.freeGoodsAllocation > 0}
            />
            <div className="w20p col padleft1 ">
              <i
                className="fas fa-door-open fs125 red-col pointer marb-q"
                onClick={() => dispatch(signOut())}
              ></i>
              <p>Çıkış Yap</p>
            </div>
          </div>
        ) : (
          <ErcModal
            modalTitleDivWidth={"85%"}
            modalTitle={() => (
              <div style={freeGoodsContainer}>
                <FreeGoodsDisplay
                  ordersInfo={ordersInfo}
                  freeGoodsAllocationActive={freeGoodsAllocationActive}
                  setFreeGoodsAllocationActive={setFreeGoodsAllocationActive}
                  detailsWholeSaleAccount={detailsWholeSaleAccount}
                />
              </div>
            )}
            modalTitleFontSize={"1rem"}
            modalTitleLineHeight={"1rem"}
            contentTitleFontSize={"1.35rem"}
            contentTitle={"Mal Fazlası Seviye Tablosu"}
            content={[
              <FreeGoodsThresHoldTable
                purchaseLevelOne={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelOne
                }
                purchaseLevelTwo={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelTwo
                }
                purchaseLevelThree={
                  detailsWholeSaleAccount?.reSeller?.limits?.purchaseLevelThree
                }
              />,
            ]}
            modalParagFontSize={"1.25rem"}
            disableToggle={ordersInfo.freeGoodsAllocation > 0}
          />
        )}
      </div>
    </div>
  );
}
