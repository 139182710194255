import React, { useEffect, useRef, useState } from "react";
import LoadingBoxWithoutText from "./LoadingBoxWithoutText";
import MessageBox from "./MessageBox";

export default function ErcDialog(props) {
  // PROPS - "ErcDialog"
  const {
    setDialogOpen,
    dialogOpen,
    dialogTitle,
    dialogTitleFontSize,
    dialogTitleLineHeight,
    dialogTitleColor,
    dialogTitleUnderline,
    contentTitle,
    contentTitleFontSize,
    content, // ARRAY
    dialogParagBorderBottom,
    dialogParagFontSize,
    yesNoButtons,
    handleGoAhead,
    goAhead,
    handleGiveUp,
    giveUp,
    loading,
    error,
    success,
    successMessage,
    callThisFunctionInCloseModalFunction,
    clickOutsideShouldWork,
  } = props;

  // STATE TO MANAGE DIALOG
  const [ercDialogOpen, setErcDialogOpen] = useState(false);

  // FUNCTION TO IMPLEMENT "ClickOutsideToClose" FUNCTIONALITY
  const refDialog = useRef(null);
  function handleClickOutsideErcDialog(event) {
    if (
      refDialog.current &&
      !refDialog.current.contains(event.target) &&
      (clickOutsideShouldWork || clickOutsideShouldWork === undefined)
    ) {
      callThisFunctionInCloseModalFunction &&
        (ercDialogOpen || dialogOpen) &&
        callThisFunctionInCloseModalFunction();
      setErcDialogOpen(false);
      setDialogOpen && setDialogOpen(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideErcDialog, false);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideErcDialog,
        false
      );
    };
  });

  // FUNCTION TO IMPLEMENT TOGGLING "ErcDialog"
  function handleToggleDialog() {
    callThisFunctionInCloseModalFunction &&
      (ercDialogOpen || dialogOpen) &&
      callThisFunctionInCloseModalFunction();

    setErcDialogOpen(!ercDialogOpen);
    setDialogOpen && setDialogOpen(!dialogOpen);
  }

  // STYLE ErcDialog
  let stylingErcDialog = {
    dialogTitle: {
      cursor: "pointer",
      fontSize: dialogTitleFontSize ? dialogTitleFontSize : "1.25rem",
      lineHeight: dialogTitleLineHeight ? dialogTitleLineHeight : "1.5rem",
      color: dialogTitleColor ? dialogTitleColor : "",
      borderBottom: dialogTitleUnderline ? dialogTitleUnderline : "",
    },
    dialog: {
      backgroundColor: "rgb(0, 0, 0, 0.75)",
      float: "right",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "100vh",
      position: "fixed",
      zIndex: "1002",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogFront: {
      position: "relative",
      margin: "0.5rem",
      maxWidth: "30rem",
      maxHeight: "95%",
      borderRadius: "0.5rem",
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    fontAwesome: {
      position: "absolute",
      right: "0",
      top: "0",
      fontSize: "1.25rem",
      color: "#ff9935",
      cursor: "pointer",
      margin: "1rem",
    },
    contentTitle: {
      fontSize: contentTitleFontSize ? contentTitleFontSize : "1.5rem",
      color: "black",
      marginTop: "2.5rem",
      marginRight: "1rem",
      marginBottom: "1rem",
      marginLeft: "1rem",
    },
    dialogContent: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      margin: "1rem",
      height: "auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
    dialogParag: {
      width: "100%",
      color: "black",
      marginBottom: "0.5rem",
      fontSize: dialogParagFontSize ? dialogParagFontSize : "1.35rem",
      lineHeight: "1.75rem",
      borderBottom: dialogParagBorderBottom ? dialogParagBorderBottom : "",
    },
    beddoLogo: {
      position: "absolute",
      left: "0",
      top: "0",
      color: "#ff9935",
      cursor: "pointer",
      margin: "1rem",
    },
  };

  return (
    <>
      {!dialogTitle ? (
        <div style={stylingErcDialog.dialog}>
          <div ref={refDialog} style={stylingErcDialog.dialogFront}>
            <i
              className="fas fa-times"
              title="Kapat"
              onClick={() => {
                handleToggleDialog();
              }}
              style={stylingErcDialog.fontAwesome}
            ></i>
            <img
              className="w3r"
              style={stylingErcDialog.beddoLogo}
              src="/images/beddo-mern-logo.png"
              alt="beddo logosu - beddo senin huzur senin"
            />
            {contentTitle && (
              <h2 style={stylingErcDialog.contentTitle}>{contentTitle}</h2>
            )}
            <div style={stylingErcDialog.dialogContent}>
              {content &&
                content.map((paragraph, index) => {
                  return (
                    paragraph && (
                      <div key={index} style={stylingErcDialog.dialogParag}>
                        {paragraph}
                      </div>
                    )
                  );
                })}
            </div>
            {yesNoButtons && (
              <div className="row-space-around w100p">
                {loading ? (
                  <LoadingBoxWithoutText />
                ) : error ? (
                  <MessageBox variant="danger">
                    <h2 className="h2c">{error}</h2>
                  </MessageBox>
                ) : !success ? (
                  <>
                    <button
                      type="button"
                      className="primary w45p"
                      onClick={handleGoAhead}
                    >
                      {goAhead}
                    </button>
                    <button
                      type="button"
                      className="secondary w45p"
                      onClick={() => {
                        handleGiveUp && handleGiveUp();
                        setDialogOpen && setDialogOpen(!dialogOpen);
                        handleToggleDialog();
                      }}
                    >
                      {giveUp}
                    </button>
                  </>
                ) : (
                  successMessage && (
                    <div className="marb1">
                      <MessageBox variant="success">
                        <h2 className="h2c">{successMessage}</h2>
                      </MessageBox>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : !ercDialogOpen ? (
        <div
          style={stylingErcDialog.dialogTitle}
          onClick={handleToggleDialog}
          ref={refDialog}
        >
          {dialogTitle()}
        </div>
      ) : (
        <div style={stylingErcDialog.dialog}>
          <div ref={refDialog} style={stylingErcDialog.dialogFront}>
            <i
              className="fas fa-times"
              title="Kapat"
              onClick={handleToggleDialog}
              style={stylingErcDialog.fontAwesome}
            ></i>
            {contentTitle && (
              <h2 style={stylingErcDialog.contentTitle}>{contentTitle}</h2>
            )}
            <div style={stylingErcDialog.dialogContent}>
              {content &&
                content.map((paragraph, index) => {
                  return (
                    paragraph && (
                      <p key={index} style={stylingErcDialog.dialogParag}>
                        {paragraph}
                      </p>
                    )
                  );
                })}
            </div>
            {yesNoButtons && (
              <div className="row-space-around w100p">
                {loading ? (
                  <LoadingBoxWithoutText />
                ) : error ? (
                  <MessageBox variant="danger">
                    <h2 className="h2c">{error}</h2>
                  </MessageBox>
                ) : !success ? (
                  <>
                    <button
                      type="button"
                      className="primary w45p"
                      onClick={handleGoAhead}
                    >
                      {goAhead}
                    </button>
                    <button
                      type="button"
                      className="secondary w45p"
                      onClick={() => {
                        handleGiveUp && handleGiveUp();
                        setDialogOpen && setDialogOpen(!dialogOpen);
                        handleToggleDialog();
                      }}
                    >
                      {giveUp}
                    </button>
                  </>
                ) : (
                  successMessage && (
                    <div className="marb1">
                      <MessageBox variant="success">
                        <h2 className="h2c">{successMessage}</h2>
                      </MessageBox>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
