import React from "react";

export default function CartScreenCartHeader(props) {
  // PROPS COMING FROM CartScreenCart
  const { item } = props;

  return (
    <div className="greenbcklight marq padq">
      <h4 className="fs1125 txtcenter marupsideh">{item.name}</h4>
    </div>
  );
}
