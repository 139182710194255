import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToUpdateWholeSaleAccount = createAsyncThunk(
  "wholeSale/jobInActionToUpdateWholeSaleAccount",
  async (updateData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/updateWholeSaleAccount",
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  updatedWholeSaleAccount: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateWholeSaleAccountSlice = createSlice({
  name: "updateWholeSaleAccount",
  initialState,
  reducers: {
    resetUpdateWholeSaleAccount: (state) => {
      state.updatedWholeSaleAccount = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateWholeSaleAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToUpdateWholeSaleAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedWholeSaleAccount = action.payload;
      })
      .addCase(actionToUpdateWholeSaleAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetUpdateWholeSaleAccount } = updateWholeSaleAccountSlice.actions;

// Export the reducer
const reducerToUpdateWholeSaleAccount = updateWholeSaleAccountSlice.reducer;

// Export the async action creator for use in components
export { reducerToUpdateWholeSaleAccount, resetUpdateWholeSaleAccount };
