import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionAdminToGetWholeSaleOrdersList = createAsyncThunk(
  "wholeSale/adminOrdersList",
  async ({ keyword = "", pageNumber = "" }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/wholesale/adminGetWholeSaleOrdersList",
        {
          params: {
            keyword,
            pageNumber,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  loading: false,
  error: null,
  orders: [],
  countPendings: null,
  page: null,
  pages: null,
};

// Create a slice using createSlice
const getWholeSaleOrdersListSlice = createSlice({
  name: "getWholeSaleOrdersList",
  initialState,
  reducers: {
    resetGetWholeSaleOrdersList: (state) => {
      state.loading = false;
      state.error = null;
      state.orders = [];
      state.countPendings = null;
      state.page = null;
      state.pages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionAdminToGetWholeSaleOrdersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actionAdminToGetWholeSaleOrdersList.fulfilled,
        (state, action) => {
          state.loading = false;
          state.orders = action.payload.orders;
          state.countPendings = action.payload.countPendings;
          state.page = action.payload.page;
          state.pages = action.payload.pages;
        }
      )
      .addCase(
        actionAdminToGetWholeSaleOrdersList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

// Export reset method
const { resetGetWholeSaleOrdersList } = getWholeSaleOrdersListSlice.actions;

// Export the reducer
const reducerAdminToGetWholeSaleOrdersList =
  getWholeSaleOrdersListSlice.reducer;

// Export the async action creator for use in components
export { reducerAdminToGetWholeSaleOrdersList, resetGetWholeSaleOrdersList };
