import React from "react";
import ErcModal from "../../modules/shared/components/ErcModal";
import { calculateDisplayedPrice } from "../../utils/calculateDisplayedPrice";
import "./CartSummary.css";

export default function CartSummary(props) {
  // PROPS COMING FROM CartScreen
  const {
    isPending,
    cartSkuItems,
    continueShoppingHandler,
    addOrRemoveSkuItemInCart,
    handleGrandUpsellDialog,
    checkoutHandler,
    cartTotal,
  } = props;

  return (
    <div className="cart-summary-holder">
      <div className="col cart-summary-component">
        <table>
          <thead>
            {cartTotal?.totalQty > 0 ? (
              <tr>
                <th>Toplam Miktar</th>
                <td>
                  <p>{cartTotal?.totalQty} Adet</p>
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
            {cartTotal?.totalAmountWithoutDiscount - cartTotal?.totalAmount >
            0 ? (
              <tr>
                <th>İndirimsiz Tutar</th>
                <td className="red-col">
                  <strike>
                    {calculateDisplayedPrice(
                      cartTotal?.totalAmountWithoutDiscount
                    )}{" "}
                  </strike>{" "}
                  TL
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
            {cartTotal?.surpriceDiscount ? (
              <tr className="purple">
                <th>Sürpriz İndirim</th>
                <td>
                  <p>
                    {calculateDisplayedPrice(cartTotal?.surpriceDiscount)} TL
                  </p>
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
            {cartTotal?.totalAmountWithoutDiscount -
              cartTotal?.totalAmount -
              (cartTotal?.surpriceDiscount || 0) >
            0 ? (
              <tr className="green">
                <th>İndirimler</th>
                <td>
                  <p>
                    {calculateDisplayedPrice(
                      cartTotal?.totalAmountWithoutDiscount -
                        cartTotal?.totalAmount -
                        cartTotal?.surpriceDiscount || 0
                    )}{" "}
                    TL
                  </p>
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
            <tr>
              <th>Ödenecek Tutar</th>
              <td>
                <p>{calculateDisplayedPrice(cartTotal?.totalAmount)} TL</p>
              </td>
            </tr>
          </thead>
        </table>
      </div>

      <div className="row-center-nowrap w100p">
        <div className="w45p marq">
          <div className="w100p">
            <button
              className="secondary w100p"
              type="button"
              onClick={continueShoppingHandler}
            >
              Alışverişe Devam
            </button>
          </div>
        </div>
        <div className="w45p marq">
          <button
            className="primary w100p"
            type="button"
            disabled={cartSkuItems.length === 0}
            onClick={() => {
              isPending &&
              isPending.isTherePending === "YES" &&
              isPending.pendingOrders[0].paymentMethod !== "CreditCard" &&
              addOrRemoveSkuItemInCart.totalPrice <= 400
                ? handleGrandUpsellDialog()
                : checkoutHandler();
            }}
          >
            Alışverişi Tamamla
          </button>
        </div>
      </div>

      <div className="inline mar1">
        <ErcModal
          modalTitle={() => (
            <>
              Nasıl kolayca iade edilir
              <i className="far fa-question-circle green fs125 marq"></i>
            </>
          )}
          modalTitleFontSize={"1.25rem"}
          modalTitleLineHeight={"2rem"}
          modalTitleColor={"#0645ad"}
          modalTitleUnderline={"0.1rem solid #0645ad"}
          contentTitle={`İade Başlatmak Çok Kolay`}
          contentTitleFontSize={"1.35rem"}
          content={[
            `* beddo.com müşteri hizmetleri sayfasına gidin.`,
            `* Sistemde kayıtlı cep telefonu numaranız ile güvenli giriş yapın.`,
            `* Giriş yaptıktan sonra siparişlerim sayfasına gidin ve "Kolay İade Başlat" butonunu tıklayın.`,
            `* Yönergeleri izleyerek saniyeler içinde bir ücretsiz kargo kodu oluşturun.`,
            `* İade paketi oluşturduğunuz kargo kodu ile kargo şubesine teslim edin. `,
            `* Akıllı beddo™ sistemi, iade paketi takip eder ve depomuza ulaşmasını takip eden 24 saat içinde kartınıza iade eder.`,
            `* Kapıda ödeme yaptı iseniz iade başlatırken girdiğiniz IBAN numarasına havale/EFT yapılır.`,
          ]}
          modalParagBorderBottom={"0.1rem solid #ff9935"}
          modalParagFontSize={"1.25rem"}
        />
      </div>
    </div>
  );
}
