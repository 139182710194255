import React from "react";
import { Link } from "react-router-dom";
import { convertToUrl } from "../../libraries/ercLibrary";
import { useDispatch } from "react-redux";
import { actionToAddSkuItemInCart } from "../../actions/cartActions";
import CartSmallInfos from "./CartSmallInfos";
import CartScreenCartHeader from "./CartScreenCartHeader";
import { calculateDisplayedPrice } from "../../utils/calculateDisplayedPrice";
// import CountDownTimer from "../../components/CountDownTimer/CountDownTimer";
import calculateLimitedTimeDiscountValidity from "../../utils/calculateLimitedTimeDiscountValidity";
import CountDownTimerVisibilityAware from "../../components/CountDownTimer/CountDownTimerVisibilityAware";

export default function CartScreenCart(props) {
  const dispatch = useDispatch();

  // PROPS COMING FROM CartScreen
  const {
    index,
    item,
    isPending,
    cartSkuItems,
    discounted,
    removeFromCardHandler,
    firstVisitTime,
    setTrigger,
  } = props;

  const { remainingTime, isDiscountValid } =
    calculateLimitedTimeDiscountValidity(item, firstVisitTime);

  return (
    <table className="cart-shadow-table-platform">
      <thead className="h3cnp whitebck">
        <tr>
          <th colSpan="7">
            <CartScreenCartHeader item={item} />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody className="h3cnp">
        <tr>
          <td className="w20p">
            <Link to={`/urun/${convertToUrl(item.name)}/${item.product}`}>
              <img className="cart-image" src={item.image} alt={item.name} />
            </Link>
          </td>
          <td className="txtmid"></td>
          <td className="txtmid">
            <select
              className="iphone-fs pointer"
              disabled={item.buyOneGetOneFree || item.price === 0}
              value={item.qty}
              onChange={
                cartSkuItems[0].skuId === item.skuId
                  ? (e) =>
                      dispatch(
                        actionToAddSkuItemInCart(
                          isPending && isPending.isTherePending,
                          item.product,
                          item.sku,
                          Number(e.target.value),
                          "cartScreenQtySelection",
                          "",
                          "",
                          isDiscountValid &&
                            item.timeLimitedDiscount.discountAmount
                        )
                      )
                  : (e) =>
                      dispatch(
                        actionToAddSkuItemInCart(
                          isPending && isPending.isTherePending,
                          item.product,
                          item.sku,
                          Number(e.target.value),
                          "cartScreenQtySelection",
                          discounted,
                          "",
                          isDiscountValid &&
                            item.timeLimitedDiscount.discountAmount
                        )
                      )
              }
            >
              {[...Array(item.maxPurchaseQty).keys()].map((q) => {
                return (
                  <option className="iphone-fs" key={q + 1} value={q + 1}>
                    {q + 1} {item.productUnit}
                  </option>
                );
              })}
            </select>
          </td>
          <td className="fs125 txtmid txtleft">
            {item.price === 0 ? (
              <>
                <div className="marb-5">
                  <strike className="red-col">
                    {calculateDisplayedPrice(item.priceForOne, item.qty)} TL
                  </strike>
                </div>
                <div>
                  <p>{calculateDisplayedPrice(item.price)} TL</p>
                </div>
              </>
            ) : isDiscountValid || isPending?.isTherePending === "YES" ? (
              index !== 0 ? (
                <>
                  <div className="marb-5">
                    <strike className="red-col">
                      {calculateDisplayedPrice(item.priceForOne, item.qty)} TL
                    </strike>
                  </div>
                  <div className="marb-q">
                    <p>
                      {calculateDisplayedPrice(item.priceGap, item.qty)}
                      TL
                    </p>
                  </div>
                </>
              ) : item.qty > 1 ? (
                <>
                  <div className="marb-5">
                    <strike className="red-col">
                      {calculateDisplayedPrice(item.priceForOne, item.qty)} TL
                    </strike>
                  </div>
                  <div className="marb-q">
                    <p>
                      {calculateDisplayedPrice(
                        item.price,
                        item.qty,
                        item.priceGap
                      )}
                      TL
                    </p>
                  </div>
                </>
              ) : item.price * item.qty < item.priceForOne * item.qty ? (
                <>
                  <div className="marb-5">
                    <strike className="red-col">
                      {calculateDisplayedPrice(item.priceForOne, item.qty)} TL{" "}
                    </strike>
                  </div>
                  <div className="marb-q">
                    <p> {calculateDisplayedPrice(item.price)} TL</p>
                  </div>
                </>
              ) : !isDiscountValid &&
                isPending?.isTherePending !== "YES" &&
                item.price < item.priceForOne ? (
                ""
              ) : (
                <div className="marb-q">
                  <p>{calculateDisplayedPrice(item.price)} TL</p>
                </div>
              )
            ) : !isDiscountValid &&
              isPending?.isTherePending !== "YES" &&
              item.price < item.priceForOne ? (
              ""
            ) : (
              <div className="marb-q">
                <p> {calculateDisplayedPrice(item.price, item.qty)} TL</p>
              </div>
            )}
            {item.price === 0 ? (
              <p
                style={{
                  borderTop: "1px solid #ff9935",
                  paddingTop: "0.25rem ",
                }}
                className="fs1 green bold-font"
              >
                {calculateDisplayedPrice(item.priceForOne, item.qty)} TL{" "}
                <span>Kazanç</span>
              </p>
            ) : isDiscountValid && isPending?.isTherePending !== "YES" ? (
              <p
                style={{
                  borderTop: "1px solid #ff9935",
                  paddingTop: "0.25rem ",
                }}
                className="fs1 green bold-font"
              >
                {calculateDisplayedPrice(
                  item.priceForOne,
                  item.qty,
                  item.priceGap,
                  "showDiscountAmount",
                  "",
                  item.timeLimitedDiscount.discountAmount,
                  "",
                  "",
                  index
                )}{" "}
                TL <span>Kazanç</span>
              </p>
            ) : !isDiscountValid &&
              isPending?.isTherePending !== "YES" &&
              item.price < item.priceForOne ? (
              <div className="marb-q">
                <p> {calculateDisplayedPrice(item.priceForOne, item.qty)} TL</p>
              </div>
            ) : (
              isPending?.isTherePending === "YES" && (
                <p
                  style={{
                    borderTop: "1px solid #ff9935",
                    paddingTop: "0.25rem ",
                  }}
                  className="fs1 green bold-font"
                >
                  {calculateDisplayedPrice(
                    item.priceForOne,
                    item.qty,
                    item.priceGap,
                    "showDiscountAmount",
                    "",
                    "",
                    "",
                    "grandUpsell"
                  )}{" "}
                  TL <span>Kazanç</span>
                </p>
              )
            )}
          </td>

          <td className="txtmid">
            {item.price !== 0 ? (
              <button
                className="small-close-button"
                type="button"
                onClick={() => removeFromCardHandler(index, item.indexId)}
              >
                <i title="sil" className="fas fa-times beddo-color"></i>
              </button>
            ) : (
              <button className="small-close-button" type="button">
                <i title="sil" className="fas fa-times ghost-grey-chatgpt"></i>
              </button>
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={7}>
            {item.price === 0 ? (
              <CartSmallInfos
                title={"Bedava Ürün"}
                message={"1 alana 1 bedava kampanyasından yararlandınız."}
              />
            ) : isDiscountValid &&
              (isPending?.isTherePending === "NOTYET" ||
                isPending?.isTherePending === "NO") ? (
              <CartSmallInfos
                title={"Sınırlı Süreli İndirim Uygulandı"}
                message={
                  <>
                    Tebrikler. Hala süreniz var.
                    <Link to="/">İndirimli alışverişe devam edin.</Link>
                  </>
                }
              />
            ) : !isDiscountValid &&
              isPending?.isTherePending !== "YES" &&
              item.price < item.priceForOne ? (
              <CartSmallInfos
                title={"İndirim Süresi Sona Erdi"}
                message={"beddo™ alışverişinizi tamamlayın ve rahat edin"}
              />
            ) : (
              isPending?.isTherePending === "YES" && (
                <CartSmallInfos
                  title={"Ek Sipariş İndirimi Uygulandı"}
                  message={"Ana siparişiniz paketlenene kadar geçerlidir."}
                />
              )
            )}
            {item?.timeLimitedDiscount?.discountAmount > 0 &&
              isPending?.isTherePending !== "YES" &&
              isDiscountValid && (
                <CountDownTimerVisibilityAware
                  milliseconds={remainingTime}
                  setTrigger={() => setTrigger(true)}
                />
              )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
