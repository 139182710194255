import { useEffect, useState } from "react";
import fetchData from "../utils/fetchData";
import { errorMessage } from "../utils/errorMessage";

export function useSaveFingerPrintInDatabase(visitorId, user, errMessage) {
  const [resultData, setResultData] = useState(null);
  const [retryCount, setRetryCount] = useState(1);
  const [errorsMessage, setErrorsMessage] = useState(null);
  const MAX_RETRY_ATTEMPTS = 3; // Maximum number of retry attempts
  const RETRY_INTERVAL_MS = 30000; // Interval between retry attempts in milliseconds

  async function fetch(fingerPrint) {
    try {
      const { resultData } = await fetchData(
        "POST",
        "/api/userIdentification/createUserIdentification",
        fingerPrint
      );

      setResultData(resultData); // Store visitorId in state
      sessionStorage.setItem("resultData", JSON.stringify(resultData));
    } catch (error) {
      const { message } = errorMessage(error);
      setErrorsMessage(message);
      console.log(
        `Failed to save visitorId. Retrying (${retryCount}/${MAX_RETRY_ATTEMPTS})...`
      );
      setRetryCount(retryCount + 1);
    }
  }

  useEffect(() => {
    if (visitorId) {
      const fingerPrint = {
        visitorId,
        firstVisitTime: Date.now(),
        registeredUserId: user?._id,
        // order_Id: order?._id,
        errMessage: errorsMessage?.message || errMessage,
      };

      if (retryCount === 1) {
        fetch(fingerPrint);
      } else if (retryCount <= MAX_RETRY_ATTEMPTS) {
        setTimeout(() => {
          fetch(fingerPrint);
        }, RETRY_INTERVAL_MS);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorId, retryCount, setResultData, user?._id /*order?._id*/]);

  return resultData;
}
