// CREATE A DISCOUNT CODE
export const ADMIN_CREATE_DISCOUNT_REQUEST = "ADMIN_CREATE_DISCOUNT_REQUEST";
export const ADMIN_CREATE_DISCOUNT_SUCCESS = "ADMIN_CREATE_DISCOUNT_SUCCESS";
export const ADMIN_CREATE_DISCOUNT_FAIL = "ADMIN_CREATE_DISCOUNT_FAIL";
export const ADMIN_CREATE_DISCOUNT_RESET = "ADMIN_CREATE_DISCOUNT_RESET";

// LIST DISCOUNT CODES
export const ADMIN_LIST_DISCOUNTS_REQUEST = "ADMIN_LIST_DISCOUNTS_REQUEST";
export const ADMIN_LIST_DISCOUNTS_SUCCESS = "ADMIN_LIST_DISCOUNTS_SUCCESS";
export const ADMIN_LIST_DISCOUNTS_FAIL = "ADMIN_LIST_DISCOUNTS_FAIL";

// GET DETAILS OF DISCOUNT CODES
export const ADMIN_DETAILS_DISCOUNT_REQUEST = "ADMIN_DETAILS_DISCOUNT_REQUEST";
export const ADMIN_DETAILS_DISCOUNT_SUCCESS = "ADMIN_DETAILS_DISCOUNT_SUCCESS";
export const ADMIN_DETAILS_DISCOUNT_FAIL = "ADMIN_DETAILS_DISCOUNT_FAIL";

// UPDATE/EDIT DISCOUNT CODE
export const ADMIN_EDIT_DISCOUNT_REQUEST = "ADMIN_EDIT_DISCOUNT_REQUEST";
export const ADMIN_EDIT_DISCOUNT_SUCCESS = "ADMIN_EDIT_DISCOUNT_SUCCESS";
export const ADMIN_EDIT_DISCOUNT_FAIL = "ADMIN_EDIT_DISCOUNT_FAIL";
export const ADMIN_EDIT_DISCOUNT_RESET = "ADMIN_EDIT_DISCOUNT_RESET";
