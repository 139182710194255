import { useEffect } from "react";

export default function useIsMounted(mountRef) {
  useEffect(() => {
    // DETECT IF DOCUMENT MOUNTED

    mountRef.current = true;

    return () => (mountRef.current = false);
  }, [mountRef]);

  return mountRef.current;
}
