import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetTrendyolBrandList = createAsyncThunk(
  "jobInActionToGetTrendyolBrandList",
  async (brand, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get("/api/trendyol/getTrendyolBrandList", {
        params: {
          brand,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getTrendyolBrandList = createSlice({
  name: "sliceToGetTrendyolBrandList",
  initialState: {
    brandsList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetTrendyolBrandList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToGetTrendyolBrandList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.brandsList = action.payload;
      })
      .addCase(actionToGetTrendyolBrandList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const reducerToGetTrendyolBrandList = getTrendyolBrandList.reducer;

export default reducerToGetTrendyolBrandList;
