import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToCreateWholeSaleOrder = createAsyncThunk(
  "wholeSale/jobInActionToCreateWholeSaleOrder",
  async (orderData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.post(
        "/api/wholesale/createWholeSaleOrder",
        orderData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  createdOrder: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const createWholeSaleOrderSlice = createSlice({
  name: "createWholeSaleOrder",
  initialState,
  reducers: {
    resetCreateWholeSaleOrder: (state) => {
      state.createdOrder = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToCreateWholeSaleOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToCreateWholeSaleOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.createdOrder = action.payload;
      })
      .addCase(actionToCreateWholeSaleOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetCreateWholeSaleOrder } = createWholeSaleOrderSlice.actions;

// Export the reducer
const reducerToCreateWholeSaleOrder = createWholeSaleOrderSlice.reducer;

// Export the async action creator for use in components
export { reducerToCreateWholeSaleOrder, resetCreateWholeSaleOrder };
