// INSERT PRODUCTS INTO GOOGLE MERCHANT
export const ADMIN_UPLOAD_ALL_GM_REQUEST = "ADMIN_UPLOAD_ALL_GM_REQUEST";
export const ADMIN_UPLOAD_ALL_GM_SUCCESS = "ADMIN_UPLOAD_ALL_GM_SUCCESS";
export const ADMIN_UPLOAD_ALL_GM_FAIL = "ADMIN_UPLOAD_ALL_GM_FAIL";

// UPDATE A PRODUCT IN GOOGLE MERCHANT
export const ADMIN_UPDATE_A_PRODUCT_REQUEST = "ADMIN_UPDATE_A_PRODUCT_REQUEST";
export const ADMIN_UPDATE_A_PRODUCT_SUCCESS = "ADMIN_UPDATE_A_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_A_PRODUCT_FAIL = "ADMIN_UPDATE_A_PRODUCT_FAIL";

// CREATE A PRODUCT IN GOOGLE MERCHANT
export const ADMIN_CREATE_A_PRODUCT_REQUEST = "ADMIN_CREATE_A_PRODUCT_REQUEST";
export const ADMIN_CREATE_A_PRODUCT_SUCCESS = "ADMIN_CREATE_A_PRODUCT_SUCCESS";
export const ADMIN_CREATE_A_PRODUCT_FAIL = "ADMIN_CREATE_A_PRODUCT_FAIL";
