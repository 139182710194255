import axios from "axios";

let loading = false;
let resultError;
let resultData;

async function getData(reqMethod, reqUrl, reqObj) {
  try {
    loading = true;
    const { data } = await (reqMethod === "GET"
      ? axios.get(`${reqUrl}`, { params: reqObj })
      : reqMethod === "POST"
      ? axios.post(`${reqUrl}`, reqObj)
      : reqMethod === "PUT"
      ? axios.put(`${reqUrl}`, reqObj)
      : reqMethod === "DELETE" && axios.get(`${reqUrl}`, { params: reqObj }));

    if (data) {
      resultData = data;
      loading = false;
    }
  } catch (error) {
    const message =
      error.response && (error.response.data.message || error.response.data)
        ? error.response.data.message || error.response.data
        : error.message;

    resultError = message;

    loading = false;
  }
}

export default async function fetchData(reqMethod, reqUrl, reqObj) {
  await getData(reqMethod, reqUrl, reqObj);

  return {
    loading,
    resultError,
    resultData,
  };
}
