import React, { useEffect, useState } from "react";

export default function RatingOnHover(props) {
  // PROPS COMING FROM Rating
  const { reviews, value } = props;

  // CREATE ARRAYS TO MAP MULTIPLE STARS
  const stars = [1, 2, 3, 4, 5];

  // FIND THE NUMBER OF REVIEWS FOR EACH STAR
  const [numberOfReviews, setNumberOfRevies] = useState({
    fiveStar: 0,
    fourStar: 0,
    threeStar: 0,
    twoStar: 0,
    oneStar: 0,
  });
  useEffect(() => {
    if (reviews && reviews.length > 0) {
      setNumberOfRevies({
        fiveStar: reviews.filter((review) => review.rating === 5).length,
        fourStar: reviews.filter((review) => review.rating === 4).length,
        threeStar: reviews.filter((review) => review.rating === 3).length,
        twoStar: reviews.filter((review) => review.rating === 2).length,
        oneStar: reviews.filter((review) => review.rating === 1).length,
      });
    }
  }, [reviews]);

  return (
    <div className="ratingonhover">
      <p className="fs-85 grey marh">
        Ortalama Puan{" "}
        <span className="bold fs1125" style={{ color: "black" }}>
          {value}
        </span>
      </p>
      <table className="admin-panel-table">
        <thead className="head-tr">
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="padleft-5">
              5
            </th>
            <td>
              {stars.map((star, index) => {
                return (
                  <i
                    key={index}
                    className="fa fa-star"
                    style={{ color: "#f0c040" }}
                  ></i>
                );
              })}
            </td>
            <th className="fs-85 grey">{numberOfReviews.fiveStar}</th>
          </tr>
          <tr>
            <th scope="row" className="padleft-5">
              4
            </th>
            <td>
              {stars.map((star, index) => {
                return (
                  <i
                    key={index + 5}
                    className={index === 4 ? "far fa-star" : "fa fa-star"}
                    style={{ color: "#f0c040" }}
                  ></i>
                );
              })}
            </td>
            <th className="fs-85 grey">{numberOfReviews.fourStar}</th>
          </tr>
          <tr>
            <th scope="row" className="padleft-5">
              3
            </th>
            <td>
              {stars.map((star, index) => {
                return (
                  <i
                    key={index + 10}
                    className={index > 2 ? "far fa-star" : "fa fa-star"}
                    style={{ color: "#f0c040" }}
                  ></i>
                );
              })}
            </td>
            <th className="fs-85 grey">{numberOfReviews.threeStar}</th>
          </tr>
          <tr>
            <th scope="row" className="padleft-5">
              2
            </th>
            <td>
              {stars.map((star, index) => {
                return (
                  <i
                    key={index + 15}
                    className={index > 1 ? "far fa-star" : "fa fa-star"}
                    style={{ color: "#f0c040" }}
                  ></i>
                );
              })}
            </td>
            <th className="fs-85 grey">{numberOfReviews.twoStar}</th>
          </tr>
          <tr>
            <th scope="row" className="padleft-5">
              1
            </th>
            <td>
              {stars.map((star, index) => {
                return (
                  <i
                    key={index + 20}
                    className={index > 0 ? "far fa-star" : "fa fa-star"}
                    style={{ color: "#f0c040" }}
                  ></i>
                );
              })}
            </td>
            <th className="fs-85 grey">{numberOfReviews.oneStar}</th>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
