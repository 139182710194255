//LIST PRODUCTS AT HOMESCREEN
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

//SHOW PRODUCT DETAILS PAGE ON CLICK
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

// CAMPAINGNED PRODUCT DETAILS
export const PRODUCT_DETAILS_CAMPAIGNED_REQUEST =
  "PRODUCT_DETAILS_CAMPAIGNED_REQUEST";
export const PRODUCT_DETAILS_CAMPAIGNED_SUCCESS =
  "PRODUCT_DETAILS_CAMPAIGNED_SUCCESS";
export const PRODUCT_DETAILS_CAMPAIGNED_FAIL =
  "PRODUCT_DETAILS_CAMPAIGNED_FAIL";
export const PRODUCT_DETAILS_CAMPAIGNED_RESET =
  "PRODUCT_DETAILS_CAMPAIGNED_RESET";

//ADMIN PRODUCT SKU PRICE
export const PRODUCT_SKU_PRICE_REQUEST = "PRODUCT_SKU_PRICE_REQUEST";
export const PRODUCT_SKU_PRICE_SUCCESS = "PRODUCT_SKU_PRICE_SUCCESS";
export const PRODUCT_SKU_PRICE_FAIL = "PRODUCT_SKU_PRICE_FAIL";
export const PRODUCT_SKU_PRICE_RESET = "PRODUCT_SKU_PRICE_RESET";
//CAMPAIGNED PRODUCT SKU
export const PRODUCT_SKU_COMPAIGNED_REQUEST = "PRODUCT_SKU_COMPAIGNED_REQUEST";
export const PRODUCT_SKU_COMPAIGNED_SUCCESS = "PRODUCT_SKU_COMPAIGNED_SUCCESS";
export const PRODUCT_SKU_COMPAIGNED_FAIL = "PRODUCT_SKU_COMPAIGNED_FAIL";
export const PRODUCT_SKU_COMPAIGNED_RESET = "PRODUCT_SKU_COMPAIGNED_RESET";

//SEARCH AND CLASSIFY SKUS
export const PRODUCT_SKU_CLASSIFY_REQUEST = "PRODUCT_SKU_CLASSIFY_REQUEST";
export const PRODUCT_SKU_CLASSIFY_SUCCESS = "PRODUCT_SKU_CLASSIFY_SUCCESS";
export const PRODUCT_SKU_CLASSIFY_FAIL = "PRODUCT_SKU_CLASSIFY_FAIL";

//LIST SIDE PRODUCTS IF ANY
export const PRODUCT_SIDE_LIST_REQUEST = "PRODUCT_SIDE_LIST_REQUEST";
export const PRODUCT_SIDE_LIST_SUCCESS = "PRODUCT_SIDE_LIST_SUCCESS";
export const PRODUCT_SIDE_LIST_FAIL = "PRODUCT_SIDE_LIST_FAIL";
export const PRODUCT_SIDE_LIST_RESET = "PRODUCT_SIDE_LIST_RESET";
