//LIST ORDERS
export const ADMIN_ORDER_LIST_REQUEST = "ADMIN_ORDER_LIST_REQUEST";
export const ADMIN_ORDER_LIST_SUCCESS = "ADMIN_ORDER_LIST_SUCCESS";
export const ADMIN_ORDER_LIST_FAIL = "ADMIN_ORDER_LIST_FAIL";

//GET ORDER DETAILS
export const ADMIN_ORDER_DETAILS_REQUEST = "ADMIN_ORDER_DETAILS_REQUEST";
export const ADMIN_ORDER_DETAILS_SUCCESS = "ADMIN_ORDER_DETAILS_SUCCESS";
export const ADMIN_ORDER_DETAILS_FAIL = "ADMIN_ORDER_DETAILS_FAIL";
export const ADMIN_ORDER_DETAILS_RESET = "ADMIN_ORDER_DETAILS_RESET";

//GET UPSELL ORDER DETAILS
export const ADMIN_UPSELL_ORDER_DETAILS_REQUEST =
  "ADMIN_UPSELL_ORDER_DETAILS_REQUEST";
export const ADMIN_UPSELL_ORDER_DETAILS_SUCCESS =
  "ADMIN_UPSELL_ORDER_DETAILS_SUCCESS";
export const ADMIN_UPSELL_ORDER_DETAILS_FAIL =
  "ADMIN_UPSELL_ORDER_DETAILS_FAIL";
export const ADMIN_UPSELL_ORDER_DETAILS_RESET =
  "ADMIN_UPSELL_ORDER_DETAILS_RESET";

//CANCEL AN ORDER (UNPAID)
export const ADMIN_ORDER_CANCEL_REQUEST = "ADMIN_ORDER_CANCEL_REQUEST";
export const ADMIN_ORDER_CANCEL_SUCCESS = "ADMIN_ORDER_CANCEL_SUCCESS";
export const ADMIN_ORDER_CANCEL_FAIL = "ADMIN_ORDER_CANCEL_FAIL";
export const ADMIN_ORDER_CANCEL_RESET = "ADMIN_ORDER_CANCEL_RESET";

//CANCEL AN ORDER PARTIALLY (UNPAID)
export const ADMIN_ORDER_CANCEL_PARTIALLY_REQUEST =
  "ADMIN_ORDER_CANCEL_PARTIALLY_REQUEST";
export const ADMIN_ORDER_CANCEL_PARTIALLY_SUCCESS =
  "ADMIN_ORDER_CANCEL_PARTIALLY_SUCCESS";
export const ADMIN_ORDER_CANCEL_PARTIALLY_FAIL =
  "ADMIN_ORDER_CANCEL_PARTIALLY_FAIL";
export const ADMIN_ORDER_CANCEL_PARTIALLY_RESET =
  "ADMIN_ORDER_CANCEL_PARTIALLY_RESET";

//CANCEL AN ORDER (PAID)
export const ADMIN_ORDER_CANCEL_PAYMENT_REQUEST =
  "ADMIN_ORDER_CANCEL_PAYMENT_REQUEST";
export const ADMIN_ORDER_CANCEL_PAYMENT_SUCCESS =
  "ADMIN_ORDER_CANCEL_PAYMENT_SUCCESS";
export const ADMIN_ORDER_CANCEL_PAYMENT_FAIL =
  "ADMIN_ORDER_CANCEL_PAYMENT_FAIL";

//UPDATE ORDER WITH CANCELLED PAYMENT INFO
export const ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST =
  "ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST";
export const ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS =
  "ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS";
export const ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_FAIL =
  "ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_FAIL";

//UPDATE ORDER DELIVERY CONTACT
export const ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_REQUEST =
  "ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_REQUEST";
export const ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS =
  "ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS";
export const ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_FAIL =
  "ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_FAIL";
export const ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_RESET =
  "ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_RESET";

//UPDATE ORDER INDIVIDUAL INVOICE
export const ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST =
  "ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST";
export const ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS =
  "ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS";
export const ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL =
  "ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL";
export const ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET =
  "ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET";

//UPDATE ORDER COMPANY INVOICE
export const ADMIN_ORDER_UPDATE_COMPANY_INVOICE_REQUEST =
  "ADMIN_ORDER_UPDATE_COMPANY_INVOICE_REQUEST";
export const ADMIN_ORDER_UPDATE_COMPANY_INVOICE_SUCCESS =
  "ADMIN_ORDER_UPDATE_COMPANY_INVOICE_SUCCESS";
export const ADMIN_ORDER_UPDATE_COMPANY_INVOICE_FAIL =
  "ADMIN_ORDER_UPDATE_COMPANY_INVOICE_FAIL";
export const ADMIN_ORDER_UPDATE_COMPANY_INVOICE_RESET =
  "ADMIN_ORDER_UPDATE_COMPANY_INVOICE_RESET";

//MARK ORDER AS PRINTED
export const ADMIN_ORDER_PRNTED_REQUEST = "ADMIN_ORDER_PRNTED_REQUEST";
export const ADMIN_ORDER_PRNTED_SUCCESS = "ADMIN_ORDER_PRNTED_SUCCESS";
export const ADMIN_ORDER_PRNTED_FAIL = "ADMIN_ORDER_PRNTED_FAIL";
export const ADMIN_ORDER_PRNTED_RESET = "ADMIN_ORDER_PRNTED_RESET";

//UPDATE ORDER AS PAID
export const ADMIN_ORDER_PAID_REQUEST = "ADMIN_ORDER_PAID_REQUEST";
export const ADMIN_ORDER_PAID_SUCCESS = "ADMIN_ORDER_PAID_SUCCESS";
export const ADMIN_ORDER_PAID_FAIL = "ADMIN_ORDER_PAID_FAIL";
export const ADMIN_ORDER_PAID_RESET = "ADMIN_ORDER_PAID_RESET";

//SET ORDER PAYMENT METHOD
export const ADMIN_ORDER_SET_PAYMENT_METHOD_REQUEST =
  "ADMIN_ORDER_SET_PAYMENT_METHOD_REQUEST";
export const ADMIN_ORDER_SET_PAYMENT_METHOD_SUCCESS =
  "ADMIN_ORDER_SET_PAYMENT_METHOD_SUCCESS";
export const ADMIN_ORDER_SET_PAYMENT_METHOD_FAIL =
  "ADMIN_ORDER_SET_PAYMENT_METHOD_FAIL";
export const ADMIN_ORDER_SET_PAYMENT_METHOD_RESET =
  "ADMIN_ORDER_SET_PAYMENT_METHOD_RESET";

//SET NOTE FOR THE ORDER
export const ADMIN_ORDER_SET_NOTE_REQUEST = "ADMIN_ORDER_SET_NOTE_REQUEST";
export const ADMIN_ORDER_SET_NOTE_SUCCESS = "ADMIN_ORDER_SET_NOTE_SUCCESS";
export const ADMIN_ORDER_SET_NOTE_FAIL = "ADMIN_ORDER_SET_NOTE_FAIL";
export const ADMIN_ORDER_SET_NOTE_RESET = "ADMIN_ORDER_SET_NOTE_RESET";

//SET UPS TRACKING CODE FOR THE ORDER
export const ADMIN_ORDER_SET_UPS_TRACKING_CODE_REQUEST =
  "ADMIN_ORDER_SET_UPS_TRACKING_CODE_REQUEST";
export const ADMIN_ORDER_SET_UPS_TRACKING_CODE_SUCCESS =
  "ADMIN_ORDER_SET_UPS_TRACKING_CODE_SUCCESS";
export const ADMIN_ORDER_SET_UPS_TRACKING_CODE_FAIL =
  "ADMIN_ORDER_SET_UPS_TRACKING_CODE_FAIL";
export const ADMIN_ORDER_SET_UPS_TRACKING_CODE_RESET =
  "ADMIN_ORDER_SET_UPS_TRACKING_CODE_RESET";

//SET UPS TRACKING CODE FOR REFUND ORDER
export const ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_REQUEST =
  "ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_REQUEST";
export const ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_SUCCESS =
  "ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_SUCCESS";
export const ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_FAIL =
  "ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_FAIL";
export const ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_RESET =
  "ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_RESET";

//SET AN ORDER "DELIVERED"
export const ADMIN_ORDER_SET_DELIVERED_REQUEST =
  "ADMIN_ORDER_SET_DELIVERED_REQUEST";
export const ADMIN_ORDER_SET_DELIVERED_SUCCESS =
  "ADMIN_ORDER_SET_DELIVERED_SUCCESS";
export const ADMIN_ORDER_SET_DELIVERED_FAIL = "ADMIN_ORDER_SET_DELIVERED_FAIL";
export const ADMIN_ORDER_SET_DELIVERED_RESET =
  "ADMIN_ORDER_SET_DELIVERED_RESET";

//SET AN ORDER "UNDELIVERED"
export const ADMIN_ORDER_SET_UNDELIVERED_REQUEST =
  "ADMIN_ORDER_SET_UNDELIVERED_REQUEST";
export const ADMIN_ORDER_SET_UNDELIVERED_SUCCESS =
  "ADMIN_ORDER_SET_UNDELIVERED_SUCCESS";
export const ADMIN_ORDER_SET_UNDELIVERED_FAIL =
  "ADMIN_ORDER_SET_UNDELIVERED_FAIL";
export const ADMIN_ORDER_SET_UNDELIVERED_RESET =
  "ADMIN_ORDER_SET_UNDELIVERED_RESET";

//SET A REFUND ORDER "DELIVERED"
export const ADMIN_ORDER_SET_REFUND_DELIVERED_REQUEST =
  "ADMIN_ORDER_SET_REFUND_DELIVERED_REQUEST";
export const ADMIN_ORDER_SET_REFUND_DELIVERED_SUCCESS =
  "ADMIN_ORDER_SET_REFUND_DELIVERED_SUCCESS";
export const ADMIN_ORDER_SET_REFUND_DELIVERED_FAIL =
  "ADMIN_ORDER_SET_REFUND_DELIVERED_FAIL";
export const ADMIN_ORDER_SET_REFUND_DELIVERED_RESET =
  "ADMIN_ORDER_SET_REFUND_DELIVERED_RESET";

// POSSIBLE DUPLICATE ORDER ALERT
export const ADMIN_ORDER_DUPLICATE_ALERT_REQUEST =
  "ADMIN_ORDER_DUPLICATE_ALERT_REQUEST";
export const ADMIN_ORDER_DUPLICATE_ALERT_SUCCESS =
  "ADMIN_ORDER_DUPLICATE_ALERT_SUCCESS";
export const ADMIN_ORDER_DUPLICATE_ALERT_FAIL =
  "ADMIN_ORDER_DUPLICATE_ALERT_FAIL";
export const ADMIN_ORDER_DUPLICATE_ALERT_RESET =
  "ADMIN_ORDER_DUPLICATE_ALERT_RESET";

// UPDATE PROFILE PHONE NUMBER
export const ADMIN_ORDER_UPDATE_PROFILE_REQUEST =
  "ADMIN_ORDER_UPDATE_PROFILE_REQUEST";
export const ADMIN_ORDER_UPDATE_PROFILE_SUCCESS =
  "ADMIN_ORDER_UPDATE_PROFILE_SUCCESS";
export const ADMIN_ORDER_UPDATE_PROFILE_FAIL =
  "ADMIN_ORDER_UPDATE_PROFILE_FAIL";
export const ADMIN_ORDER_UPDATE_PROFILE_RESET =
  "ADMIN_ORDER_UPDATE_PROFILE_RESET";

// LIST OF ORDER ITEMS THAT ARE PENDING SHIPPING
export const ADMIN_ORDER_PENDING_SHIPPING_REQUEST =
  "ADMIN_ORDER_PENDING_SHIPPING_REQUEST";
export const ADMIN_ORDER_PENDING_SHIPPING_SUCCESS =
  "ADMIN_ORDER_PENDING_SHIPPING_SUCCESS";
export const ADMIN_ORDER_PENDING_SHIPPING_FAIL =
  "ADMIN_ORDER_PENDING_SHIPPING_FAIL";
export const ADMIN_ORDER_PENDING_SHIPPING_RESET =
  "ADMIN_ORDER_PENDING_SHIPPING_RESET";
