import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetHepsiBuradaCategories = createAsyncThunk(
  "jobInActionToGetHepsiBuradaCategories",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/hepsiBurada/getHepsiBuradaCategories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getHepsiBuradaCategories = createSlice({
  name: "sliceToGetHepsiBuradaCategories",
  initialState: {
    categories: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetGetHepsiBuradaCategories: (state) => {
      state.categories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetHepsiBuradaCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToGetHepsiBuradaCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categories = action.payload;
      })
      .addCase(actionToGetHepsiBuradaCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetGetHepsiBuradaCategories } =
  getHepsiBuradaCategories.actions;

const reducerToGetHepsiBuradaCategories = getHepsiBuradaCategories.reducer;

export default reducerToGetHepsiBuradaCategories;
