import {
  CART_ADD_SKU_ITEM,
  CART_REMOVE_SKU_ITEM,
  CART_EMPTY_SKU,
  CART_ADD_ITEM_REQUEST,
  CALCULATE_ITEMS_PRICE,
  UPDATE_SHIPPING_PRICE,
  UPDATE_TOTAL_PRICE,
  CALCULATE_TAX_PRICE,
} from "../constants/cartConstants";
import calculateLimitedTimeDiscountValidity from "../utils/calculateLimitedTimeDiscountValidity";

// Helper function to calculate taxPrice
const calculateTaxPrice = (cartItems) => {
  return cartItems.reduce(
    (acc, item) => acc + (item.price * item.vat) / 100,
    0
  );
};

export const reducerToAddOrRemoveSkuItemInCart = (
  state = { cartSkuItems: [], loading: false, taxPrice: 0, shippingPrice: 0 },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CART_ADD_SKU_ITEM:
      const item = action.payload;
      const existItem = state.cartSkuItems.find((x) => x.skuId === item.skuId);

      if (
        existItem &&
        item.buyOneGetOneFree &&
        !item.buyOneGetOtherProductFree
      ) {
        return {
          ...state,
          loading: false,
          cartSkuItems: [...state.cartSkuItems, item],
          taxPrice: calculateTaxPrice([...state.cartSkuItems, item]),
          shippingPrice: 0,
        };
      } else if (existItem) {
        return {
          ...state,
          loading: false,
          /*cartSkuItems: state.cartSkuItems.map((x) =>
            x.skuId === existItem.skuId ? item : x
          ),*/
          cartSkuItems: state.cartSkuItems.map((x) =>
            x.skuId === existItem.skuId
              ? {
                  ...item,
                  qty:
                    item.addToCartButtonLocation === "cartScreenQtySelection"
                      ? item.qty
                      : Number(x.qty) + Number(item.qty),
                }
              : x
          ),

          taxPrice: calculateTaxPrice([...state.cartSkuItems, item]),
          shippingPrice: 0,
        };
      } else {
        return {
          ...state,
          loading: false,
          cartSkuItems: [...state.cartSkuItems, item],
          taxPrice: calculateTaxPrice([...state.cartSkuItems, item]),
          shippingPrice: 0,
        };
      }

    case CART_REMOVE_SKU_ITEM:
      const indexIdToRemove = action.payload;

      return {
        ...state,
        cartSkuItems: state.cartSkuItems.filter(
          (item) => item.indexId !== indexIdToRemove
        ),

        taxPrice: calculateTaxPrice(
          state.cartSkuItems.filter((item) => item.indexId !== indexIdToRemove)
        ),
        shippingPrice: 0,
      };

    case CART_EMPTY_SKU:
      localStorage.removeItem("cartSkuItems");
      localStorage.removeItem("addedInCart");
      // localStorage.removeItem("extraDiscount");
      return { ...state, cartSkuItems: [], taxPrice: 0, shippingPrice: 0 };

    case CALCULATE_ITEMS_PRICE:
      const { cartItems, firstVisitTime } = action.payload;
      const itemsPrice = cartItems.reduce((acc, item) => {
        const { isDiscountValid } = calculateLimitedTimeDiscountValidity(
          item,
          firstVisitTime
        );
        return item.price === 0
          ? acc + item.price + (item.qty - 1) * item.price
          : isDiscountValid
          ? acc + item.price + (item.qty - 1) * item.priceGap
          : acc + item.price + (item.qty - 1) * item.price;
      }, 0);
      return { ...state, itemsPrice };

    case UPDATE_SHIPPING_PRICE:
      const shippingPrice = action.payload;
      return { ...state, shippingPrice };

    case UPDATE_TOTAL_PRICE:
      const totalPrice = action.payload;
      return { ...state, totalPrice };

    case CALCULATE_TAX_PRICE:
      const taxPrice = state.cartSkuItems.reduce(
        (acc, item) => acc + (item.price * item.vat) / 100,
        0
      );
      return { ...state, taxPrice };

    default:
      return state;
  }
};
