import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { actionToGetProductList } from "../../actions/productActions";
import MessageBox from "../../modules/shared/components/MessageBox";
import "./HomeScreen.css";
import { useHistory, useLocation } from "react-router-dom";
import { actionOrderToCheckUnpaidOrders } from "../../actions/orderActions";
import Product from "../../components/Product";
import SearchResultsInfo from "../../components/SearchResultsInfo";
import MessageProductFeature from "../../components/MessageProductFeature";
import SuspenseFallback from "../../components/SuspenseFallback";
import useIsMounted from "../../hooks/useIsMounted";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useScrollRestorationById } from "../../hooks/useScrollRestorationById";
import LoadingProducts from "../../components/LoadingProducts";

export default function HomeScreen(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // PROPS COMING FROM APP
  const { createdFingerPrint } = props;

  // REDUX STATE THAT HOLDS INFO IF THERE IS A PENDING ORDER OF CLIENT
  const checkIfThereIsApendingOrder = useSelector(
    (state) => state.checkIfThereIsApendingOrder
  );
  const { isPending } = checkIfThereIsApendingOrder;

  // SCROLL RESTORATION LOGIC
  const mountRef = useRef(false);
  const isMounted = useIsMounted(mountRef);
  useScrollRestorationById(isMounted);

  //GET SEARCH KEYWORD FROM URL
  const keyword = props.match.params.keyword;

  //GET PAGE NUMBER FROM URL
  // const pageNumber = props.match.params.pageNumber || 1;

  //REDUX STORE THAT HOLDS PRODUCT LIST
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, activeProductCount, allProducts } =
    productList;

  //REDUX STORE THAT HOLDS SKU CART ITEMS
  const addOrRemoveSkuItemInCart = useSelector(
    (state) => state.addOrRemoveSkuItemInCart
  );
  const { cartSkuItems } = addOrRemoveSkuItemInCart;

  // INFINITE SCROLL LOGIC
  const { itemsList: productsList, lastElementRef } = useInfiniteScroll(
    products,
    loading,
    pages,
    actionToGetProductList,
    keyword,
    activeProductCount,
    allProducts
  );

  //IF THERE IS A PHONE ITEM IN LOCAL STORAGE
  //IT IS PASSED TO ACTION AND DISPATCH A REQUEST
  //TO FIND OUT HOW MANY ORDERS ARE UNPAID
  //THAT IS RELATED TO THIS PHONE NUMBER
  useEffect(() => {
    const phoneNumber = JSON.parse(localStorage.getItem("checkUnpaid"));
    if (phoneNumber) {
      dispatch(actionOrderToCheckUnpaidOrders(phoneNumber));
    }
  }, [history, dispatch, location]);

  // SHOW SEARCH RESULTS INFO
  const [displaySearchInfo, setDisplaySearchInfo] = useState(false);
  useEffect(() => {
    if (location.pathname.substring(0, 4) === "/ara") {
      setDisplaySearchInfo(true);
    } else {
      setDisplaySearchInfo(false);
    }
  }, [displaySearchInfo, location]);

  // STATE TO DETECT FIRST LOADING OF THE PRODUCT LIST
  const [isFirstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (loading && !isFirstLoad) {
      setFirstLoad(true);
    }
  }, [loading, isFirstLoad]);

  return (
    <>
      <Helmet>
        <title>beddo™ Anasayfa | Tüm beddo™ Ürünleri | Şimdi İncele</title>
        <meta
          name="description"
          content="Sağlıklı, rahat ve huzur veren beddo™ ürünlerine bir gözatın. Kullanıcı yararı gözetilerek üretilen beddo™ ürünleri tam 90 gün garantili. "
        />
        <meta name="keywords" content="beddo, beddo ürünleri" />
        <meta name="author" content="beddo™ Marketing Team" />
        <meta name="application-name" content="beddo™ ERP" />
      </Helmet>
      {loading && !isFirstLoad ? (
        <SuspenseFallback></SuspenseFallback>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          {displaySearchInfo && <SearchResultsInfo products={products} />}
          <div className="products">
            {productsList?.length > 0 &&
              productsList.map((product, index) => {
                if (index === productsList.length - 1) {
                  return (
                    <div
                      className="product-outer white-shadowbackground"
                      key={index}
                    >
                      <div className="w100p">
                        <MessageProductFeature product={product} />
                      </div>
                      <div id={product._id} ref={mountRef}></div>
                      <Product
                        productsList={productsList}
                        product={product}
                        cartSkuItems={cartSkuItems}
                        isPending={isPending}
                        createdFingerPrint={createdFingerPrint}
                        lastElementRef={lastElementRef}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="product-outer white-shadowbackground"
                      key={index}
                    >
                      <div className="w100p">
                        <MessageProductFeature product={product} />
                      </div>
                      <div id={product._id} ref={mountRef}></div>
                      <Product
                        productsList={productsList}
                        product={product}
                        cartSkuItems={cartSkuItems}
                        isPending={isPending}
                        createdFingerPrint={createdFingerPrint}
                      />
                    </div>
                  );
                }
              })}
          </div>
          {loading && isFirstLoad && (
            <LoadingProducts content={"Ürünler yükleniyor"} />
          )}
        </>
      )}
    </>
  );
}
