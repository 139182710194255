import React from "react";
import { stylerDecimalPart } from "../../../../utils/stylerDecimalPart";

export default function WarningCartTotalPlusPrice(props) {
  // PROPS COMING FROM useFreeGoodsAllocation
  const {
    setShowDetails,
    setElementId,
    cartTotalVatExcluded,
    vatExcludedPrice,
    freeGoodsAllocationActive,
    orderNumber,
  } = props;

  return (
    <p className="fs1">
      Sepet tutarı, tanımlı mal fazlası tutarını aşacağı için yeni ürün
      eklenemiyor.{" "}
      <span
        onClick={() => {
          return [
            setShowDetails(
              <table className="w100p fs1 dark-grey">
                <thead>
                  <tr>
                    <th colSpan={4} className="txtcenter slightlyyellowbck">
                      {orderNumber} Numaralı Sipariş İçin
                    </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="w70p">Mevcut Sepet Toplamı</th>
                    <td className="txtleft w30p">
                      {parseInt(cartTotalVatExcluded)}.
                      {stylerDecimalPart(cartTotalVatExcluded)} TL
                    </td>
                  </tr>
                  <tr>
                    <th className="w70p">Ürün Fiyatı</th>
                    <td className="txtleft w30p">
                      {parseInt(vatExcludedPrice)}.
                      {stylerDecimalPart(vatExcludedPrice)} TL
                    </td>
                  </tr>
                  <tr>
                    <th className="w70p">Sepet Ve Ürün Toplamı</th>
                    <td className="txtleft w30p">
                      {parseInt(cartTotalVatExcluded + vatExcludedPrice)}.
                      {stylerDecimalPart(
                        cartTotalVatExcluded + vatExcludedPrice
                      )}{" "}
                      TL
                    </td>
                  </tr>
                  <tr>
                    <th className="w70p">Mal Fazlası Tutarı</th>
                    <td className="txtleft w30p">
                      {parseInt(freeGoodsAllocationActive)}.
                      {stylerDecimalPart(freeGoodsAllocationActive)} TL
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={4} className="txtcenter slightlyyellowbck">
                      Mal Fazlası Tutarı Yetersiz
                    </th>
                    <td></td>
                  </tr>
                  <tr id="scroll-element">
                    <th colSpan={4} className="txtleft greenbcklight">
                      Harcanamayan tutarlar nakit olarak alınmaz ya da indirim
                      olarak yansıtılmaz. Ancak, bir sonraki siparişinize
                      aktarılır.
                    </th>
                    <td></td>
                  </tr>
                </thead>
              </table>
            ),
            setElementId("scroll-element"),
          ];
        }}
        className="linkblue"
      >
        Detay Gör
      </span>
    </p>
  );
}
