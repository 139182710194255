import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetHepsiBuradaCategoryAttributes = createAsyncThunk(
  "jobInActionToGetHepsiBuradaCategoryAttributes",
  async (categoryId, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/hepsiBurada/getHepsiBuradaCategoryAttributes",
        {
          params: {
            categoryId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getHepsiBuradaCategoryAttributes = createSlice({
  name: "sliceToGetHepsiBuradaCategoryAttributes",
  initialState: {
    data: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetHepsiBuradaCategoryAttributes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actionToGetHepsiBuradaCategoryAttributes.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        actionToGetHepsiBuradaCategoryAttributes.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

const reducerToGetHepsiBuradaCategoryAttributes =
  getHepsiBuradaCategoryAttributes.reducer;

export default reducerToGetHepsiBuradaCategoryAttributes;
