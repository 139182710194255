// ADMIN GET INVOICE DETAILS
export const ADMIN_INVOICE_DETAILS_REQUEST = "ADMIN_INVOICE_DETAILS_REQUEST";
export const ADMIN_INVOICE_DETAILS_SUCCESS = "ADMIN_INVOICE_DETAILS_SUCCESS";
export const ADMIN_INVOICE_DETAILS_FAIL = "ADMIN_INVOICE_DETAILS_FAIL";

// ADMIN GET E-DOCUMENT DETAILS
export const ADMIN_E_DOCUMENT_DETAILS_REQUEST =
  "ADMIN_E_DOCUMENT_DETAILS_REQUEST";
export const ADMIN_E_DOCUMENT_DETAILS_SUCCESS =
  "ADMIN_E_DOCUMENT_DETAILS_SUCCESS";
export const ADMIN_E_DOCUMENT_DETAILS_FAIL = "ADMIN_E_DOCUMENT_DETAILS_FAIL";

// ADMIN RUN CREATE MULTIPLE INVOICES SQUENCE
export const ADMIN_INVOICES_CREATE_REQUEST = "ADMIN_INVOICES_CREATE_REQUEST";
export const ADMIN_INVOICES_CREATE_SUCCESS = "ADMIN_INVOICES_CREATE_SUCCESS";
export const ADMIN_INVOICES_CREATE_FAIL = "ADMIN_INVOICES_CREATE_FAIL";

// ADMIN CANCEL AN INVOICE
export const ADMIN_INVOICE_CANCEL_REQUEST = "ADMIN_INVOICE_CANCEL_REQUEST";
export const ADMIN_INVOICE_CANCEL_SUCCESS = "ADMIN_INVOICE_CANCEL_SUCCESS";
export const ADMIN_INVOICE_CANCEL_FAIL = "ADMIN_INVOICE_CANCEL_FAIL";
export const ADMIN_INVOICE_CANCEL_RESET = "ADMIN_INVOICE_CANCEL_RESET";

// SET INVOICE STATUS "Expense slip issued" AFTER PRINTING
export const ADMIN_EXPENSE_SLIP_PRINTED_REQUEST =
  "ADMIN_EXPENSE_SLIP_PRINTED_REQUEST";
export const ADMIN_EXPENSE_SLIP_PRINTED_SUCCESS =
  "ADMIN_EXPENSE_SLIP_PRINTED_SUCCESS";
export const ADMIN_EXPENSE_SLIP_PRINTED_FAIL =
  "ADMIN_EXPENSE_SLIP_PRINTED_FAIL";
export const ADMIN_EXPENSE_SLIP_PRINTED_RESET =
  "ADMIN_EXPENSE_SLIP_PRINTED_RESET";

// ADMIN RUN CREATE SINGLE INVOICE
export const ADMIN_INVOICE_CREATE_REQUEST = "ADMIN_INVOICE_CREATE_REQUEST";
export const ADMIN_INVOICE_CREATE_SUCCESS = "ADMIN_INVOICE_CREATE_SUCCESS";
export const ADMIN_INVOICE_CREATE_FAIL = "ADMIN_INVOICE_CREATE_FAIL";
