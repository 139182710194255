import { useEffect, useState } from "react";
// import calculateLimitedTimeDiscountValidity from "../utils/calculateLimitedTimeDiscountValidity";

export function useCalculateCartTotal(
  cartSkuItems,
  firstVisitTime,
  isPending,
  extraDiscountAmount,
  trigger,
  isDiscountValid
) {
  // IMPORTANT NOTES
  /*

    * priceForOne : Price without the discount
    * price : Value depends on many factors such as length of cart, quantity and limited time discount
    * priceGap: This is the price that applies if the quantity of cart is more than 1
    * BuyOneGetOneFree: If but one get one free campaign is defined for an item, when added in cart, there will be 2 items, one with price or priceGap and the other is 0
    
  */

  // LOGIC
  /*

    * Remove free items from cartSkuItems
    * Slice index 0 and sum the prices based on the criteria of discountAmount and qty 
    * (İf there is a single qty price is calculated by substracting discountAmount from priceForOne)
    * (if there are multiple qty in index 0 first piece is calculated at priceForOne and other pieces are calculated at priceGap)
    * For the items in index 1 and more we slice out index 0 and reduce the rest by calculating at price gap
    * Than save the results in a state var and return
    
  */

  // CHECK THE CART TOTAL AND DECIDE IF CAP IS REACHED TO OFFER pricaGap
  const [cartReport, setCartReport] = useState({
    totalAmount: 0,
    totalQty: 0,
    totalAmountWithoutDiscount: 0,
    surpriceDiscount: 0,
    isDiscountApplied: false,
    hasFreeItem: false,
  });

  useEffect(() => {
    let totalQty = cartSkuItems?.reduce((a, c) => a + Number(c.qty), 0);
    let totalAmount = cartSkuItems
      ?.reduce((a, c) => a + c.itemsTotalPrice, 0)
      .toFixed(2);
    let totalAmountWithoutDiscount = cartSkuItems?.reduce(
      (a, c) => a + c.priceForOne * Number(c.qty),
      0
    );
    let cartItemsWithoutFreeItems = cartSkuItems?.filter(
      (item) => item.price > 0
    );

    const isDiscountApplied = totalAmountWithoutDiscount > totalAmount;

    setCartReport({
      totalAmount,
      /* extraDiscountAmount && !isDiscountApplied
          ? totalAmount - extraDiscountAmount
          : totalAmount*/
      totalQty,
      totalAmountWithoutDiscount,
      surpriceDiscount: extraDiscountAmount ? extraDiscountAmount : 0,
      isDiscountApplied,
      hasFreeItem: cartSkuItems?.length > cartItemsWithoutFreeItems?.length,
    });
  }, [
    cartSkuItems,
    firstVisitTime,
    isPending?.isTherePending,
    extraDiscountAmount,
    trigger,
    isDiscountValid,
  ]);

  return cartReport;
}
