import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetCategories = createAsyncThunk(
  "wholeSale/jobInActionToGetCategories",
  async (_, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get("/api/wholesale/getCategories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  categories: {},
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getCategoriesSlice = createSlice({
  name: "getCategories",
  initialState,
  reducers: {
    resetGetCategories: (state) => {
      state.categories = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToGetCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(actionToGetCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetGetCategories } = getCategoriesSlice.actions;

// Export the reducer
const reducerToGetCategories = getCategoriesSlice.reducer;

// Export the async action creator for use in components
export { reducerToGetCategories, resetGetCategories };
