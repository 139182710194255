import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionAdminToListPops } from "../../actions/adminPopsActions";
import LoadingBox from "../../modules/shared/components/LoadingBox";
import MessageBox from "../../modules/shared/components/MessageBox";
import PopsBanner from "./PopBanner";
import useGetLimitedTimeDiscountInfo from "../../hooks/useGetLimitedTimeDiscountInfo";
// import calculateLimitedTimeDiscountValidity from "../../utils/calculateLimitedTimeDiscountValidity";

export default function PopsComponent(props) {
  const dispatch = useDispatch();

  // PROPS COMING FROM App
  const { createdFingerPrint } = props;

  // BRING IN POPS LIST FROM REDUX STORE
  const listPops = useSelector((state) => state.listPops);
  const { loading, error, popsList } = listPops;
  // DISPATCH ACTION TO LIST POPS
  useEffect(() => {
    dispatch(actionAdminToListPops());
  }, [dispatch]);

  const { isDiscountValid, remainingTime } = useGetLimitedTimeDiscountInfo(
    createdFingerPrint,
    createdFingerPrint
  );

  return (
    <>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        popsList.map((pop, index) => {
          return (
            <PopsBanner
              key={index}
              pop={pop}
              isDiscountValid={isDiscountValid}
              remainingTime={remainingTime}
            />
          );
        })
      )}
    </>
  );
}
