// CREATE A POP
export const ADMIN_CREATE_POP_REQUEST = "ADMIN_CREATE_POP_REQUEST";
export const ADMIN_CREATE_POP_SUCCESS = "ADMIN_CREATE_POP_SUCCESS";
export const ADMIN_CREATE_POP_FAIL = "ADMIN_CREATE_POP_FAIL";
export const ADMIN_CREATE_POP_RESET = "ADMIN_CREATE_POP_RESET";

// LIST POPS
export const ADMIN_LIST_POPS_REQUEST = "ADMIN_LIST_POPS_REQUEST";
export const ADMIN_LIST_POPS_SUCCESS = "ADMIN_LIST_POPS_SUCCESS";
export const ADMIN_LIST_POPS_FAIL = "ADMIN_LIST_POPS_FAIL";

// EDIT A POP
export const ADMIN_EDIT_POP_REQUEST = "ADMIN_EDIT_POP_REQUEST";
export const ADMIN_EDIT_POP_SUCCESS = "ADMIN_EDIT_POP_SUCCESS";
export const ADMIN_EDIT_POP_FAIL = "ADMIN_EDIT_POP_FAIL";
export const ADMIN_EDIT_POP_RESET = "ADMIN_EDIT_POP_RESET";

// GET DETAILS OF POP
export const ADMIN_DETAILS_POP_REQUEST = "ADMIN_DETAILS_POP_REQUEST";
export const ADMIN_DETAILS_POP_SUCCESS = "ADMIN_DETAILS_POP_SUCCESS";
export const ADMIN_DETAILS_POP_FAIL = "ADMIN_DETAILS_POP_FAIL";
