import Axios from "axios";

import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_PHONE_CHECK_REQUEST,
  ORDER_PHONE_CHECK_SUCCESS,
  ORDER_PHONE_CHECK_FAIL,
  //ORDER_IYZICO_FORM_SUCCESS,
  //ORDER_IYZICO_FORM_REQUEST,
  //ORDER_IYZICO_FORM_FAIL,
  //ORDER_IYZICO_FORM_RESULT_REQUEST,
  //ORDER_IYZICO_FORM_RESULT_SUCCESS,
  //ORDER_IYZICO_FORM_RESULT_FAIL,
  ORDER_UPDATE_PAYMENT_INFO_REQUEST,
  ORDER_UPDATE_PAYMENT_INFO_SUCCESS,
  ORDER_UPDATE_PAYMENT_INFO_FAIL,
  ORDER_CHECK_UNPAID_SUCCESS,
  ORDER_CHECK_UNPAID_REQUEST,
  ORDER_CHECK_UNPAID_FAIL,
  ORDER_REFUND_IBAN_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_LIST_USER_REQUEST,
  ORDER_LIST_USER_SUCCESS,
  ORDER_LIST_USER_FAIL,
  ORDER_CANCEL_PARTIALLY_REQUEST,
  ORDER_CANCEL_PARTIALLY_SUCCESS,
  ORDER_CANCEL_PARTIALLY_FAIL,
  ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS,
  ORDER_UPDATE_DELIVERY_CONTACT_REQUEST,
  ORDER_UPDATE_DELIVERY_CONTACT_FAIL,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS,
  ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL,
  ORDER_CANCEL_PAYMENT_SUCCESS,
  ORDER_CANCEL_PAYMENT_FAIL,
  ORDER_CANCEL_PAYMENT_REQUEST,
  ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS,
  ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST,
  ORDER_UPDATE_CANCELLED_PAYMENT_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_REFUND_REQUEST,
  ORDER_REFUND_SUCCESS,
  ORDER_REFUND_FAIL,
  ORDER_REFUND_CANCEL_REQUEST,
  ORDER_REFUND_CANCEL_FAIL,
  ORDER_REFUND_CANCEL_SUCCESS,
  ORDER_REFUND_IBAN_SUCCESS,
  ORDER_REFUND_IBAN_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_APPLY_DISCOUNTCODE_REQUEST,
  ORDER_APPLY_DISCOUNTCODE_SUCCESS,
  ORDER_APPLY_DISCOUNTCODE_FAIL,
  ORDER_VIEW_INVOICE_REQUEST,
  ORDER_VIEW_INVOICE_SUCCESS,
  ORDER_VIEW_INVOICE_FAIL,
  UPSELL_ORDER_DETAILS_REQUEST,
  UPSELL_ORDER_DETAILS_SUCCESS,
  UPSELL_ORDER_DETAILS_FAIL,
  ORDER_IS_PENDING_REQUEST,
  ORDER_IS_PENDING_SUCCESS,
  ORDER_IS_PENDING_FAIL,
} from "../constants/orderConstants";

//POST AN API CALL TO CREATE AN ORDER
export const actionToCreateOrder = (order) => async (dispatch, getState) => {
  dispatch({
    type: ORDER_CREATE_REQUEST,
  });
  try {
    const { data } = await Axios.post("/api/orders", order);
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
    localStorage.setItem("order", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_CREATE_FAIL, payload: message });
  }
};

//IF THERE IS AN ATTEMPT TO PAY FOR THE ORDER
//THIS ACTION UPDATES PAYEMNT INFO FOR THE ORDER
export const actionToUpdatePaymentInfo =
  (paymentStatus) => async (dispatch) => {
    dispatch({
      type: ORDER_UPDATE_PAYMENT_INFO_REQUEST,
    });
    try {
      const { data } = await Axios.put(
        `/api/orders/paymentinfo`,
        paymentStatus
      );
      dispatch({ type: ORDER_UPDATE_PAYMENT_INFO_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_UPDATE_PAYMENT_INFO_FAIL, payload: message });
    }
  };

//CHECKS IF USER HAS AN ORDER WITH THE PHONE NUMBER THAT MATCHES THE INPUT PHONE NUMBER
export const actionToMatchOrderPhone = (phone) => async (dispatch) => {
  dispatch({ type: ORDER_PHONE_CHECK_REQUEST, payload: phone });
  try {
    const { data } = await Axios.post("/api/orders/checkorderphone", { phone });
    dispatch({ type: ORDER_PHONE_CHECK_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_PHONE_CHECK_FAIL, payload: message });
  }
};

//ORDER DETAILS ARE POSTED TO BACKEND AND RESPONSE IS SAVED IN REDUX AND LOCAL STORAGE
//IFRAME PAYMENT PAGE URL IS SAVED IN REDUX STATE
/*export const actionToRequestIyzicoForm =
  (order) => async (dispatch, getState) => {
    dispatch({ type: ORDER_IYZICO_FORM_REQUEST });
    try {
      const { data } = await Axios.post("/api/orders/iyzicoform", order);
      dispatch({ type: ORDER_IYZICO_FORM_SUCCESS, payload: data });
      localStorage.setItem(
        "iyzicoForm",
        JSON.stringify(getState().requestIyzicoForm.iyzicoForm)
      );
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_IYZICO_FORM_FAIL, payload: message });
    }
  };*/

//POST A REQUEST TO RECEIVE PAYMENT RESULT
/*export const actionToGetIyzicoFormResult =
  (token) => async (dispatch, getState) => {
    dispatch({ type: ORDER_IYZICO_FORM_RESULT_REQUEST });
    try {
      const { data } = await Axios.post(`/api/orders/iyzicoformresult`, token);
      dispatch({ type: ORDER_IYZICO_FORM_RESULT_SUCCESS, payload: data });
      localStorage.setItem(
        "paymentResult",
        JSON.stringify(getState().getIyzicoFormResult.iyzicoPaymentResult)
      );
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_IYZICO_FORM_RESULT_FAIL, payload: message });
    }
  };*/

//AUTHORISED USER REQUEST A LIST OF ORDERS IN "MY ORDERS SCREEN"
export const actionOrderToListUserOrderHistory =
  (phone) => async (dispatch, getState) => {
    dispatch({ type: ORDER_LIST_USER_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.get(`/api/orders/orderhistory/${phone}`, {
        headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
      });
      dispatch({ type: ORDER_LIST_USER_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_LIST_USER_FAIL, payload: message });
    }
  };

//THIS ACTION IS TO CHECK IF THERE ARE ANY UNPAID ORDERS
//BELONG TO CUSTOMERS PHONE NUMBER
export const actionOrderToCheckUnpaidOrders = (phone) => async (dispatch) => {
  dispatch({ type: ORDER_CHECK_UNPAID_REQUEST });
  try {
    const { data } = await Axios.get(`/api/orders/checkunpaid/${phone}`);

    dispatch({ type: ORDER_CHECK_UNPAID_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_CHECK_UNPAID_FAIL, payload: message });
  }
};

//THIS ACTION IS TO CANCEL AN ORDER (BEFORE PAYMENT)
export const actionOrderToCancelAnOrder =
  (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CANCEL_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.post(`/api/orders/cancelorder`, orderId, {
        headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
      });
      dispatch({ type: ORDER_CANCEL_SUCCESS, payload: data });
      localStorage.removeItem("checkUnpaid");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_CANCEL_FAIL, payload: message });
    }
  };

//THIS ACTION IS TO CANCEL AN ORDER (AFTER PAYMENT)
export const actionOrderToCancelPaymentOrder =
  (idAndIp) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CANCEL_PAYMENT_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.post(
        `/api/orders/cancelpaymentorder`,
        idAndIp,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_CANCEL_PAYMENT_SUCCESS, payload: data });
      localStorage.removeItem("checkUnpaid");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_CANCEL_PAYMENT_FAIL, payload: message });
    }
  };

// UPDATE CANCELLED PAYMENT INFO IN ORDER
export const actionOrderToUpdateCancelledPayment =
  (cancelledPayment) => async (dispatch, getState) => {
    dispatch({ type: ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/orders/updatecancelledpaymentorder`,
        cancelledPayment,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_UPDATE_CANCELLED_PAYMENT_FAIL,
        payload: message,
      });
    }
  };

//THIS ACTION IS TO CANCEL AN ORDER PARTIALLY (PRODUCT BY PRODUCT)
export const actionOrderToCancelAnOrderPartially =
  (itemAndOrderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CANCEL_PARTIALLY_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.post(
        `/api/orders/cancelorderpartially`,
        itemAndOrderId,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_CANCEL_PARTIALLY_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_CANCEL_PARTIALLY_FAIL, payload: message });
    }
  };

//THIS ACTION UPDATES THE DELIVERY CONTACT INFORMATION
export const actionOrderToUpdateDeliveryContact =
  (deliveryContact) => async (dispatch, getState) => {
    dispatch({
      type: ORDER_UPDATE_DELIVERY_CONTACT_REQUEST,
    });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/orders/updatedeliverycontact`,
        {
          deliveryContact,
        },
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS, payload: data });
      //SETTING IN LOCAL STORAGE TO BE ABLE TO SHOW
      //"UPDATE SUCCESS MESSAGE" WITHOUT ANOTHER SERVER REQUEST
      localStorage.setItem(
        "updatedOrder",
        JSON.stringify(getState().updateDeliveryContact.updatedDeliveryContact)
      );
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_UPDATE_DELIVERY_CONTACT_FAIL,
        payload: message,
      });
    }
  };

//THIS ACTION UPDATES THE INDIVIDUAL INVOICE INFORMATION
export const actionOrderToUpdateIndividualInvoice =
  (individualInvoice) => async (dispatch, getState) => {
    dispatch({
      type: ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST,
    });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/orders/updateindividualinvoice`,
        {
          individualInvoice,
        },
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({
        type: ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS,
        payload: data,
      });
      //SETTING IN LOCAL STORAGE TO BE ABLE TO SHOW
      //"UPDATE SUCCESS MESSAGE" WITHOUT ANOTHER SERVER REQUEST
      localStorage.setItem(
        "updatedIndOrder",
        JSON.stringify(
          getState().updateIndividualInvoice.updatedIndividualInvoice
        )
      );
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL,
        payload: message,
      });
    }
  };

//USER REQUESTS DETAILS OF AN ORDER
export const actionOrderToGetOrderDetails = (orderId) => async (dispatch) => {
  dispatch({ type: ORDER_DETAILS_REQUEST });

  try {
    const { data } = await Axios.get(`/api/orders/orderdetails/${orderId}`);
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
  }
};

//USER REQUESTS DETAILS OF AN UPSELL ORDER
export const actionOrderToGetUpsellOrderDetails =
  (orderId) => async (dispatch, getState) => {
    dispatch({ type: UPSELL_ORDER_DETAILS_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.get(
        `/api/orders/upsellorderdetails/${orderId}`,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: UPSELL_ORDER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: UPSELL_ORDER_DETAILS_FAIL, payload: message });
    }
  };

//ADMIN REQUESTS DETAILS OF AN ORDER
export const actionOrderToDeleteOrder =
  (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_DELETE_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.delete(
        `/api/orders/orderdelete/${orderId}`,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_DELETE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_DELETE_FAIL, payload: message });
    }
  };

//USER REQUESTS REFUND ORDER
export const actionOrderToRefundOrder =
  (order) => async (dispatch, getState) => {
    dispatch({ type: ORDER_REFUND_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.post(`/api/orders/refundorder`, order, {
        headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
      });
      dispatch({ type: ORDER_REFUND_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_REFUND_FAIL, payload: message });
    }
  };

// CANCEL REFUND REQUEST
export const actionOrderToCancelRefund =
  (cancelRefund) => async (dispatch, getState) => {
    dispatch({ type: ORDER_REFUND_CANCEL_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/orders/cancelrefund`,
        cancelRefund,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_REFUND_CANCEL_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ORDER_REFUND_CANCEL_FAIL,
        payload: message,
      });
    }
  };

// COLLECT REFUND IBAN - PUT
export const actionOrderToCollectIban =
  (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_REFUND_IBAN_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(`/api/orders/refundiban`, orderId, {
        headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
      });
      dispatch({ type: ORDER_REFUND_IBAN_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_REFUND_IBAN_FAIL, payload: message });
    }
  };

// COLLECT REFUND IBAN - PUT
export const actionOrderToApplyDiscountCode =
  (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_APPLY_DISCOUNTCODE_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.put(
        `/api/orders/applydiscountcode`,
        orderId,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_APPLY_DISCOUNTCODE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_APPLY_DISCOUNTCODE_FAIL, payload: message });
    }
  };

// GET
// USER REQUEST TO VIEW INVOICE
export const actionOrderToViewInvoice =
  (invoiceId, isEArchive) => async (dispatch, getState) => {
    dispatch({ type: ORDER_VIEW_INVOICE_REQUEST });
    const {
      authenticateUser: { authenticatedUser },
    } = getState();
    try {
      const { data } = await Axios.get(
        `/api/orders/viewinvoice/${invoiceId}/${isEArchive}`,
        {
          headers: { Authorization: `Bearer ${authenticatedUser?.token}` },
        }
      );
      dispatch({ type: ORDER_VIEW_INVOICE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_VIEW_INVOICE_FAIL, payload: message });
    }
  };

// GET
// CHECK PENDING ORDER
export const actionOrderToCheckIfThereIsApendingOrder =
  (orderId) => async (dispatch) => {
    dispatch({ type: ORDER_IS_PENDING_REQUEST });

    try {
      const { data } = await Axios.get(
        `/api/orders/checkifclienthasapendingorder/${orderId}`
      );
      dispatch({ type: ORDER_IS_PENDING_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_IS_PENDING_FAIL, payload: message });
    }
  };
