import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToUpdateDeliveryAddress = createAsyncThunk(
  "wholeSale/jobInActionToUpdateDeliveryAddress",
  async (updateData, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.put(
        "/api/wholesale/updateDeliveryAddress",
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  accountWithUpdatedDeliveryAddress: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const updateDeliveryAddressSlice = createSlice({
  name: "updateDeliveryAddress",
  initialState,
  reducers: {
    resetUpdateDeliveryAddress: (state) => {
      state.accountWithUpdatedDeliveryAddress = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToUpdateDeliveryAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToUpdateDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.accountWithUpdatedDeliveryAddress = action.payload;
      })
      .addCase(actionToUpdateDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetUpdateDeliveryAddress } = updateDeliveryAddressSlice.actions;

// Export the reducer
const reducerToUpdateDeliveryAddress = updateDeliveryAddressSlice.reducer;

// Export the async action creator for use in components
export { reducerToUpdateDeliveryAddress, resetUpdateDeliveryAddress };
