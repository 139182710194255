import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { actionAdminCreateDiscountCode } from "../../actions/adminDiscountCodeActions";
import "./PopBanner.css";
import PopBannerRender from "./PopBannerRender";

export default function PopsBanner(props) {
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  // PROPS COMING FROM "PopsComponent"
  const { pop, isDiscountValid, remainingTime } = props;

  // DISCOUNT CODE
  const [discountCodeState, setDiscountCodeState] = useState("");

  // CHECK IF THERE IS AUTHENTICATED USER
  //REDUX STATE THAT HOLDS AUTHENTICATED USER INFO
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authenticateUser = useSelector((state) => state.authenticateUser);
  const { authenticatedUser } = authenticateUser;
  useEffect(() => {
    if (authenticatedUser && pop.existDiscount) {
      setIsAuthenticated(true);
    }
  }, [authenticatedUser, pop]);

  // CHECK IF POP IS TIME RESTRICTED
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (!pop.isTemporary) {
      setIsValid(true);
    } else if (pop.startDate) {
      const nowInMiliseconds = new Date().getTime();
      // COMPARE DATES TO CHECK IF START/END DATE IS VALID
      if (
        nowInMiliseconds > Number(pop.startDateInMiliseconds) &&
        nowInMiliseconds < Number(pop.endDateInMiliseconds)
      ) {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  }, [pop]);

  // CHECK LOCAL STORAGE TO DECIDE WHEATHER A POP MUST BE DISPLAYED OR NOT
  const [mustKeptClose, setMustKeptClose] = useState(false);
  useEffect(() => {
    const popsTurnedOff = localStorage.getItem("popsTurnedOff");
    if (popsTurnedOff === "Yes") {
      setMustKeptClose(true);
    }
  }, []);

  // FUNCTION TO IMPLEMENT "ClickOutsideToClose" FUNCTIONALITY
  const refPop = useRef(null);
  const handleClickOutsidePop = (event) => {
    if (refPop.current && !refPop.current.contains(event.target)) {
      if (pop.popType === "intentPop") {
        setIntentPopOpen(false);
      } else if (pop.popType === "exitPop") {
        setExitPopOpen(false);
      }

      // ONCE A POP IS CLOSED IT SHOULD STAY CLOSED OR SHOULD NOT
      if (pop.existDiscount) {
        localStorage.setItem("popsTurnedOff", "Yes");
        setMustKeptClose(true);
      } else {
        localStorage.setItem("popsTurnedOff", "No");
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsidePop, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePop, false);
    };
  });
  // FUNCTION TO HANDLE CLOSE ICON
  function handleClosePops() {
    if (pop.popType === "intentPop") {
      setIntentPopOpen(false);
    } else if (pop.popType === "exitPop") {
      setExitPopOpen(false);
    }

    // ONCE A POP IS CLOSED IT SHOULD STAY CLOSED OR SHOULD NOT
    if (pop.existDiscount) {
      localStorage.setItem("popsTurnedOff", "Yes");
      setMustKeptClose(true);
    } else {
      localStorage.setItem("popsTurnedOff", "No");
    }
  }

  // EXIT POP LOGIC
  const [exitPopOpen, setExitPopOpen] = useState(false);
  const onMouseUpdate = (e) => {
    // CHECK LOCAL STORAGE TO SEE IF CONTACT DETAILS SAVED
    const isLocal = localStorage.getItem("beddo");
    // ONLY POP IF ISPOP === NO
    const isPoped = localStorage.getItem("popsTurnedOff");

    // ONLY RUN WHEN LOCATION MATCHES
    if (
      !isLocal &&
      pop.displayOnlyHere.find((free) => location.includes(free))
    ) {
      // let x = e.pageX;
      // let y = e.pageY;

      if (
        !e.toElement && // THESE SIGNAL THAT MOUSE IS OUT OF WINDOW
        !e.relatedTarget &&
        e.clientY < 10 && // THIS SIGNALS THAT CLIENT MOUSE GOES UPWARDS
        !mustKeptClose &&
        (isPoped === "No" || !isPoped) &&
        pop.isActive &&
        pop.popType === "exitPop" &&
        isValid &&
        !isAuthenticated
      ) {
        // OPEN EXIT POP
        setExitPopOpen(true);

        if (!exitPopOpen) {
          // GET NOW IN MILISECONDS
          const nowInMiliseconds = new Date().getTime();
          const year = new Date().getFullYear();
          const month = new Date().getMonth() + 1;
          const day = new Date().getDate();

          // CREATE DISCOUNT CODE
          const discountCode = `beddo${
            nowInMiliseconds.toString().slice(-3) +
            Math.floor(Math.random() * 9).toString()
          }`;

          // SAVE DISCOUNT CODE IN REACT STATE
          setDiscountCodeState(discountCode);

          // DISPATCH DISCOUNT CODE
          dispatch(
            actionAdminCreateDiscountCode({
              discountCode: discountCode,
              discountAmount: pop.discountAmount,
              discountExpiryDate: `${year}-${month}-${day}`,
              discountExpiryInMiliseconds:
                nowInMiliseconds + Number(pop.discountExpiry) * 60000,
              useDiscountCodeXTimes: pop.useDiscountXtimes
                ? pop.useDiscountXtimes
                : "Kısıtlama yok",
              remainingDiscountCodeXTimes: pop.useDiscountXtimes
                ? pop.useDiscountXtimes
                : "Kısıtlama yok",
              discountType: "Temporary",
              isDuplicateAllowed: true,
            })
          );
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("mouseout", onMouseUpdate, false);
    }, 5000);
    return () => document.removeEventListener("mouseout", onMouseUpdate, false);
  });

  // INTENT POP LOGIC
  const [intentPopOpen, setIntentPopOpen] = useState(false);
  const [countDown, setCountDown] = useState(pop.popTimer);
  const [runTimer, setRunTimer] = useState(true);

  useEffect(() => {
    let timerId;
    const isLocated = pop.displayOnlyHere.find((free) =>
      location.includes(free)
    );

    if (runTimer && countDown > 0 && pop.isActive && isLocated) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, countDown, pop, location]);

  useEffect(() => {
    // CHECK LOCAL STORAGE TO SEE IF CONTACT DETAILS SAVED
    const isLocal = localStorage.getItem("beddo");
    // CHECK IF INTENT POP WAS POPED
    const isPoped = localStorage.getItem("popsTurnedOff");

    if (
      countDown <= 0 &&
      runTimer &&
      !mustKeptClose &&
      !isPoped &&
      pop.isActive &&
      pop.popType === "intentPop" &&
      isValid &&
      !isAuthenticated &&
      !isLocal
    ) {
      // RESET COUNTERS
      setRunTimer(false);
      setCountDown(0);

      // OPEN INTENT POP
      setIntentPopOpen(true);

      // IF POP IS DISCOUNTED SET LOCAL STORAGE THIS WAY IF NOT THAT WAY
      if (pop.existDiscount) {
        localStorage.setItem("popsTurnedOff", "Yes");
      } else {
        localStorage.setItem("popsTurnedOff", "No");
      }

      if (isValid) {
        // GET NOW IN MILISECONDS
        const nowInMiliseconds = new Date().getTime();
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();

        // CREATE DISCOUNT CODE
        const discountCode = `beddo${
          nowInMiliseconds.toString().slice(-3) +
          Math.floor(Math.random() * 9).toString()
        }`;

        // SAVE DISCOUNT CODE IN REACT STATE
        setDiscountCodeState(discountCode);

        // CREATE A DISCOUNT CODE
        dispatch(
          actionAdminCreateDiscountCode({
            discountCode: discountCode,
            discountAmount: pop.discountAmount,
            discountExpiryDate: `${year}-${month}-${day}`,
            discountExpiryInMiliseconds:
              nowInMiliseconds + Number(pop.discountExpiry) * 60000,
            useDiscountCodeXTimes: pop.useDiscountXtimes
              ? pop.useDiscountXtimes
              : "Kısıtlama yok",
            remainingDiscountCodeXTimes: pop.useDiscountXtimes
              ? pop.useDiscountXtimes
              : "Kısıtlama yok",
            discountType: "Temporary",
          })
        );
      }
    }
  }, [
    countDown,
    runTimer,
    mustKeptClose,
    pop,
    dispatch,
    isValid,
    isAuthenticated,
  ]);

  // CSS TRANSITION LOGIC FOR MODAL POP
  const [transition, setTransition] = useState(false);
  useEffect(() => {
    if (intentPopOpen) {
      setTimeout(() => {
        setTransition(true);
      }, 200);
    }
  }, [intentPopOpen]);

  return (
    <>
      {pop.isActive &&
        (pop.doNotDisplayHere.find((ban) => location.includes(ban))
          ? ""
          : pop.displayOnlyHere.find((free) => location.includes(free)) &&
            ((intentPopOpen && pop.popType === "intentPop") ||
              (exitPopOpen && pop.popType === "exitPop"))) &&
        (isDiscountValid && pop.displayCountDownTimer ? (
          <PopBannerRender
            refPop={refPop}
            pop={pop}
            transition={transition}
            intentPopOpen={intentPopOpen}
            handleClosePops={handleClosePops}
            discountCodeState={discountCodeState}
            isDiscountValid={isDiscountValid}
            remainingTime={remainingTime}
          />
        ) : (
          !pop.displayCountDownTimer && (
            <PopBannerRender
              refPop={refPop}
              pop={pop}
              transition={transition}
              intentPopOpen={intentPopOpen}
              handleClosePops={handleClosePops}
              discountCodeState={discountCodeState}
            />
          )
        ))}
    </>
  );
}
