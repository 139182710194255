import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useWidth from "../hooks/useWidth";

export default function FourOFour(props) {
  const width = useWidth();
  const history = useHistory();
  const location = useLocation();

  function handleShopButton() {
    history.push("/");
  }

  function handleContactButton() {
    history.push("/iletisim");
  }
  return (
    <>
      {location.pathname.includes("admin-") ? (
        ""
      ) : (
        <div
          className={
            width < 600
              ? "col w90p whitebck marupside1"
              : "col w45p whitebck marupside1"
          }
        >
          <h1 className="fs6">404</h1>
          <div className="padh">
            <p className="fs15 marb1">Aradığınız sayfayı bulamıyoruz.</p>
            <p className="fs125 marb1">
              Alışverişinizi kesintiye uğrattığımız için özür dileriz.
            </p>
            <p className="fs125 marb1">
              Aşağıdaki indirim kodu ile alışverişinizi indirimli
              tamamlayabilirsiniz.
            </p>
          </div>

          <div className="col beddo-border-dashed-3 w95p">
            <p className="fs3 beddo-border-bottom marb1">
              <span className="fs15">İndirim Kodu:</span> hata20
            </p>
            <ul className="fs125">
              <li className="beddo-border-bottom-light marb-q padb-q">
                * İndirim kodunu kopyalayın ya da not edin.
              </li>
              <li className="beddo-border-bottom-light marb-q padb-q">
                * Alışverişi tamamlayarak sipariş formuna ilerleyin.
              </li>
              <li className="beddo-border-bottom-light marb-q padb-q">
                * Formdaki "İndirim Kodu" kutusuna kodu yazın.
              </li>
              <li className="beddo-border-bottom-light marb-q padb-q">
                * Siparişi Kaydet butonuna tıklayın ve ilerleyin.
              </li>
              <li className="beddo-border-bottom-light marb-q padb-q">
                * İndirim tutarı toplam tutardan düşecektir.
              </li>
            </ul>
          </div>
          <button
            type="button"
            className="primary w95p"
            onClick={() => handleShopButton()}
          >
            Alışverişe Devam Et
          </button>
          <button
            type="button"
            className="secondary w95p"
            onClick={() => handleContactButton()}
          >
            İletişim Sayfasına Git
          </button>
        </div>
      )}
    </>
  );
}
