import React from "react";
import { useDispatch } from "react-redux";
import { textShortener } from "../../../../../libraries/ercLibrary";
import useWidth from "../../../../../hooks/useWidth";
import { vatCalculator } from "../../../../../libraries/ercLibrary";
import { stylerDecimalPart } from "../../../../../utils/stylerDecimalPart";
import {
  decrementQty,
  incrementQty,
  deleteItem,
} from "../actions/actionToAddOrRemoveWholeSaleItemInCart";
import { useRemoveItemsMissingFreeProduct } from "../hooks/useRemoveItemsMissingFreeProduct";

export default function ItemList(props) {
  const dispatch = useDispatch();
  const width = useWidth();

  // PROPS COMING FROM NavigationTop && NavigationBottom
  const { freeGoodsAllocationActive, wholeSaleCartItems, privatePricing } =
    props;

  // REMOVE A FREE PROVIDER ITEM IF freeProducts ARRAY IS EMPTY WHICH MEANS IF FREE DRODUCT IS MISSING , FREE PROVIDER ITEM IS NOT ALLOWED IN CART
  useRemoveItemsMissingFreeProduct();

  return (
    <div className="item-list table:last-child">
      {wholeSaleCartItems.map((item, index) => {
        // FIND ACCOUNT LEVEL PRICING IF APPLICABLE
        const accountLevelPricing = privatePricing?.skus.find(
          (sku) => sku.sku === item.sku
        )?.price;

        // ACCOUNT LEVEL PRICING INCLUDING VAT
        const accountLevelPricingIncludingVat =
          accountLevelPricing + (accountLevelPricing / 100) * item.vat;

        const vatExcluded = accountLevelPricing
          ? accountLevelPricing * item.qty
          : vatCalculator(item.vat, item.price * item.qty, "excluded");

        const vatIncluded = accountLevelPricingIncludingVat
          ? accountLevelPricingIncludingVat * item.qty
          : item.price * item.qty;

        return (
          <table key={index} className="fs1 w100p shadow-light-topless marb-5">
            <thead>
              <tr>
                <th rowSpan={4} className="w10p txtmid txtcenter">
                  <img
                    className="small-image-wholesale txtmid marq"
                    src={item.image}
                    alt={item.name}
                  />
                </th>
              </tr>
              <tr>
                <td
                  title={item.name}
                  colSpan={2}
                  className={width < 600 ? "w70p txtleft" : "w40p txtleft"}
                >
                  {textShortener(item.name, 25)}
                </td>
                {vatIncluded > 0 ? (
                  <td title="Sil" className="fs-1 txtright txtbot">
                    <i
                      className="far fa-trash-alt red-col pointer marginright0-5r"
                      onClick={() => dispatch(deleteItem(item.skuId))}
                    ></i>
                  </td>
                ) : (
                  <td className="fs-65 txtleft"></td>
                )}
                <td></td>
              </tr>
              <tr>
                <td className="fs-65 txtleft green">SKU: {item.sku}</td>
                {vatIncluded > 0 ? (
                  <td className="fs-65 txtleft">KDV Dahil</td>
                ) : (
                  <td></td>
                )}
                {vatIncluded > 0 ? (
                  <td className="txtleft">
                    {parseInt(vatIncluded)}.{stylerDecimalPart(vatIncluded)}{" "}
                    <span className="fs-85">TL</span>
                  </td>
                ) : (
                  <td className="beddo-color txtleft"></td>
                )}
              </tr>
              <tr>
                <td className="w45p txtleft txtmid">
                  {vatIncluded > 0 && !freeGoodsAllocationActive ? (
                    <div
                      className={`row-nowrap ${
                        width < 600 ? "w85p" : "w50p"
                      } marb-q gray-border`}
                    >
                      <button
                        type="button"
                        className="wholesale-cart-button"
                        onClick={() => dispatch(incrementQty(item.skuId))}
                      >
                        +
                      </button>
                      <div className="row-nowrap w65p">
                        <input
                          className="w40p"
                          type="number"
                          value={item.qty}
                          readOnly
                        />
                        <p className="w50p">{item.productUnit}</p>
                      </div>
                      <button
                        type="button"
                        className="wholesale-cart-button"
                        onClick={() => dispatch(decrementQty(item.skuId))}
                      >
                        -
                      </button>
                    </div>
                  ) : (
                    <div className="row-left w45p purple bold-font">
                      {item.qty} {item.productUnit}
                    </div>
                  )}
                </td>
                {vatIncluded > 0 ? (
                  <td className="w15p fs-65 txtleft">KDV Hariç</td>
                ) : (
                  <td></td>
                )}
                {vatExcluded > 0 ? (
                  <td className="w30p txtleft">
                    {parseInt(vatExcluded)}.{stylerDecimalPart(vatExcluded)}{" "}
                    <span className="fs-85">TL</span>
                  </td>
                ) : (
                  <td className="beddo-color txtleft">Bedava</td>
                )}
                <td></td>
              </tr>

              <tr>
                {item?.freeProducts?.length > 0 ? (
                  item.freeProducts.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <th rowSpan={2} className="txtmid txtcenter">
                          <img
                            className="small-image-wholesale txtmid marq"
                            src={item.image}
                            alt={item.name}
                          />
                        </th>
                        <td
                          title={item.name}
                          colSpan={2}
                          className={
                            width < 600 ? "w70p txtleft" : "w40p txtleft"
                          }
                        >
                          {textShortener(item.name, 25)}
                        </td>
                        <td className="beddo-color txtleft">Bedava</td>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <td></td>
                )}
              </tr>
              <tr>
                {item?.freeProducts?.length > 0 ? (
                  item.freeProducts.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <td className="fs-65 txtleft green">SKU: {item.sku}</td>
                        <td></td>
                        <td className="txtleft">
                          <span className="beddo-border-bottom">
                            {item.qty} {item.productUnit}
                          </span>
                        </td>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <td></td>
                )}
              </tr>
            </thead>
          </table>
        );
      })}
    </div>
  );
}
