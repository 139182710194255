import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../utils/errorMessage";

export const actionToGetResultOfTrendyolUpload = createAsyncThunk(
  "jobInActionToGetResultOfTrendyolUpload",
  async ({ batchRequestId, productId }, thunkAPI) => {
    const token = thunkAPI.getState().adminUserToLogin.adminUserInfo.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/trendyol/getResultOfTrendyolUpload",
        {
          params: {
            batchRequestId,
            productId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      const { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

const getResultOfTrendyolUpload = createSlice({
  name: "sliceToGetResultOfTrendyolUpload",
  initialState: {
    batchResult: {},
    loading: false,
    error: null,
  },
  reducers: {
    resetGetResultOfTrendyolUploadIsUploaded: (state) => {
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetResultOfTrendyolUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actionToGetResultOfTrendyolUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.isSuccess = true;
        state.batchResult = action.payload;
      })
      .addCase(actionToGetResultOfTrendyolUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetGetResultOfTrendyolUploadIsUploaded } =
  getResultOfTrendyolUpload.actions;

const reducerToGetResultOfTrendyolUpload = getResultOfTrendyolUpload.reducer;

export default reducerToGetResultOfTrendyolUpload;
