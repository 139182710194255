import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionToPostCart } from "../cart/actions/actionToPostCart";

export function useSaveCartInDataBase(
  cartQuantity,
  detailsWholeSaleAccount,
  wholeSaleCartItems
) {
  // const locationPathname = useLocation().pathname;
  const dispatch = useDispatch();

  // SAVE CART IN DATABASE
  useEffect(() => {
    if (detailsWholeSaleAccount && cartQuantity >= 0) {
      const cartId = sessionStorage.getItem("cartId");

      dispatch(
        actionToPostCart({
          id: detailsWholeSaleAccount?._id,
          cart: wholeSaleCartItems,
          cartId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, detailsWholeSaleAccount, cartQuantity]);
}
