import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToDeleteDeliveryAddress = createAsyncThunk(
  "wholeSale/jobInActionToDeleteDeliveryAddress",
  async ({ accountId, addressId }, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token; // Gets the current Redux state

    try {
      // Call your API function here
      const { data } = await axios.delete(
        "/api/wholesale/deleteDeliveryAddress",

        {
          params: {
            accountId,
            addressId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);
      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  deletedDeliveryAddress: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const deleteDeliveryAddressSlice = createSlice({
  name: "createDeliveryAddress",
  initialState,
  reducers: {
    resetDeleteDeliveryAddress: (state) => {
      state.deletedDeliveryAddress = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToDeleteDeliveryAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToDeleteDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.deletedDeliveryAddress = action.payload;
      })
      .addCase(actionToDeleteDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetDeleteDeliveryAddress } = deleteDeliveryAddressSlice.actions;

// Export the reducer
const reducerToDeleteDeliveryAddress = deleteDeliveryAddressSlice.reducer;

// Export the async action creator for use in components
export { reducerToDeleteDeliveryAddress, resetDeleteDeliveryAddress };
