import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../actions/actionToSendSecurityCodeToSignIn";

export function useRedirectToSignInWhenTokenExpired(error, pathname, reset) {
  const dispatch = useDispatch();
  const history = useHistory();

  // REDIRECT TO LOGIN SCREEN IF
  useEffect(() => {
    if (error === "Wholesale user token expired") {
      dispatch(signOut());

      history.push(pathname);
    }

    return () => dispatch(reset());
  }, [error, dispatch, history, pathname, reset]);
}
