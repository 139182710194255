import { useEffect, useState } from "react";

export function useBrowserStorage(
  criteria,
  storageType,
  operation,
  storageKey
) {
  const [storageItem, setStorageItem] = useState("");

  useEffect(() => {
    if (criteria || criteria === 0) {
      // criteria zero comes from the fingerPrint firstTimeVisit
      if (storageType === "session" && operation === "set") {
        sessionStorage.setItem(storageKey, criteria);
      } else if (storageType === "session" && operation === "get") {
        setStorageItem(sessionStorage.getItem(storageKey));
      } else if (storageType === "session" && operation === "remove") {
        sessionStorage.removeItem(storageKey);
      } else if (storageType === "local" && operation === "set") {
        localStorage.setItem(storageKey);
      } else if (storageType === "local" && operation === "get") {
        setStorageItem(localStorage.getItem(storageKey));
      } else if (storageType === "local" && operation === "remove") {
        localStorage.removeItem(storageKey);
      }
    }

    return () => setStorageItem("");
  }, [criteria, storageKey, operation, storageType]);

  return storageItem;
}
