export const ADMIN_USER_REGISTER_REQUEST = "ADMIN_USER_REGISTER_REQUEST";
export const ADMIN_USER_REGISTER_SUCCESS = "ADMIN_USER_REGISTER_SUCCESS";
export const ADMIN_USER_REGISTER_FAIL = "ADMIN_USER_REGISTER_FAIL";

export const ADMIN_USER_LOGIN_REQUEST = "ADMIN_USER_LOGIN_REQUEST";
export const ADMIN_USER_LOGIN_SUCCESS = "ADMIN_USER_LOGIN_SUCCESS";
export const ADMIN_USER_LOGIN_FAIL = "ADMIN_USER_LOGIN_FAIL";
export const ADMIN_USER_LOGOUT = "ADMIN_USER_LOGOUT";

export const ADMIN_USER_USERSLIST_REQUEST = "ADMIN_USER_USERLIST_REQUEST";
export const ADMIN_USER_USERSLIST_SUCCESS = "ADMIN_USER_USERLIST_SUCCESS";
export const ADMIN_USER_USERSLIST_FAIL = "ADMIN_USER_USERLIST_FAIL";

export const ADMIN_USER_USERDETAILS_REQUEST = "ADMIN_USER_USERDETAILS_REQUEST";
export const ADMIN_USER_USERDETAILS_SUCCESS = "ADMIN_USER_USERDETAILS_SUCCESS";
export const ADMIN_USER_USERDETAILS_FAIL = "ADMIN_USER_USERDETAILS_FAIL";

export const ADMIN_USER_UPDATEUSER_REQUEST = "ADMIN_USER_UPDATEUSER_REQUEST";
export const ADMIN_USER_UPDATEUSER_SUCCESS = "ADMIN_USER_UPDATEUSER_SUCCESS";
export const ADMIN_USER_UPDATEUSER_FAIL = "ADMIN_USER_UPDATEUSER_FAIL";
export const ADMIN_USER_UPDATEUSER_RESET = "ADMIN_USER_UPDATEUSER_RESET";

export const ADMIN_USER_ORDERLIST_REQUEST = "ADMIN_USER_ORDERLIST_REQUEST";
export const ADMIN_USER_ORDERLIST_SUCCESS = "ADMIN_USER_ORDERLIST_SUCCESS";
export const ADMIN_USER_ORDERLIST_FAIL = "ADMIN_USER_ORDERLIST_FAIL";
