export function stylerDecimalPart(number) {
  const toNumber = Number(number);

  if (toNumber)
    return (
      <span className="fs-65">
        {toNumber.toFixed(2).toString().split(".")[1]}
      </span>
    );
}
