import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";

export function useCalculateExtraDiscount(
  cartSkuItems,
  isPending,
  createdFingerPrint,
  isDiscountValid
) {
  //  const history = useHistory();
  /* Calculate the discount loss for user that occurs because most of the discounted product is in the first index of cart array. 
  When most discounted product first in the array, discount is not applied. */
  const [discountCompansation, setDiscountCompansation] = useState(0);
  // const [runOnce, setRunOnce] = useState(true);
  const [prevDiscount, setPrevDiscount] = useState(0);
  const [extraDiscountAmount, setExtraDiscountAmount] = useState(0);
  const stopDiscount = sessionStorage.getItem("stopDiscount"); // If an item is removed from cart discount will not be recalculated in CALCULATE EXTRA DISCOUNT headlined useEffect

  useEffect(() => {
    if (stopDiscount) {
      setExtraDiscountAmount(0);
    }
  }, [stopDiscount]);

  // RESET runOnce
  /* useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== "/sepet") {
        setRunOnce(true);
      }
    });
  }, [history, cartSkuItems.length]); */

  // CALCULATE EXTRA DISCOUNT
  useEffect(() => {
    if (
      /*runOnce &&*/ cartSkuItems?.length > 0 &&
      isPending?.isTherePending === "NOTYET" &&
      stopDiscount !== "true" &&
      !isDiscountValid
    ) {
      const makeTheMostOfTheDsicountForCustomer = Math.ceil(
        cartSkuItems[0]?.priceForOne / 20
      );

      const currentDsicountForCustomer =
        createdFingerPrint?.isExtraDiscountShown ||
        createdFingerPrint?.passedHoursAfterFirstVisit > 36
          ? makeTheMostOfTheDsicountForCustomer
          : 0; /*cartSkuItems
        .map(
          (item) =>
            item.price !== 0 &&
            item.price >= 100 &&
            item.priceForOne - item.priceGap
        )
        .filter((item) => item !== false)
        .splice(1, cartSkuItems.length - 1)
        .reduce((a, c) => a + c, 0);*/

      /* const makeTheMostOfTheDsicountForCustomer = cartSkuItems
        .map(
          (item) =>
            item.price !== 0 &&
            item.price >= 100 &&
            item.priceForOne - item.priceGap
        )
        .filter((item) => item !== false)
        .sort((a, b) => a - b)
        .splice(1, cartSkuItems.length - 1)
        .reduce((a, c) => a + c, 0);*/

      // IF EXTRA DISCOUNT AMOUNT IS 0 THAN DELETE LOCAL STORAGE
      if (
        makeTheMostOfTheDsicountForCustomer - currentDsicountForCustomer ===
        0
      ) {
        localStorage.removeItem("extraDiscount");
        setDiscountCompansation(false);
      }

      if (
        makeTheMostOfTheDsicountForCustomer - currentDsicountForCustomer !==
          0 &&
        cartSkuItems.length > 0
        /*  cartSkuItems.length > 1 &&
        cartSkuItems[1].price !== 0 */
      ) {
        const discountAmount =
          makeTheMostOfTheDsicountForCustomer - currentDsicountForCustomer;

        const storedDiscountInfo = JSON.parse(
          localStorage.getItem("extraDiscount")
        );

        if (storedDiscountInfo) {
          setPrevDiscount(Number(storedDiscountInfo.discountAmount).toFixed(2));
        }

        if (
          !storedDiscountInfo ||
          Number(storedDiscountInfo.discountAmount) !== discountAmount
        ) {
          setDiscountCompansation(
            Number(
              makeTheMostOfTheDsicountForCustomer - currentDsicountForCustomer
            ).toFixed(2)
          );

          // setRunOnce(false);
        } else {
          setDiscountCompansation(false);
        }

        localStorage.setItem(
          "extraDiscount",
          JSON.stringify({
            sku:
              cartSkuItems &&
              cartSkuItems.length > 1 &&
              cartSkuItems[cartSkuItems.length - 1].price === 0
                ? cartSkuItems[cartSkuItems.length - 2].sku
                : cartSkuItems[cartSkuItems.length - 1].sku,
            discountAmount: discountAmount,
          })
        );
      }
    }
  }, [
    cartSkuItems,
    cartSkuItems.length,
    isPending?.isTherePending,
    createdFingerPrint?.isExtraDiscountShown,
    createdFingerPrint?.passedHoursAfterFirstVisit,
    stopDiscount,
    isDiscountValid,
  ]);

  // GET extraDiscount LOCAL STORAGE

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("extraDiscount"));

    if (storage && /*cartSkuItems.length > 1*/ cartSkuItems.length > 0) {
      setExtraDiscountAmount(Number(storage.discountAmount.toFixed(2)));
    }
  }, [cartSkuItems]);

  // GET PREV VALUE FOR CARTSKU ITEMS AND IF VALUE GOES DOWN SET EXTRA DISCOUNT TO ZERO
  /*  const [cartLength, setCartLength] = useState(cartSkuItems?.length);
  const cartStorageItemsLength = sessionStorage.getItem("cartSkuItemsLength");
  const cartStorageLength = sessionStorage.getItem("cartLength");

  useEffect(() => {
    if (cartSkuItems) {
      const cartSkuItemsLength = cartSkuItems.length;
      setCartLength((prevLength) =>
        prevLength > cartSkuItemsLength ? prevLength : cartSkuItemsLength
      );

      sessionStorage.setItem("cartSkuItemsLength", cartSkuItemsLength);
      sessionStorage.setItem("cartLength", cartLength);

      if (cartStorageItemsLength < cartStorageLength) {
        setExtraDiscountAmount(0);
        // setPrevDiscount(0)
        setDiscountCompansation(0);
        setTrigger(true);
        localStorage.removeItem("extraDiscount");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartSkuItems.length, cartLength]);*/

  return {
    discountCompansation: Number(discountCompansation),
    prevDiscount: Number(prevDiscount),
    extraDiscountAmount: stopDiscount ? 0 : extraDiscountAmount,
  };
}
