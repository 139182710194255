import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorMessage } from "../../../../../../utils/errorMessage";

// Create an asynchronous thunk using createAsyncThunk
export const actionToGetDetailsWholeSaleOrder = createAsyncThunk(
  "wholeSale/jobInActionToGetDetailsWholeSaleOrder",
  async (id, thunkAPI) => {
    // GET JWT TOKEN FROM REDUX STORE
    const token =
      thunkAPI.getState().sendSecurityCodeToSignIn.authenticatedUser.token;

    try {
      // Call your API function here
      const { data } = await axios.get(
        "/api/wholesale/getDetailsWholeSaleOrder",
        {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming the API response contains the data
      return data;
    } catch (error) {
      let { message } = errorMessage(error);

      throw new Error(message);
    }
  }
);

// Define an initial state for the slice
const initialState = {
  detailsWholeSaleOrder: null,
  loading: false,
  error: null,
};

// Create a slice using createSlice
const getDetailsWholeSaleOrderSlice = createSlice({
  name: "getDetailsWholeSaleOrder",
  initialState,
  reducers: {
    resetGetDetailsWholeSaleOrder: (state) => {
      state.detailsWholeSaleOrder = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionToGetDetailsWholeSaleOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionToGetDetailsWholeSaleOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsWholeSaleOrder = action.payload;
      })
      .addCase(actionToGetDetailsWholeSaleOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export reset method
const { resetGetDetailsWholeSaleOrder } = getDetailsWholeSaleOrderSlice.actions;

// Export the reducer
const reducerToGetDetailsWholeSaleOrder = getDetailsWholeSaleOrderSlice.reducer;

// Export the async action creator for use in components
export { reducerToGetDetailsWholeSaleOrder, resetGetDetailsWholeSaleOrder };
