import React from "react";
import { Link } from "react-router-dom";
import CountDownTimerVisibilityAware from "../../components/CountDownTimer/CountDownTimerVisibilityAware";
import { useGreetings } from "../../hooks/useGreetings";

export default function TopBarRender(props) {
  // PROPS COMING FROM TopBar
  const { topBar, isDiscountValid, remainingTime } = props;

  // Set greetings
  const greeting = useGreetings();

  return (
    <div className="topbar row-center-nowrap">
      {isDiscountValid && topBar.displayCountDownTimer ? (
        <CountDownTimerVisibilityAware
          milliseconds={remainingTime}
          topBarStyle={"topBarStyle"}
        />
      ) : topBar?.link !== "nolink" ? (
        <h1>
          <Link className="nodeco" to={topBar.link}>
            {topBar.title}
          </Link>
        </h1>
      ) : (
        <h1>{topBar.isGreeting ? greeting : topBar.title}</h1>
      )}
      {topBar.images.length > 0 &&
        topBar.images.map((image) => (
          <img
            key={image}
            style={{
              height: "3rem",
              width: "auto",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              margin: "0 0.1rem 0 0.1rem",
              border: "solid 1px green",
              borderRadius: "0.25rem",
            }}
            src={image}
            alt="beddo"
          />
        ))}
      <ul>
        {topBar.bullets &&
          topBar.bullets.length > 0 &&
          topBar.bullets.map((bullet) => (
            <li key={bullet} className="row-left-nowrap">
              <i className="fas fa-square fs-45"></i>
              <p>{bullet}</p>
            </li>
          ))}
      </ul>
    </div>
  );
}
