import {
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DELETE_REVIEW_FAIL,
  PRODUCT_DELETE_REVIEW_REQUEST,
  PRODUCT_DELETE_REVIEW_RESET,
  PRODUCT_DELETE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_REVIEW_FAIL,
  PRODUCT_DETAILS_REVIEW_REQUEST,
  PRODUCT_DETAILS_REVIEW_RESET,
  PRODUCT_DETAILS_REVIEW_SUCCESS,
  PRODUCT_EDIT_REVIEW_FAIL,
  PRODUCT_EDIT_REVIEW_REQUEST,
  PRODUCT_EDIT_REVIEW_RESET,
  PRODUCT_EDIT_REVIEW_SUCCESS,
  PRODUCT_LIST_REVIEW_FAIL,
  PRODUCT_LIST_REVIEW_REQUEST,
  PRODUCT_LIST_REVIEW_SUCCESS,
  REFUND_IMAGE_REVIEW_FAIL,
  REFUND_IMAGE_REVIEW_REQUEST,
  REFUND_IMAGE_REVIEW_RESET,
  REFUND_IMAGE_REVIEW_SUCCESS,
} from "../constants/reviewConstants";

//SAVE THE CREATED REVIEW INFO IN REDUX STATE
export const reducerProductToCreateReview = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
        result: action.payload,
        inUseMessage: action.payload.inUseMessage,
      };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE REVIEW DETAILS IN REDUX STATE
export const reducerProductToGetReviewDetails = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
        reviewDetails: action.payload,
      };
    case PRODUCT_DETAILS_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DETAILS_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

//SAVE EDITTED REVIEW INFO IN REDUX STATE
export function reducerProductToEditReview(state = { success: false }, action) {
  switch (action.type) {
    case PRODUCT_EDIT_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_EDIT_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case PRODUCT_EDIT_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_EDIT_REVIEW_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//SAVE THE REVIEW DELETE INFO IN REDUX STATE
export function reducerProductToDeleteReview(state = {}, action) {
  switch (action.type) {
    case PRODUCT_DELETE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case PRODUCT_DELETE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
}

//SAVE THE REVIEWS LIST IN REDUX STATE
export const reducerToGetReviewsList = (
  state = { loading: true, reviewsList: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REVIEW_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_LIST_REVIEW_SUCCESS:
      return { loading: false, reviewsList: action.payload };
    case PRODUCT_LIST_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//POST A REQUEST TO REFUND FOR IMAGE REVIEW
export const reducerToRefundForImageReview = (state = {}, action) => {
  switch (action.type) {
    case REFUND_IMAGE_REVIEW_REQUEST:
      return { loading: true };
    case REFUND_IMAGE_REVIEW_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case REFUND_IMAGE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case REFUND_IMAGE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};
