import {
  ADMIN_ORDER_CANCEL_FAIL,
  ADMIN_ORDER_CANCEL_PARTIALLY_FAIL,
  ADMIN_ORDER_CANCEL_PARTIALLY_REQUEST,
  ADMIN_ORDER_CANCEL_PARTIALLY_RESET,
  ADMIN_ORDER_CANCEL_PARTIALLY_SUCCESS,
  ADMIN_ORDER_CANCEL_PAYMENT_FAIL,
  ADMIN_ORDER_CANCEL_PAYMENT_REQUEST,
  ADMIN_ORDER_CANCEL_PAYMENT_SUCCESS,
  ADMIN_ORDER_CANCEL_REQUEST,
  ADMIN_ORDER_CANCEL_RESET,
  ADMIN_ORDER_CANCEL_SUCCESS,
  ADMIN_ORDER_DETAILS_FAIL,
  ADMIN_ORDER_DETAILS_REQUEST,
  ADMIN_ORDER_DETAILS_RESET,
  ADMIN_ORDER_DETAILS_SUCCESS,
  ADMIN_ORDER_DUPLICATE_ALERT_FAIL,
  ADMIN_ORDER_DUPLICATE_ALERT_REQUEST,
  ADMIN_ORDER_DUPLICATE_ALERT_RESET,
  ADMIN_ORDER_DUPLICATE_ALERT_SUCCESS,
  ADMIN_ORDER_LIST_FAIL,
  ADMIN_ORDER_LIST_REQUEST,
  ADMIN_ORDER_LIST_SUCCESS,
  ADMIN_ORDER_PAID_FAIL,
  ADMIN_ORDER_PAID_REQUEST,
  ADMIN_ORDER_PAID_RESET,
  ADMIN_ORDER_PAID_SUCCESS,
  ADMIN_ORDER_PENDING_SHIPPING_FAIL,
  ADMIN_ORDER_PENDING_SHIPPING_REQUEST,
  ADMIN_ORDER_PENDING_SHIPPING_SUCCESS,
  ADMIN_ORDER_PRNTED_FAIL,
  ADMIN_ORDER_PRNTED_REQUEST,
  ADMIN_ORDER_PRNTED_RESET,
  ADMIN_ORDER_PRNTED_SUCCESS,
  ADMIN_ORDER_SET_DELIVERED_FAIL,
  ADMIN_ORDER_SET_DELIVERED_REQUEST,
  ADMIN_ORDER_SET_DELIVERED_RESET,
  ADMIN_ORDER_SET_DELIVERED_SUCCESS,
  ADMIN_ORDER_SET_NOTE_FAIL,
  ADMIN_ORDER_SET_NOTE_REQUEST,
  ADMIN_ORDER_SET_NOTE_RESET,
  ADMIN_ORDER_SET_NOTE_SUCCESS,
  ADMIN_ORDER_SET_PAYMENT_METHOD_FAIL,
  ADMIN_ORDER_SET_PAYMENT_METHOD_REQUEST,
  ADMIN_ORDER_SET_PAYMENT_METHOD_RESET,
  ADMIN_ORDER_SET_PAYMENT_METHOD_SUCCESS,
  ADMIN_ORDER_SET_REFUND_DELIVERED_FAIL,
  ADMIN_ORDER_SET_REFUND_DELIVERED_REQUEST,
  ADMIN_ORDER_SET_REFUND_DELIVERED_RESET,
  ADMIN_ORDER_SET_REFUND_DELIVERED_SUCCESS,
  ADMIN_ORDER_SET_UNDELIVERED_FAIL,
  ADMIN_ORDER_SET_UNDELIVERED_REQUEST,
  ADMIN_ORDER_SET_UNDELIVERED_RESET,
  ADMIN_ORDER_SET_UNDELIVERED_SUCCESS,
  ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_FAIL,
  ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_REQUEST,
  ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_RESET,
  ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_SUCCESS,
  ADMIN_ORDER_SET_UPS_TRACKING_CODE_FAIL,
  ADMIN_ORDER_SET_UPS_TRACKING_CODE_REQUEST,
  ADMIN_ORDER_SET_UPS_TRACKING_CODE_RESET,
  ADMIN_ORDER_SET_UPS_TRACKING_CODE_SUCCESS,
  ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_FAIL,
  ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST,
  ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS,
  ADMIN_ORDER_UPDATE_COMPANY_INVOICE_FAIL,
  ADMIN_ORDER_UPDATE_COMPANY_INVOICE_REQUEST,
  ADMIN_ORDER_UPDATE_COMPANY_INVOICE_RESET,
  ADMIN_ORDER_UPDATE_COMPANY_INVOICE_SUCCESS,
  ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_FAIL,
  ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_REQUEST,
  ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_RESET,
  ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS,
  ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL,
  ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST,
  ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET,
  ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS,
  ADMIN_ORDER_UPDATE_PROFILE_FAIL,
  ADMIN_ORDER_UPDATE_PROFILE_REQUEST,
  ADMIN_ORDER_UPDATE_PROFILE_RESET,
  ADMIN_ORDER_UPDATE_PROFILE_SUCCESS,
  ADMIN_UPSELL_ORDER_DETAILS_FAIL,
  ADMIN_UPSELL_ORDER_DETAILS_REQUEST,
  ADMIN_UPSELL_ORDER_DETAILS_RESET,
  ADMIN_UPSELL_ORDER_DETAILS_SUCCESS,
} from "../constants/adminOrderConstants";

//SAVING ORDERS
export function reducerAdminOrderToListOrders(
  state = { loading: true, ordersList: [] },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_LIST_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        ordersList: action.payload.orders,
        countPendings: action.payload.countPendings,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ADMIN_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//SAVING ORDER DETAILS
export function reducerAdminOrderToGetOrderDetails(
  state = { loading: true, orderDetails: "" },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        orderDetails: action.payload,
      };
    case ADMIN_ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_DETAILS_RESET:
      return { loading: true, orderDetails: "" };
    default:
      return state;
  }
}

//SAVING UPSELL ORDER DETAILS
export function reducerAdminOrderToGetUpsellOrderDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case ADMIN_UPSELL_ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_UPSELL_ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        upsellOrderDetails: action.payload,
      };
    case ADMIN_UPSELL_ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPSELL_ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}

//ADMIN CANCEL AN UNPAID ORDER
export function reducerAdminOrderToCancelAnOrder(state = {}, action) {
  switch (action.type) {
    case ADMIN_ORDER_CANCEL_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_CANCEL_SUCCESS:
      return { loading: false, cancelledOrder: action.payload };
    case ADMIN_ORDER_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_CANCEL_RESET:
      return {};
    default:
      return state;
  }
}

//ADMIN CANCEL AN ORDER PARTIALLY (PRODUCT BY PRODUCT)
export function reducerAdminOrderToCancelAnOrderPartially(state = {}, action) {
  switch (action.type) {
    case ADMIN_ORDER_CANCEL_PARTIALLY_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_CANCEL_PARTIALLY_SUCCESS:
      return { loading: false, cancelledPartially: action.payload };
    case ADMIN_ORDER_CANCEL_PARTIALLY_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_CANCEL_PARTIALLY_RESET:
      return {};
    default:
      return state;
  }
}

//ADMIN CANCEL AN ORDER (AFTER PAYMENT)
export function reducerAdminOrderToCancelPaymentOrder(state = {}, action) {
  switch (action.type) {
    case ADMIN_ORDER_CANCEL_PAYMENT_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_CANCEL_PAYMENT_SUCCESS:
      return { loading: false, cancelledPaymentOrder: action.payload };
    case ADMIN_ORDER_CANCEL_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// ADMIN UPDATE CANCELLED PAYMENT INFO IN ORDER
export function reducerAdminOrderToUpdateCancelledPayment(state = {}, action) {
  switch (action.type) {
    case ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_SUCCESS:
      return { loading: false, updateCancelledPaymentOrder: action.payload };
    case ADMIN_ORDER_UPDATE_CANCELLED_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

//ADMIN UPDATES THE DELIVERY CONTACT INFORMATION
export function reducerAdminOrderToUpdateDeliveryContact(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedDeliveryContact: action.payload,
      };
    case ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_UPDATE_DELIVERY_CONTACT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//ADMIN UPDATES THE INDIVIDUAL INVOICE INFORMATION
export function reducerAdminOrderToUpdateIndividualInvoice(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedIndividualInvoice: action.payload,
      };
    case ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_UPDATE_INDIVIDUAL_INVOICE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//ADMIN UPDATES THE INDIVIDUAL INVOICE INFORMATION
export function reducerAdminOrderToUpdateCompanyInvoice(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_UPDATE_COMPANY_INVOICE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_UPDATE_COMPANY_INVOICE_SUCCESS:
      return {
        loading: false,
        success: true,
        updatedCompanyInvoice: action.payload,
      };
    case ADMIN_ORDER_UPDATE_COMPANY_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_UPDATE_COMPANY_INVOICE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

//MARK ORDER AS PRINTED
export function reducerAdminOrderToMarkOrderAsPrinted(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_PRNTED_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_PRNTED_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ORDER_PRNTED_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_PRNTED_RESET:
      return {};
    default:
      return state;
  }
}

//UPDATE ORDER AS PAID
export function reducerAdminOrderToUpdateOrderAsPaid(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_PAID_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_PAID_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ORDER_PAID_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_PAID_RESET:
      return {};
    default:
      return state;
  }
}

//SET ORDER PAYMENT METHOD
export function reducerAdminOrderToSetPaymentMethod(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_PAYMENT_METHOD_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_PAYMENT_METHOD_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ORDER_SET_PAYMENT_METHOD_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_PAYMENT_METHOD_RESET:
      return {};
    default:
      return state;
  }
}

// SET NOTE FOR THE ORDER
export function reducerAdminOrderToSetNote(state = { success: false }, action) {
  switch (action.type) {
    case ADMIN_ORDER_SET_NOTE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_NOTE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ORDER_SET_NOTE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_NOTE_RESET:
      return {};
    default:
      return state;
  }
}

//SET UPS TRACKING CODE FOR THE ORDER
export function reducerAdminOrderToSetUpsTrackingCode(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_UPS_TRACKING_CODE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_UPS_TRACKING_CODE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_ORDER_SET_UPS_TRACKING_CODE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_UPS_TRACKING_CODE_RESET:
      return {};
    default:
      return state;
  }
}

//SET UPS TRACKING CODE FOR THE REFUND ORDER
export function reducerAdminOrderToSetUpsTrackingCodeForRefundOrder(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_UPS_REFUND_TRACKING_CODE_RESET:
      return {};
    default:
      return state;
  }
}

//SET AN ORDER "DELIVERED"
export function reducerAdminOrderToSetDelivered(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_DELIVERED_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_DELIVERED_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_ORDER_SET_DELIVERED_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_DELIVERED_RESET:
      return {};
    default:
      return state;
  }
}

//SET AN ORDER "UNDELIVERED"
export function reducerAdminOrderToSetUndelivered(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_UNDELIVERED_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_UNDELIVERED_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_ORDER_SET_UNDELIVERED_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_UNDELIVERED_RESET:
      return {};
    default:
      return state;
  }
}

//SET A REFUND ORDER "DELIVERED"
export function reducerAdminOrderToSetRefundOrderDelivered(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_SET_REFUND_DELIVERED_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_SET_REFUND_DELIVERED_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case ADMIN_ORDER_SET_REFUND_DELIVERED_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_SET_REFUND_DELIVERED_RESET:
      return {};
    default:
      return state;
  }
}

// POSSIBLE DUPLICATE ORDER ALERT
export function reducerAdminToAlertDuplicateOrders(
  state = { duplicateFound: "" },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_DUPLICATE_ALERT_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_DUPLICATE_ALERT_SUCCESS:
      return {
        loading: false,
        duplicateFound: action.payload,
      };
    case ADMIN_ORDER_DUPLICATE_ALERT_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_DUPLICATE_ALERT_RESET:
      return { duplicateFound: "" };
    default:
      return state;
  }
}

// UPDATE PROFILE PHONE NUMBER
export function reducerAdminOrderToUpdateProfilePhone(
  state = { success: false },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_UPDATE_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_ORDER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ORDER_UPDATE_PROFILE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

// LIST OF ORDER ITEMS THAT ARE PENDING SHIPPING
export function reducerAdminOrderToListOrdersPendingShipping(
  state = { loading: true, ordersListPendingShipping: [] },
  action
) {
  switch (action.type) {
    case ADMIN_ORDER_PENDING_SHIPPING_REQUEST:
      return { loading: true };
    case ADMIN_ORDER_PENDING_SHIPPING_SUCCESS:
      return {
        loading: false,
        ordersListPendingShipping: action.payload,
      };
    case ADMIN_ORDER_PENDING_SHIPPING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
