import {
  // LIST_PARASUT_ACTIVITIES_FAIL,
  // LIST_PARASUT_ACTIVITIES_REQUEST,
  // LIST_PARASUT_ACTIVITIES_SUCCESS,
  MATERIALS_LIST_FAIL,
  MATERIALS_LIST_REQUEST,
  MATERIALS_LIST_SUCCESS,
  MATERIAL_CREATE_FAIL,
  MATERIAL_CREATE_REQUEST,
  MATERIAL_CREATE_RESET,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_DELETE_FAIL,
  MATERIAL_DELETE_REQUEST,
  MATERIAL_DELETE_RESET,
  MATERIAL_DELETE_SUCCESS,
  MATERIAL_DETAILS_FAIL,
  MATERIAL_DETAILS_REQUEST,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_EDIT_FAIL,
  MATERIAL_EDIT_REQUEST,
  MATERIAL_EDIT_RESET,
  MATERIAL_EDIT_SUCCESS,
  INVENTORY_ACTIVITY_CREATE_FAIL,
  INVENTORY_ACTIVITY_CREATE_REQUEST,
  INVENTORY_ACTIVITY_CREATE_RESET,
  INVENTORY_ACTIVITY_CREATE_SUCCESS,
  INVENTORY_ACTIVITY_DETAILS_FAIL,
  INVENTORY_ACTIVITY_DETAILS_REQUEST,
  INVENTORY_ACTIVITY_DETAILS_SUCCESS,
  INVENTORY_ACTIVITY_EDIT_FAIL,
  INVENTORY_ACTIVITY_EDIT_REQUEST,
  INVENTORY_ACTIVITY_EDIT_RESET,
  INVENTORY_ACTIVITY_EDIT_SUCCESS,
  INVENTORY_ACTIVITY_LIST_FAIL,
  INVENTORY_ACTIVITY_LIST_REQUEST,
  INVENTORY_ACTIVITY_LIST_SUCCESS,
  WAREHOUSE_CREATE_FAIL,
  WAREHOUSE_CREATE_REQUEST,
  WAREHOUSE_CREATE_RESET,
  WAREHOUSE_CREATE_SUCCESS,
  WAREHOUSE_DELETE_FAIL,
  WAREHOUSE_DELETE_REQUEST,
  WAREHOUSE_DELETE_RESET,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_DETAILS_FAIL,
  WAREHOUSE_DETAILS_REQUEST,
  WAREHOUSE_DETAILS_SUCCESS,
  WAREHOUSE_EDIT_FAIL,
  WAREHOUSE_EDIT_REQUEST,
  WAREHOUSE_EDIT_RESET,
  WAREHOUSE_EDIT_SUCCESS,
  WAREHOUSE_LIST_FAIL,
  WAREHOUSE_LIST_REQUEST,
  WAREHOUSE_LIST_SUCCESS,
  MATERIALS_LIST_RESET,
  MARK_INVENTORY_ACTIVITY_PRINTED_REQUEST,
  MARK_INVENTORY_ACTIVITY_PRINTED_SUCCESS,
  MARK_INVENTORY_ACTIVITY_PRINTED_FAIL,
  MARK_INVENTORY_ACTIVITY_PRINTED_RESET,
  LATEST_PRICING_INVENTORY_ACTIVITY_REQUEST,
  LATEST_PRICING_INVENTORY_ACTIVITY_SUCCESS,
  LATEST_PRICING_INVENTORY_ACTIVITY_FAIL,
  LATEST_PRICING_INVENTORY_ACTIVITY_RESET,
  DELETE_INVENTORY_ACTIVITY_REQUEST,
  DELETE_INVENTORY_ACTIVITY_SUCCESS,
  DELETE_INVENTORY_ACTIVITY_FAIL,
  DELETE_INVENTORY_ACTIVITY_RESET,
  INVENTORY_ACTIVITY_LIST_RESET,
  SET_IO_DATA,
} from "../constants/inventoryConstants";

/* CRUD OPERATIONS FOR MATERIALS: Materials are parts that are used to produce a product */

// @desc Create a material
export const reducerCreateMaterial = (state = { success: false }, action) => {
  switch (action.type) {
    case MATERIAL_CREATE_REQUEST:
      return { loading: true };
    case MATERIAL_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        material: action.payload,
      };
    case MATERIAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MATERIAL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// @desc List materials
export function reducerListMaterials(
  state = { loading: true, materialsList: [] },
  action
) {
  switch (action.type) {
    case MATERIALS_LIST_REQUEST:
      return { loading: true, ...state };
    case MATERIALS_LIST_SUCCESS:
      return {
        loading: false,
        materialsList: action.payload.materialsList,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case MATERIALS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MATERIALS_LIST_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Material details
export function reducerMaterialDetails(state = { loading: true }, action) {
  switch (action.type) {
    case MATERIAL_DETAILS_REQUEST:
      return { loading: true };
    case MATERIAL_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsMaterial: action.payload,
      };
    case MATERIAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Material edit
export function reducerMaterialEdit(state = { success: false }, action) {
  switch (action.type) {
    case MATERIAL_EDIT_REQUEST:
      return { loading: true };
    case MATERIAL_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MATERIAL_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case MATERIAL_EDIT_RESET:
      return { success: false };
    default:
      return state;
  }
}

// @desc delete material
export function reducerDeleteMaterial(state = {}, action) {
  switch (action.type) {
    case MATERIAL_DELETE_REQUEST:
      return { loading: true };
    case MATERIAL_DELETE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MATERIAL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case MATERIAL_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

/* CRUD OPERATIONS FOR WAREHOUSES: Warehouses are places where materials and products are kept. */

// @desc Create a warehouse
export const reducerCreateWarehouse = (state = { success: false }, action) => {
  switch (action.type) {
    case WAREHOUSE_CREATE_REQUEST:
      return { loading: true };
    case WAREHOUSE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        warehouse: action.payload,
      };
    case WAREHOUSE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WAREHOUSE_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// @desc List warehouses
export function reducerListWarehouses(
  state = { loading: true, warehousesList: [] },
  action
) {
  switch (action.type) {
    case WAREHOUSE_LIST_REQUEST:
      return { loading: true, ...state };
    case WAREHOUSE_LIST_SUCCESS:
      return {
        loading: false,
        warehousesList: action.payload,
      };
    case WAREHOUSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Warehouse details
export function reducerWarehouseDetails(state = { loading: true }, action) {
  switch (action.type) {
    case WAREHOUSE_DETAILS_REQUEST:
      return { loading: true };
    case WAREHOUSE_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsWarehouse: action.payload,
      };
    case WAREHOUSE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Warehouse edit
export function reducerWarehouseEdit(state = { success: false }, action) {
  switch (action.type) {
    case WAREHOUSE_EDIT_REQUEST:
      return { loading: true };
    case WAREHOUSE_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case WAREHOUSE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case WAREHOUSE_EDIT_RESET:
      return { success: false };
    default:
      return state;
  }
}

// @desc delete warehouse
export function reducerDeleteWarehouse(state = {}, action) {
  switch (action.type) {
    case WAREHOUSE_DELETE_REQUEST:
      return { loading: true };
    case WAREHOUSE_DELETE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case WAREHOUSE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case WAREHOUSE_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

/* CRUD OPERATIONS FOR INVENTORY ACTIVITIES: Inventory activities are the in and out of the inventory */

// @desc Create inventory activity
export const reducerCreateInventoryActivity = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case INVENTORY_ACTIVITY_CREATE_REQUEST:
      return { loading: true };
    case INVENTORY_ACTIVITY_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        activity: action.payload,
      };
    case INVENTORY_ACTIVITY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case INVENTORY_ACTIVITY_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// @desc List inventory activities
export function reducerListInventoryActivities(
  state = { loading: true, inventoryActivitiesList: [] },
  action
) {
  switch (action.type) {
    case INVENTORY_ACTIVITY_LIST_REQUEST:
      return { loading: true, ...state };
    case INVENTORY_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        inventoryActivitiesList: action.payload.inventoryActivities,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case INVENTORY_ACTIVITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case INVENTORY_ACTIVITY_LIST_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Warehouse activity details
export function reducerInventoryActivityDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case INVENTORY_ACTIVITY_DETAILS_REQUEST:
      return { loading: true };
    case INVENTORY_ACTIVITY_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsInventoryActivity: action.payload,
      };
    case INVENTORY_ACTIVITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Warehouse activity edit
export function reducerInventoryActivityEdit(
  state = { success: false },
  action
) {
  switch (action.type) {
    case INVENTORY_ACTIVITY_EDIT_REQUEST:
      return { loading: true };
    case INVENTORY_ACTIVITY_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case INVENTORY_ACTIVITY_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case INVENTORY_ACTIVITY_EDIT_RESET:
      return { success: false };
    default:
      return state;
  }
}

// @desc Get a list of paraşüt stock movement and create multiple warehouse activities based on the movements
/*export function reducerListParasutStockMovement(
  state = { loading: true, parasutStockMovements: [] },
  action
) {
  switch (action.type) {
    case LIST_PARASUT_ACTIVITIES_REQUEST:
      return { loading: true, ...state };
    case LIST_PARASUT_ACTIVITIES_SUCCESS:
      return {
        loading: false,
        parasutStockMovements: action.payload,
      };
    case LIST_PARASUT_ACTIVITIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}*/

// @desc Mark the inventory activities as printed
export function reducerMarkInventoryActivityPrinted(
  state = { success: false },
  action
) {
  switch (action.type) {
    case MARK_INVENTORY_ACTIVITY_PRINTED_REQUEST:
      return { loading: true };
    case MARK_INVENTORY_ACTIVITY_PRINTED_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case MARK_INVENTORY_ACTIVITY_PRINTED_FAIL:
      return { loading: false, error: action.payload };
    case MARK_INVENTORY_ACTIVITY_PRINTED_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Mark the inventory activities as printed
export function reducerFindMaterialLatestPricing(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case LATEST_PRICING_INVENTORY_ACTIVITY_REQUEST:
      return { loading: true };
    case LATEST_PRICING_INVENTORY_ACTIVITY_SUCCESS:
      return {
        loading: false,
        materialPricing: action.payload,
      };
    case LATEST_PRICING_INVENTORY_ACTIVITY_FAIL:
      return { loading: false, error: action.payload };
    case LATEST_PRICING_INVENTORY_ACTIVITY_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Delete an inventory activity
export function reducerDeleteInventoryActivity(state = {}, action) {
  switch (action.type) {
    case DELETE_INVENTORY_ACTIVITY_REQUEST:
      return { loading: true };
    case DELETE_INVENTORY_ACTIVITY_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case DELETE_INVENTORY_ACTIVITY_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_INVENTORY_ACTIVITY_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Get sales report inventory activity
export const reducerSalesReport = (state = { ioData: null }, action) => {
  switch (action.type) {
    case SET_IO_DATA:
      return {
        ...state,
        ioData: action.payload, // Update the ioData with the received data
      };
    // Other cases...
    default:
      return state;
  }
};
