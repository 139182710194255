import { useEffect, useState } from "react";
import { vatCalculator } from "../../../../libraries/ercLibrary";
import WarningCartTotalPlusPrice from "../../components/freeGoods/WarningCartTotalPlusPrice";
import WarningPrice from "../../components/freeGoods/WarningPrice";
import WarningCartTotalPlusTotalPrice from "../../components/freeGoods/WarningCartTotalPlusTotalPrice";
import { useScrollRestorationInPop } from "../../../../hooks/useScrollRestorationInPop";
import WarningPriceAndQty from "../../components/freeGoods/WarningPriceAndQty";

export function useFreeGoodsAllocation(
  qty,
  setQty,
  price,
  product,
  cartTotalVatExcluded,
  multiSku,
  updatedWholeSaleOrderWithFreeGoods
) {
  const order = JSON.parse(localStorage.getItem("orderHasFreeGoodsAllocation"));

  // ORDER THAT HAS FREE GOODS ALLOCATION
  const [freeGoodsAllocationActive, setFreeGoodsAllocationActive] =
    useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [freeGoodsWarning, setFreeGoodsWarning] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const [elementId, setElementId] = useState(null);
  const [allowAddToCart, setAllowAddToCart] = useState(true);

  // SCROLL
  useScrollRestorationInPop(elementId);

  // CHECK IF THERE IS AN ORDER WITH FREE GOODS ALLOCATION
  useEffect(() => {
    if (updatedWholeSaleOrderWithFreeGoods) {
      setFreeGoodsAllocationActive(null);
    } else if (order) {
      setFreeGoodsAllocationActive(order.freeGoodsAllocation);
      setOrderNumber(order.orderNumber);
    }
  }, [order, updatedWholeSaleOrderWithFreeGoods]);

  // CHECK IF QTY IS CHOOSEN AND IF CHOOSEN MAKE SURE IT DOESNT EXCEED THE freeGoodsAllocation LIMIT
  useEffect(() => {
    const numQty = Number(qty);
    const vatExcludedPrice =
      price && Number(vatCalculator(product.defaultVat, price, "excluded"));
    const totalPrice = numQty * vatExcludedPrice;

    // FIRST CONDITION MAKES SURE THAT THERE IS freeGoodsAllocation TO LET USER SPEND
    if (freeGoodsAllocationActive) {
      // THAN NEXT BATCH OF CONDITIONS MAKE SURE USER SPEND NO MORE THAN freeGoodsAllocation
      if (vatExcludedPrice > freeGoodsAllocationActive) {
        setFreeGoodsWarning(
          <WarningPrice
            orderNumber={orderNumber}
            setShowDetails={setShowDetails}
            setElementId={setElementId}
            vatExcludedPrice={vatExcludedPrice}
            freeGoodsAllocationActive={freeGoodsAllocationActive}
          />
        );
        setAllowAddToCart(false);
      } else if (
        cartTotalVatExcluded > 0 &&
        cartTotalVatExcluded + vatExcludedPrice > freeGoodsAllocationActive
      ) {
        setFreeGoodsWarning(
          <WarningCartTotalPlusPrice
            orderNumber={orderNumber}
            setShowDetails={setShowDetails}
            setElementId={setElementId}
            cartTotalVatExcluded={cartTotalVatExcluded}
            vatExcludedPrice={vatExcludedPrice}
            freeGoodsAllocationActive={freeGoodsAllocationActive}
          />
        );
        setAllowAddToCart(false);
      } else if (
        cartTotalVatExcluded > 0 &&
        cartTotalVatExcluded + totalPrice > freeGoodsAllocationActive
      ) {
        const newQty = numQty > 1 ? numQty - 1 : 0;

        if (newQty === 0) {
          setFreeGoodsWarning(
            <WarningCartTotalPlusTotalPrice
              orderNumber={orderNumber}
              setShowDetails={setShowDetails}
              setElementId={setElementId}
              cartTotalVatExcluded={cartTotalVatExcluded}
              vatExcludedPrice={vatExcludedPrice}
              totalPrice={totalPrice}
              numQty={numQty}
              freeGoodsAllocationActive={freeGoodsAllocationActive}
            />
          );
          setAllowAddToCart(false);
        } else if (newQty > 0) {
          setFreeGoodsWarning(
            <WarningCartTotalPlusTotalPrice
              orderNumber={orderNumber}
              setShowDetails={setShowDetails}
              setElementId={setElementId}
              cartTotalVatExcluded={cartTotalVatExcluded}
              vatExcludedPrice={vatExcludedPrice}
              totalPrice={totalPrice}
              numQty={numQty}
              newQty={newQty}
              freeGoodsAllocationActive={freeGoodsAllocationActive}
              product={product}
            />
          );
          setQty(newQty);
        }
      } else if (qty > 0 && vatExcludedPrice && freeGoodsAllocationActive) {
        if (totalPrice > freeGoodsAllocationActive) {
          const newQty = parseInt(freeGoodsAllocationActive / vatExcludedPrice);

          setFreeGoodsWarning(
            <WarningPriceAndQty
              orderNumber={orderNumber}
              qty={qty}
              newQty={newQty}
              totalPrice={totalPrice}
              vatExcludedPrice={vatExcludedPrice}
              freeGoodsAllocationActive={freeGoodsAllocationActive}
              product={product}
              setShowDetails={setShowDetails}
              setElementId={setElementId}
            />
          );
          setQty(newQty);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty, price, freeGoodsAllocationActive, multiSku]);

  return {
    setFreeGoodsAllocationActive,
    setFreeGoodsWarning,
    setAllowAddToCart,
    freeGoodsAllocationActive,
    orderNumber,
    freeGoodsWarning,
    showDetails,
    allowAddToCart,
  };
}
