import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ErcModal from "../../../shared/components/ErcModal";
import useWidth from "../../../../hooks/useWidth";
import { useDispatch } from "react-redux";
import { resetModal } from "../../actions/actionToToggleErcModal";
import ItemList from "../../wholeSale/cart/components/ItemList";
import CartFooter from "../../wholeSale/cart/components/CartFooter";
import { resetItemAddedInCart } from "../../wholeSale/cart/actions/actionToAddOrRemoveWholeSaleItemInCart";

export default function NavigationBottom(props) {
  const history = useHistory();
  const location = useLocation().pathname;
  const width = useWidth();
  const dispatch = useDispatch();

  // PROPS COMING FROM AppWholeSaleUser
  const {
    freeGoodsAllocationActive,
    orderNumber,
    wholeSaleCartItems,
    cartQuantity,
    blinkAgain,
    setBlinkAgain,
    limits,
    cartTotalVatExcluded,
    isModalOpen,
    privatePricing,
  } = props;

  const styling = {
    borderTop: "1px solid grey",
    backgroundColor: "#f5f7fa",
    width: "100%",
    height: "4rem",
    position: "fixed",
    bottom: "0",
    zIndex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  };

  // STATE TO CONTROL add another product BUTTON
  const [closeOnClick, setCloseOnClick] = useState(false);

  return (
    <>
      {width <= 600 && (
        <div style={styling}>
          <div className="w25p h100p col">
            <i
              className={`fs15 fas fa-user-circle ${
                location === "/toptan-hesabi" ? "beddo-color" : "green"
              }  marb-q pointer `}
              onClick={() => history.push("/toptan-hesabi")}
            ></i>
            <p className={location === "/toptan-hesabi" ? "beddo-color" : ""}>
              Hesabım
            </p>
          </div>
          <div className="w25p h100p col">
            <i
              className={`fs125 fas fa-store ${
                location === "/toptan-urunler" ? "beddo-color" : "green"
              }  marb-q pointer `}
              onClick={() => history.push("/toptan-urunler")}
            ></i>
            <p className={location === "/toptan-urunler" ? "beddo-color" : ""}>
              Mağaza
            </p>
          </div>
          <div className="w25p h100p col">
            <ErcModal
              modalTitle={() => (
                <>
                  <i
                    title={
                      cartQuantity > 0
                        ? `Sepetinizde ${cartQuantity} ürün var`
                        : "Sepetiniz boş"
                    }
                    className={`relative fs15 fad fa-shopping-cart green pointer ${
                      blinkAgain && "blink-effect-3"
                    }`}
                    onAnimationEnd={() => setBlinkAgain(false)}
                  >
                    <p
                      style={{
                        position: "absolute",
                        left: "1.5rem",
                        color: "red",
                      }}
                    >
                      {cartQuantity > 0 && cartQuantity}
                    </p>
                  </i>

                  <p>Sepetim</p>
                </>
              )}
              modalFrontBackgroundColor={
                freeGoodsAllocationActive > 0 && "#E6FFE6"
              }
              modalTitleFontSize={"0.65rem"}
              modalTitleLineHeight={"0.85rem"}
              modalFrontHeight={width < 600 ? "70%" : "95%"}
              contentTitleFontSize={"1.25rem"}
              contentTitleMargin={"1rem"}
              modalParagBorderBottom={""}
              modalParagFontSize={""}
              modalContentMargin={"1rem 1rem 6rem 1rem"}
              modalContentHeight={"70vh"}
              contentTitle={
                cartQuantity > 0 ? "Toptan Sepetiniz" : "Sepetiniz Boş"
              }
              content={[
                <ItemList
                  freeGoodsAllocationActive={freeGoodsAllocationActive}
                  wholeSaleCartItems={wholeSaleCartItems}
                  privatePricing={privatePricing}
                />,
              ]}
              positionFixedContent={[
                <CartFooter
                  wholeSaleCartItems={wholeSaleCartItems}
                  setCloseOnClick={setCloseOnClick}
                  freeGoodsAllocationActive={freeGoodsAllocationActive}
                  orderNumber={orderNumber}
                  limits={limits}
                  cartTotalVatExcluded={cartTotalVatExcluded}
                  privatePricing={privatePricing}
                />,
              ]}
              openOnClick={isModalOpen}
              closeOnClick={closeOnClick}
              clickOutsideShouldWork={false}
              callThisFunctionInCloseModalFunction={() => {
                return [
                  dispatch(resetModal()),
                  dispatch(resetItemAddedInCart()),
                  setCloseOnClick(false),
                ];
              }}
            />
          </div>
          <div className="w25p h100p col">
            <ErcModal
              modalTitle={() => (
                <div className="w100p col">
                  <i className="fs125 fal fa-list-alt green pointer"></i>
                  <p>Kategoriler</p>
                </div>
              )}
              modalTitleFontSize={"0.65rem"}
              modalTitleLineHeight={"0.85rem"}
              contentTitle={"Kategoriler hazırlanıyor"}
              contentTitleFontSize={"1.5rem"}
              content={[""]}
              modalParagBorderBottom={""}
              modalParagFontSize={""}
              clickOutsideShouldWork={false}
            />
          </div>
        </div>
      )}
    </>
  );
}
