import React, { useEffect, useState } from "react";

export default function LoadingProducts({ content }) {
  const [colorsArr, setColorsArr] = useState([]);

  useEffect(() => {
    if (content) {
      const delay = 500; // Adjust the delay time in milliseconds
      const colors = content.split("").map((letter, index) => {
        const redValue = 255; // Red component stays constant
        const greenValue = 255 - index * 20; // Decrease green for transition
        const blueValue = 0; // No blue for yellow to red transition

        // Set timeout for each letter's coloring
        setTimeout(() => {
          const updatedColors = [...colorsArr];
          updatedColors[
            index
          ] = `rgb(${redValue}, ${greenValue}, ${blueValue})`;
          setColorsArr(updatedColors);
        }, delay * index);

        return "transparent"; // Initially set color to transparent
      });

      setColorsArr(colors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div className="w100p row-center marupside1 inline lightbeddobck">
      <p className="fs1 transparent">
        {content.split("").map((letter, index) => (
          <span
            key={index}
            style={{
              transition: "color 0.3s ease",
              color:
                colorsArr[index] === "rgb(255, -85, 0)"
                  ? "black"
                  : colorsArr[index],
            }}
          >
            {letter}
          </span>
        ))}
      </p>
      <i className="fs1 fa fa-spinner fa-spin marq"></i>
    </div>
  );
}
