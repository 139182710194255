// CREATE A TOPBAR
export const ADMIN_CREATE_TOPBAR_REQUEST = "ADMIN_CREATE_TOPBAR_REQUEST";
export const ADMIN_CREATE_TOPBAR_SUCCESS = "ADMIN_CREATE_TOPBAR_SUCCESS";
export const ADMIN_CREATE_TOPBAR_FAIL = "ADMIN_CREATE_TOPBAR_FAIL";
export const ADMIN_CREATE_TOPBAR_RESET = "ADMIN_CREATE_TOPBAR_RESET";

// LIST TOPBARS
export const LIST_TOPBARS_REQUEST = "LIST_TOPBARS_REQUEST";
export const LIST_TOPBARS_SUCCESS = "LIST_TOPBARS_SUCCESS";
export const LIST_TOPBARS_FAIL = "LIST_TOPBARS_FAIL";

// EDIT A TOPBAR
export const ADMIN_EDIT_TOPBAR_REQUEST = "ADMIN_EDIT_TOPBAR_REQUEST";
export const ADMIN_EDIT_TOPBAR_SUCCESS = "ADMIN_EDIT_TOPBAR_SUCCESS";
export const ADMIN_EDIT_TOPBAR_FAIL = "ADMIN_EDIT_TOPBAR_FAIL";
export const ADMIN_EDIT_TOPBAR_RESET = "ADMIN_EDIT_TOPBAR_RESET";

// GET DETAILS OF TOPBAR
export const ADMIN_DETAILS_TOPBAR_REQUEST = "ADMIN_DETAILS_TOPBAR_REQUEST";
export const ADMIN_DETAILS_TOPBAR_SUCCESS = "ADMIN_DETAILS_TOPBAR_SUCCESS";
export const ADMIN_DETAILS_TOPBAR_FAIL = "ADMIN_DETAILS_TOPBAR_FAIL";
