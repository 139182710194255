export function calculateItemsTotalPriceInCartAction(
  timeLimitedDiscount,
  data,
  isThereApendingOrder,
  free,
  qty,
  location,
  cartLength,
  existItem,
  indexOfExistItem,
  isExistItemBuyOneGetOneFree
) {
  const numQty = Number(qty);
  const noDiscount = !timeLimitedDiscount && isThereApendingOrder !== "YES";
  const upsellLocationDiscount = location === "upsellLocation" ? 5 * numQty : 0;

  /*console.log("free " + free);
  console.log("noDiscount " + noDiscount);
  console.log("location " + location);
  console.log("existItem?.qty " + existItem?.qty);
  console.log("indexOfExistItem " + indexOfExistItem);
  console.log("numQty " + numQty);
  console.log("upsellLocationDiscount " + upsellLocationDiscount);
  console.log("index " + index);
  console.log("timeLimitedDiscount " + timeLimitedDiscount);
  console.log("cartLength " + cartLength);
  console.log("isThereApendingOrder " + isThereApendingOrder);
  console.log(
    "data.skus[0].sku === cartSkuItems[0].sku " + data.skus[0]?.sku ===
      cartSkuItems[0]?.sku
  );*/

  const totalPrice = free
    ? // IF FREE RETURN "ZERO"
      0
    : // IF FREE PROVIDER "PRICEGAP * QTY"
    isExistItemBuyOneGetOneFree
    ? data.skus[0].priceGap * numQty
    : // NO DISCOUNT CONDITIONS
    noDiscount && location === "cartScreenQtySelection"
    ? (data.skus[0]?.price || data.price) * numQty
    : noDiscount
    ? (data.skus[0]?.price || data.price) *
      ((Number(existItem?.qty) || 0) + numQty)
    : // GRANDUPSELL CONDITIONS
    isThereApendingOrder === "YES" && location === "cartScreenQtySelection" // This criteria is shared by both grandUpsell and upsell
    ? data.skus[0].priceGap * numQty - upsellLocationDiscount
    : isThereApendingOrder === "YES" // This criteria is shared by both grandUpsell and upsell
    ? data.skus[0].priceGap * ((Number(existItem?.qty) || 0) + numQty) -
      upsellLocationDiscount
    : // TIME LIMITED DISCOUNT CONDITION - 1 - QTY SELECT IN CART - FIRST IN CART AND QTY 1
    timeLimitedDiscount &&
      location === "cartScreenQtySelection" &&
      indexOfExistItem === 0 &&
      numQty === 1
    ? data.skus[0].price - timeLimitedDiscount
    : // TIME LIMITED DISCOUNT CONDITION - 2 - QTY SELECT IN CART - FIRST IN CART AND QTY > 1
    timeLimitedDiscount &&
      location === "cartScreenQtySelection" &&
      indexOfExistItem === 0 &&
      numQty > 1
    ? data.skus[0].price -
      timeLimitedDiscount +
      data.skus[0].priceGap * (numQty - 1)
    : // TIME LIMITED DISCOUNT CONDITION - 3 - QTY SELECT IN CART - NOT FIRST
    timeLimitedDiscount &&
      location === "cartScreenQtySelection" &&
      indexOfExistItem > 0
    ? data.skus[0].priceGap * numQty
    : // TIME LIMITED DISCOUNT CONDITION - 4 - FIRST IN CART and NO EXIST ITEM
    timeLimitedDiscount && !existItem && cartLength === 0 && numQty === 1
    ? data.skus[0].price - timeLimitedDiscount
    : timeLimitedDiscount && !existItem && cartLength === 0 && numQty > 1
    ? data.skus[0].price -
      timeLimitedDiscount +
      data.skus[0].priceGap * (numQty - 1)
    : // TIME LIMITED DISCOUNT CONDITION - 5 - MULTIPLE ITEMS IN CART and NO EXIST ITEM
    timeLimitedDiscount && !existItem && cartLength > 0
    ? data.skus[0].priceGap * numQty
    : // TIME LIMITED DISCOUNT CONDITION - 6 - FIRST IN CART and EXIST ITEM
    timeLimitedDiscount && existItem && indexOfExistItem === 0
    ? data.skus[0].price -
      timeLimitedDiscount +
      data.skus[0].priceGap * (Number(existItem?.qty) + numQty - 1)
    : // TIME LIMITED DISCOUNT CONDITION - 7 - MULTIPLE ITEMS IN CART and EXIST ITEM
    timeLimitedDiscount && existItem && indexOfExistItem > 0
    ? data.skus[0].priceGap * ((Number(existItem?.qty) || 0) + numQty)
    : "";

  return totalPrice;
}
