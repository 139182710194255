import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

const initialState = {
  isConnected: false,
  loading: false,
  started: "",
  count: "",
  result: [],
  socketError: "",
};

// Async thunk to dispatch form data to the server via a socket connection
export const requestConversionDataBySocket = createAsyncThunk(
  "socket/requestConversionDataBySocket",
  async (formData) => {
    try {
      // Get the socket connection
      const socket = io();

      // Emit the form data through the socket
      socket.emit("requestConversionDataBySocket", formData);

      // Wrap the event listener inside a Promise
      const receivedData = await new Promise((resolve, reject) => {
        socket.on("requestConversionDataBySocketResponse", (data) => {
          // Resolve the Promise with the received data
          resolve(data);
        });

        // If there is no result, emit an error event to the client and reject the Promise
        socket.on("requestConversionDataBySocketError", (error) => {
          // Reject the Promise with the error object
          reject(new Error(error));
        });
      });

      // Return the received data
      return receivedData;
    } catch (error) {
      // Handle any errors that occur during the socket connection or data transmission
      throw new Error(error.message);
    }
  }
);

const socketSlice = createSlice({
  name: "socket",
  initialState,
  extraReducers: (builder) => {
    // Handle the result of requestConversionDataBySocket async thunk
    builder.addCase(requestConversionDataBySocket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestConversionDataBySocket.fulfilled,
      (state, action) => {
        state.loading = false;
        state.result = action.payload;
      }
    );
    builder.addCase(requestConversionDataBySocket.rejected, (state, action) => {
      state.loading = false;
      state.socketError = action.error.message;
    });
  },
});

// Export the socket reducer
export const socketSliceReducer = socketSlice.reducer;
